import { Card, CardHeader, Icon, Typography } from '@mui/material';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function QuestionAndAnswersList(props) {
  const { questions, answers } = props;

  const renderQuestionAnswers = () => {
    const list = questions.map((singleQuestion) => {
      const result = answers.find((obj) => {
        return obj.question_id === singleQuestion.id;
      });
      return { ...singleQuestion, givenAnswer: result.given_answer };
    });

    return (
      <>
        <Card sx={{ overflowWrap: 'anywhere' }}>
          <CardHeader title="Questions and Your Given Answers" />
          <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {list.map((singleList, index) => (
                <ListItem alignItems="flex-start" key={singleList.id}>
                  <ListItemText
                    primary={`${index + 1}. ${singleList.title}`}
                    sx={{ color: '#233c8d' }}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {singleList.givenAnswer === 'not given' ? (
                            <span>
                              <strong> Answer Not Given </strong>
                            </span>
                          ) : null}
                        </Typography>
                        {singleList.options.length ? (
                          singleList.options.map((option, i) => {
                            return (
                              <>
                                {option.isCorrect ? (
                                  <>
                                    <Typography sx={{ pl: 4 }}>
                                      {i + 1}. {option.optionText}
                                      &nbsp;
                                      <Icon color="success">
                                        <CheckCircleIcon twoToneColor="#52c41a" />
                                      </Icon>
                                      {singleList.givenAnswer ===
                                      option.optionText ? (
                                        <span>
                                          <strong> Given Answer </strong>
                                        </span>
                                      ) : null}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography sx={{ pl: 4 }}>
                                      {i + 1}. {option.optionText}
                                      &nbsp;
                                      <Icon color="error">
                                        <CancelIcon twoToneColor="#eb2f96" />
                                      </Icon>
                                      {singleList.givenAnswer ===
                                      option.optionText ? (
                                        <span>
                                          <strong> Given Answer </strong>
                                        </span>
                                      ) : null}
                                    </Typography>
                                  </>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <Typography>
                            No Options found in &nbsp;
                            {singleList.title}
                          </Typography>
                        )}{' '}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Card>
      </>
    );
  };

  return <>{renderQuestionAnswers()}</>;
}

export default QuestionAndAnswersList;
