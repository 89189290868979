/* eslint-disable no-unused-vars */

import { Grid, Tab, Tabs } from '@mui/material';
import React from 'react';
import EnrolledCourseTabPanel from '../courses/student/EnrolledCourseTabPanel';
import EducatorFollower from './EducatorFollower';
import InstitutionFollower from './InstitutionFollower';
import StudentFollower from './StudentFollower';

function UserFollowers(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid
          item
          xl={props.itemxl ? props.itemxl : '10'}
          lg={props.itemlg ? props.itemlg : '10'}
          md={props.itemmd ? props.itemmd : '11'}
          sm={props.itemsm ? props.itemsm : '11'}
          xs={props.itemxs ? props.itemxs : '11'}
        >
          <Tabs
            value={value}
            onChange={(e, newval) => handleChange(e, newval)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant={window.screen.width < 600 ? `scrollable` : `standard`}
          >
            <Tab
              id="one"
              label="Students"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="one"
              label="Educators"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="one"
              label="Institutions"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
          </Tabs>{' '}
          <EnrolledCourseTabPanel value={value} index={0}>
            <StudentFollower />
          </EnrolledCourseTabPanel>
          <EnrolledCourseTabPanel value={value} index={1}>
            <EducatorFollower />
          </EnrolledCourseTabPanel>{' '}
          <EnrolledCourseTabPanel value={value} index={2}>
            <InstitutionFollower />
          </EnrolledCourseTabPanel>
        </Grid>
      </Grid>
    </>
  );
}
export default UserFollowers;
