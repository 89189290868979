import { Button, Box, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useUpdateTestTopicMutation } from '../../../../../app/services/TestsApi';

function UpdateTestTopic(props) {
  const { testTopic } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [UpdateTestTopic, result] = useUpdateTestTopicMutation();

  const validateData = (testTopicData) => {
    let err = true;

    if (!testTopicData.title) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const testTopicData = {
      id: testTopic.id,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(testTopicData);
    if (!res) {
      UpdateTestTopic(testTopicData);
    }
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          defaultValue={testTopic.title}
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          defaultValue={testTopic.description}
          rows="2"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Test Topic
        </Button>
      </Box>
    </>
  );
}

export default UpdateTestTopic;
