/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const BaseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    headers: { 'content-type': 'application/json' },
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().auth.token ||
        localStorage.getItem(`${LOCALSTORAGE_APP_KEY}-token`);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    'Category',
    'SubCategory',
    'Exam',
    'Question',
    'Course',
    'Subject',
    'Topic',
    'Videos',
    'Test',
    'Course Enrol',
    'Exam Enrol',
    'Practice Test',
    'Test',
    'TestSubject',
    'TestTopic',
    'TestQuestion',
    'SubscriptionPlan',
    'Home',
    'Grade',
    'Grade Subject',
    'Publication',
    'Keyword',
    'Profile',
    'Learning Objective',
    'Followers',
    'Following',
    'Course Reviews',
    'Exam Reviews',
    'Test Reviews',
    'Publication Edition',
    'Educator Profile',
    'Educator Following',
    'Institution Following',
    'Student Following',
    'Educator Follower',
    'Institution Follower',
    'Student Follower',
    'Public Course',
    'Video Violations',
    'Course Violations',
    'Educator Channel',
    'EducatorSubscriptionPlan',
    'Documents',
    'Support Ticket',
    'Educator BankInfo',
    'CourseExam',
    'Course Exam Enrol',
    'EducatorSubscription',
    'Educator Earning',
    'EducatorWithdrawal',
  ],
  endpoints: () => ({}),
});
