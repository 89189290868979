/* eslint-disable no-unused-vars */

import {
  Button,
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Alert,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  usePublicPublicationsQuery,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';
import {
  useCreateCourseMutation,
  useUpdateCourseSatusMutation,
} from '../../../app/services/CoursesApi';
import { useSnackbar } from 'notistack';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { uploadCourseImageRequest } from '../../../app/services/UploadRequests';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DisplayCoursePreview from './DisplayCoursePreview';
import { allLanguages } from '../../../Uitils';
import { EditCourse } from './EditCourse';
import HlsJs from '../HlsJs';
import AddCourseSubject from './AddCourseSubject';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

const steps = [
  'Create Course',
  'Progress',
  'Curriculum',
  'Preview',
  'Publish Status',
];

function NewCreateCourse() {
  const { enqueueSnackbar } = useSnackbar();
  const { token, isSignedIn } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get('courseId');
  const [updateCourseData, setUpdateCourseData] = useState({});

  // const finalNewCourse = useSelector((state) => state.course);
  const getCreatedCourseDetails = async (query) => {
    axios
      .get(`${API_URL}/course/show/${query}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data) {
          setUpdateCourseData(res?.data);
          setCourseCostType(res?.data?.course?.cost ? 'paid' : 'free');
          if (res?.data?.course?.cost) {
            setCourseCost(res?.data?.course?.cost);
          }
        }
      });
  };
  useEffect(() => {
    setCourseId(queryParamValue);
    // getCreatedCourseDetails(queryParamValue);
  }, [queryParamValue]);
  const { data } = usePublishedCategoriesQuery();
  const publicationsRes = usePublicPublicationsQuery();
  const [courseId, setCourseId] = useState(null);
  const [createCourse] = useCreateCourseMutation();
  const [courseStatus, setCourseStatus] = useState(false);
  const [imageStatus, setImageStatus] = useState(false);
  const [videoStatus, setVideoStatus] = useState(false);
  const [video, setVideo] = useState(null);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [categoryTitle, setCategoryTitle] = useState('');

  const [subCategoryTitle, setSubCategoryTitle] = useState('');
  const [gradeTitle, setGradeTitle] = useState('');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState('');

  const [publicationTitle, setPublicationTitle] = useState('');
  const [publicationEditionTitle, setPublicationEditionTitle] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [vidUrl, setVideoUrl] = useState(null);
  const [duration, setSetDuration] = useState(0);
  const videoRef = useRef();
  const [processProgress, setProcessProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const [subjects, setSubjects] = useState(false);
  const [courseCostType, setCourseCostType] = useState(null);
  const [courseCost, setCourseCost] = useState(null);
  const allowedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];

  const [updateCourseStatus] = useUpdateCourseSatusMutation();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedVideo) {
      setVideoUrl(URL.createObjectURL(selectedVideo));
    }
    videoRef.current?.load();
  }, [selectedVideo]);

  const loadedMetaData = () => {
    const video = videoRef.current;
    if (!video) return;

    setSetDuration(Math.floor(video.duration));
  };

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const [selectedGrade, setSelectedGrade] = useState({
    title: '',
    id: null,
  });

  const [selectedGradeSubject, setSelectedGradeSubject] = useState({
    title: '',
    id: null,
  });

  const [selectedPublication, setSelectedPublication] = useState({
    title: '',
    id: null,
  });

  const [selectedPublicationEdition, setSelectedPublicationEdition] = useState({
    edition: '',
    id: null,
  });

  const [level, setLevel] = useState('beginner');
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (courseId) {
      getCreatedCourseDetails(courseId);
    }
  }, [activeIndex]);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const validateData = () => {
    let err = true;
    if (title.trim().length === 0 || description.trim().length === 0) {
      enqueueSnackbar(
        'Title or Description cannot be empty or contain only spaces',
        {
          variant: 'error',
        }
      );
      return err;
    }
    if (!title || !selectedSubCategory.id || !language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };
  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/video/upload/${uploadData.courseId}`,
      {
        file: uploadData.file,
        file_type: 'course',
        title: title,
        description: description,
        duration: uploadData.duration,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };

  const handleSubmit = () => {
    let courseData = {
      title: title,
      description: description,
      sub_category_id: selectedSubCategory.id,
      language: language,
      grade_id: selectedGrade.id,
      publication_id: selectedPublication.id,
      grade_subject_id: selectedGradeSubject.id,
      publication_edition_id: selectedPublicationEdition.id,
      level: level,
    };
    if (courseCostType === 'paid') {
      courseData = { ...courseData, cost: courseCost };
    }
    if (courseCostType === 'paid') {
      if (courseCost <= 1) {
        enqueueSnackbar('Cost should be greater than 1', {
          variant: 'error',
        });
        return;
      }
    }
    createCourse(courseData)
      .unwrap()
      .then((res) => {
        setCourseStatus('success');
        setCourseId(res.course.id);

        if (res.course.id && selectedImage) {
          const UploadData = {
            file: selectedImage,
            token: token,
          };

          uploadCourseImageRequest(UploadData, res.course.id)
            .then((response) => {
              if (response.url) {
                setImageStatus('success');
              } else {
                setImageStatus('error');
              }
            })
            .catch((err) => {
              setImageStatus('error');
            });
        } else {
          setImageStatus('error');
        }
        if (res.course.id && selectedVideo) {
          const videoUploadData = {
            file: selectedVideo,
            token: token,
            courseId: res.course.id,
            title: title,
            description: description,
            duration: duration,
          };

          fileUploadRequest(videoUploadData)
            .then((res) => {
              if (res.data.video.id) {
                setVideoStatus('success');
                setVideo(res.data.video);
                setProcessProgress(100);
                enqueueSnackbar('Video Uploaded Successfully.', {
                  variant: 'success',
                });
              } else {
                setVideoStatus('error');
                enqueueSnackbar('Error uploading Video. ', {
                  variant: 'error',
                });
              }
            })
            .catch((err) => {
              setVideoStatus('error');
            });
        } else {
          setVideoStatus('error');
        }
      })
      .catch((error) => {
        setCourseStatus('error');
        setImageStatus('error');
        setVideoStatus('error');
      });
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data?.categories?.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
    setSelectedSubCategory({
      title: '',
      id: null,
    });

    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationChange = (event) => {
    const tempSelectedPublication = publicationsRes.data.publications.find(
      (singlePublication) => singlePublication.id === event.target.value
    );

    setSelectedPublication(tempSelectedPublication);
    setPublicationTitle(event.target.value);
    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationEditionChange = (event) => {
    const tempPublicationEdition = selectedPublication.publication_editions.find(
      (singlePublicationEdition) =>
        singlePublicationEdition.id === event.target.value
    );

    setSelectedPublicationEdition(tempPublicationEdition);
    setPublicationEditionTitle(event.target.value);
  };

  const setNextButton = (truthValue) => {
    setSubjects(truthValue);
  };
  const handleExternalSaveAsDraft = () => {
    if (activeIndex === 0) {
      if (courseId) {
        navigate(`/courses/${courseId}`);
      } else {
        navigate(`/courses`);
      }
    }
    if (activeIndex === 1) {
      navigate(`/courses/${courseId}`);
    }
    if (activeIndex === 2) {
      navigate(`/courses/${courseId}`);
    }
    if (activeIndex === 3) {
      navigate(`/courses/${courseId}`);
    }
    if (activeIndex === 4) {
      navigate(`/courses/${courseId}`);
    }
  };
  const handleExternalNextButton = () => {
    if (activeIndex === 0) {
      setActiveIndex(activeIndex + 1);
    }
    if (activeIndex === 1) {
      if (courseId) {
        setActiveIndex(activeIndex + 1);
      }
    }
    if (activeIndex === 2) {
      if (subjects) {
        setActiveIndex(activeIndex + 1);
      } else {
        enqueueSnackbar('Please Provide at least one Chapter', {
          variant: 'error',
        });
      }
    }
    if (activeIndex === 3) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const onStatusChange = (id, status) => {
    updateCourseStatus({ id, status, published_at: null });
  };

  return !isSignedIn ? (
    <Typography>Not Authorized</Typography>
  ) : (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xl="10" lg="10" md="12" sm="11" xs="11">
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#ee3163',
                fontSize: '18px',
                fontWeight: 600,
                marginBottom: '1rem',
              }}
            >
              New Course
            </Typography>
            <Stepper activeStep={activeIndex} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Grid container spacing={2} sx={{ mt: 5 }}>
            {activeIndex == 0 &&
              (courseId && updateCourseData?.course ? (
                <EditCourse
                  course={updateCourseData?.course}
                  showExtraButton={true}
                  handleExternalSaveAsDraft={handleExternalSaveAsDraft}
                  handleExternalNextButton={handleExternalNextButton}
                  setImageStatus={setImageStatus}
                  setExternalVideoStatus={setVideoStatus}
                />
              ) : (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    style={{ height: 'auto' }}
                  >
                    <TextField
                      required
                      fullWidth
                      id="title"
                      label=" Title"
                      name="title"
                      autoComplete="title"
                      autoFocus
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete="description"
                      autoFocus
                      multiline
                      rows="2"
                      onChange={handleDescriptionChange}
                    />
                  </Grid>
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      select
                      required
                      fullWidth
                      id="category"
                      label="Category"
                      onChange={handleCategoryChange}
                      name="category"
                      autoComplete="category"
                      autoFocus
                      value={categoryTitle}
                    >
                      {data?.categories && data?.categories.length
                        ? data?.categories.map((singleCategory) => (
                            <MenuItem
                              value={singleCategory.id}
                              key={singleCategory.id}
                            >
                              {singleCategory.title}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      select
                      required
                      fullWidth
                      id="sub_category"
                      label="Sub Category"
                      onChange={handleSubCategoryChange}
                      name="sub_category"
                      autoComplete="sub_category"
                      autoFocus
                      value={subCategoryTitle}
                    >
                      {selectedCategory &&
                      selectedCategory.published_sub_categories.length
                        ? selectedCategory.published_sub_categories.map(
                            (singleSubCategory) => (
                              <MenuItem
                                value={singleSubCategory.id}
                                key={singleSubCategory.id}
                              >
                                {singleSubCategory.title}
                              </MenuItem>
                            )
                          )
                        : null}
                    </TextField>
                  </Grid>
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedSubCategory.grades.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="grade"
                        label="Grade"
                        onChange={handleGradeChange}
                        name="grade"
                        autoComplete="grade"
                        autoFocus
                        value={gradeTitle}
                      >
                        {selectedCategory &&
                        selectedSubCategory &&
                        selectedSubCategory.grades.length
                          ? selectedSubCategory.grades.map((singleGrade) => (
                              <MenuItem value={singleGrade.id} key={singleGrade.id}>
                                {singleGrade.title}
                              </MenuItem>
                            ))
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedGrade.id &&
                  selectedGrade.grade_subjects.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="grade_subject"
                        label="Grade Subject"
                        onChange={handleGradeSubjectChange}
                        name="grade subject"
                        autoComplete="grade subject"
                        autoFocus
                        value={gradeSubjectTitle}
                      >
                        {selectedGrade.grade_subjects.length
                          ? selectedGrade.grade_subjects.map(
                              (singleGradeSubject) => (
                                <MenuItem
                                  value={singleGradeSubject.id}
                                  key={singleGradeSubject.id}
                                >
                                  {singleGradeSubject.title}
                                </MenuItem>
                              )
                            )
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedGrade.id &&
                  publicationsRes.data?.publications.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="publication"
                        label="Publication"
                        onChange={handlePublicationChange}
                        name="publication"
                        autoComplete="publication"
                        autoFocus
                        value={publicationTitle}
                      >
                        {publicationsRes.data.publications.length
                          ? publicationsRes.data.publications.map(
                              (singlePublication) => (
                                <MenuItem
                                  value={singlePublication.id}
                                  key={singlePublication.id}
                                >
                                  {singlePublication.title}
                                </MenuItem>
                              )
                            )
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedGrade.id &&
                  publicationsRes.data?.publications.length &&
                  selectedPublication.id &&
                  selectedPublication.publication_editions.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="publication_edition"
                        label="Publication Edition"
                        onChange={handlePublicationEditionChange}
                        name="Publication Edition"
                        autoComplete="Publication Edition"
                        autoFocus
                        value={publicationEditionTitle}
                      >
                        {selectedPublication.publication_editions.length
                          ? selectedPublication.publication_editions.map(
                              (singlePublicationEdition) => (
                                <MenuItem
                                  value={singlePublicationEdition.id}
                                  key={singlePublicationEdition.id}
                                >
                                  {singlePublicationEdition.edition}
                                </MenuItem>
                              )
                            )
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="language"
                      label="What Language will you teach your course"
                      name="language"
                      autoComplete="language"
                      autoFocus
                      onChange={handleLanguageChange}
                      select
                    >
                      {Array.isArray(allLanguages) && allLanguages.length > 0
                        ? allLanguages.map((singleLanguage) => (
                            <MenuItem value={singleLanguage} key={singleLanguage}>
                              {singleLanguage}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="coursefee"
                      label="Course Fee"
                      name="coursefee"
                      autoComplete="coursefee"
                      autoFocus
                      onChange={(e) => {
                        setCourseCostType(e.target.value);
                      }}
                      select
                    >
                      {[
                        { label: 'Free', value: 'free' },
                        { label: 'Paid', value: 'paid' },
                      ].map((singleItem) => (
                        <MenuItem value={singleItem.value} key={singleItem.value}>
                          {singleItem.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {courseCostType === 'paid' && (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        required
                        fullWidth
                        id="cost"
                        label="Cost"
                        name="cost"
                        autoComplete="cost"
                        autoFocus
                        type="number"
                        onChange={(e) => {
                          setCourseCost(e.target.value);
                        }}
                        defaultValue={courseCost}
                      />
                    </Grid>
                  )}

                  <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Learning Level
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={level}
                        onChange={handleLevelChange}
                      >
                        <FormControlLabel
                          value="beginner"
                          control={<Radio />}
                          label="Beginner"
                        />
                        <FormControlLabel
                          value="intermediate"
                          control={<Radio />}
                          label="Intermediate"
                        />
                        <FormControlLabel
                          value="expert"
                          control={<Radio />}
                          label="Expert"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
                    <Box sx={{ height: '100%' }}>
                      <Grid container spacing={1}>
                        <Grid item xl="3" lg="3" md="3" sm="3" xs="3">
                          <img
                            src={
                              imageUrl
                                ? imageUrl
                                : updateCourseData?.course?.url ||
                                  updateCourseData?.course?.image_url
                            }
                            style={{
                              border: '1px solid #c7c7c7',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </Grid>
                        <Grid item xl="9" lg="9" md="9" sm="9" xs="9">
                          <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                            {`${
                              selectedCategory?.title == 'Academics'
                                ? 'Textbook'
                                : ''
                            } Cover Image`}
                          </Typography>
                          <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                            Upload Course image here. It must meet our course image
                            quality standard to be accepted. Important guidelines:
                            750*422 pixels; .jpg, .png, .jpeg, .gif or .png no text
                            on the image
                          </Typography>
                          <form className="form" style={{ marginTop: '2rem' }}>
                            <div
                              className="file-upload-wrapper"
                              data-text={
                                selectedImage
                                  ? selectedImage.name
                                  : 'No File Selected'
                              }
                              data-button-text="Select File"
                            >
                              <input
                                name="file-upload-field"
                                type="file"
                                accept="image/*"
                                className="file-upload-field"
                                value=""
                                onChange={(e) => {
                                  if (
                                    allowedImageTypes.includes(
                                      e.target.files[0].type
                                    )
                                  ) {
                                    setSelectedImage(e.target.files[0]);
                                  } else {
                                    enqueueSnackbar(
                                      'Please select a PNG, JPEG, or SVG file.',
                                      {
                                        variant: 'error',
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          </form>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                          {/* <video
                            controls
                            ref={videoRef}
                            controlsList="nodownload"
                            onLoadedMetadata={loadedMetaData}
                          >
                            <source src={vidUrl} type="video/mp4" />
                            Your browser does not support HTML video.
                          </video> */}
                          {vidUrl ? (
                            <video
                              controls
                              ref={videoRef}
                              controlsList="nodownload"
                              onLoadedMetadata={loadedMetaData}
                            >
                              <source
                                // src={vidUrl}
                                src={vidUrl}
                                type="video/mp4"
                              />
                              Your browser does not support HTML video.
                            </video>
                          ) : updateCourseData?.course?.video?.vid_url ? (
                            <HlsJs
                              vidSrc={updateCourseData?.course?.video?.vid_url}
                            />
                          ) : null}
                          <Box>
                            <Typography>
                              File Name
                              <br />
                              <label
                                href=""
                                style={{
                                  fontSize: '12px',
                                  color: 'blue',
                                  textDecoration: 'underline',
                                }}
                              >
                                {selectedVideo ? selectedVideo.name : null}
                              </label>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xl="8" lg="8" md="8" sm="8" xs="8">
                          <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                            Promotional video
                          </Typography>
                          <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                            Your promo video is a quick and compelling way for
                            students to preview what they learn in your course.
                            Students considering your course are more likely to
                            enroll if your promo video is well made. Learn how to
                            make your promo video awesome!
                          </Typography>
                          <form className="form" style={{ marginTop: '2rem' }}>
                            <div
                              className="file-upload-wrapper"
                              data-text={
                                selectedVideo
                                  ? selectedVideo.name
                                  : 'No File Selected'
                              }
                              data-button-text="Select File"
                            >
                              <input
                                name="file-upload-field"
                                type="file"
                                accept="video/*"
                                className="file-upload-field"
                                value=""
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  if (
                                    selectedFile &&
                                    selectedFile.type.includes('video')
                                  ) {
                                    setSelectedVideo(e.target.files[0]);
                                  } else {
                                    enqueueSnackbar('Please select a Video file.', {
                                      variant: 'error',
                                    });
                                  }
                                }}
                              />
                            </div>
                          </form>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xl="6" lg="6" md="12" sm="12" xs="12"></Grid>
                </Grid>
              ))}

            {activeIndex == 1 && (
              <div style={{ width: '100%' }}>
                <Divider textAlign="left">Course Description</Divider>

                <Box
                  sx={{
                    display: 'flex',
                    minInlineSize: '-webkit-fill-available',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      {title}
                    </Typography>
                  </Box>
                  {courseStatus === false ? (
                    <CircularProgress disableShrink />
                  ) : null}
                  {courseStatus === 'success' ? (
                    <Alert severity="success">Course Created</Alert>
                  ) : null}
                  {courseStatus === 'error' ? (
                    <Alert severity="error">(Error Creating Course)</Alert>
                  ) : null}
                </Box>

                {/* <Divider>
                  <Chip label="Course Image" size="small" />
                </Divider> */}
                <Divider textAlign="left">Course Image</Divider>

                <Grid container spacing={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      minInlineSize: '-webkit-fill-available',
                      mt: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    {updateCourseData?.course?.image_url ? (
                      <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                        <img
                          src={updateCourseData?.course?.url}
                          style={{
                            border: '1px solid #c7c7c7',
                            // width: '100%',
                            // height: '100%',
                            height: '500px',
                            width: '300px',
                          }}
                        />
                      </Grid>
                    ) : selectedImage ? (
                      <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                        <img
                          src={imageUrl}
                          style={{
                            border: '1px solid #c7c7c7',
                            // width: '100%',
                            // height: '100%',
                            height: '500px',
                            width: '300px',
                          }}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                      {updateCourseData?.course?.image_url ? (
                        <>
                          {imageStatus === false ? (
                            <CircularProgress disableShrink />
                          ) : null}
                          {imageStatus === 'success' ? (
                            <Alert severity="success">Course Image Uploaded</Alert>
                          ) : null}
                          {imageStatus === 'error' && selectedImage ? (
                            <>
                              <Alert severity="error">Error Uploading Image</Alert>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {imageStatus === false ? (
                            <CircularProgress disableShrink />
                          ) : null}
                          {imageStatus === 'success' ? (
                            <Alert severity="success">Course Image Uploaded</Alert>
                          ) : null}
                          {imageStatus === 'error' && selectedImage ? (
                            <>
                              <Alert severity="error">Error Uploading Image</Alert>
                            </>
                          ) : null}
                          {!selectedImage ? (
                            <>
                              <Alert severity="error">No Image Provided</Alert>
                            </>
                          ) : null}
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>

                {/* <Divider>
                  <Chip label="Course Promo Video" size="small" />
                </Divider> */}
                <Divider textAlign="left">Course Promo Video</Divider>

                <Grid container spacing={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      minInlineSize: '-webkit-fill-available',
                      mt: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    {selectedVideo ? (
                      <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                        <video
                          controls
                          ref={videoRef}
                          controlsList="nodownload"
                          onLoadedMetadata={loadedMetaData}
                        >
                          <source src={vidUrl} type="video/mp4" />
                          Your browser does not support HTML video.
                        </video>
                      </Grid>
                    ) : updateCourseData?.course?.video?.vid_url ? (
                      <HlsJs vidSrc={updateCourseData?.course?.video?.vid_url} />
                    ) : null}

                    <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                      <Box>
                        <Typography>
                          <label
                            href=""
                            style={{
                              fontSize: '12px',
                              color: 'blue',
                              textDecoration: 'underline',
                            }}
                          >
                            {selectedVideo ? selectedVideo.name : null}
                          </label>
                        </Typography>
                      </Box>
                      {/* {videoStatus === false ? (
                        <CircularProgress disableShrink />
                      ) : null} */}
                      {videoStatus === 'success' ? (
                        <Alert severity="success">Course Promo Video Uploaded</Alert>
                      ) : null}
                      {videoStatus === 'error' && selectedVideo ? (
                        <>
                          <Alert severity="error">Error Uploading Video</Alert>
                        </>
                      ) : null}
                      {queryParamValue && courseId && updateCourseData?.course ? (
                        <></>
                      ) : !selectedVideo ? (
                        <>
                          <Alert severity="error">No Video Provided</Alert>
                        </>
                      ) : null}
                    </Grid>
                  </Box>
                </Grid>
                {progress > 0 ? (
                  <Box sx={{ width: '100%', mt: '4px' }}>
                    <LinearProgressWithLabel value={progress} color="secondary" />
                  </Box>
                ) : null}

                {processProgress > 0 && processProgress < 100 ? (
                  <Alert severity="info">
                    Please wait while we upload your video
                  </Alert>
                ) : null}
                {/* {videoStatus === 'PROCESSING' ? (
                  <Alert severity="info">
                    Your video is being processed. Please Wait
                  </Alert>
                ) : null} */}
                {processProgress === 100 ? (
                  <Alert severity="success">Your video Uploaded Successfully</Alert>
                ) : null}
              </div>
            )}
            {activeIndex == 2 &&
              ((queryParamValue || courseId) && updateCourseData?.course ? (
                <AddCourseSubject
                  courseId={courseId}
                  // course={updateCourseData?.course}
                  nextButton={setNextButton}
                />
              ) : null)}
            {activeIndex == 3 && <DisplayCoursePreview courseId={courseId} />}
            {activeIndex == 4 && (
              <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography variant="subtitle1" paragraph gutterBottom>
                      Thank You for Creating Your Course
                    </Typography>
                    <Typography variant="subtitle1" paragraph gutterBottom>
                      Click Below Button to Submit for Review.
                    </Typography>
                    {/* <Button
                      type="button"
                      variant="contained"
                      onClick={() => navigate(`/courses`)}
                    >
                      Submit For Review And Exit
                    </Button> */}
                    <Alert severity="info">
                      If You Submit, Admin will review and Publish your Course. You
                      will recieve notification.{' '}
                    </Alert>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid
              item
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box>
                {activeIndex > 0 && (
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // disabled={result.isLoading || result.isSuccess}
                    onClick={() => {
                      if (activeIndex == 1) {
                        setActiveIndex(0);
                        if (courseId && location.search == '') {
                          navigate({ search: `courseId=${courseId}` });
                          getCreatedCourseDetails(courseId);
                        }
                      } else {
                        if ((activeIndex == 2 || activeIndex == 3) && courseId) {
                          getCreatedCourseDetails(courseId);
                        }
                        if (activeIndex > 1) setActiveIndex(activeIndex - 1);
                      }
                    }}
                  >
                    Previous
                  </Button>
                )}
              </Box>
              {courseId && updateCourseData?.course && activeIndex == 0 ? null : (
                <Box>
                  {activeIndex !== 4 && (
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, mr: 2 }}
                      onClick={() => {
                        if (activeIndex === 0) {
                          const res = validateData();
                          if (!res) {
                            handleSubmit();
                            if (courseId) {
                              navigate(`/educator/dashboard`, {
                                state: { activeIndex: 1 },
                              });
                              // navigate(`/courses/${courseId}`);
                            } else {
                              // navigate(`/courses`);
                              navigate(`/educator/dashboard`, {
                                state: { activeIndex: 1 },
                              });
                            }
                          }
                        }
                        if (activeIndex === 1) {
                          // navigate(`/courses/${courseId}`);
                          navigate(`/educator/dashboard`, {
                            state: { activeIndex: 1 },
                          });
                        }
                        if (activeIndex === 2) {
                          // navigate(`/courses/${courseId}`);
                          navigate(`/educator/dashboard`, {
                            state: { activeIndex: 1 },
                          });
                        }
                        if (activeIndex === 3) {
                          // navigate(`/courses/${courseId}`);
                          navigate(`/educator/dashboard`, {
                            state: { activeIndex: 1 },
                          });
                        }
                        if (activeIndex === 4) {
                          // navigate(`/courses/${courseId}`);
                          navigate(`/educator/dashboard`, {
                            state: { activeIndex: 1 },
                          });
                        }
                      }}
                    >
                      Save as Draft
                    </Button>
                  )}
                  {activeIndex == 4 && (
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => {
                        onStatusChange(courseId, 'in_review');
                        navigate(`/courses`);
                      }}
                      sx={{ mt: 3, mb: 2, mr: 2 }}
                    >
                      Submit For Review And Exit
                    </Button>
                  )}
                  {/* <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2 }}
                >
                  Save & Countinue
                </Button> */}
                  {activeIndex <= 3 ? (
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => {
                        if (activeIndex === 0) {
                          const res = validateData();
                          if (!res) {
                            handleSubmit();
                            setActiveIndex(activeIndex + 1);
                          }
                        }
                        if (activeIndex === 1) {
                          if (courseId) {
                            setActiveIndex(activeIndex + 1);
                          } else if (courseStatus === 'error') {
                            enqueueSnackbar('Error Creating Course', {
                              variant: 'error',
                            });
                          } else {
                            enqueueSnackbar(' Creating Course, Please Wait', {
                              variant: 'success',
                            });
                          }
                        }
                        if (activeIndex === 2) {
                          if (subjects) {
                            setActiveIndex(activeIndex + 1);
                          } else {
                            enqueueSnackbar('Please Provide at least one Chapter', {
                              variant: 'error',
                            });
                          }
                        }
                        if (activeIndex === 3) {
                          setActiveIndex(activeIndex + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  ) : null}
                </Box>
              )}
            </Grid>
          </Grid>
          {/* </Box> */}
        </Grid>
      </Grid>
    </>
  );
}

export default NewCreateCourse;
