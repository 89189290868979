/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChangeEducatorPlanStatus from './ChangeEducatorPlanStatus';

function EducatorPlansList(props) {
  const { plans } = props;

  const navigate = useNavigate();

  const [expandedId, setExpandedId] = useState(-1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlan(null);
  };
  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewPlan = (id) => {
    navigate(`/educator-subscription-plans/${id}`);
  };

  const editPlan = (id) => {
    navigate(`/edit-educator-subscription-plans/${id}`);
  };
  const changeStatus = (singlePlan) => {
    setSelectedPlan(singlePlan);
    setOpen(true);
  };

  return (
    <>
      <Typography>Educator Subscription Plans</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Cost Per Month</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>
                <span style={{ marginLeft: '1rem' }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans &&
              plans.length &&
              plans
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((singlePlan, index) => (
                  <TableRow
                    key={singlePlan.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{singlePlan.title}</TableCell>
                    <TableCell>{singlePlan.cost_per_month}</TableCell>
                    <TableCell style={{ textTransform: 'capitalize' }}>
                      {singlePlan.status}
                    </TableCell>
                    <TableCell>
                      {moment(singlePlan.created_at).format('Do MMM YY hh:mm A')}
                    </TableCell>
                    <TableCell>
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 200,
                          bgcolor: 'background.paper',
                        }}
                      >
                        <ListItemButton
                          onClick={() => handleClick(index)}
                          aria-expanded={expandedId === index}
                        >
                          <ListItemText primary="Options" />
                          <ExpandMore />
                        </ListItemButton>
                        <Collapse
                          in={expandedId === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              onClick={() => viewPlan(singlePlan.id)}
                            >
                              <ListItemText primary="View" />
                            </ListItemButton>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              onClick={() => editPlan(singlePlan.id)}
                            >
                              <ListItemText primary="Edit" />
                            </ListItemButton>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              onClick={() => changeStatus(singlePlan)}
                            >
                              <ListItemText primary="Change Status" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={plans && plans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {selectedPlan ? (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Change {selectedPlan.title} Status</DialogTitle>
          <DialogContent>
            <ChangeEducatorPlanStatus plan={selectedPlan} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}
export default EducatorPlansList;
