import { ArrowForwardIos } from '@mui/icons-material';
import { Accordion, AccordionDetails, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIos />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  // '& .MuiAccordionSummary-content': {
  //   marginLeft: theme.spacing(1),
  // },
}));
function DisplayCourseCurriculum(props) {
  const { subjects, height } = props;
  const [expandAll, setExpandAll] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState({});

  // Update all accordions based on `expandAll`
  useEffect(() => {
    const initialState = subjects.reduce((acc, subject) => {
      acc[subject.id] = expandAll;
      return acc;
    }, {});
    setExpandedAccordions(initialState);
  }, [expandAll, subjects]);

  const handleAccordionToggle = (id) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleExpand = () => {
    setExpandAll(!expandAll);
  };
  return (
    <Paper
      sx={{
        m: 1,
        maxHeight: height ? height : 'auto',
        overflowY: 'scroll',
      }}
    >
      <div style={{ textAlign: 'end' }}>
        <Button onClick={handleExpand}>{`${
          expandAll ? 'Collapse' : 'Expand'
        } All`}</Button>
      </div>
      {subjects.length >= 1 ? (
        <>
          {subjects.map((singleSubject, index) => (
            <>
              <Accordion
                // {...(expandAll && { expanded: true })}
                expanded={expandedAccordions[singleSubject.id] || false}
                key={singleSubject.id}
                onChange={() => handleAccordionToggle(singleSubject.id)}
                style={props.mainAccordion ? props.mainAccordion : {}}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={props.accordionSummary ? props.accordionSummary : {}}
                >
                  <Typography sx={{ width: '90%' }}>
                    {index + 1}. {singleSubject.title}
                  </Typography>
                  {singleSubject.published_video ? (
                    <Typography sx={{ color: 'text.secondary' }}>
                      {moment
                        .utc(singleSubject.published_video?.duration * 1000)
                        .format('HH:mm:ss')}
                    </Typography>
                  ) : null}
                </AccordionSummary>
                <AccordionDetails
                  style={
                    props?.accordionDetailsStyle
                      ? {
                          ...(props?.accordionDetailsStyle || {}), // Spread the props object if it exists
                          ...(subjects.length - 1 === index
                            ? { borderBottom: '1px solid #d1d7dc' }
                            : {}),
                        }
                      : {}
                  }
                >
                  {singleSubject.topics.length >= 1 &&
                    singleSubject.topics.map((singleTopic, i) => (
                      <>
                        <AccordionSummary
                          expandIcon={''}
                          aria-controls="panel1a-content"
                          id="panel1a-header-subname"
                        >
                          <Typography
                            sx={{ width: '100%', maxWidth: 'calc(100% - 65px)' }}
                          >
                            {index + 1}. {i + 1}. {singleTopic.title}
                          </Typography>
                          {singleTopic.published_video ? (
                            <Typography sx={{ color: 'text.secondary' }}>
                              {moment
                                .utc(singleTopic.published_video?.duration * 1000)
                                .format('HH:mm:ss')}
                            </Typography>
                          ) : null}
                          {singleTopic.published_document ? (
                            <Typography sx={{ color: 'text.secondary' }}>
                              <DocumentScannerOutlinedIcon />
                            </Typography>
                          ) : null}
                        </AccordionSummary>
                      </>
                    ))}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </>
      ) : null}
    </Paper>
  );
}

export default DisplayCourseCurriculum;
