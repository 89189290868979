/* eslint-disable no-unused-vars */

import { Box, Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCreateEducatorPaymentWithdrawalMutation } from '../../../app/services/EducatorApi';

function CreateWithdrawalRequest(props) {
  const { balance } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [createRequest, result] = useCreateEducatorPaymentWithdrawalMutation();

  const validateData = (requestData) => {
    let err = true;

    if (!requestData.amount || !requestData.notes) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else if (requestData?.amount < 0) {
      enqueueSnackbar('Amount cannot be a nagetive value ', {
        variant: 'error',
      });
    } else if (requestData.amount > balance) {
      enqueueSnackbar('Amount exceeds available balance ', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const requestData = {
      amount: data.get('amount'),
      notes: data.get('notes'),
    };

    const res = validateData(requestData);
    if (!res) {
      createRequest(requestData);
    }
  };

  return result.data ? (
    result.data.message
  ) : (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        fullWidth
        id="amount"
        label="Amount"
        name="amount"
        autoComplete="amount"
        autoFocus
        type="number"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="notes"
        label="Notes"
        name="notes"
        autoComplete="notes"
        autoFocus
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={result.isLoading || result.isSuccess}
      >
        Create Withdrawal Request
      </Button>
    </Box>
  );
}

export default CreateWithdrawalRequest;
