import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import DataLoader from '../../../components/DataLoader';
import { useEnroledTestsQuery } from '../../../app/services/StudentTestsApi';

function EnroledTests() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { data, isLoading } = useEnroledTestsQuery(token);
  const viewTest = (id) => {
    navigate(`/enrols/test/${id}`);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Enrolled Tests
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Enrolled On</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.enrols &&
              data.enrols.length &&
              data.enrols.map((singleEnrol, index) => (
                <TableRow
                  key={singleEnrol.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singleEnrol.test.title}</TableCell>

                  <TableCell>
                    {moment(singleEnrol.created_at).format('Do MMM YY hh:mm A')}
                  </TableCell>
                  <TableCell>
                    <Button sx={{ pl: 4 }} onClick={() => viewTest(singleEnrol.id)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EnroledTests;
