import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreatePracticeTestResultMutation } from '../../../app/services/StudentTestsApi';
import PracticeTestQuestionAndAnswersList from './PracticeTestQuestionAndAnswersList';
import QuestionTimer from './QuestionTimer';

function TimedPracticeTestWithQuestions(props) {
  const { questions, practiceTestId } = props;
  const [answers, setAnswers] = useState([]);

  const [practiceTestResult, setPracticeTestResult] = useState({});
  const [createResult] = useCreatePracticeTestResultMutation();

  const onChange = (e, questionId) => {
    if (answers && answers.find((element) => element.question_id === questionId)) {
      const updatedAnswers = answers.map((singleAnswer) => {
        if (singleAnswer.question_id === questionId) {
          return {
            ...singleAnswer,
            given_answer: e.target.value,
          };
        }
        return singleAnswer;
      });
      setAnswers(updatedAnswers);
    } else {
      setAnswers([
        ...answers,
        { given_answer: e.target.value, question_id: questionId },
      ]);
    }
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [countDown, setCountDown] = useState(questions[currentQuestion].time);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [finished, setFinished] = useState(false);

  const resetCount = (newQuestion) => {
    // eslint-disable-next-line no-unused-expressions
    newQuestion < questions.length + 1
      ? setCurrentQuestion(newQuestion)
      : setCurrentQuestion(1);
  };

  const setQuestionsFinished = () => {
    // eslint-disable-next-line no-unused-expressions
    setFinished(true);
  };

  useEffect(() => {
    if (questions[currentQuestion]) {
      setCountDown(questions[currentQuestion].time);
    } else {
      setCountDown(questions[0].time);
    }

    if (questions.length - 1 === currentQuestion) {
      setLastQuestion(true);
    }
  }, [currentQuestion]);

  const checkAnswers = (singleQuestion) => {
    const found = answers.some(
      (singleAnswer) => singleAnswer.question_id === singleQuestion.id
    );
    if (!found)
      answers.push({ question_id: singleQuestion.id, given_answer: 'not given' });
    return true;
  };

  const submitAnswers = async () => {
    if (countDown > 0) {
      setCountDown(0);
    }
    await questions.every(checkAnswers);

    const res = await createResult({ answers, practiceTestId });
    if (res.data.message === 'SUCCESS') {
      setPracticeTestResult(res.data.practiceTestResult);
    }
  };

  return (
    <>
      <Grid>
        {' '}
        {'id' in practiceTestResult ? (
          <>
            <Card title="Result">
              <h3>
                Result:&nbsp;
                {practiceTestResult.total_marks}
              </h3>
              <PracticeTestQuestionAndAnswersList
                questions={practiceTestResult.questions}
                answers={practiceTestResult.answers}
              />
            </Card>
          </>
        ) : questions[currentQuestion].time === countDown ? (
          <>
            <Grid container justifyContent="center">
              <QuestionTimer
                duration={countDown}
                resetCountDown={resetCount}
                questionNumber={currentQuestion}
                questionLength={questions.length}
                submitAnswers={submitAnswers}
                lastQuestion={lastQuestion}
                setQuestionsFinished={setQuestionsFinished}
              />
            </Grid>
          </>
        ) : null}
        {questions[currentQuestion] &&
        questions[currentQuestion].time === countDown &&
        !finished ? (
          <>
            {' '}
            <Grid item md={12}>
              <Card>
                <CardHeader
                  title={`${questions[currentQuestion].title}`}
                  sx={{ color: '#233c8d' }}
                />
                <CardContent>
                  <FormControl sx={{ marginTop: 2 }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      // value={value}
                      onChange={(e) => onChange(e, questions[currentQuestion].id)}
                    >
                      {questions[currentQuestion].options.map((option) => (
                        <FormControlLabel
                          value={option.optionText}
                          control={<Radio />}
                          label={option.optionText}
                          key={option.optionText}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
}

export default TimedPracticeTestWithQuestions;
