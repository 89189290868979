import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Alert,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router';
import moment from 'moment';

import {
  useLazyGetPracticeTestQuestionsQuery,
  usePracticeTestQuery,
} from '../../../app/services/StudentTestsApi';
import PracticeTestQuestionAndAnswersList from './PracticeTestQuestionAndAnswersList';
import UntimedPracticeTestWithQuestions from './UntimedPracticeTestWithQuestions';
import TimedPracticeTestWithQuestions from './TimedPracticeTestWithQuestions';
import TestReview from './TestReview';
import DisplayTestReview from './DisplayTestReview';
import DataLoader from '../../../components/DataLoader';

function PracticeTest() {
  const params = useParams();

  const [questions, setQuestions] = React.useState([]);
  const { data, isLoading, isSuccess } = usePracticeTestQuery({
    id: parseInt(params.practiceTestId, 10),
  });
  const [getQuestions] = useLazyGetPracticeTestQuestionsQuery();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = async (id) => {
    const res = await getQuestions({ id });

    setQuestions(res.data.practiceTest.questions);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return isSuccess && data.practiceTest ? (
    <Box>
      <Grid container align="left">
        {!questions.length >= 1 ? (
          <>
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              <Card>
                <CardHeader title={data.practiceTest.title} />
                <>
                  <CardMedia
                    component="img"
                    height="194"
                    image={
                      data.practiceTest.test.url ||
                      `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                    }
                    alt="Paella dish"
                  />

                  <CardContent
                    sx={{ alignItems: 'center', overflowWrap: 'anywhere' }}
                  >
                    <Typography>For Test: {data.practiceTest.test.title}</Typography>
                    <Typography>
                      Created On:
                      {moment(data.practiceTest.created_at).format(
                        'Do MMM YY hh:mm A'
                      )}
                    </Typography>
                    <Typography>
                      No Of Questions: {data.practiceTest.no_of_questions}
                    </Typography>
                    <Typography>
                      Questions with Severity:&nbsp;
                      {data.practiceTest.severity.low ? 'Low' : null}&nbsp;
                      {data.practiceTest.severity.medium ? 'Medium' : null}&nbsp;
                      {data.practiceTest.severity.high ? 'High' : null}
                    </Typography>
                    <Typography>
                      Style Of Questions: {data.practiceTest.style}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {data.practiceTest.practice_test_result ? (
                      <>
                        <Alert severity="info">
                          Practice Test Completed. View Result Below
                        </Alert>
                        <div style={{ textAlign: 'center' }}>
                          {data.practiceTest.testReview ? (
                            <DisplayTestReview
                              testReview={data.practiceTest.testReview}
                            />
                          ) : (
                            <>
                              <Button variant="contained" onClick={handleClickOpen}>
                                Submit Test Review
                              </Button>
                              <Dialog open={open} onClose={handleClose}>
                                <DialogTitle> Test Review</DialogTitle>
                                <DialogContent>
                                  <TestReview test_id={data.practiceTest.test.id} />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose}>Close</Button>
                                </DialogActions>
                              </Dialog>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <Button
                        onClick={() => onClick(data.practiceTest.id)}
                        variant="contained"
                      >
                        Take the Practice Test
                      </Button>
                    )}
                  </CardActions>
                </>
              </Card>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={2}></Grid>
            <Grid item md={8}>
              {data.practiceTest.practice_test_result ? (
                <>
                  <Card title="Result">
                    <p>
                      Result:&nbsp;
                      {data.practiceTest.practice_test_result.total_marks}
                    </p>
                    <PracticeTestQuestionAndAnswersList
                      questions={data.practiceTest.questions}
                      answers={data.practiceTest.practice_test_result.answers}
                    />
                  </Card>
                </>
              ) : null}
            </Grid>
            <Grid item md={2}></Grid>
          </>
        ) : (
          <Grid item md={12}>
            {data.practiceTest.style === 'timed' ? (
              <TimedPracticeTestWithQuestions
                questions={questions}
                practiceTestId={data.practiceTest.id}
              />
            ) : (
              <UntimedPracticeTestWithQuestions
                questions={questions}
                practiceTestId={data.practiceTest.id}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  ) : (
    <Typography>Practice Test Not Found</Typography>
  );
}

export default PracticeTest;
