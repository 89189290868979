/* eslint-disable no-unused-vars */
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Icon, InputAdornment, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SortableTopic(props) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: props.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Box
        style={{
          display: 'flex',
          border: '1px solid #cacbcf',
          background: '#fff',
          borderRadius: '6px',
          padding: '1rem',
          marginTop: '1rem',
        }}
      >
        <TextField
          value={props.title}
          autoFocus
          required
          fullWidth
          size="small"
          placeholder="What is Herbivorus animal"
          label={`Lecture ${props.displayIndex + 1}`}
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon color="success">
                  <CheckCircleIcon twoToneColor="#52c41a" />
                </Icon>{' '}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </div>
  );
}

export default SortableTopic;
