import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';

function UserSupportTicketsList(props) {
  const { supportTickets } = props;
  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const viewSupportTicket = (id) => {
    navigate(`/support/ticket/${id}`);
  };

  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Support Tickets
      </Typography>
      <Paper sx={{ height: 400, width: '100%', overflow: 'scroll' }}>
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Ticket Title</TableCell>
                <TableCell>Ticket Status</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>
                  <span style={{ marginLeft: '1rem' }}>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supportTickets.map((singleSupportTicket, index) => (
                <TableRow
                  key={singleSupportTicket.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singleSupportTicket.title}</TableCell>
                  <TableCell>{singleSupportTicket.status}</TableCell>
                  <TableCell>
                    {moment(singleSupportTicket.created_at).format(
                      'Do MMM YY hh:mm A'
                    )}
                  </TableCell>{' '}
                  <TableCell>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 200,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleClick(index)}
                        aria-expanded={expandedId === index}
                      >
                        <ListItemText primary="Options" />
                        <ExpandMore />
                      </ListItemButton>
                      <Collapse
                        in={expandedId === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => viewSupportTicket(singleSupportTicket.id)}
                          >
                            <ListItemText primary="View" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default UserSupportTicketsList;
