import { Avatar, Grid, Rating, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import moment from 'moment';

function DisplayAllReviews(props) {
  const { reviews } = props;

  return (
    <Grid container>
      {reviews.map((item, i) => (
        <Grid key={i} item xs="12" sm="12" md="12" lg="12" xl="12">
          <div style={{ padding: '0.5rem' }}>
            <Grid container>
              <Grid item xs="1" sm="1" md="1" lg="1" xl="1">
                <Avatar
                  sx={{ bgcolor: deepOrange[500] }}
                  src={item?.review_creator?.student_info?.url}
                  srcSet={item?.review_creator?.student_info?.url}
                >
                  {item?.review_creator?.student_info?.first_name
                    ? item?.review_creator?.student_info?.first_name
                        ?.charAt()
                        ?.toUpperCase()
                    : item?.review_creator?.name?.charAt()?.toUpperCase()}
                </Avatar>
              </Grid>
              <Grid style={{ display: 'grid' }} xs="6" sm="6" md="6" lg="6" xl="6">
                <span
                  style={{ textTransform: 'capitalize' }}
                  className="font-14 font-700"
                >
                  {item?.review_creator?.student_info?.first_name &&
                  item?.review_creator?.student_info?.last_name
                    ? `${item?.review_creator?.student_info.first_name} ${item?.review_creator?.student_info.last_name}`
                    : item?.review_creator.name}
                </span>
                <span style={{}} className="font-14">
                  {moment(item.created_at).format('Do MMM YY')}
                </span>
              </Grid>
              <Rating value={item.rating} readOnly precision={0.5} />{' '}
            </Grid>
            <div>
              <Typography className="font-14 font-500" sx={{ marginTop: '2rem' }}>
                {item.review_text}
              </Typography>
            </div>
            <hr />
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
export default DisplayAllReviews;
