import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useCategoryQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewSubCategory from './CreateNewSubCategory';
import SubCategoriesList from './SubCategoriesList';
import UpdateCategory from './UpdateCategory';

function SingleCategory() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useCategoryQuery({
    id: parseInt(params.categoryId, 10),
  });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <>
      {isSuccess && data.category ? (
        <>
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen1}>
              Update Category
            </Button>
            <>
              <Dialog open={open1} onClose={handleClose1}>
                <DialogTitle> Update Category</DialogTitle>
                <DialogContent>
                  <UpdateCategory category={data.category} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose1}>Close</Button>
                </DialogActions>
              </Dialog>
            </>
            &nbsp;
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Sub Category
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle> Create New Sub Category</DialogTitle>
              <DialogContent>
                <CreateNewSubCategory categoryId={data.category.id} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.category.title}
            <br />
            Status: {data.category.status}
          </Typography>

          {data.category.sub_categories && data.category.sub_categories.length ? (
            <SubCategoriesList subCategories={data.category.sub_categories} />
          ) : (
            <Alert severity="info">Did Not Find Any Sub Category</Alert>
          )}
        </>
      ) : (
        <Alert severity="info">Did Not Find Category</Alert>
      )}
    </>
  );
}

export default SingleCategory;
