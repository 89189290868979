/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // AccordionSummary,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import moment from 'moment';
import { ExpandMore } from '@mui/icons-material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useEffect, useState } from 'react';

// const style = {
//   width: '100%',
//   // maxWidth: 360,
//   bgcolor: 'background.paper',
// };

function DisplayEnroledLiveCourseCurriculum(props) {
  const { subjects, enrolId, selectedIndex } = props;

  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState({});

  const [expandedAccordions, setExpandedAccordions] = useState({});
  const viewTopic = (Id, topicId) => {
    navigate(`/enrols/course/live/${enrolId}/topic/${topicId}`);
  };

  const getSelecteSubject = () => {
    let finalSubjects = subjects || [];
    let selectedSubject = finalSubjects.find((singleSubject) =>
      singleSubject?.topics?.find(
        (singleTopic) => singleTopic?.id == selectedIndex?.topicId
      )
    );
    if (selectedSubject) {
      setSelectedSubject({ ...selectedSubject });
      setExpandedAccordions({
        [selectedSubject.id]: true,
      });
    }
  };
  useEffect(() => {
    getSelecteSubject();
  }, [selectedIndex]);

  useEffect(() => {
    getSelecteSubject();
  }, [selectedIndex]);
  const handleAccordionToggle = (id) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  return (
    <>
      {subjects.length >= 1
        ? subjects.map((singleSubject, index) => (
            <Accordion
              // defaultExpanded={false}
              aria-label="mailbox folders"
              key={singleSubject.id}
              sx={{ maxWidth: '100%', justifyContent: 'center' }}
              expanded={expandedAccordions[singleSubject.id] || false}
              onChange={() => handleAccordionToggle(singleSubject.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-Header"
                defaultExpanded={selectedSubject?.id == singleSubject?.id}
                // style={{ marginTop: '5px', marginBottom: '5px' }}
              >
                <ListItem
                  sx={{
                    display: 'block',
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    marginTop: '5px',
                    marginBottom: '5px',
                  }}
                >
                  <ListItemText
                    primary={`${index + 1}. ${singleSubject.title} `}
                    title={`${index + 1}. ${singleSubject.title}`}
                    sx={
                      {
                        // textOverflow: 'ellipsis',
                        // overflow: 'hidden',
                        // whiteSpace: 'nowrap',
                        // maxWidth: 'calc(100% - 30px)',
                      }
                    }
                    className="custom-list-text"
                  />
                </ListItem>
              </AccordionSummary>
              <Divider />
              <AccordionDetails sx={{ p: 0 }}>
                {singleSubject.topics.length >= 1
                  ? singleSubject.topics.map((singleTopic, i) => (
                      <List
                        // sx={style}
                        component="nav"
                        aria-label="mailbox folders"
                        key={singleTopic.id}
                        sx={{ p: 0 }}
                      >
                        <ListItem sx={{ display: 'block', p: 0 }}>
                          <ListItemButton
                            sx={{ display: 'block' }}
                            onClick={() =>
                              viewTopic(singleSubject.course_id, singleTopic.id)
                            }
                          >
                            <ListItemText
                              primary={`${index + 1}.${i + 1}. ${singleTopic.title}`}
                              title={`${index + 1}.${i + 1}. ${singleTopic.title}`}
                              sx={
                                {
                                  // textOverflow: 'ellipsis',
                                  // overflow: 'hidden',
                                  // whiteSpace: 'nowrap',
                                }
                              }
                              className="custom-list-text"
                            />
                          </ListItemButton>
                        </ListItem>

                        <Divider />
                      </List>
                    ))
                  : null}
              </AccordionDetails>
              {/* </List> */}
            </Accordion>
          ))
        : null}
    </>
  );
}

export default DisplayEnroledLiveCourseCurriculum;
