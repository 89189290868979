import React, { useEffect } from 'react';
import './App.css';
import './common.css';
import { Route, Routes } from 'react-router';
import AppLayout from './layout/AppLayout';
import UserSelect from './features/authentication/UserSelect';
import StudentRegistrationPage from './features/authentication/student/StudentRegistrationPage';
import EducatorRegistrationPage from './features/authentication/educator/EducatorRegistrationPage';
import InstitutionRegistrationPage from './features/authentication/institution/InstitutionRegistrationPage';
import VerifyEmailPage from './features/authentication/VerifyEmailPage';
import ResendEmailVerification from './features/authentication/ResendEmailVerification';
import ResetPasswordPage from './features/authentication/ResetPasswordPage';
import NotFoundPage from './components/NotFoundPage';
import CategoriesManager from './features/categories/admin/CategoriesManager';
import { useDispatch } from 'react-redux';
import SingleCategory from './features/categories/admin/SingleCategory';
import SingleSubCategory from './features/categories/admin/SingleSubCategory';
import { updateLocalSignInStatus } from './app/slices/authSlice';
import DisplaySingleCourse from './features/courses/public/DisplaySingleCourse';
import CoursesManager from './features/courses/adminOrEducatorOrInstitution/CoursesManager';
import SingleCourse from './features/courses/adminOrEducatorOrInstitution/SingleCourse';
import EnroledCourses from './features/courses/student/EnroledCourses';
import EnroledCourse from './features/courses/student/EnroledCourse';
import ExamsManager from './features/exams/adminOrEducatorOrInstitution/ExamsManager';
import SingleExam from './features/exams/adminOrEducatorOrInstitution/SingleExam';
import SingleQuestion from './features/exams/adminOrEducatorOrInstitution/questions/SingleQuestion';
import EnroledExams from './features/exams/student/EnroledExams';
import EnroledExam from './features/exams/student/EnroledExam';
import DisplaySingleExam from './features/exams/public/DisplaySingleExam';
import StudentsManager from './features/users/admin/StudentsManager';
import InstitutionsManager from './features/users/admin/InstitutionsManager';
import EducatorsManager from './features/users/admin/EducatorsManager';
import TestsManager from './features/tests/adminOrEducatorOrInstitution/TestsManager';
import SingleTest from './features/tests/adminOrEducatorOrInstitution/SingleTest';
import SingleTestSubject from './features/tests/adminOrEducatorOrInstitution/testSubjects/SingleTestSubject';
import SingleTestTopic from './features/tests/adminOrEducatorOrInstitution/testSubjects/testTopics/SingleTestTopic';
import SingleTestQuestion from './features/tests/adminOrEducatorOrInstitution/testSubjects/testTopics/testQuestions/SingleTestQuestion';
import DisplaySingleTest from './features/tests/public/DisplaySingleTest';
import PracticeTests from './features/tests/student/PracticeTests';
import PracticeTest from './features/tests/student/PracticeTest';
import DisplayEnroledSingleCourseVideo from './features/courses/student/DisplayEnroledSingleCourseVideo';
import DIsplaySingleCourseVideo from './features/courses/adminOrEducatorOrInstitution/DIsplaySingleCourseVideo';
import VideoManager from './features/courses/adminOrEducatorOrInstitution/admin/VideoManager';
import DisplaySingleVideo from './features/courses/adminOrEducatorOrInstitution/admin/DisplaySingleVideo';
import HomePage from './components/HomePage';
import GradesManager from './features/categories/admin/GradesManager';
import SingleGrade from './features/categories/admin/SIngleGrade';
import SingleGradeSubject from './features/categories/admin/SingleGradeSubject';
import PublicationsManager from './features/categories/admin/PublicationsManager';
import SinglePublication from './features/categories/admin/SinglePublication';
import KeywordsManager from './features/keywords/admin/KeywordsManager';
import SingleKeyword from './features/keywords/admin/SingleKeyword';
import SearchQueryPage from './features/courses/public/SearchQueryPage';
import StudentDashboard from './features/users/student/StudentDashboard';
import StudentProfile from './features/users/student/StudentProfile';
import LearningObjectivesManager from './features/users/admin/learningObjective/LearningObjectivesManager';
import SingleLearningObjective from './features/users/admin/learningObjective/SingleLearningObjective';
import UserFollowers from './features/users/UserFollowers';
import UserFollowing from './features/users/UserFollowing';
import UserReviews from './features/users/UserReviews';
import AdminDashboard from './features/users/admin/AdminDashboard';
import EducatorDashboard from './features/users/educator/EducatorDashboard';
import InstitutionDashboard from './features/users/institution/InstitutionDashboard';
import SinglePublicationEdition from './features/categories/admin/SinglePublicationEdition';
import VideoViolationManager from './features/courses/adminOrEducatorOrInstitution/admin/VideoViolationManager';
import CourseViolationManager from './features/courses/adminOrEducatorOrInstitution/admin/CourseViolationManager';
import SingleVideoViolation from './features/courses/adminOrEducatorOrInstitution/admin/SingleVideoViolation';
import SingleCourseViolation from './features/courses/adminOrEducatorOrInstitution/admin/SingleCourseViolation';
import SubCategoryResults from './features/courses/public/SubCategoryResults';
import { EducatorChannel } from './features/users/educator/EducatorChannel';
import NewCreateCourse from './features/courses/adminOrEducatorOrInstitution/NewCreateCourse';
import { TeachEducator } from './components/onboard/Educator/TeachEducator';
import { HowToUseApp } from './components/onboard/Educator/HowToUseApp';
import { TeacherPlan } from './components/onboard/Educator/TeacherPlan';
import EditSingleCourse from './features/courses/adminOrEducatorOrInstitution/EditSingleCourse';
import DocumentManager from './features/courses/adminOrEducatorOrInstitution/admin/DocumentManager';
import DisplayEnroledSingleCourseDocument from './features/courses/student/DisplayEnroledSingleCourseDocument';
import DisplayEnroledCourseExternalVideo from './features/courses/student/DisplayEnroledCourseExternalVideo';
import SupportTicketManager from './features/supportTickets/admin/SupportTicketManager';
import SingleSupportTicket from './features/supportTickets/SingleSupportTicket';
import UserSupportTicketManager from './features/supportTickets/users/UserSupportTicketManager';
import { EducatorPerformance } from './features/users/educator/EducatorPerformance';
import { EducatorPlanProfilePayout } from './features/users/educator/EducatorPlanProfilePayout';
import { EducatorEngagement } from './features/users/educator/EducatorEngagement';
import GradeSubjectPage from './features/courses/public/GradeSubjectPage';
import { PrivacyPloicy } from './layout/PrivacyPolicy';
import { RefundPolicy } from './layout/RefundPolicy';
import { SubscriptionCancellationPolicy } from './layout/SubscriptionCancellationPolicy';
import { TermsConditions } from './layout/TermsConditions';
import { AcceptanceOfAgreement } from './layout/AcceptanceOfAgreement';
import ScrollToTop from './layout/ScrollToTop';
import AccountPage from './features/authentication/AccountPage';
import NewCreateExam from './features/exams/adminOrEducatorOrInstitution/NewCreateExam';
import NewCreateTest from './features/tests/adminOrEducatorOrInstitution/NewCreateTest';
import EditSingleExam from './features/exams/adminOrEducatorOrInstitution/EditSingleExam';
import EditSingleTest from './features/tests/adminOrEducatorOrInstitution/EditSingleTest';
import EnroledTests from './features/tests/student/EnroledTests';
import EnroledTest from './features/tests/student/EnroledTest';
import DisplayEnroledSingleCourseExam from './features/courses/courseExams/DisplayEnroledSingleCourseExam';
import EditSingleCourseExam from './features/courses/courseExams/EditSingleCourseExam';
import NewCreateCourseExam from './features/courses/courseExams/NewCreateCourseExam';
import DispalaySingleCourseExams from './features/courses/courseExams/DispalaySingleCourseExams';
import CourseExamsManager from './features/courses/courseExams/CourseExamsManager';
import NewCreateCourseExam2 from './features/courses/courseExams/NewCreateCourseExam2';
import EnroledCourseExams from './features/courses/courseExams/EnroledCourseExams';
import EnroledCourseExam from './features/courses/courseExams/EnroledCourseExam';
import NewCreateLiveCourse from './features/courses/adminOrEducatorOrInstitution/NewCreateLiveCourse';
import DisplaySingleLiveCourse from './features/courses/adminOrEducatorOrInstitution/DisplaySingleLiveCourse';
import DisplaySingleLiveCourseTopic from './features/courses/adminOrEducatorOrInstitution/DisplaySingleLiveCourseTopic';
import DisplayEnroledLiveCourse from './features/courses/student/DisplayEnroledLiveCourse';
import DisplayEnroledLiveCourseTopic from './features/courses/student/DisplayEnroledLiveCourseTopic';
import WebcamStream from './features/courses/adminOrEducatorOrInstitution/WebcamStream';
import OBSStream from './features/courses/adminOrEducatorOrInstitution/OBSStream';
import CreateEducatorPlan from './features/subscriptions/admin/CreateEducatorPlan';
import ManageEducatorPlans from './features/subscriptions/admin/ManageEducatorPlans';
import SingleEducatorPlan from './features/subscriptions/admin/SingleEducatorPlan';
import UpdateEducatorPlan from './features/subscriptions/admin/UpdateEducatorPlan';
import SubscribedPlans from './features/subscriptions/educator/SubscribedPlans';
import SingleSubscribedPlan from './features/subscriptions/educator/SingleSubscribedPlan';
import EducatorSubscriptionPlans from './features/subscriptions/public/EducatorSubscriptionPlans';
import TotalEarnings from './features/users/educator/TotalEarnings';
import EducatorPaymentWithdrawalList from './features/users/admin/EducatorPaymentWithdrawalList';
import SingleEducatorWithdrawal from './features/users/admin/SingleEducatorWithdrawal';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLocalSignInStatus());
    if (document && process.env.REACT_APP_NAME) {
      document.title = process.env.REACT_APP_NAME;
    }
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/teacher-plan" element={<TeacherPlan />} />
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/teach-educator" element={<TeachEducator />} />
          <Route path="/how-to-use" element={<HowToUseApp />} />
          <Route path="/select-account" element={<UserSelect />} />
          <Route path="/search" element={<SearchQueryPage />} />
          <Route path="categories" element={<CategoriesManager />} />
          <Route path="categories/:categoryId" element={<SingleCategory />} />
          <Route
            path="subcategories/:subcategoryId"
            element={<SingleSubCategory />}
          />

          <Route path="grades" element={<GradesManager />} />
          <Route path="grades/:gradeId" element={<SingleGrade />} />
          <Route
            path="gradesubjects/:gradeSubjectId"
            element={<SingleGradeSubject />}
          />

          <Route path="publications" element={<PublicationsManager />} />
          <Route
            path="publications/:publicationId"
            element={<SinglePublication />}
          />

          <Route
            path="publicationEditions/:publicationEditionId"
            element={<SinglePublicationEdition />}
          />

          {/* Student Sidebar Routes */}
          <Route path="/student/dashboard" element={<StudentDashboard />} />
          <Route path="/student/profile" element={<StudentProfile />} />

          {/* Admin Dashboard */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />

          {/* Educator Dashboard */}
          <Route path="/educator/dashboard" element={<EducatorDashboard />} />
          {/* Educator Performace */}
          <Route path="/educator/performance" element={<EducatorPerformance />} />
          {/* Educator Engagement */}
          <Route path="/educator/engagement" element={<EducatorEngagement />} />
          {/* Educator Profile */}
          <Route path="/educator/profile" element={<EducatorPlanProfilePayout />} />
          {/* Educator Channel */}
          <Route path="/educator/channel/:Id" element={<EducatorChannel />} />
          {/* Institution Dashboard */}
          <Route path="/institution/dashboard" element={<InstitutionDashboard />} />

          {/* Educator Course Routes */}
          <Route path="add-course" element={<NewCreateCourse />} />
          <Route path="add-live-course" element={<NewCreateLiveCourse />} />
          <Route path="courses" element={<CoursesManager />} />
          <Route path="courses/:Id" element={<SingleCourse />} />
          <Route path="edit-courses/:Id" element={<EditSingleCourse />} />
          <Route path="course/video/:Id" element={<DIsplaySingleCourseVideo />} />

          <Route path="course/violations" element={<CourseViolationManager />} />
          <Route path="course/violations/:Id" element={<SingleCourseViolation />} />

          <Route path="courses/live/:Id" element={<DisplaySingleLiveCourse />} />
          <Route
            path="courses/live/:Id/topic/:topicId"
            element={<DisplaySingleLiveCourseTopic />}
          />

          {/* Student Course Routes */}
          <Route path="enrols/course" element={<EnroledCourses />} />

          <Route path="enrols/course/:enrolId" element={<EnroledCourse />} />
          <Route
            path="enrols/:Id/video/:videoId"
            element={<DisplayEnroledSingleCourseVideo />}
          />

          <Route
            path="enrols/course/live/:enrolId"
            element={<DisplayEnroledLiveCourse />}
          />

          <Route
            path="enrols/course/live/:enrolId/topic/:topicId"
            element={<DisplayEnroledLiveCourseTopic />}
          />

          <Route
            path="enrols/:Id/exam/:examId"
            element={<DisplayEnroledSingleCourseExam />}
          />

          <Route
            path="enrols/:Id/document/:documentId"
            element={<DisplayEnroledSingleCourseDocument />}
          />

          <Route
            path="enrols/:Id/topic/:topicId/external-video"
            element={<DisplayEnroledCourseExternalVideo />}
          />

          {/* Public Courses */}
          <Route
            path=":categoryTitle/:subCategoryId"
            element={<SubCategoryResults />}
          />
          <Route
            path=":categoryTitle/:subCategoryId/:gradeId"
            element={<SubCategoryResults />}
          />
          <Route
            path=":categoryTitle/:subCategoryId/:gradeTitle/:subjectTitle"
            element={<SubCategoryResults />}
          />
          <Route
            path="allgrades/:categoryTitle/:subCategoryId/:gradeId"
            element={<GradeSubjectPage />}
          />
          <Route
            path=":categoryTitle/:subCategoryTitle/course/:courseId"
            element={<DisplaySingleCourse />}
          />
          {/* Exam Routes */}
          <Route path="exams" element={<ExamsManager />} />
          <Route path="exams/:Id" element={<SingleExam />} />
          <Route path="enrols/exam" element={<EnroledExams />} />
          <Route path="enrols/exam/:enrolId" element={<EnroledExam />} />
          <Route path="questions/:questionId" element={<SingleQuestion />} />
          <Route
            path=":categoryTitle/:subCategoryTitle/exam/:examId"
            element={<DisplaySingleExam />}
          />
          <Route path="add-exam" element={<NewCreateExam />} />

          <Route path="course-exams" element={<CourseExamsManager />} />

          <Route path="course/:Id/exams" element={<DispalaySingleCourseExams />} />

          <Route path="course/:Id/add-exam" element={<NewCreateCourseExam />} />
          <Route path="courses/add-exam" element={<NewCreateCourseExam2 />} />

          <Route path="edit-exams/:Id" element={<EditSingleExam />} />
          <Route path="edit-course-exams/:Id" element={<EditSingleCourseExam />} />
          <Route path="enrols/course/exam" element={<EnroledCourseExams />} />
          <Route
            path="enrols/course/exam/:enrolId"
            element={<EnroledCourseExam />}
          />

          {/* Test Routes */}
          <Route path="tests" element={<TestsManager />} />
          <Route path="tests/:Id" element={<SingleTest />} />
          <Route
            path="testSubjects/:testSubjectId"
            element={<SingleTestSubject />}
          />
          <Route path="testTopics/:testTopicId" element={<SingleTestTopic />} />
          <Route
            path="testQuestions/:testQuestionId"
            element={<SingleTestQuestion />}
          />
          <Route
            path=":categoryTitle/:subCategoryTitle/test/:testId"
            element={<DisplaySingleTest />}
          />
          <Route path="enrols/test" element={<EnroledTests />} />

          <Route path="enrols/test/:enrolId" element={<EnroledTest />} />

          <Route path="practice/tests" element={<PracticeTests />} />
          <Route path="practice/tests/:practiceTestId" element={<PracticeTest />} />
          <Route path="add-test" element={<NewCreateTest />} />

          <Route path="edit-tests/:Id" element={<EditSingleTest />} />

          {/* Admin User Management */}

          <Route path="students" element={<StudentsManager />} />
          <Route path="educators" element={<EducatorsManager />} />
          <Route path="institutions" element={<InstitutionsManager />} />

          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

          {/* Admin Videos management */}

          <Route path="videos" element={<VideoManager />} />
          <Route path="videos/:Id" element={<DisplaySingleVideo />} />

          <Route path="video/violations" element={<VideoViolationManager />} />
          <Route path="video/violations/:Id" element={<SingleVideoViolation />} />
          {/* Admin Documents */}

          <Route path="documents" element={<DocumentManager />} />

          <Route
            path="/resend/email/verification"
            element={<ResendEmailVerification />}
          />

          {/* Admin Keywords */}
          <Route path="keywords" element={<KeywordsManager />} />
          <Route path="keywords/:Id" element={<SingleKeyword />} />

          {/* Admin Learning Objectives */}
          <Route
            path="learning/objectives"
            element={<LearningObjectivesManager />}
          />
          <Route
            path="learning/objectives/:Id"
            element={<SingleLearningObjective />}
          />

          {/* Support Tickets */}
          <Route path="manage/support/tickets" element={<SupportTicketManager />} />
          <Route path="support/tickets" element={<UserSupportTicketManager />} />
          <Route path="support/ticket/:Id" element={<SingleSupportTicket />} />

          {/* User Following and Followers */}
          <Route path="following" element={<UserFollowing />} />
          <Route path="followers" element={<UserFollowers />} />

          {/* User Reviews */}
          <Route path="reviews" element={<UserReviews />} />

          {/* Footer Pages Route */}
          <Route path="privacy-policy" element={<PrivacyPloicy />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route
            path="subscription-cancellation-policy"
            element={<SubscriptionCancellationPolicy />}
          />
          <Route path="terms-conditions" element={<TermsConditions />} />
          <Route
            path="acceptance-of-agreement"
            element={<AcceptanceOfAgreement />}
          />

          <Route path="web-streaming" element={<WebcamStream />} />
          <Route path="obs-streaming/:Id" element={<OBSStream />} />

          {/* Admin educator subscription plans */}

          <Route
            path="create-educator-subscription-plan"
            element={<CreateEducatorPlan />}
          />
          <Route
            path="educator-subscription-plans"
            element={<ManageEducatorPlans />}
          />
          <Route
            path="educator-subscription-plans/:Id"
            element={<SingleEducatorPlan />}
          />
          <Route
            path="edit-educator-subscription-plans/:Id"
            element={<UpdateEducatorPlan />}
          />

          {/* Educator subscription */}
          <Route path="subscribed-plans" element={<SubscribedPlans />} />
          <Route path="subscribed-plans/:Id" element={<SingleSubscribedPlan />} />

          <Route
            path="educator/subscription/plans"
            element={<EducatorSubscriptionPlans />}
          />

          {/* Educator Earnings */}

          <Route path="educator-earnings" element={<TotalEarnings />} />

          {/* Educator Withdrawals */}

          <Route
            path="educator-withdrawals"
            element={<EducatorPaymentWithdrawalList />}
          />
          <Route
            path="educator-withdrawals/:Id"
            element={<SingleEducatorWithdrawal />}
          />
        </Route>
        <Route path="*" element={<NotFoundPage />} />

        {/* Authentication Routes */}
        <Route path="/student-account" element={<StudentRegistrationPage />} />
        <Route path="/educator-account" element={<EducatorRegistrationPage />} />
        <Route
          path="/institution-account"
          element={<InstitutionRegistrationPage />}
        />
        <Route
          path="/verify-email/:id/:expire/:signature"
          element={<VerifyEmailPage />}
        />
        <Route path="/account" element={<AccountPage />} />
      </Routes>
    </div>
  );
}

export default App;
