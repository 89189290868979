/* eslint-disable no-unused-vars */

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCourseExamMutation,
  useUpdateCourseExamSatusMutation,
} from '../../../app/services/CourseExamsApi';

import { useCourseQuery, useCoursesQuery } from '../../../app/services/CoursesApi';
import { uploadCourseExamImageRequest } from '../../../app/services/UploadRequests';

import DataLoader from '../../../components/DataLoader';
import { allLanguages } from '../../../Uitils';
import DisplayCourseExamPreview from './DisplayCourseExamPreview';
import NewCreateCourseExamQuestion from './NewCreateCourseExamQuestion';

const steps = ['Create Exam', 'Progress', 'Questions', 'Preview', 'Publish Status'];

function NewCreateCourseExam2() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useCoursesQuery();

  const { token, isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [createCourseExam] = useCreateCourseExamMutation();
  const [updateExamStatus] = useUpdateCourseExamSatusMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get('examId');
  const [courseTitle, setCourseTitle] = useState('');

  const [selectedCourse, setSelectedCourse] = useState({
    title: '',
    id: null,
  });
  useEffect(() => {
    if (queryParams.get('courseId') && data?.courses.length > 0) {
      handleCourseChange({
        target: {
          value: parseInt(queryParams.get('courseId')),
        },
      });
    }
  }, [queryParams.get('courseId'), data]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [level, setLevel] = useState('beginner');
  const [examId, setExamId] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const allowedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const [questions, setQuestions] = useState(false);
  const [examStatus, setExamStatus] = useState(false);
  const [imageStatus, setImageStatus] = useState(false);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const [examCostType, setExamCostType] = useState(null);
  const [examCost, setExamCost] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    setExamId(queryParamValue);
  }, [queryParamValue]);

  const setNextButton = (truthValue) => {
    setQuestions(truthValue);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleCourseChange = (event) => {
    const tempSelectedCourse = data?.courses?.find(
      (singleCourse) => singleCourse.id === event.target.value
    );
    setSelectedCourse({ ...tempSelectedCourse });
    setCourseTitle(event.target.value);
  };

  const validateData = () => {
    let err = true;
    if (title.trim().length === 0 || description.trim().length === 0) {
      enqueueSnackbar(
        'Title or Description cannot be empty or contain only spaces',
        {
          variant: 'error',
        }
      );
      return err;
    }
    if (!title || !language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = () => {
    let examData = {
      title: title,
      description: description,
      course_id: selectedCourse?.id,
      language: language,
      level: level,
      cost: examCostType === 'paid' ? examCost : null,
    };

    createCourseExam(examData)
      .unwrap()
      .then((res) => {
        setExamStatus('success');
        setExamId(res.course_exam.id);

        if (res.course_exam.id && selectedImage) {
          const UploadData = {
            file: selectedImage,
            token: token,
          };

          uploadCourseExamImageRequest(UploadData, res.course_exam.id)
            .then((response) => {
              if (response.url) {
                setImageStatus('success');
              } else {
                setImageStatus('error');
              }
            })
            .catch((err) => {
              setImageStatus('error');
            });
        } else {
          setImageStatus('error');
        }
      })
      .catch((error) => {
        setExamStatus('error');
        setImageStatus('error');
      });
  };

  const onStatusChange = (id, status) => {
    updateExamStatus({ id, status });
  };

  return !isSignedIn ? (
    <Typography>Not Authorized</Typography>
  ) : (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xl="10" lg="10" md="12" sm="11" xs="11">
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#ee3163',
                fontSize: '18px',
                fontWeight: 600,
                marginBottom: '1rem',
              }}
            >
              New Exam for Course {selectedCourse?.title}
            </Typography>
            <Stepper activeStep={activeIndex} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            {activeIndex == 0 && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xl="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  style={{ height: 'auto' }}
                >
                  <TextField
                    select
                    required
                    fullWidth
                    id="course"
                    label="Course"
                    onChange={handleCourseChange}
                    name="course"
                    autoComplete="course"
                    autoFocus
                    value={courseTitle}
                    defaultValue={selectedCourse?.title}
                  >
                    {data?.courses && data?.courses.length
                      ? data?.courses.map((singleCourse) => (
                          <MenuItem value={singleCourse.id} key={singleCourse.id}>
                            {singleCourse.title}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xl="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  style={{ height: 'auto' }}
                >
                  <TextField
                    required
                    fullWidth
                    id="title"
                    label=" Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    onChange={handleTitleChange}
                  />
                </Grid>
                <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                  <TextField
                    required
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete="description"
                    autoFocus
                    multiline
                    rows="2"
                    onChange={handleDescriptionChange}
                  />
                </Grid>

                <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                  <TextField
                    required
                    fullWidth
                    id="language"
                    label="What Language the exam is in"
                    name="language"
                    autoComplete="language"
                    autoFocus
                    onChange={handleLanguageChange}
                    select
                  >
                    {Array.isArray(allLanguages) && allLanguages.length > 0
                      ? allLanguages.map((singleLanguage) => (
                          <MenuItem value={singleLanguage} key={singleLanguage}>
                            {singleLanguage}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
                <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                  <TextField
                    required
                    fullWidth
                    id="examfee"
                    label="Exam Fee"
                    name="examfee"
                    autoComplete="examfee"
                    autoFocus
                    onChange={(e) => {
                      setExamCostType(e.target.value);
                    }}
                    select
                  >
                    {[
                      { label: 'Free', value: 'free' },
                      { label: 'Paid', value: 'paid' },
                    ].map((singleItem) => (
                      <MenuItem value={singleItem.value} key={singleItem.value}>
                        {singleItem.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {examCostType === 'paid' && (
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="cost"
                      label="Cost"
                      name="cost"
                      autoComplete="cost"
                      autoFocus
                      type="number"
                      onChange={(e) => {
                        setExamCost(e.target.value);
                      }}
                      defaultValue={examCost}
                    />
                  </Grid>
                )}

                <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Learning Level
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={level}
                      onChange={handleLevelChange}
                    >
                      <FormControlLabel
                        value="beginner"
                        control={<Radio />}
                        label="Beginner"
                      />
                      <FormControlLabel
                        value="intermediate"
                        control={<Radio />}
                        label="Intermediate"
                      />
                      <FormControlLabel
                        value="expert"
                        control={<Radio />}
                        label="Expert"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
                  <Box sx={{ height: '100%' }}>
                    <Grid container spacing={1}>
                      <Grid item xl="3" lg="3" md="3" sm="3" xs="3">
                        <img
                          src={imageUrl ? imageUrl : null}
                          style={{
                            border: '1px solid #c7c7c7',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </Grid>
                      <Grid item xl="9" lg="9" md="9" sm="9" xs="9">
                        {/* <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                            {`${
                              selectedCategory?.title == 'Academics' ? 'Textbook' : ''
                            } Cover Image`}
                          </Typography> */}
                        <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                          Upload exam image here. It must meet our exam image quality
                          standard to be accepted. Important guidelines: 750*422
                          pixels; .jpg, .png, .jpeg, .gif or .png no text on the
                          image
                        </Typography>
                        <form className="form" style={{ marginTop: '2rem' }}>
                          <div
                            className="file-upload-wrapper"
                            data-text={
                              selectedImage ? selectedImage.name : 'No File Selected'
                            }
                            data-button-text="Select File"
                          >
                            <input
                              name="file-upload-field"
                              type="file"
                              accept="image/*"
                              className="file-upload-field"
                              value=""
                              onChange={(e) => {
                                if (
                                  allowedImageTypes.includes(e.target.files[0].type)
                                ) {
                                  setSelectedImage(e.target.files[0]);
                                } else {
                                  enqueueSnackbar(
                                    'Please select a PNG, JPEG, or SVG file.',
                                    {
                                      variant: 'error',
                                    }
                                  );
                                }
                              }}
                            />
                          </div>
                        </form>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}

            {activeIndex == 1 && (
              <div style={{ width: '100%' }}>
                <Divider textAlign="left">Exam Description</Divider>

                <Box
                  sx={{
                    display: 'flex',
                    minInlineSize: '-webkit-fill-available',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      {title}
                    </Typography>
                  </Box>
                  {examStatus === false ? <CircularProgress disableShrink /> : null}
                  {examStatus === 'success' ? (
                    <Alert severity="success">Exam Created</Alert>
                  ) : null}
                  {examStatus === 'error' ? (
                    <Alert severity="error">(Error Creating Exam)</Alert>
                  ) : null}
                </Box>

                <Divider textAlign="left">Exam Image</Divider>

                <Grid container spacing={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      minInlineSize: '-webkit-fill-available',
                      mt: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    {selectedImage ? (
                      <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                        <img
                          src={imageUrl}
                          style={{
                            border: '1px solid #c7c7c7',
                            // width: '100%',
                            // height: '100%',
                            height: '500px',
                            width: '300px',
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                      <>
                        {imageStatus === false ? (
                          <CircularProgress disableShrink />
                        ) : null}
                        {imageStatus === 'success' ? (
                          <Alert severity="success">Exam Image Uploaded</Alert>
                        ) : null}
                        {imageStatus === 'error' && selectedImage ? (
                          <>
                            <Alert severity="error">Error Uploading Image</Alert>
                          </>
                        ) : null}
                        {!selectedImage ? (
                          <>
                            <Alert severity="error">No Image Provided</Alert>
                          </>
                        ) : null}
                      </>
                    </Grid>
                  </Box>
                </Grid>
              </div>
            )}
            {activeIndex == 2 &&
              (queryParamValue || examId ? (
                <NewCreateCourseExamQuestion
                  examId={examId}
                  nextButton={setNextButton}
                />
              ) : null)}
            {activeIndex == 3 && <DisplayCourseExamPreview examId={examId} />}
            {activeIndex == 4 && (
              <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography variant="subtitle1" paragraph gutterBottom>
                      Thank You for Creating Your Exam for Course{' '}
                      {selectedCourse?.title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph gutterBottom>
                      Click Below Button to Submit for Review.
                    </Typography>
                    {/* <Button
                          type="button"
                          variant="contained"
                          onClick={() => navigate(`/exams`)}
                        >
                          Submit For Review And Exit
                        </Button> */}
                    <Alert severity="info">
                      If You Submit, Admin will review and Publish your Course Exam.
                      You will recieve notification.{' '}
                    </Alert>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid
              item
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box>
                {activeIndex > 0 && (
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // disabled={result.isLoading || result.isSuccess}
                    // onClick={() => {
                    //   if (activeIndex == 1) {
                    //     setActiveIndex(0);
                    //     if (examId && location.search == '') {
                    //       navigate({ search: `examId=${examId}` });
                    //       getCreatedexamDetails(examId);
                    //     }
                    //   } else {
                    //     if ((activeIndex == 2 || activeIndex == 3) && examId) {
                    //       getCreatedexamDetails(examId);
                    //     }
                    //     if (activeIndex > 1) setActiveIndex(activeIndex - 1);
                    //   }
                    // }}
                    onClick={() => setActiveIndex(activeIndex - 1)}
                  >
                    Previous
                  </Button>
                )}
              </Box>

              <Box>
                {activeIndex == 4 && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      onStatusChange(examId, 'in_review');
                      navigate(`/course-exams`);
                    }}
                    sx={{ mt: 3, mb: 2, mr: 2 }}
                  >
                    Submit For Review And Exit
                  </Button>
                )}

                {activeIndex <= 3 && (
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {
                      if (activeIndex === 0) {
                        const res = validateData();
                        if (!res) {
                          handleSubmit();
                          setActiveIndex(activeIndex + 1);
                        }
                      }
                      if (activeIndex === 1) {
                        if (examId) {
                          setActiveIndex(activeIndex + 1);
                        } else if (examStatus === 'error') {
                          enqueueSnackbar('Error Creating Exam', {
                            variant: 'error',
                          });
                        } else {
                          enqueueSnackbar(' Creating Exam, Please Wait', {
                            variant: 'success',
                          });
                        }
                      }

                      if (activeIndex === 2) {
                        if (questions) {
                          setActiveIndex(activeIndex + 1);
                        } else {
                          enqueueSnackbar('Please Provide at least one Question', {
                            variant: 'error',
                          });
                        }
                      }
                      if (activeIndex === 3) {
                        setActiveIndex(activeIndex + 1);
                      }
                    }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default NewCreateCourseExam2;
