/* eslint-disable no-unused-vars */

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  usePublicPublicationsQuery,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';
import { useUpdateTestMutation } from '../../../app/services/TestsApi';
import { uploadTestImageRequest } from '../../../app/services/UploadRequests';
import { allLanguages } from '../../../Uitils';

function EditTest(props) {
  const { test } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSelector((state) => state.auth);
  const [updateTest] = useUpdateTestMutation();
  const { data } = usePublishedCategoriesQuery();
  const publicationsRes = usePublicPublicationsQuery();

  const [title, setTitle] = useState(test.title || '');
  const [description, setDescription] = useState(test.description || '');
  const [language, setLanguage] = useState(test.language, '');
  const [categoryTitle, setCategoryTitle] = useState(
    test.sub_category.category.id || ''
  );

  const [subCategoryTitle, setSubCategoryTitle] = useState(
    test.sub_category.id || ''
  );
  const [gradeTitle, setGradeTitle] = useState(test.grade ? test.grade.id : '');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState(
    test.grade_subject ? test.grade_subject.id : ''
  );

  const [publicationTitle, setPublicationTitle] = useState(
    test.publication ? test.publication.id : ''
  );
  const [publicationEditionTitle, setPublicationEditionTitle] = useState(
    test.publication_edition ? test.publication_edition.id : ''
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const allowedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const [isDisabled, setIsDiabled] = useState(false);
  const [loading, setLoading] = useState(false);
  let [buttonType, setButtonType] = useState('');

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [selectedCategory, setSelectedCategory] = useState(
    test.sub_category.category || {
      title: '',
      id: null,
      published_sub_categories: [],
    }
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    test.sub_category || {
      title: '',
      id: null,
    }
  );

  const [selectedGrade, setSelectedGrade] = useState(
    test.grade || {
      title: '',
      id: null,
    }
  );

  const [selectedGradeSubject, setSelectedGradeSubject] = useState(
    test.grade_subject || {
      title: '',
      id: null,
    }
  );

  const [selectedPublication, setSelectedPublication] = useState(
    test.publication || {
      title: '',
      id: null,
      publication_editions: [],
    }
  );

  const [selectedPublicationEdition, setSelectedPublicationEdition] = useState(
    test.publication_edition || {
      edition: '',
      id: null,
    }
  );

  const [level, setLevel] = useState(test.level || 'beginner');

  const [testCostType, setTestCostType] = useState(test?.cost ? 'paid' : 'free');
  const [testCost, setTestCost] = useState(test?.cost || null);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data?.categories?.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);

    setSelectedSubCategory({
      title: '',
      id: null,
    });

    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationChange = (event) => {
    const tempSelectedPublication = publicationsRes.data.publications.find(
      (singlePublication) => singlePublication.id === event.target.value
    );

    setSelectedPublication(tempSelectedPublication);
    setPublicationTitle(event.target.value);
    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationEditionChange = (event) => {
    const tempPublicationEdition = selectedPublication.publication_editions.find(
      (singlePublicationEdition) =>
        singlePublicationEdition.id === event.target.value
    );

    setSelectedPublicationEdition(tempPublicationEdition);
    setPublicationEditionTitle(event.target.value);
  };

  const validateData = (testData) => {
    let err = true;

    if (
      testData.title.trim().length === 0 ||
      testData.description.trim().length === 0
    ) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });
      setIsDiabled(false);
      return err;
    }
    if (!testData.title || !testData.sub_category_id || !testData.language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
      setIsDiabled(false);
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    setIsDiabled(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const testData = {
      id: test.id,
      title: title,
      description: description,
      sub_category_id: selectedSubCategory.id,
      language: language,
      // start_date: startDate.format(),
      // end_date: endDate.format(),
      educator_id: test.educator_id,
      institution_id: test.institution_id,
      grade_id: selectedGrade.id,
      publication_id: selectedPublication.id,
      grade_subject_id: selectedGradeSubject.id,
      publication_edition_id: selectedPublicationEdition.id,
      level: level,
      cost: testCostType === 'paid' ? testCost : null,
    };

    const res = validateData(testData);

    if (!res) {
      updateTest(testData)
        .then((res) => {
          enqueueSnackbar(res?.data?.message || 'Test Updated', {
            variant: 'success',
          });
          setIsDiabled(false);
          if (buttonType === 'saveasdraft' && props.handleExternalSaveAsDraft) {
            props.handleExternalSaveAsDraft && props.handleExternalSaveAsDraft();
          }
          if (buttonType === 'next' && props.handleExternalNextButton) {
            props.handleExternalNextButton && props.handleExternalNextButton();
          }
        })
        .catch((error) => {
          enqueueSnackbar('Test Not Updated (Refresh the page)', {
            variant: 'error',
          });
          setIsDiabled(false);
        });
    }
  };

  const UploadImage = () => {
    // event.preventDefault();
    setLoading(true);
    if (props.setImageStatus) {
      props.setImageStatus(false);
    }
    const UploadData = {
      file: selectedImage,
      token: token,
    };
    uploadTestImageRequest(UploadData, test.id).then((res) => {
      if (res.url) {
        setLoading(false);
        enqueueSnackbar(
          'Image Uploaded Successfully. Please refresh your page for now.',
          {
            variant: 'success',
          }
        );
        if (props.setImageStatus) {
          props.setImageStatus('success');
        }
      } else {
        setLoading(false);

        enqueueSnackbar('Error Uploading Image. ', {
          variant: 'error',
        });
        if (props.setImageStatus) {
          props.setImageStatus('error');
        }
      }
    });
  };

  return (
    <Fragment>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            item
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            style={{ height: 'auto' }}
          >
            <TextField
              required
              fullWidth
              id="title"
              label=" Title"
              name="title"
              autoComplete="title"
              autoFocus
              onChange={handleTitleChange}
              defaultValue={test.title}
            />
          </Grid>
          <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              autoFocus
              multiline
              rows="2"
              onChange={handleDescriptionChange}
              defaultValue={test.description}
            />
          </Grid>
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              select
              required
              fullWidth
              id="category"
              label="Category"
              onChange={handleCategoryChange}
              name="category"
              autoComplete="category"
              autoFocus
              value={categoryTitle}
            >
              {data?.categories && data?.categories.length
                ? data?.categories.map((singleCategory) => (
                    <MenuItem value={singleCategory.id} key={singleCategory.id}>
                      {singleCategory.title}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>

          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              select
              required
              fullWidth
              id="sub_category"
              label="Sub Category"
              onChange={handleSubCategoryChange}
              name="sub_category"
              autoComplete="sub_category"
              autoFocus
              value={subCategoryTitle}
            >
              {selectedCategory && selectedCategory.published_sub_categories?.length
                ? selectedCategory.published_sub_categories.map(
                    (singleSubCategory) => (
                      <MenuItem
                        value={singleSubCategory.id}
                        key={singleSubCategory.id}
                      >
                        {singleSubCategory.title}
                      </MenuItem>
                    )
                  )
                : null}
            </TextField>
          </Grid>

          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedSubCategory.grades?.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="grade"
                label="Grade"
                onChange={handleGradeChange}
                name="grade"
                autoComplete="grade"
                autoFocus
                value={gradeTitle}
              >
                {selectedCategory &&
                selectedSubCategory &&
                selectedSubCategory.grades?.length
                  ? selectedSubCategory.grades.map((singleGrade) => (
                      <MenuItem value={singleGrade.id} key={singleGrade.id}>
                        {singleGrade.title}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ) : null}
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedGrade.id &&
          selectedGrade.grade_subjects?.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="grade_subject"
                label="Grade Subject"
                onChange={handleGradeSubjectChange}
                name="grade subject"
                autoComplete="grade subject"
                autoFocus
                value={gradeSubjectTitle}
              >
                {selectedGrade.grade_subjects?.length
                  ? selectedGrade.grade_subjects.map((singleGradeSubject) => (
                      <MenuItem
                        value={singleGradeSubject.id}
                        key={singleGradeSubject.id}
                      >
                        {singleGradeSubject.title}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ) : null}
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedGrade.id &&
          publicationsRes.data?.publications?.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="publication"
                label="Publication"
                onChange={handlePublicationChange}
                name="publication"
                autoComplete="publication"
                autoFocus
                value={publicationTitle}
              >
                {publicationsRes.data.publications?.length
                  ? publicationsRes.data.publications.map((singlePublication) => (
                      <MenuItem
                        value={singlePublication.id}
                        key={singlePublication.id}
                      >
                        {singlePublication.title}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ) : null}
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedGrade.id &&
          publicationsRes.data?.publications?.length &&
          selectedPublication.id &&
          selectedPublication.publication_editions?.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="publication_edition"
                label="Publication Edition"
                onChange={handlePublicationEditionChange}
                name="Publication Edition"
                autoComplete="Publication Edition"
                autoFocus
                value={publicationEditionTitle}
              >
                {selectedPublication.publication_editions?.length
                  ? selectedPublication.publication_editions.map(
                      (singlePublicationEdition) => (
                        <MenuItem
                          value={singlePublicationEdition.id}
                          key={singlePublicationEdition.id}
                        >
                          {singlePublicationEdition.edition}
                        </MenuItem>
                      )
                    )
                  : null}
              </TextField>
            </Grid>
          ) : null}
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="language"
              label="What Language the test is in"
              name="language"
              autoComplete="language"
              autoFocus
              onChange={handleLanguageChange}
              select
              defaultValue={test.language}
            >
              {Array.isArray(allLanguages) && allLanguages.length > 0
                ? allLanguages.map((singleLanguage) => (
                    <MenuItem value={singleLanguage} key={singleLanguage}>
                      {singleLanguage}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="testfee"
              label="Test Fee"
              name="testfee"
              autoComplete="testfee"
              autoFocus
              onChange={(e) => {
                setTestCostType(e.target.value);
              }}
              select
              defaultValue={testCostType}
            >
              {[
                { label: 'Free', value: 'free' },
                { label: 'Paid', value: 'paid' },
              ].map((singleItem) => (
                <MenuItem value={singleItem.value} key={singleItem.value}>
                  {singleItem.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {testCostType === 'paid' && (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                required
                fullWidth
                id="cost"
                label="Cost"
                name="cost"
                autoComplete="cost"
                autoFocus
                type="number"
                onChange={(e) => {
                  setTestCost(e.target.value);
                }}
                defaultValue={testCost}
              />
            </Grid>
          )}

          <Grid item xl="4" lg="4" md="12" sm="12" xs="12">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Learning Level
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={level}
                onChange={handleLevelChange}
              >
                <FormControlLabel
                  value="beginner"
                  control={<Radio />}
                  label="Beginner"
                />
                <FormControlLabel
                  value="intermediate"
                  control={<Radio />}
                  label="Intermediate"
                />
                <FormControlLabel
                  value="expert"
                  control={<Radio />}
                  label="Expert"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xl="5" lg="5" md="12" sm="12" xs="12">
            <Box sx={{ height: '100%' }}>
              <Grid container spacing={1}>
                <Grid item xl="3" lg="3" md="3" sm="3" xs="3">
                  <img
                    src={imageUrl ? imageUrl : test?.url || test?.image_url}
                    style={{
                      border: '1px solid #c7c7c7',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Grid>
                <Grid item xl="9" lg="9" md="9" sm="9" xs="9">
                  <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    Textbook Cover Image
                  </Typography>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                    title={`Upload Test image here. It must meet our test image quality
                      standard to be accepted. Important guidelines: 750*422 pixels;
                      .jpg, .png, .jpeg, .gif or .png no text on the image`}
                  >
                    <label
                      style={{
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '3',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      Upload Test image here. It must meet our test image quality
                      standard to be accepted. Important guidelines: 750*422 pixels;
                      .jpg, .png, .jpeg, .gif or .png no text on the image
                    </label>
                  </Typography>
                  <form
                    className="form"
                    // onSubmit={UploadImage}
                    style={{ marginTop: '2rem' }}
                  >
                    <div
                      className="file-upload-wrapper"
                      data-text={
                        selectedImage ? selectedImage.name : 'No File Selected'
                      }
                      data-button-text="Select File"
                    >
                      <input
                        name="file-upload-field"
                        type="file"
                        accept="image/*"
                        className="file-upload-field"
                        value=""
                        onChange={(e) => {
                          if (allowedImageTypes.includes(e.target.files[0].type)) {
                            setSelectedImage(e.target.files[0]);
                          } else {
                            enqueueSnackbar(
                              'Please select a PNG, JPEG, or SVG file.',
                              {
                                variant: 'error',
                              }
                            );
                          }
                        }}
                      />
                    </div>
                    {selectedImage && (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          UploadImage();
                        }}
                        sx={{ marginTop: '1rem' }}
                      >
                        Upload
                      </Button>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={
            props.showExtraButton ? { display: 'flex', justifyContent: 'end' } : {}
          }
        >
          {props.showExtraButton ? (
            <>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                onClick={() => {
                  buttonType = 'saveasdraft';
                  setButtonType(buttonType);
                }}
              >
                Save as Draft
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  buttonType = 'next';
                  setButtonType(buttonType);
                }}
              >
                Next
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
            >
              Update Test
            </Button>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}

export default EditTest;
