/* eslint-disable no-unused-vars */

import { Grid } from '@mui/material';
import React from 'react';
import { useTestsQuery } from '../../../app/services/TestsApi';
import DataLoader from '../../../components/DataLoader';
import TestsList from './TestsList';

function TestsManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useTestsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={1} justifyContent={'center'}>
        <Grid item xs="11" sm="11" md="11" lg="11" xl="11">
          {isSuccess ? <TestsList tests={data?.tests} /> : <p>No Tests found</p>}
        </Grid>
      </Grid>
    </>
  );
}

export default TestsManager;
