/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const subscriptionPlansApi = BaseApi.injectEndpoints({
  reducerPath: 'subscriptionPlansApi',
  endpoints: (builder) => ({
    educatorSubscriptionPlans: builder.query({
      query: () => ({
        url: '/educator/subscription/plans',
        method: 'GET',
      }),
      providesTags: ['EducatorSubscriptionPlan'],
    }),
    createEducatorSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          features: details.features,
          cost_per_month: details.cost_per_month,
        },
      }),
      invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    educatorSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/educator/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['EducatorSubscriptionPlan'],
    }),
    updateEducatorSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: `/educator/subscription/plan/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          features: details.features,
          cost_per_month: details.cost_per_month,
        },
      }),
      invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    updateEducatorSubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          educator_subscription_plan_id: details.id,
        },
      }),
      invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    publishedEducatorSubscriptionPlans: builder.query({
      query: (details) => ({
        url: `/public/educator/subscription/plans`,
        method: 'GET',
      }),
      providesTags: ['EducatorSubscriptionPlan'],
    }),
  }),
});

export const {
  useEducatorSubscriptionPlansQuery,
  useCreateEducatorSubscriptionPlanMutation,
  useEducatorSubscriptionPlanQuery,
  useUpdateEducatorSubscriptionPlanMutation,
  useUpdateEducatorSubscriptionPlanSatusMutation,
  usePublishedEducatorSubscriptionPlansQuery,
} = subscriptionPlansApi;
