/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {
  ArrowDropDown,
  CheckBox,
  Clear,
  ExpandMore,
  FormatListBulleted,
} from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { styled, alpha } from '@mui/material/styles';
import { groupBy } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { TableVirtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search';
import {
  useDeleteExamMutation,
  useUpdateExamSatusMutation,
} from '../../../app/services/ExamsApi';
import ChangeExamStatus from './admin/ChangeExamStatus';

const columns = [
  {
    width: 40,
    label: 'Sr. No',
  },
  {
    width: 70,
    label: 'Exam ID',
  },
  {
    width: 200,
    label: 'Title',
  },
  {
    width: 120,
    label: 'Status',
  },
  {
    width: 120,
    label: 'Category',
  },
  {
    width: 120,
    label: 'Sub Category',
  },
  {
    width: 120,
    label: 'Created On',
  },
  {
    width: 120,
    label: 'Action',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            // backgroundColor: 'background.paper',
            background: '#10496a',
            color: '#fff',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function generateGroupedVideos(exams) {
  const groupedExams = groupBy(exams, (exam) => exam.title[0]);
  const groupCounts = Object.values(groupedExams).map((users) => users.length);
  const groups = Object.keys(groupedExams);

  return { groupCounts, groups };
}

function ExamsList(props) {
  const { exams } = props;
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const virtuoso = useRef(null);
  const [selectedExam, setSelectedExam] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [createEL, setCreateEL] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const open = Boolean(anchorEl);
  const createOpen = Boolean(createEL);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filteredExams, setFilteredExam] = useState(exams ? exams : []);

  const [isOpen1, setIsOpen1] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const changeStatus = (e) => {
    e.stopPropagation();
    setIsOpen1(true);
  };

  const handleClose1 = () => {
    setIsOpen1(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleMenuClick = (event, exam) => {
    setSelectedExam(exam);
    setAnchorEl(event.currentTarget);
  };
  const handleCreateMenuClick = (event) => {
    setCreateEL(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseCreate = () => {
    setCreateEL(null);
  };

  const [updateExamStatus] = useUpdateExamSatusMutation();

  const [deleteExam] = useDeleteExamMutation();

  const viewExam = (id) => {
    navigate(`/exams/${id}`);
  };
  const editExam = (id) => {
    navigate(`/edit-exams/${id}`);
  };

  const editCourseExam = (id) => {
    navigate(`/edit-course-exams/${id}`);
  };

  // const onStatusChange = (id, status) => {
  //   const res = updateExamStatus({ id, status });
  // };

  const onDelete = (id) => {
    deleteExam({ id });
    handleCloseDialog();
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    const updateData = {
      id: selectedExam.id,
      status: 'publish_request',
      published_at: null,
    };

    updateExamStatus(updateData)
      .unwrap()
      .then((res) => {
        if (res.message === 'UPDATED_EXAM_STATUS') {
          enqueueSnackbar('Requested To Publish Success', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Error Requesting to publish ', {
            variant: 'error',
          });
        }
      });
  };

  generateGroupedVideos(filteredExams);
  const renderMenuItems = (selectedExam) => {
    return selectedExam ? (
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            viewExam(selectedExam.id);
            handleClose();
          }}
          sx={{ background: '#f9f5f5', borderBottom: '1px soild #fff' }}
        >
          Preview
        </MenuItem>
        {user?.role === 'admin' ? (
          <MenuItem
            onClick={(e) => changeStatus(e, selectedExam)}
            sx={{ background: '#f9f5f5' }}
          >
            Change Status
          </MenuItem>
        ) : null}
        {selectedExam?.status === 'draft' && user?.role === 'educator' ? (
          <MenuItem onClick={onChangeStatus} sx={{ background: '#f9f5f5' }}>
            Request To Publish
          </MenuItem>
        ) : null}
        {selectedExam.status !== 'published' ? (
          <MenuItem
            onClick={() => {
              if (selectedExam.course_id) {
                editCourseExam(selectedExam.id);
              } else {
                editExam(selectedExam.id);
              }
              handleClose();
            }}
            sx={{ background: '#f9f5f5' }}
          >
            Edit Exam
          </MenuItem>
        ) : null}
        {selectedExam.status !== 'published' ? (
          <MenuItem
            onClick={() => {
              handleClickOpenDialog();
              // onDelete(selectedExam.id);
              // handleClose();
            }}
            sx={{ background: '#f9f5f5' }}
          >
            Delete Exam
          </MenuItem>
        ) : null}
      </StyledMenu>
    ) : null;
  };
  const rendeCreaterMenuItems = () => {
    return (
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={createEL}
        open={createOpen}
        onClose={handleCloseCreate}
      >
        <MenuItem
          onClick={() => {
            navigate('/add-exam');
            handleCloseCreate();
          }}
          sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
        >
          Create Exam
        </MenuItem>
      </StyledMenu>
    );
  };
  const onSearchClick = (inputSearchValue) => {
    if (inputSearchValue.trim().length === 0) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });

      return;
    } else {
      let finalFilterExam = filteredExams.filter((elem) =>
        elem.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      console.log('thisi s', finalFilterExam);
      setFilteredExam([...finalFilterExam]);
    }
  };
  const onClearSearchClick = () => {
    setInputValue('');
    if (selectedStatus) {
      const finalData =
        Array.isArray(exams) &&
        exams.length > 0 &&
        exams.filter((elem) => elem.status === selectedStatus);

      setFilteredExam([...finalData]);
    } else {
      setFilteredExam([...exams]);
    }
  };
  return (
    <>
      {user?.role === 'educator' ? (
        <Alert severity="info">
          To edit published exams please request permission by creating a support
          ticket and posting a screenshot.
        </Alert>
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {[
            { displayName: 'Draft', name: 'draft' },
            { displayName: 'In Review', name: 'in_review' },
            { displayName: 'Published', name: 'published' },
            { displayName: 'On Hold', name: 'on_hold' },
            { displayName: 'Rejected', name: 'rejected' },
            {
              displayName: 'Requested To Publish',
              name: 'publish_request',
            },
          ].map((item, ind) => (
            <Typography
              sx={{
                color: selectedStatus == item?.name ? '#fff' : '#300c1e',
                fontSize: '16px',
                lineHeight: '32px',
                width: item?.name == 'publish_request' ? '200px' : '100px',
                background: selectedStatus == item?.name ? '#10496a' : '#def0f5',
                paddingLeft: '1rem',
                paddingTop: '0.3rem',
                paddingBottom: '0.3rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedStatus(item?.name);

                const finalData =
                  Array.isArray(exams) &&
                  exams.length > 0 &&
                  exams.filter((elem) => elem.status === item?.name);

                setFilteredExam([...finalData]);
              }}
              key={'key' + ind}
            >
              {item?.displayName}
            </Typography>
          ))}
        </div>
        <div>
          <TextField
            placeholder="Search exams"
            required
            onChange={(_event) => {
              setInputValue(_event.target.value);
            }}
            value={inputValue}
            InputProps={{
              type: '',
              endAdornment: (
                <InputAdornment position="end">
                  {inputValue && (
                    <IconButton
                      onClick={() => onClearSearchClick()}
                      // disabled={!inputValue.length}
                    >
                      <Clear />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => onSearchClick(inputValue)}
                    // disabled={!inputValue.length}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ marginRight: '0.5rem' }}
          />
          <Button
            id="demo-customized-button"
            aria-controls={createOpen ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={createOpen ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleCreateMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{ background: '#09768d' }}
          >
            Create Exam
          </Button>
          {rendeCreaterMenuItems()}
        </div>
      </div>
      {exams.length > 0 ? (
        <Paper style={{ height: 400, width: '100%' }}>
          <TableVirtuoso
            data={filteredExams}
            ref={virtuoso}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            checkboxSelection
            className="exam-list-table"
            itemContent={(index, singleExam) => (
              <>
                {' '}
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ padding: '8px', color: '#0c778e' }}
                >
                  {index + 1}
                </TableCell>
                <TableCell sx={{ padding: '8px', color: '#0c778e' }}>
                  {singleExam.id}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={singleExam?.title}
                >
                  {singleExam.title}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                    textTransform: 'capitalize',
                  }}
                  title={singleExam.status || 'draft'}
                >
                  {singleExam.status || 'draft'}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={
                    singleExam.sub_category
                      ? singleExam.sub_category.category.title
                      : null
                  }
                >
                  {singleExam.sub_category
                    ? singleExam.sub_category.category.title
                    : null}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={
                    singleExam.sub_category ? singleExam.sub_category.title : null
                  }
                >
                  {singleExam.sub_category ? singleExam.sub_category.title : null}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={moment(singleExam.created_at).format('Do MMM YY hh:mm A')}
                >
                  {moment(singleExam.created_at).format('Do MMM YY hh:mm A')}
                </TableCell>
                <TableCell
                  sx={{
                    padding: '8px',
                  }}
                >
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => handleMenuClick(e, singleExam)}
                    endIcon={<KeyboardArrowDownIcon />}
                    // startIcon={<FormatListBulleted />}
                    sx={{ background: '#09768d' }}
                  >
                    Action
                  </Button>
                  {renderMenuItems(selectedExam)}
                </TableCell>
              </>
            )}
          />
        </Paper>
      ) : (
        <p>No exams found</p>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this exam
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => {
              onDelete(selectedExam.id);
              handleClose();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {selectedExam ? (
        <Dialog open={isOpen1} onClose={handleClose1}>
          <DialogTitle> Change {selectedExam.title} Status</DialogTitle>
          <DialogContent>
            <ChangeExamStatus exam={selectedExam} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

export default ExamsList;
