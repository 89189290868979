/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const educatorSubscriptionApi = BaseApi.injectEndpoints({
  reducerPath: 'educatorSubscriptionApi',
  endpoints: (builder) => ({
    educatorSubscriptions: builder.query({
      query: () => ({
        url: '/educator/subscriptions',
        method: 'GET',
      }),
      providesTags: ['EducatorSubscription'],
    }),
    createEducatorSubscription: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          cost: details.cost,
        },
      }),
      invalidatesTags: ['EducatorSubscription'],
    }),
    educatorSubscription: builder.query({
      query: (details) => ({
        url: `/educator/subscription/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['EducatorSubscription'],
    }),

    createEducatorPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
          educator_subscription_plan_id: details.educator_subscription_plan_id,
        },
      }),
    }),

    createEducatorSubscriptionWithPayment: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          educator_subscription_plan_id: details.educator_subscription_plan_id,
          email: details.email,
          bill_type: details.bill_type,
        },
      }),
    }),
  }),
});

export const {
  useEducatorSubscriptionsQuery,
  useCreateEducatorSubscriptionMutation,
  useEducatorSubscriptionQuery,
  useCreateEducatorPaymentOrderIdMutation,
  useCreateEducatorSubscriptionWithPaymentMutation,
} = educatorSubscriptionApi;
