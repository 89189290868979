/* eslint-disable no-unused-vars */
import { useEducatorPaymentWithdrawalsQuery } from '../../../app/services/EducatorApi';
import { CurrencyRupee, ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';

function EducatorPaymentWithdrawalList() {
  const { data, isLoading, isSuccess } = useEducatorPaymentWithdrawalsQuery();

  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const viewWithdrawal = (id) => {
    navigate(`/educator-withdrawals/${id}`);
  };

  return data ? (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Educator Payment Withdrawals
      </Typography>
      <Paper sx={{ height: 400, width: '100%', overflow: 'scroll' }}>
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Requeested By</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>
                  <span style={{ marginLeft: '1rem' }}>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.paymentWithdrawals?.map((singleWithdrawal, index) => (
                <TableRow
                  key={singleWithdrawal.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {' '}
                    <CurrencyRupee />
                    {singleWithdrawal.amount}
                  </TableCell>
                  <TableCell>{singleWithdrawal.creator.name}</TableCell>
                  <TableCell>{singleWithdrawal.status}</TableCell>
                  <TableCell>
                    {moment(singleWithdrawal.created_at).format('Do MMM YY hh:mm A')}
                  </TableCell>{' '}
                  <TableCell>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 200,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleClick(index)}
                        aria-expanded={expandedId === index}
                      >
                        <ListItemText primary="Options" />
                        <ExpandMore />
                      </ListItemButton>
                      <Collapse
                        in={expandedId === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => viewWithdrawal(singleWithdrawal.id)}
                          >
                            <ListItemText primary="View" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  ) : (
    <Typography>No data</Typography>
  );
}

export default EducatorPaymentWithdrawalList;
