import { BaseApi } from './BaseApi';

export const studentCoursesApi = BaseApi.injectEndpoints({
  reducerPath: 'studentCoursesApi',
  endpoints: (builder) => ({
    courseEnrol: builder.mutation({
      query: (details) => ({
        url: '/course/enrol/create',
        method: 'POST',
        body: {
          course_id: details.course_id,
        },
      }),
    }),
    enroledCourses: builder.query({
      query: (token) => ({
        url: '/course/enrols',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    enroledCourse: builder.query({
      query: (details) => ({
        url: `/course/enrol/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),
    createCourseReview: builder.mutation({
      query: (details) => ({
        url: '/course/review/create',
        method: 'POST',
        body: {
          rating: details.rating,
          review_text: details.reviewText,
          course_id: details.course_id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    getEnroledVideoUrl: builder.query({
      query: (details) => ({
        // url: `/enroled/course/video/show/${details.id}`,
        url: `/enroled/course/${details.id}/video/show/${details.videoId}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),

    getEnroledSubjectVideoUrl: builder.query({
      query: (details) => ({
        url: `/enroled/course/subject/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    getEnroledTopicVideoUrl: builder.query({
      query: (details) => ({
        url: `/enroled/course/topic/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    followUser: builder.mutation({
      query: (details) => ({
        url: '/follow/user',
        method: 'POST',
        body: {
          id_to_follow: details.id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    unFollowCreatorUser: builder.mutation({
      query: (details) => ({
        url: `/unfollow/user/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    createVideoComment: builder.mutation({
      query: (details) => ({
        url: '/video/comment/create',
        method: 'POST',
        body: {
          comment: details.commentText,
          video_id: details.video_id,
          comment_id: details.comment_id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    updateVideoProgress: builder.mutation({
      query: (details) => ({
        url: '/course/enrol/video/progress',
        method: 'POST',
        body: {
          enrol_id: details.enrol_id,
          video_progress: details.vidProgress,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    createCourseViolation: builder.mutation({
      query: (details) => ({
        url: '/course/violation/create',
        method: 'POST',
        body: {
          course_id: details.course_id,
          issue_type: details.issue_type,
          issue_details: details.issue_details,
        },
      }),
      invalidatesTags: ['Course Violation'],
    }),
    createVideoViolation: builder.mutation({
      query: (details) => ({
        url: '/video/violation/create',
        method: 'POST',
        body: {
          video_id: details.video_id,
          issue_type: details.issue_type,
          issue_details: details.issue_details,
        },
      }),
      invalidatesTags: ['Video Violation'],
    }),
    getEnroledDocumentUrl: builder.query({
      query: (details) => ({
        // url: `/enroled/course/video/show/${details.id}`,
        url: `/enroled/course/${details.id}/document/show/${details.documentId}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),

    getEnroledTopicExternalUrl: builder.query({
      query: (details) => ({
        // url: `/enroled/course/video/show/${details.id}`,
        url: `/enroled/course/${details.id}/topic/show/${details.topicId}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),

    createStudentCourseEnrolPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/student/course/enrol/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
          course_id: details.course_id,
        },
      }),
    }),
    createStudentCourseEnrolWithPayment: builder.mutation({
      query: (details) => ({
        url: '/student/course/enrol/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          email: details.email,
          course_id: details.course_id,
        },
      }),
    }),
    createVideoLikeUnlike: builder.mutation({
      query: (details) => ({
        url: '/video/like/unlike/create',
        method: 'POST',
        body: {
          liked: details.liked,
          unliked: details.unliked,
          video_id: details.video_id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    updateVideoLikeUnlike: builder.mutation({
      query: (details) => ({
        url: `/video/like/unlike/update/${details.id}`,
        method: 'POST',

        body: {
          liked: details.liked,
          unliked: details.unliked,
          video_id: details.video_id,
        },
      }),
      invalidatesTags: ['Course Enrol'],
    }),
    getEnroledCourseExam: builder.query({
      query: (details) => ({
        // url: `/enroled/course/video/show/${details.id}`,
        url: `/enroled/course/${details.id}/exam/show/${details.examId}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),
    getEnroledTopic: builder.query({
      query: (details) => ({
        // url: `/enroled/course/video/show/${details.id}`,
        url: `/enroled/course/${details.id}/topic/show/${details.topicId}`,
        method: 'GET',
      }),
      providesTags: ['Course Enrol'],
    }),
  }),
});

export const {
  useCourseEnrolMutation,
  useEnroledCoursesQuery,
  useEnroledCourseQuery,
  useCreateCourseReviewMutation,
  useGetEnroledVideoUrlQuery,
  useGetEnroledSubjectVideoUrlQuery,
  useGetEnroledTopicVideoUrlQuery,
  useFollowUserMutation,
  useUnFollowCreatorUserMutation,
  useCreateVideoCommentMutation,
  useUpdateVideoProgressMutation,
  useCreateCourseViolationMutation,
  useCreateVideoViolationMutation,
  useGetEnroledDocumentUrlQuery,
  useGetEnroledTopicExternalUrlQuery,
  useCreateStudentCourseEnrolPaymentOrderIdMutation,
  useCreateStudentCourseEnrolWithPaymentMutation,
  useCreateVideoLikeUnlikeMutation,
  useUpdateVideoLikeUnlikeMutation,
  useGetEnroledCourseExamQuery,
  useGetEnroledTopicQuery,
} = studentCoursesApi;
