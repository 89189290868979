/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Rating,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DisplayCourseExams(props) {
  const { exams, enrolId } = props;

  const navigate = useNavigate();

  const onExamClick = (selected) => {
    navigate(`/enrols/${enrolId}/exam/${selected.id}`);
  };

  return Array.isArray(exams) && exams.length >= 1 ? (
    exams.map((singleExam, index) => (
      <CardActionArea
        component="a"
        onClick={() => onExamClick(singleExam)}
        key={index}
        sx={{ mt: 1 }}
      >
        <Card sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
            image={
              singleExam.url ||
              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
            }
          />
          <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
            <Typography component="h2" variant="h5">
              <strong>{singleExam.title}</strong>
            </Typography>
            {/* <Typography variant="subtitle1" color="text.secondary">
             Starts from&nbsp;
             {moment(singleExam.start_date).format('Do MMM YY hh:mm A')}
           </Typography> */}
            <Typography variant="subtitle1" color="text.secondary">
              In&nbsp;
              {singleExam.language}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {singleExam.description
                ? singleExam.description.substring(0, 118)
                : null}
            </Typography>
            {/* <Box sx={{ display: 'flex' }}>
              <Box>
                <Typography>
                  {Math.round(singleExam.exam_reviews_avg_rating * 10) / 10}
                  &nbsp;
                </Typography>
              </Box>
              <Rating
                name="read-only"
                value={Math.round(singleExam.exam_reviews_avg_rating * 10) / 10}
                readOnly
                precision={0.5}
              />

              {singleExam.exam_reviews_count ? (
                <Typography>
                  &#40;{singleExam.exam_reviews_count}
                  &#41;
                </Typography>
              ) : null}
            </Box> */}
            {singleExam.cost ? (
              <Typography component="h2" color="text.primary">
                <strong>₹{singleExam.cost}</strong>
              </Typography>
            ) : (
              <Typography>Free Exam</Typography>
            )}
            <Typography variant="subtitle1" color="primary">
              View Exam ...
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    ))
  ) : (
    <p>No results</p>
  );
}

export default DisplayCourseExams;
