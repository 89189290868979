import { BaseApi } from './BaseApi';

export const publicCoursesApi = BaseApi.injectEndpoints({
  reducerPath: 'publicCoursesApi',
  endpoints: (builder) => ({
    publiCourses: builder.query({
      query: () => ({
        url: '/public/courses',
        method: 'GET',
      }),
    }),
    publicCourse: builder.query({
      query: (details) => ({
        url: `/public/category/subCategory/course/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Public Course'],
    }),
    followCreator: builder.mutation({
      query: (details) => ({
        url: '/follow/user',
        method: 'POST',
        body: {
          id_to_follow: details.id,
        },
      }),
      invalidatesTags: ['Public Course', 'Educator Channel'],
    }),
  }),
});

export const {
  usePublicCourseQuery,
  usePubliCoursesQuery,
  useFollowCreatorMutation,
} = publicCoursesApi;
