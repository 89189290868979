import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { verifyEmailRequest } from '../../app/services/AuthRequests';

function VerifyEmailPage() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id, expire, signature } = params;
  const [message, setMessage] = useState('Please wait...');
  const { isSignedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const verificationDetails = {
      id,
      expire,
      signature,
    };

    verifyEmailRequest(verificationDetails).then((res) => {
      if (res.message === 'EMAIL_VERIFIED') {
        setMessage('EMAIL_VERIFIED');
        enqueueSnackbar('Email Verified.', {
          variant: 'success',
        });
        navigate('/select-account');
      } else {
        setMessage('Error Verifying Email');

        enqueueSnackbar('Error Verifying Email', {
          variant: 'error',
        });
      }
    });
  }, []);

  if (isSignedIn) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Paper elevation={3}>
          <InfoOutlinedIcon />
          <Typography>{message}</Typography>
        </Paper>
        <Box mt={2}></Box>
      </Grid>
    </>
  );
}

export default VerifyEmailPage;
