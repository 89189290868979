/* eslint-disable no-unused-vars */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import GaugeIndicator from './GaugeIndicator';
import { Gauge } from '@mui/x-charts/Gauge';
import { CustomDateRange } from './CustomDateRange';
import { useEducatorCoursesEnrolsCountQuery } from '../../../app/services/EducatorApi';
import moment from 'moment';

export const Analytics = () => {
  const { data } = useEducatorCoursesEnrolsCountQuery();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [noGender, setNoGender] = useState(0);
  const [locations, setLocations] = useState([]);
  const [ages, setAges] = useState([]);
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [sortedCourse, setSortedCourse] = useState(
    data?.courses ? data?.courses : []
  );
  const [courseReviews, setCourseReviews] = useState([]);
  useEffect(() => {
    let finalRating =
      Array.isArray(selectedCourse?.course_reviews) &&
      selectedCourse?.course_reviews.length > 0
        ? selectedCourse.course_reviews
        : [];
    if (Array.isArray(finalRating) && finalRating.length > 0) {
      finalRating = [...finalRating].sort((a, b) => b.rating - a.rating);
    }
    setCourseReviews([...finalRating]);
  }, [selectedCourse]);

  const RatingchartSetting = {
    xAxis: [
      {
        max: 5,
        // label: 'rainfall (mm)',
      },
    ],
    width: 250,
    height: 150,
  };
  const castchartSetting = {
    yAxis: [
      {
        // label: 'rainfall (mm)',
      },
    ],
    width: 400,
    height: 150,
  };
  const agechartSetting = {
    yAxis: [
      {
        label: 'Total No',
      },
    ],

    width: 400,
    height: 250,
  };
  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: 'Jan',
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: 'Feb',
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: 'Mar',
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: 'Apr',
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: 'May',
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: 'June',
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: 'July',
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: 'Aug',
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: 'Sept',
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: 'Oct',
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: 'Nov',
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: 'Dec',
    },
  ];
  const valueFormatter = (value) => `${value}`;
  // function createData(srNo, courseId, type) {
  //   return { srNo, courseId, type, enroll: type };
  // }
  const ratingData = [
    {
      data: 35,
      number: '1',
    },
    {
      data: 20,
      number: '2',
    },
    {
      data: 28,
      number: '3',
    },
    {
      data: 35,
      number: '4',
    },
    {
      data: 98,
      number: '5',
    },
  ];
  // const ageData = [
  //   {
  //     data: 2177,
  //     label: '24 and under',
  //   },
  //   {
  //     data: 37738,
  //     number: '25-34',
  //   },
  //   {
  //     data: 50045,
  //     number: '35-44',
  //   },
  //   {
  //     data: 52627,
  //     number: '45-54',
  //   },
  //   {
  //     data: 45758,
  //     number: '55-64',
  //   },
  //   {
  //     data: 32557,
  //     number: '65+',
  //   },
  // ];
  const pieParams = { height: 150, width: 150, margin: { right: 5 } };

  const genderCount = (courseEnrols) => {
    let initialMale = 0;
    let initialFemale = 0;
    let initialUndefined = 0;

    courseEnrols.forEach((singleEnrol) => {
      if (singleEnrol.user.student_info.gender === 'male') {
        initialMale += 1;
      } else if (singleEnrol.user.student_info.gender === 'female') {
        initialFemale += 1;
      } else {
        initialUndefined += 1;
      }
    });

    setMale(initialMale);
    setFemale(initialFemale);
    setNoGender(initialUndefined);
  };

  const findLocations = (courseEnrols) => {
    let initialLocations = [];

    courseEnrols.forEach((singleEnrol) => {
      if (
        initialLocations.length > 0 &&
        initialLocations.find(
          (element) => element.name === singleEnrol.user.student_info.district
        )
      ) {
        const updatedLocations = initialLocations.map((singleLocation) => {
          if (singleLocation.name === singleEnrol.user.student_info.district) {
            return {
              ...singleLocation,
              noOfTimes: singleLocation.noOfTimes + 1,
            };
          }
          return singleLocation;
        });
        initialLocations = updatedLocations;
      } else {
        initialLocations.push({
          name: singleEnrol.user.student_info.district,
          noOfTimes: 1,
        });
      }
    });

    setLocations(initialLocations);
  };

  const findAges = (courseEnrols) => {
    let initialAges = [];

    courseEnrols.forEach((singleEnrol) => {
      if (
        initialAges.length > 0 &&
        initialAges.find(
          (element) =>
            element.age ===
            moment().diff(
              moment(singleEnrol.user.student_info?.date_of_birth),
              'years'
            )
        )
      ) {
        const updatedAges = initialAges.map((singleAge) => {
          if (
            singleAge.age ===
            moment().diff(
              moment(singleEnrol.user.student_info?.date_of_birth),
              'years'
            )
          ) {
            return {
              ...singleAge,
              noOfTimes: singleAge.noOfTimes + 1,
            };
          }
          return singleAge;
        });
        initialAges = updatedAges;
      } else {
        if (singleEnrol.user.student_info?.date_of_birth !== null) {
          initialAges.push({
            age: moment().diff(
              moment(singleEnrol.user.student_info?.date_of_birth),
              'years'
            ),
            noOfTimes: 1,
          });
        }
      }
    });

    setAges(initialAges);
  };

  useEffect(() => {
    if (selectedCourse && selectedCourse.course_enrols.length > 0) {
      genderCount(selectedCourse.course_enrols);
      findLocations(selectedCourse.course_enrols);
      findAges(selectedCourse.course_enrols);
    } else {
      setMale(0);
      setFemale(0);
      setNoGender(0);
      setLocations([]);
      setAges([]);
    }
  }, [selectedCourse]);
  useEffect(() => {
    // Function to update width
    const updateWidth = () => {
      if (containerRef?.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    // Calculate initial width
    updateWidth();

    // Add resize event listener
    window.addEventListener('resize', updateWidth);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const getEnrollmentCount = () => {
    let totalCount = 0;
    let filteredCourses = [];
    if (Array.isArray(data?.courses)) {
      filteredCourses = data?.courses;
    }
    if (Array.isArray(filteredCourses)) {
      totalCount = filteredCourses.reduce(
        (acc, cuu) => acc + (cuu?.course_enrols_count || 0),
        0
      );
    }
    return totalCount;
  };
  const convertToTimeFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  const getTotalWatchHourse = () => {
    let totalWatchTime = 0;
    let allEnrolls = data?.courses.flatMap((course) => course.course_enrols);
    if (Array.isArray(allEnrolls)) {
      totalWatchTime = allEnrolls.reduce((sum, enrol) => {
        if (enrol.video_progress) {
          try {
            const progressArray = JSON.parse(enrol.video_progress);
            const totalForUser = progressArray.reduce(
              (acc, item) => acc + item.timeStamp,
              0
            );
            return sum + totalForUser;
          } catch (error) {
            console.error('Error parsing video_progress:', error);
          }
        }
        return sum;
      }, 0);
    }
    return convertToTimeFormat(totalWatchTime);
  };

  useEffect(() => {
    if (Array.isArray(data?.courses)) {
      const sorted = [...data.courses].sort(
        (a, b) => b.course_enrols_count - a.course_enrols_count
      );
      setSortedCourse(sorted);
    }
  }, [data]);

  return (
    <Fragment>
      {/* <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div> */}
      <Grid container spacing={2} sx={{ marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box sx={{ background: '#fff', boxShadow: '1px 1px 10px 1px #dcdcdc' }}>
            <Grid container justifyContent={'center'}>
              <Grid item xl={9} lg={9} md={12} sm={12}>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    padding: '1rem',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      height: '120px',
                      width: '200px',
                      background: '#efd27f',
                      borderRadius: '10px',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: '#124b6b',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Total Enrollments
                      </Typography>
                      <Typography
                        sx={{
                          color: '#124b6b',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        {getEnrollmentCount()}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '120px',
                      width: '200px',
                      background: '#8245a4',
                      borderRadius: '10px',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Typography
                        sx={{ color: '#fff', textAlign: 'center', fontWeight: 600 }}
                      >
                        Total Watch Hrs
                      </Typography>
                      <Typography
                        sx={{ color: '#fff', textAlign: 'center', fontWeight: 600 }}
                      >
                        {getTotalWatchHourse()}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <BarChart
                  dataset={dataset}
                  xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                  series={[
                    { dataKey: 'london', label: 'London', valueFormatter },
                    { dataKey: 'paris', label: 'Paris', valueFormatter },
                    { dataKey: 'newYork', label: 'New York', valueFormatter },
                    { dataKey: 'seoul', label: 'Seoul', valueFormatter },
                  ]}
                  {...chartSetting}
                /> */}
              </Grid>
              {/* <Grid
                item
                xl={3}
                lg={3}
                md={12}
                sm={12}
                sx={{ borderLeft: '3px solid #cbcbcb', padding: '1rem' }}
              >
                <Typography
                  sx={{ color: '#000', fontWeight: 700, fontSize: '18px' }}
                >
                  Overall Performance
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '2rem',
                  }}
                >
                  <div
                    style={{
                      boxShadow: '2px 2px 10px 2px grey',
                      padding: '1rem',
                      width: '150px',
                      borderRadius: '20px',
                    }}
                  >
                    <Typography
                      sx={{ textAlign: 'center', color: '#000', fontWeight: 700 }}
                    >
                      U Rank in
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#41b448',
                        fontSize: '22px',
                        fontWeight: 700,
                      }}
                    >
                      Top 10
                    </Typography>
                    <Typography
                      sx={{ textAlign: 'center', color: '#000', fontWeight: 700 }}
                    >
                      Performers
                    </Typography>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '2rem',
                  }}
                >
                  <GaugeIndicator />
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography sx={{ marginBottom: '0.5rem', fontWeight: 600 }}>
              Top Performing Courses
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: '690px' }}>
              <Table
                sx={{ minWidth: '100%' }}
                aria-label="simple table"
                stickyHeader={true}
              >
                <TableHead sx={{ background: '#eef7fa' }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        background: '#10496a',
                      }}
                    >
                      Sr.No
                    </TableCell>
                    <TableCell
                      align="start"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '100px',
                        background: '#10496a',
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '100px',
                        background: '#10496a',
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '100px',
                        background: '#10496a',
                      }}
                    >
                      Enrolls
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    sortedCourse?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        className="analytics-custom-mui-table-row"
                        onClick={() => {
                          setSelectedCourse(row);
                        }}
                        sx={{
                          background: selectedCourse?.id == row?.id ? '#cdeef9' : '',
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell align="center">
                          <div>{index + 1}</div>
                        </TableCell>
                        <TableCell align="start" sx={{ cursor: 'pointer' }}>
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 2,
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              width: '150px',
                            }}
                          >
                            {' '}
                            <Tooltip title={row?.title}>{row?.title}</Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.cost ? 'Paid' : 'Free'}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.course_enrols_count}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        {selectedCourse ? (
          <Grid item xl={8} lg={8} md={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>Rating Distributions</Typography>
                  <div style={{ display: 'flex' }}>
                    <Box sx={{ width: '150px', position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '3rem',
                          right: '2.5rem',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: '20px',
                          }}
                        >
                          {selectedCourse?.course_reviews_avg_rating
                            ? parseFloat(
                                selectedCourse.course_reviews_avg_rating
                              ).toFixed(1)
                            : '-'}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        >
                          {selectedCourse.course_reviews_count} Reviews
                        </Typography>
                      </div>
                      <Gauge
                        width={150}
                        height={150}
                        value={
                          selectedCourse?.course_reviews_avg_rating
                            ? (parseFloat(
                                selectedCourse.course_reviews_avg_rating
                              ).toFixed(1) *
                                100) /
                              5
                            : '-'
                        }
                        innerRadius="70%"
                        outerRadius="100%"
                        text={() => ``}
                      />
                    </Box>
                    <Box>
                      <BarChart
                        dataset={courseReviews}
                        yAxis={[{ scaleType: 'band', dataKey: 'rating' }]}
                        series={[
                          {
                            dataKey: 'rating',
                          },
                        ]}
                        layout="horizontal"
                        {...RatingchartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>User Overview</Typography>
                  <div style={{ display: 'flex' }} className="comming-soon-parent">
                    <div className="comming-soon-text">Coming Soon</div>
                    <Box>
                      <PieChart
                        series={[
                          {
                            data: [{ value: 10, color: 'blue' }, { value: 20 }],
                            innerRadius: 30,
                            outerRadius: 50,
                            paddingAngle: 0,
                            cornerRadius: 0,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 70,
                            cy: 70,
                          },
                        ]}
                        {...pieParams}
                      />
                    </Box>
                    <Box sx={{ width: '200px', alignSelf: 'center' }}>
                      <Typography
                        sx={{
                          color: '#8fc9db',
                          textAlign: 'center',
                          fontWeight: 700,
                          fontSize: '22px',
                        }}
                      >
                        Active 1000
                      </Typography>
                      <Typography
                        sx={{
                          color: '#3756fd',
                          textAlign: 'center',
                          fontWeight: 700,
                          fontSize: '18px',
                        }}
                      >
                        Idle 300
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>CSAT/DSAT</Typography>
                  <div style={{ display: 'flex' }} className="comming-soon-parent">
                    <div className="comming-soon-text">Coming Soon</div>
                    <Box>
                      <BarChart
                        dataset={ratingData}
                        yAxis={[{ scaleType: 'band', dataKey: 'number' }]}
                        series={[
                          {
                            dataKey: 'data',
                          },
                        ]}
                        layout="vertical"
                        {...castchartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>User by Gender</Typography>
                  <div style={{ display: 'flex' }}>
                    <Box>
                      <PieChart
                        series={[
                          {
                            data: [
                              { value: male },
                              { value: female, color: 'blue' },
                              { value: noGender, color: 'grey' },
                            ],
                            innerRadius: 30,
                            outerRadius: 50,
                            paddingAngle: 0,
                            cornerRadius: 0,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 70,
                            cy: 70,
                          },
                        ]}
                        {...pieParams}
                      />
                    </Box>
                    <Box sx={{ width: '200px', alignSelf: 'center' }}>
                      <Typography
                        sx={{
                          color: '#8fc9db',
                          textAlign: 'center',
                          fontWeight: 700,
                          fontSize: '22px',
                        }}
                      >
                        Male {male}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#3756fd',
                          textAlign: 'center',
                          fontWeight: 700,
                          fontSize: '18px',
                        }}
                      >
                        Female {female}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'grey',
                          textAlign: 'center',
                          fontWeight: 700,
                          fontSize: '18px',
                        }}
                      >
                        Unknown {noGender}
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                sx={{
                  boxSizing: 'border-box',
                }}
              >
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                    boxSizing: 'border-box',
                  }}
                >
                  <Typography>Users by Location</Typography>
                  <Box
                    sx={{
                      maxHeight: '260px',
                      overflowX: 'scroll',
                      boxSizing: 'border-box',
                    }}
                    ref={containerRef}
                  >
                    {locations.length > 0 &&
                      locations.map((singleLocation, index) => (
                        <div key={index} style={{ boxSizing: 'border-box' }}>
                          <Typography
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: '0rem',
                              maxWidth: 'calc(100% - 22px)',
                              textTransform: 'capitalize',
                            }}
                          >
                            <span>
                              {singleLocation.name ? singleLocation.name : 'unknown'}
                            </span>
                            <span style={{ fontWeight: 700 }}>
                              {singleLocation.noOfTimes}
                            </span>
                          </Typography>
                          <Slider
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            disabled
                            step={1}
                            value={singleLocation.noOfTimes}
                            min={0}
                            max={selectedCourse.course_enrols_count}
                            sx={{
                              boxSizing: 'border-box',
                              maxWidth: 'calc(100% - 22px)',
                            }}
                          />
                        </div>
                      ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>Users by Age Group</Typography>
                  <div style={{ display: 'flex' }}>
                    <Box>
                      <BarChart
                        dataset={ages}
                        xAxis={[{ scaleType: 'band', dataKey: 'age' }]}
                        series={[
                          {
                            dataKey: 'noOfTimes',
                          },
                        ]}
                        layout="vertical"
                        {...agechartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};
