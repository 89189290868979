import { BaseApi } from './BaseApi';

export const EducatorApi = BaseApi.injectEndpoints({
  reducerPath: 'EducatorApi',
  endpoints: (builder) => ({
    educatorProfile: builder.query({
      query: () => ({
        url: '/educator/profile',
        method: 'GET',
      }),
      providesTags: ['Educator Profile'],
    }),
    updateEducatorProfile: builder.mutation({
      query: (details) => ({
        url: `/educator/profile/update/${details.id}`,
        method: 'POST',

        body: {
          first_name: details.first_name,
          last_name: details.last_name,
          qualification: details.qualification,
          language: details.language,
          location: details.location,
          headline: details.headline,
          bio: details.bio,
          subject: details.subject,
          date_of_birth: details.dob,
          gender: details.gender,
          address: details.address,
          gst_number: details.gstno,
          pin_code: details.pincode,
        },
      }),
      invalidatesTags: ['Educator Profile'],
    }),
    educatorChannel: builder.query({
      query: (details) => ({
        url: `/educator/channel/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Educator Channel'],
    }),
    updateTeachingPreference: builder.mutation({
      query: (details) => ({
        url: '/educator/teaching/preference/create',
        method: 'POST',

        body: {
          educator_id: details.educator_id,
          preferred_languages: details.preferred_languages,
          teaching_interests: details.teaching_interests,
          bio: details.bio,
        },
      }),
      invalidatesTags: ['Educator Profile'],
    }),
    educatorCoursesAndEnrols: builder.query({
      query: () => ({
        url: `/educator/coursesandenrols`,
        method: 'GET',
      }),
      // providesTags: ['Educator Channel'],
    }),
    educatorCoursesEnrolsCount: builder.query({
      query: () => ({
        url: `/educator/coursesenrolscount`,
        method: 'GET',
      }),
      // providesTags: ['Educator Channel'],
    }),
    educatorCoursesAndReviews: builder.query({
      query: () => ({
        url: `/educator/coursesandreviews`,
        method: 'GET',
      }),
      // providesTags: ['Educator Channel'],
    }),

    educatorBankInfo: builder.query({
      query: () => ({
        url: '/educator/bankinfo',
        method: 'GET',
      }),
      providesTags: ['Educator BankInfo'],
    }),
    createEducatorBankInfo: builder.mutation({
      query: (details) => ({
        url: `/educator/bankinfo/create`,
        method: 'POST',

        body: {
          account_name: details.account_name,
          account_number: details.account_number,
          ifsc_code: details.ifsc_code,
          account_type: details.account_type,
          branch: details.branch,
          bank_name: details.bank_name,
          branch_address: details.branch_address,
        },
      }),
      invalidatesTags: ['Educator BankInfo'],
    }),
    updateEducatorBankInfo: builder.mutation({
      query: (details) => ({
        url: `/educator/bankinfo/update/${details.id}`,
        method: 'POST',

        body: {
          account_name: details.account_name,
          account_number: details.account_number,
          ifsc_code: details.ifsc_code,
          account_type: details.account_type,
          branch: details.branch,
          bank_name: details.bank_name,
          branch_address: details.branch_address,
        },
      }),
      invalidatesTags: ['Educator BankInfo'],
    }),
    educatorPaymentWithdrawals: builder.query({
      query: () => ({
        url: '/educator/payment/withdrawals',
        method: 'GET',
      }),
      providesTags: ['EducatorWithdrawal'],
    }),
    createEducatorPaymentWithdrawal: builder.mutation({
      query: (details) => ({
        url: `/educator/payment/withdraw`,
        method: 'POST',

        body: {
          amount: details.amount,
          notes: details.notes,
        },
      }),
      invalidatesTags: ['EducatorWithdrawal'],
    }),
    educatorEarnings: builder.query({
      query: () => ({
        url: '/educator/earnings',
        method: 'GET',
      }),
      providesTags: ['Educator Earning', 'EducatorWithdrawal'],
    }),
    verifyBankAccount: builder.mutation({
      query: () => ({
        url: `/verify/bank/account`,
        method: 'POST',

        // body: {
        //   amount: details.amount,
        //   notes: details.notes,
        // },
      }),
      invalidatesTags: ['EducatorWithdrawal'],
    }),

    educatorPaymentWithdrawal: builder.query({
      query: (details) => ({
        url: `/educator/withdrawal/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['EducatorWithdrawal'],
    }),
    createApprovePaymentWithdrawal: builder.mutation({
      query: (details) => ({
        url: `/approve/payment/withdrawal/${details.id}`,
        method: 'POST',

        // body: {
        //   amount: details.amount,
        //   notes: details.notes,
        // },
      }),
      invalidatesTags: ['EducatorWithdrawal'],
    }),
  }),
});

export const {
  useEducatorProfileQuery,
  useUpdateEducatorProfileMutation,
  useEducatorChannelQuery,
  useUpdateTeachingPreferenceMutation,
  useEducatorCoursesAndEnrolsQuery,
  useEducatorCoursesEnrolsCountQuery,
  useEducatorCoursesAndReviewsQuery,
  useEducatorBankInfoQuery,
  useEducatorPaymentWithdrawalsQuery,
  useCreateEducatorBankInfoMutation,
  useUpdateEducatorBankInfoMutation,
  useCreateEducatorPaymentWithdrawalMutation,
  useEducatorEarningsQuery,
  useVerifyBankAccountMutation,
  useEducatorPaymentWithdrawalQuery,
  useCreateApprovePaymentWithdrawalMutation,
} = EducatorApi;
