/* eslint-disable no-unused-vars */

import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Stack,
  TablePagination,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSubCategoryFilterMutation } from '../../../app/services/SearchApi';
import DisplaySearchCourseResults from './DisplaySearchCourseResults';
import {
  usePublicPublicationsQuery,
  usePublicSubCategoryQuery,
} from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';
import DisplayAcadmicsSearchCourseResults from './DisplayAcadmicsSearchCourseResults';

// const languages = [
//   'Assamese',
//   'Bengali',
//   'Bodo',
//   'Dogri',
//   'English',
//   'Gujarati',
//   'Hindi',
//   'Kannada',
//   'Kashmiri',
//   'Konkani',
//   'Maithili',
//   'Malayalam',
//   'Marathi',
//   'Meitei',
//   'Nepali',
//   'Odia',
//   'Punjabi',
//   'Sanskrit',
//   'Santali',
//   'Sindhi',
//   'Tamil',
//   'Telugu',
//   'Urdu',
// ];

// const grades = [
//   'Class 10',
//   'Class 9',
//   'Class 8',
//   'Class 7',
//   'Class 6',
//   'Class 5',
//   'Class 4',
//   'Class 3',
//   'Class 2',
//   'Class 1',
// ];

// const locations = [
//   'Amaravati',
//   'Itanagar',
//   'Dispur',
//   'Patna',
//   'Raipur',
//   'Panaji',
//   'Gandhinagar',
//   'Chandigarh',
//   'Shimla',
//   'Srinagar',
//   'Ranchi',
//   'Bengaluru',
//   'Trivandrum',
//   'Bhopal',
//   'Mumbai	',
//   'Imphal',
//   'Shillong',
//   'Aizawl',
//   'Kohima',
//   'Bhubaneswar',
//   'Chandigarh',
//   'Jaipur',
//   'Gangtok',
//   'Chennai',
//   'Hyderabad',
//   'Agartala',
//   'Lucknow',
//   'Dehradun',
//   'Kolkata',
// ];

// const gradeSubjectsList = [
//   'English',
//   'Hindi',
//   'Marathi',
//   'Mathematics',
//   'Science',
//   'Social Studies',
//   'History',
//   'Geography',
//   'Civics',
//   'Political Science',
//   'Economics',
//   'Environmental Science',
//   'General Knowledge',
//   'Physical Education',
//   'Art',
//   'Music',
//   'Computer Science',
//   'Information Technology',
//   'Health and Physical Education',
//   'Business Studies',
//   'Accountancy',
//   'Physics',
//   'Chemistry',
//   'Biology',
//   'Psychology',
//   'Sociology',
//   'Philosophy',
// ];

function SubCategoryResults() {
  const params = useParams();
  const [filterRequest, result] = useSubCategoryFilterMutation();

  const { data, isSuccess } = usePublicSubCategoryQuery({
    id: parseInt(params.subCategoryId, 10),
  });
  const publicationsRes = usePublicPublicationsQuery();

  const [value, setValue] = useState([]);
  const [levelValue, setLevelValue] = useState([]);
  const [priceValue, setPriceValue] = useState([]);
  const [ratingValue, setRatingValue] = useState([]);
  const [language, setLanguage] = useState([]);
  const [grade, setGrade] = useState([]);
  const [gradeSubject, setGradeSubject] = useState([]);
  const [publication, setPublication] = useState([]);
  const [locationValue, setLocationValue] = useState([]);

  const [selectedAllFilters, setSelectedAllFilters] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (parseInt(params.subCategoryId, 10)) {
      filterRequest({
        sub_category_id: parseInt(params.subCategoryId, 10),
        offering: value,
        price: priceValue,
        rating: ratingValue,
        level: levelValue,
        language: language,
        grade: grade,
        publication: publication,
        location: locationValue,
        gradeSubject: gradeSubject,
      });
    }
  }, [
    value,
    priceValue,
    ratingValue,
    levelValue,
    language,
    grade,
    publication,
    locationValue,
    gradeSubject,
    params,
  ]);
  useEffect(() => {
    if (params?.gradeTitle) {
      setGrade([params?.gradeTitle]);
    } else {
      setGrade([]);
    }
    if (params?.subjectTitle) {
      setGradeSubject([params?.subjectTitle]);
    } else {
      setGradeSubject([]);
    }
  }, [params]);
  const handleChipClick = () => {
    setValue([]);
    setLevelValue([]);
    setPriceValue([]);
    setRatingValue([]);
    setLanguage([]);
    setGrade([]);
    setGradeSubject([]);
    setPublication([]);
    setLocationValue([]);
  };

  useEffect(() => {
    setSelectedAllFilters([
      ...value,
      ...priceValue,
      ...levelValue,
      ...language,
      ...ratingValue,
      ...publication,
      ...locationValue,
      ...grade,
      ...gradeSubject,
    ]);
  }, [
    value,
    priceValue,
    levelValue,
    language,
    ratingValue,
    publication,
    locationValue,
    grade,
    gradeSubject,
  ]);

  const handlePublicationChange = (event) => {
    if (!event.target.checked) {
      const index = publication.indexOf(event.target.name);
      if (index > -1) {
        publication.splice(index, 1);
      }

      const filterPublications = publication.filter(
        (singlePublication) => singlePublication != event.target.name
      );

      setPublication(filterPublications);
    } else {
      setPublication([...publication, event.target.name]);
    }
  };

  const handleGradeChange = (event) => {
    if (!event.target.checked) {
      const index = grade.indexOf(event.target.name);
      if (index > -1) {
        grade.splice(index, 1);
      }
      const filteredGrade = grade.filter(
        (singleGrade) => singleGrade != event.target.name
      );

      setGrade(filteredGrade);
    } else {
      setGrade([...grade, event.target.name]);
    }
  };

  const handleGradeSubjectChange = (event) => {
    if (!event.target.checked) {
      const index = gradeSubject.indexOf(event.target.name);
      if (index > -1) {
        gradeSubject.splice(index, 1);
      }
      const filteredGradeSubject = gradeSubject.filter(
        (singleGradeSubject) => singleGradeSubject != event.target.name
      );

      setGradeSubject(filteredGradeSubject);
    } else {
      setGradeSubject([...gradeSubject, event.target.name]);
    }
  };

  const handleLanguageChange = (event) => {
    if (!event.target.checked) {
      const index = language.indexOf(event.target.name);
      if (index > -1) {
        language.splice(index, 1);
      }
      const filteredLanguage = language.filter(
        (singlePublication) => singlePublication != event.target.name
      );

      setLanguage(filteredLanguage);
    } else {
      setLanguage([...language, event.target.name]);
    }
  };

  const handleLevelChange = (event) => {
    if (!event.target.checked) {
      const index = levelValue.indexOf(event.target.name);
      if (index > -1) {
        levelValue.splice(index, 1);
      }
      const filteredLevel = levelValue.filter(
        (singlePublication) => singlePublication != event.target.name
      );

      setLevelValue(filteredLevel);
    } else {
      setLevelValue([...levelValue, event.target.name]);
    }
  };

  const handleLocationChange = (event) => {
    if (!event.target.checked) {
      const index = locationValue.indexOf(event.target.name);
      if (index > -1) {
        locationValue.splice(index, 1);
      }
      const filteredLocation = locationValue.filter(
        (singlePublication) => singlePublication != event.target.name
      );

      setLocationValue(filteredLocation);
    } else {
      setLocationValue([...locationValue, event.target.name]);
    }
  };

  const handleChange = (event) => {
    if (!event.target.checked) {
      const index = value.indexOf(event.target.name);
      if (index > -1) {
        value.splice(index, 1);
      }
      const filteredValue = value.filter(
        (singleValue) => singleValue != event.target.name
      );

      setValue(filteredValue);
    } else {
      setValue([...value, event.target.name]);
    }
  };

  const handlePriceChange = (event) => {
    if (!event.target.checked) {
      const index = priceValue.indexOf(event.target.name);
      if (index > -1) {
        priceValue.splice(index, 1);
      }
      const filteredPrice = priceValue.filter(
        (singlePrice) => singlePrice != event.target.name
      );

      setPriceValue(filteredPrice);
    } else {
      setPriceValue([...priceValue, event.target.name]);
    }
  };

  const handleRatingChange = (event) => {
    if (!event.target.checked) {
      const index = ratingValue.indexOf(event.target.name);
      if (index > -1) {
        ratingValue.splice(index, 1);
      }
      const filteredRating = ratingValue.filter(
        (singlePublication) => singlePublication != event.target.name
      );

      setRatingValue(filteredRating);
    } else {
      setRatingValue([...ratingValue, event.target.name]);
    }
  };

  const handleClearSingleChip = (chip) => {
    const filteredChip = selectedAllFilters.filter((item) => item !== chip);
    setSelectedAllFilters([...filteredChip]);
    const filteredValue = value.filter((item) => item !== chip);
    const filteredPriceValue = priceValue.filter((item) => item !== chip);
    const filteredLevelValue = levelValue.filter((item) => item !== chip);
    const filteredLanguage = language.filter((item) => item !== chip);
    const filteredRatingValue = ratingValue.filter((item) => item !== chip);
    const filteredPublication = publication.filter((item) => item !== chip);
    const filteredLocationValue = locationValue.filter((item) => item !== chip);
    const filteredGrade = grade.filter((item) => item !== chip);
    setValue([...filteredValue]);
    setPriceValue([...filteredPriceValue]);
    setLevelValue([...filteredLevelValue]);
    setLanguage([...filteredLanguage]);
    setRatingValue([...filteredRatingValue]);
    setPublication([...filteredPublication]);
    setLocationValue([...filteredLocationValue]);
    setGrade([...filteredGrade]);
  };

  const displayFilterRes = (res) => {
    if (res.data) {
      return (
        <>
          {res.data.courses.length ? (
            <Stack spacing={2}>
              <Typography>Courses</Typography>
              {params?.categoryTitle == 'Academics' ||
              params?.categoryTitle == 'Academic' ? (
                <DisplayAcadmicsSearchCourseResults
                  courses={res.data.courses.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                />
              ) : (
                <DisplaySearchCourseResults
                  courses={res.data.courses.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                />
              )}
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={res.data.courses && res.data.courses.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          ) : (
            <Typography>No Courses Found </Typography>
          )}
          {/* {res.data.exams.length ? (
            <>
              <Typography>Exams</Typography>
              <DisplaySearchExamResults exams={res.data.exams} />
            </>
          ) : null}
          {res.data.tests.length ? (
            <>
              <Typography>Tests(Create Multiple Practice Tests)</Typography>
              <DisplaySearchTestResults tests={res.data.tests} />
            </>
          ) : null} */}
        </>
      );
    }
  };
  const displayNonFilterRes = (subRes) => {
    if (subRes.subCategory) {
      return (
        <>
          {subRes.subCategory.published_courses.length ? (
            <Stack spacing={2}>
              <Typography>Courses</Typography>
              {subRes?.subCategory?.category?.id === 2 ? (
                <DisplayAcadmicsSearchCourseResults
                  courses={subRes.subCategory.published_courses.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                />
              ) : (
                <DisplaySearchCourseResults
                  courses={subRes.subCategory.published_courses.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                />
              )}
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={
                  subRes.subCategory.published_courses &&
                  subRes.subCategory.published_courses.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          ) : (
            <Typography>No Courses Found </Typography>
          )}
          {/* {subRes.subCategory.published_exams.length ? (
            <>
              <>
                <Typography>Exams</Typography>
                <DisplaySearchExamResults
                  exams={subRes.subCategory.published_exams}
                />
              </>
            </>
          ) : null}
          {subRes.subCategory.published_tests.length ? (
            <>
              <Typography>Tests(Create Multiple Practice Tests)</Typography>
              <DisplaySearchTestResults tests={subRes.subCategory.published_tests} />
            </>
          ) : null} */}
        </>
      );
    }
  };
  useEffect(() => {
    // Scroll to the top whenever the route parameter changes
    window && window.scrollTo(0, 0);
  }, [page, rowsPerPage]);
  return (
    <Grid
      container
      spacing={2}
      align="left"
      justifyContent={'center'}
      sx={{ background: '#fafafa' }}
    >
      {/* <Grid item xs="12" sm="12" md="3" lg="3" xl="3">
        <Box sx={{ m: 2, overflowWrap: 'anywhere' }}>
          <>
            <Typography color="text.secondary">
              Filters
              <Chip
                label="Clear"
                onClick={handleChipClick}
                deleteIcon={<DeleteIcon />}
                variant="outlined"
                size="small"
                sx={{ float: 'right' }}
              />
              <Box>
                <Stack
                  direction="row"
                  useFlexGap={true}
                  sx={{ flexWrap: 'wrap', gap: '0.5rem' }}
                >
                  {selectedAllFilters.map((item, index) => (
                    <Chip
                      key={index}
                      label={item}
                      variant="outlined"
                      size="small"
                      onDelete={() => handleClearSingleChip(item)}
                    />
                  ))}
                </Stack>
              </Box>
            </Typography>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="offerings">
                Types of offerings
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Courses"
                  control={
                    <Checkbox
                      name="courses"
                      onChange={handleChange}
                      checked={value.includes('courses')}
                    />
                  }
                  key="courses"
                  size="small"
                />
                <FormControlLabel
                  label="Exams"
                  control={
                    <Checkbox
                      name="exams"
                      onChange={handleChange}
                      checked={value.includes('exams')}
                    />
                  }
                  key="exams"
                />
                <FormControlLabel
                  label="Practice Tests"
                  control={
                    <Checkbox
                      name="practiceTests"
                      onChange={handleChange}
                      checked={value.includes('practiceTests')}
                    />
                  }
                  key="practiceTests"
                  size="small"
                />
              </FormGroup>
            </FormControl>

            <Divider />

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="price">
                Price
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Free"
                  control={
                    <Checkbox
                      name="free"
                      onChange={handlePriceChange}
                      checked={priceValue.includes('free')}
                    />
                  }
                  key="free"
                  size="small"
                />
                <FormControlLabel
                  label="Paid"
                  control={
                    <Checkbox
                      name="paid"
                      onChange={handlePriceChange}
                      checked={priceValue.includes('paid')}
                    />
                  }
                  key="paid"
                />
              </FormGroup>
            </FormControl>

            <Divider />

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="ratings">
                Ratings
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={4.5}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>4.5 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="4.5"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('4.5')}
                    />
                  }
                  key="4.5"
                  size="small"
                />
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={4}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>4 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="4"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('4')}
                    />
                  }
                  key="4"
                  size="small"
                />
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={3.5}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>3.5 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="3.5"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('3.5')}
                    />
                  }
                  key="3.5"
                />
                <FormControlLabel
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        name="read-only"
                        value={3}
                        readOnly
                        precision={0.5}
                        size="small"
                      />
                      <Box sx={{ ml: 2, fontSize: 'x-small' }}>3 & up</Box>
                    </Box>
                  }
                  control={
                    <Checkbox
                      name="3"
                      onChange={handleRatingChange}
                      checked={ratingValue.includes('3')}
                    />
                  }
                  key="3"
                  size="small"
                />
              </FormGroup>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="levels">
                Levels
              </FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Beginner"
                  control={
                    <Checkbox
                      name="beginner"
                      onChange={handleLevelChange}
                      checked={levelValue.includes('beginner')}
                    />
                  }
                  key="beginner"
                  size="small"
                />
                <FormControlLabel
                  label="Intermediate"
                  control={
                    <Checkbox
                      name="intermediate"
                      onChange={handleLevelChange}
                      checked={levelValue.includes('intermediate')}
                    />
                  }
                  key="intermediate"
                />
                <FormControlLabel
                  label="Expert"
                  control={
                    <Checkbox
                      name="expert"
                      onChange={handleLevelChange}
                      checked={levelValue.includes('expert')}
                    />
                  }
                  key="expert"
                  size="small"
                />
              </FormGroup>
            </FormControl>

            <Divider />

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="grades">
                Grade
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore
                  ? grades.map((singleGrade, index) => (
                      <FormControlLabel
                        // value={singleGrade}
                        control={
                          <Checkbox
                            name={singleGrade}
                            onChange={handleGradeChange}
                            checked={grade.includes(singleGrade)}
                          />
                        }
                        label={singleGrade}
                        labelPlacement="end"
                        key={singleGrade}
                      />
                    ))
                  : grades.slice(0, 5).map((singleGrade, index) => (
                      <FormControlLabel
                        // value={singleGrade}
                        control={
                          <Checkbox
                            name={singleGrade}
                            onChange={handleGradeChange}
                            // checked={grade[singleGrade]}
                            checked={grade.includes(singleGrade)}
                          />
                        }
                        label={singleGrade}
                        labelPlacement="end"
                        key={singleGrade}
                      />
                    ))}
                {openMore ? (
                  <Button
                    onClick={() => {
                      setOpenMore(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="gradeSubjects">
                Grade Subject
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore4
                  ? gradeSubjectsList.map((singleGradeSubject, index) => (
                      <FormControlLabel
                        // value={singleGradeSubject}
                        control={
                          <Checkbox
                            name={singleGradeSubject}
                            onChange={handleGradeSubjectChange}
                            checked={gradeSubject.includes(singleGradeSubject)}
                          />
                        }
                        label={singleGradeSubject}
                        labelPlacement="end"
                        key={singleGradeSubject}
                      />
                    ))
                  : gradeSubjectsList
                      .slice(0, 5)
                      .map((singleGradeSubject, index) => (
                        <FormControlLabel
                          // value={singleGradeSubject}
                          control={
                            <Checkbox
                              name={singleGradeSubject}
                              onChange={handleGradeSubjectChange}
                              // checked={grade[singleGradeSubject]}
                              checked={gradeSubject.includes(singleGradeSubject)}
                            />
                          }
                          label={singleGradeSubject}
                          labelPlacement="end"
                          key={singleGradeSubject}
                        />
                      ))}
                {openMore4 ? (
                  <Button
                    onClick={() => {
                      setOpenMore4(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore4(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="languages">
                Language
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore1
                  ? languages.map((singleLanguage, index) => (
                      <FormControlLabel
                        value={singleLanguage}
                        control={
                          <Checkbox
                            name={singleLanguage}
                            onChange={handleLanguageChange}
                            // checked={language[singleLanguage]}
                            checked={language.includes(singleLanguage)}
                          />
                        }
                        label={singleLanguage}
                        labelPlacement="end"
                        key={singleLanguage}
                      />
                    ))
                  : languages.slice(0, 5).map((singleLanguage, index) => (
                      <FormControlLabel
                        value={singleLanguage}
                        control={
                          <Checkbox
                            name={singleLanguage}
                            onChange={handleLanguageChange}
                            // checked={language[singleLanguage]}
                            checked={language.includes(singleLanguage)}
                          />
                        }
                        label={singleLanguage}
                        labelPlacement="end"
                        key={singleLanguage}
                      />
                    ))}
                {openMore1 ? (
                  <Button
                    onClick={() => {
                      setOpenMore1(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore1(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />

            {publicationsRes.data && publicationsRes.data.publications.length ? (
              <>
                <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                  <FormLabel component="legend" id="publications">
                    Publication
                  </FormLabel>

                  <FormGroup aria-label="position">
                    {openMore2
                      ? publicationsRes.data.publications.map(
                          (singlePublication, index) => (
                            <FormControlLabel
                              value={singlePublication.title}
                              control={
                                <Checkbox
                                  name={singlePublication.title}
                                  onChange={handlePublicationChange}
                                  // checked={publication[singlePublication.title]}
                                  checked={publication.includes(
                                    singlePublication.title
                                  )}
                                />
                              }
                              label={singlePublication.title}
                              labelPlacement="end"
                              key={singlePublication.title}
                            />
                          )
                        )
                      : publicationsRes.data.publications
                          .slice(0, 5)
                          .map((singlePublication, index) => (
                            <FormControlLabel
                              value={singlePublication.title}
                              control={
                                <Checkbox
                                  name={singlePublication.title}
                                  onChange={handlePublicationChange}
                                  // checked={publication[singlePublication.title]}
                                  checked={publication.includes(
                                    singlePublication.title
                                  )}
                                />
                              }
                              label={singlePublication.title}
                              labelPlacement="end"
                              key={singlePublication.title}
                            />
                          ))}
                    {openMore2 ? (
                      <Button
                        onClick={() => {
                          setOpenMore2(false);
                        }}
                        sx={{ zIndex: 'modal' }}
                      >
                        Less
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setOpenMore2(true);
                        }}
                        sx={{ zIndex: 'modal' }}
                      >
                        More
                      </Button>
                    )}
                  </FormGroup>
                </FormControl>
              </>
            ) : null}

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend" id="locations">
                Locations
              </FormLabel>

              <FormGroup aria-label="position">
                {openMore3
                  ? locations.map((singleLocation, index) => (
                      <FormControlLabel
                        value={singleLocation}
                        control={
                          <Checkbox
                            name={singleLocation}
                            onChange={handleLocationChange}
                            // checked={locationValue[singleLocation]}
                            checked={locationValue.includes(singleLocation)}
                          />
                        }
                        label={singleLocation}
                        labelPlacement="end"
                        key={singleLocation}
                      />
                    ))
                  : locations.slice(0, 5).map((singleLocation, index) => (
                      <FormControlLabel
                        value={singleLocation}
                        control={
                          <Checkbox
                            name={singleLocation}
                            onChange={handleLocationChange}
                            // checked={locationValue[singleLocation]}
                            checked={locationValue.includes(singleLocation)}
                          />
                        }
                        label={singleLocation}
                        labelPlacement="end"
                        key={singleLocation}
                      />
                    ))}
                {openMore3 ? (
                  <Button
                    onClick={() => {
                      setOpenMore3(false);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    Less
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenMore3(true);
                    }}
                    sx={{ zIndex: 'modal' }}
                  >
                    More
                  </Button>
                )}
              </FormGroup>
            </FormControl>
            <Divider />
          </>
        </Box>
      </Grid> */}
      <Grid item xs="12" sm="12" md="9" lg="10" xl="10">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography underline="hover" color="inherit" href="/">
            {data?.subCategory?.category?.title}
          </Typography>
          <Typography underline="hover" color="inherit" href="/">
            {data?.subCategory?.title}
          </Typography>
          {params?.gradeTitle && (
            <Typography underline="hover" color="inherit" href="/">
              {params?.gradeTitle}
            </Typography>
          )}
          {params?.subjectTitle && (
            <Typography underline="hover" color="inherit" href="/">
              {params?.subjectTitle}
            </Typography>
          )}
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
            <Box
              sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
              }}
            >
              {/* {isLoading || result?.isLoading ? (
                <DataLoader thickness={5} size={50} />
              ) : null} */}
              {value.length >= 1 ||
              levelValue.length >= 1 ||
              priceValue.length >= 1 ||
              ratingValue.length >= 1 ||
              language.length >= 1 ||
              grade.length >= 1 ||
              gradeSubject.length >= 1 ||
              publication.length >= 1 ||
              locationValue.length >= 1 ? (
                result.isSuccess ? (
                  displayFilterRes(result)
                ) : (
                  <DataLoader thickness={5} size={50} />
                )
              ) : isSuccess && data ? (
                displayNonFilterRes(data)
              ) : (
                <DataLoader thickness={5} size={50} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SubCategoryResults;
