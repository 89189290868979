/* eslint-disable no-unused-vars */

import {
  CastForEducation,
  Clear,
  Language,
  LocationOn,
  PeopleSharp,
  PersonalVideo,
  RateReview,
  ReportProblem,
  Star,
  ThumbDown,
  ThumbUp,
  Timelapse,
  Topic,
} from '@mui/icons-material';
import {
  Typography,
  Grid,
  CardActionArea,
  Card,
  CardMedia,
  LinearProgress,
  Button,
  InputAdornment,
  TextField,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useEnroledCoursesQuery } from '../../../app/services/StudentCoursesApi';
import { Box } from '@mui/system';
import DataLoader from '../../../components/DataLoader';

import ShareButton from '../ShareButton';
import { genericDisplayTopicCount } from '../../../Uitils';
import { useSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search';
function EnroledCourses() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { data, isLoading } = useEnroledCoursesQuery(token);
  const { enqueueSnackbar } = useSnackbar();
  const [filteredEnrolledCourses, setFilteredEnrolledCourse] = useState(
    data?.enrols ? data?.enrols : []
  );
  const [inputValue, setInputValue] = useState('');
  const viewCourse = (id) => {
    navigate(`/enrols/course/${id}`);
  };

  const viewLiveCourse = (id) => {
    navigate(`/enrols/course/live/${id}`);
  };
  useEffect(() => {
    setFilteredEnrolledCourse(data?.enrols);
  }, [data]);
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const findVideoProgress = (course, videoProgress) => {
    const totalCourseDuration =
      Number(course.published_video_sum_duration) +
      Number(course.published_topic_video_sum_duration);

    const totalVideoProgressDuration =
      videoProgress && videoProgress.reduce((n, { timeStamp }) => n + timeStamp, 0);
    if (
      Math.round((totalVideoProgressDuration * 100) / totalCourseDuration) <= 100
    ) {
      return Math.round((totalVideoProgressDuration * 100) / totalCourseDuration);
    } else {
      return 0;
    }
  };

  const getCourseProgressCourses = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      let progressArray = data.map((singleEnrol) =>
        findVideoProgress(singleEnrol.course, singleEnrol.video_progress)
      );
      let inProgress = progressArray.filter(
        (courseProgress) => courseProgress > 0 && courseProgress < 100
      );

      let completed = progressArray.filter(
        (courseProgress) => courseProgress == 100
      );
      return {
        courseInprogress: Array.isArray(inProgress) ? inProgress.length : 0,
        completedCourseCount: Array.isArray(completed) ? completed.length : 0,
      };
    }
  };

  const onSearchClick = (inputSearchValue) => {
    if (inputSearchValue.trim().length === 0) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });

      return;
    } else {
      let finalFilterCourse = data?.enrols.filter((elem) =>
        elem.course.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredEnrolledCourse([...finalFilterCourse]);
    }
  };

  const onClearSearchClick = () => {
    setInputValue('');
    if (Array.isArray(data.enrols) && data.enrols.length > 0) {
      setFilteredEnrolledCourse([...data.enrols]);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs="12" sm="6" md="6" lg="4" xl="4">
              <Box
                sx={{
                  background: '#FFFF02',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0.5rem 2rem',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  <img
                    src="/sand-clock.jpeg"
                    style={{ height: '40px', width: '40px', borderRadius: '20px' }}
                  />
                  Course Enrolled
                </Typography>
                <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
                  {Array.isArray(data?.enrols) ? data?.enrols.length : 0}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs="12" sm="6" md="6" lg="4" xl="4">
              <Box
                sx={{
                  background: '#FFBB02',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0.5rem 2rem',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  <img
                    src="/sand-clock.jpeg"
                    style={{ height: '40px', width: '40px', borderRadius: '20px' }}
                  />
                  Course Inprogress
                </Typography>
                <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
                  {Array.isArray(data?.enrols)
                    ? getCourseProgressCourses(data?.enrols)?.courseInprogress
                    : 0}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs="12" sm="6" md="6" lg="4" xl="4">
              <Box
                sx={{
                  background: '#B1FF02',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0.5rem 2rem',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  <img
                    src="/sand-clock.jpeg"
                    style={{ height: '40px', width: '40px', borderRadius: '20px' }}
                  />
                  Course Completed
                </Typography>
                <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
                  {Array.isArray(data?.enrols)
                    ? getCourseProgressCourses(data?.enrols)?.completedCourseCount
                    : 0}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '0.5rem',
              flexWrap: 'wrap',
              marginTop: '1rem',
            }}
          >
            <TextField
              placeholder="Search courses"
              required
              onChange={(_event) => {
                console.log('thi sis newValue', _event.target.value);
                setInputValue(_event.target.value);
              }}
              value={inputValue}
              InputProps={{
                type: '',
                endAdornment: (
                  <InputAdornment position="end">
                    {inputValue && (
                      <IconButton
                        onClick={() => onClearSearchClick()}
                        // disabled={!inputValue.length}
                      >
                        <Clear />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => onSearchClick(inputValue)}
                      // disabled={!inputValue.length}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </div>
          {Array.isArray(filteredEnrolledCourses) &&
            filteredEnrolledCourses.length > 0 &&
            filteredEnrolledCourses?.map((singleEnrol, index) => {
              return (
                <div
                  style={{
                    marginBottom: '1rem',
                    marginTop: index == 0 ? '1rem' : '2rem',
                  }}
                  key={index}
                >
                  <Grid
                    container
                    sx={{
                      // border: '1px solid #949494',
                      boxShadow: '2px 1px 10px 3px #e3e3e3;',
                    }}
                  >
                    {/* <Grid
                    item
                    xs="12"
                    sm="12"
                    md="4"
                    lg="3"
                    xl="3"
                    key={index}
                    sx={{ m: 2, margin: '0px' }}
                  >
                    <CardActionArea
                      component="a"
                      onClick={() => viewCourse(singleEnrol.id)}
                    >
                      <Card sx={{ display: 'grid', margin: '0px' }}>
                        <CardMedia
                          component="img"
                          sx={{
                            width: '100%',
                            display: { xs: 'none', sm: 'block' },
                          }}
                          image={`https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`}
                        />
                        <Button
                          style={{
                            background: '#1b75bb',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 600,
                            marginTop: '0.5rem',
                          }}
                        >
                          Start Course
                        </Button>
                      </Card>
                    </CardActionArea>
                  </Grid> */}
                    <Grid item xs="12" sm="12" md="8" lg="8" xl="8" key={index}>
                      <div style={{ padding: '0.5rem 1rem 0.5rem 1rem ' }}>
                        <Typography component="h2" variant="h5">
                          <strong className="webkit-box-ellipsis">
                            <label title={singleEnrol.course.title}>
                              {singleEnrol.course.title}
                            </label>
                            {singleEnrol.course.grade
                              ? ` | ${singleEnrol.course.grade.title}`
                              : null}
                            {singleEnrol.course.grade_subject
                              ? ` | ${singleEnrol.course.grade_subject.title}`
                              : null}

                            {singleEnrol.course.publication
                              ? ` | ${singleEnrol.course.publication.title}`
                              : null}
                            {singleEnrol.course.publication_edition
                              ? ` | ${singleEnrol.course.publication_edition.edition} Edition`
                              : null}
                          </strong>
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            flexWrap: 'wrap',
                            marginTop: '0.5rem',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <PeopleSharp sx={{ color: '#4b86c7' }} />{' '}
                            {singleEnrol.course.creator.educator_info
                              ? singleEnrol.course.creator.educator_info
                                  ?.first_name || singleEnrol.course.creator.name
                              : null}
                            {singleEnrol.course.creator.educator_info?.last_name
                              ? ` ${singleEnrol.course.creator.educator_info?.last_name}`
                              : null}
                            {singleEnrol.course.institution_info
                              ? singleEnrol.course.institution_info?.name ||
                                singleEnrol.course.creator.name
                              : null}{' '}
                            {!singleEnrol.course.creator.educator_info &&
                            !singleEnrol.course.institution_info
                              ? singleEnrol.course.creator.name
                              : null}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <LocationOn sx={{ color: '#4b86c7' }} />{' '}
                            {singleEnrol.course.creator.educator_info
                              ? singleEnrol.course.creator.educator_info?.location
                              : null}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <CastForEducation sx={{ color: '#4b86c7' }} />
                            {`Lessons ${
                              singleEnrol.course.subjects?.length
                                ? singleEnrol.course.subjects?.length
                                : 0
                            }`}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <PersonalVideo sx={{ color: '#4b87c7' }} />{' '}
                            {`Videos ${
                              singleEnrol.course.published_video_count +
                              singleEnrol.course.published_subject_video_count +
                              singleEnrol.course.published_subject_video_count +
                              singleEnrol.course.published_topic_video_count
                            }`}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <Topic sx={{ color: '#4b87c7' }} />{' '}
                            {`Topics ${genericDisplayTopicCount(
                              singleEnrol?.course?.subjects
                            )}`}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <Timelapse sx={{ color: '#4b87c7' }} />{' '}
                            {moment
                              .utc(
                                (Number(
                                  singleEnrol.course.published_video_sum_duration
                                ) +
                                  Number(
                                    singleEnrol.course
                                      .published_subject_video_sum_duration
                                  ) +
                                  Number(
                                    singleEnrol.course
                                      .published_topic_video_sum_duration
                                  )) *
                                  1000
                              )
                              .format('HH:mm:ss')}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <Language sx={{ color: '#4b87c7' }} />{' '}
                            {singleEnrol.course.language}
                          </span>
                        </div>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          sx={{ marginTop: '1rem' }}
                        >
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '3rem',
                              }}
                            >
                              <span
                                style={{
                                  background: '#09768d',
                                  color: '#fff',
                                  padding: '0.1rem 0.5rem',
                                  borderRadius: '20px',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  display: 'flex',
                                  alignItems: 'center',
                                  minWidth: '40px',
                                  maxWidth: '50px',
                                  justifyContent: 'center',
                                }}
                              >
                                {singleEnrol?.course?.course_reviews_avg_rating
                                  ? Math.round(
                                      singleEnrol?.course
                                        ?.course_reviews_avg_rating * 10
                                    ) / 10
                                  : 0}
                                &nbsp;
                                <Star sx={{ color: '#fff', fontSize: '1rem' }} />
                              </span>
                              <div>
                                {singleEnrol?.course?.published_at
                                  ? `published on ${moment(
                                      singleEnrol?.course?.published_at
                                    ).format('Do MMM YY ')}`
                                  : `created on ${moment(
                                      singleEnrol?.course?.created_at
                                    ).format('Do MMM YY ')}`}

                                {singleEnrol?.course?.course_enrols_count
                                  ? `| ${singleEnrol?.course?.course_enrols_count} Enrollment`
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          paragraph
                          sx={{ margin: '0rem', fontSize: '14px' }}
                          title={
                            singleEnrol?.course?.description.length > 0 &&
                            singleEnrol.course.description
                          }
                        >
                          {singleEnrol.course.description.substring(0, 118)}
                        </Typography>
                        {singleEnrol.course.start_date &&
                        moment().isSameOrBefore(
                          singleEnrol.course.end_date
                        ) ? null : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                              <LinearProgress
                                value={
                                  findVideoProgress(
                                    singleEnrol.course,
                                    singleEnrol.video_progress
                                  ) || 0
                                }
                                color="secondary"
                                variant="determinate"
                                sx={{
                                  height: 10,
                                  borderRadius: 5,
                                }}
                              />
                            </Box>
                            <Box sx={{ minWidth: 35, overflowWrap: 'normal' }}>
                              <Typography variant="body2" color="text.secondary">{`${
                                findVideoProgress(
                                  singleEnrol.course,
                                  singleEnrol.video_progress
                                ) || 0
                              }%`}</Typography>
                            </Box>
                          </Box>
                        )}
                        {singleEnrol.course.start_date &&
                        moment().isSameOrBefore(singleEnrol.course.end_date) ? (
                          <Button
                            style={{
                              background: '#1b75bb',
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 600,
                              marginTop: '0.5rem',
                            }}
                            component="a"
                            onClick={() => viewLiveCourse(singleEnrol.id)}
                          >
                            View Live Course
                          </Button>
                        ) : (
                          <Button
                            style={{
                              background: '#1b75bb',
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 600,
                              marginTop: '0.5rem',
                            }}
                            component="a"
                            onClick={() => viewCourse(singleEnrol.id)}
                          >
                            {findVideoProgress(
                              singleEnrol.course,
                              singleEnrol.video_progress
                            ) > 0
                              ? 'Continue Course'
                              : ' Start Course'}
                          </Button>
                        )}
                        {/* <PieChart
                        series={[
                          {
                            data: [
                              { label: 'Group A', value: 2400 },
                              { label: 'Group B', value: 4567 },
                              { label: 'Group C', value: 1398 },
                              { label: 'Group D', value: 9800 },
                              { label: 'Group E', value: 3908 },
                              { label: 'Group F', value: 4800 },
                            ],
                            cx: 500,
                            cy: 200,
                            innerRadius: 40,
                            outerRadius: 80,
                          },
                        ]}
                        height={300}
                        legend={{ hidden: true }}
                      /> */}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      xl="4"
                      key={index}
                      sx={{ m: 2, margin: '0px' }}
                    >
                      <div
                        style={{ width: '100%', height: '100%', display: 'flex' }}
                      >
                        <div style={{ width: '50%', height: '100%' }}>
                          <CardMedia
                            component="img"
                            sx={{
                              width: '100%',
                              height: '100%',
                              maxHeight: '240px',
                              display: { xs: 'none', sm: 'block' },
                            }}
                            image={
                              singleEnrol?.course?.url ||
                              singleEnrol?.course?.image_url ||
                              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                            }
                          />
                        </div>
                        <div style={{ width: '50%', height: '100%' }}>
                          <div
                            style={{
                              display: 'grid',
                              gap: '1rem',
                              padding: '0.5rem',
                            }}
                          >
                            <span
                              color="warning"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ShareButton
                                course={singleEnrol?.course}
                                enrolledpage={true}
                              />
                            </span>
                            <span
                              color="warning"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Star sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Favourite
                              </span>
                            </span>
                            <span
                              color="info"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ThumbUp sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Like
                              </span>
                            </span>
                            <span
                              color="error"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ThumbDown sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Dislike
                              </span>
                            </span>
                            <span
                              color="warning"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <RateReview sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Rate & Review
                              </span>
                            </span>
                            <span
                              color="error"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ReportProblem sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Report
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          {/* <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant={window.screen.width < 600 ? `scrollable` : `standard`}
          >
            <Tab
              id="one"
              label="Enrolled Courses"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="two"
              label="Completed Courses"
              {...a11yProps(1)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
          </Tabs>
          <EnrolledCourseTabPanel value={value} index={0}>
            {data?.enrols?.map((singleEnrol, index) => {
              return (
                <div style={{ marginBottom: '1rem' }} key={index}>
                  <Grid container sx={{ border: '1px solid #949494' }}>
                    <Grid
                      item
                      xs="12"
                      sm="12"
                      md="4"
                      lg="3"
                      xl="3"
                      key={index}
                      sx={{ m: 2, margin: '0px' }}
                    >
                      <CardActionArea
                        component="a"
                        onClick={() => viewCourse(singleEnrol.id)}
                      >
                        <Card sx={{ display: 'grid', margin: '0px' }}>
                          <CardMedia
                            component="img"
                            sx={{
                              width: '100%',
                              display: { xs: 'none', sm: 'block' },
                            }}
                            image={
                              singleEnrol.url ||
                              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                            }
                          />
                          <Button
                            style={{
                              background: '#1b75bb',
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 600,
                              marginTop: '0.5rem',
                            }}
                          >
                            Start Course
                          </Button>
                        </Card>
                      </CardActionArea>
                    </Grid>
                    <Grid item xs="12" sm="12" md="6" lg="6" xl="6" key={index}>
                      <div style={{ padding: '0.5rem 1rem 0.5rem 1rem ' }}>
                        <Typography component="h2" variant="h5">
                          <strong>
                            {singleEnrol.course.title}
                            {singleEnrol.course.grade
                              ? ` | ${singleEnrol.course.grade.title}`
                              : null}
                            {singleEnrol.course.grade_subject
                              ? ` | ${singleEnrol.course.grade_subject.title}`
                              : null}

                            {singleEnrol.course.publication
                              ? ` | ${singleEnrol.course.publication.title}`
                              : null}
                            {singleEnrol.course.publication_edition
                              ? ` | ${singleEnrol.course.publication_edition.edition} Edition`
                              : null}
                          </strong>
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            flexWrap: 'wrap',
                            marginTop: '0.5rem',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <PeopleSharp sx={{ color: '#4b86c7' }} />{' '}
                            {singleEnrol.course.creator.educator_info
                              ? singleEnrol.course.creator.educator_info
                                  ?.first_name || singleEnrol.course.creator.name
                              : null}
                            {singleEnrol.course.institution_info
                              ? singleEnrol.course.institution_info?.name ||
                                singleEnrol.course.creator.name
                              : null}{' '}
                            {!singleEnrol.course.creator.educator_info &&
                            !singleEnrol.course.institution_info
                              ? singleEnrol.course.creator.name
                              : null}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <LocationOn sx={{ color: '#4b86c7' }} />{' '}
                            {singleEnrol.course.creator.educator_info
                              ? singleEnrol.course.creator.educator_info?.location
                              : null}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <CastForEducation sx={{ color: '#4b86c7' }} />
                            {`Lessons ${
                              singleEnrol.course.subjects?.length
                                ? singleEnrol.course.subjects?.length
                                : 0
                            }`}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <PersonalVideo sx={{ color: '#4b87c7' }} />{' '}
                            {`Videos ${
                              singleEnrol.course.published_video_count +
                              singleEnrol.course.published_subject_video_count +
                              singleEnrol.course.published_subject_video_count +
                              singleEnrol.course.published_topic_video_count +
                              singleEnrol.course.published_sub_topic_video_count
                            }`}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <Topic sx={{ color: '#4b87c7' }} />{' '}
                            {`Topics ${singleEnrol?.course?.published_topic_video_count}`}{' '}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <Timelapse sx={{ color: '#4b87c7' }} />{' '}
                            {moment
                              .utc(
                                (Number(
                                  singleEnrol.course.published_video_sum_duration
                                ) +
                                  Number(
                                    singleEnrol.course
                                      .published_subject_video_sum_duration
                                  ) +
                                  Number(
                                    singleEnrol.course
                                      .published_topic_video_sum_duration
                                  ) +
                                  Number(
                                    singleEnrol.course
                                      .published_sub_topic_video_sum_duration
                                  )) *
                                  1000
                              )
                              .format('HH:mm:ss')}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <Language sx={{ color: '#4b87c7' }} />{' '}
                            {singleEnrol.course.language}
                          </span>
                        </div>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          sx={{ marginTop: '1rem' }}
                        >
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '3rem',
                              }}
                            >
                              <span
                                style={{
                                  background: '#09768d',
                                  color: '#fff',
                                  padding: '0.1rem 0.2rem',
                                  borderRadius: '20px',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '40px',
                                  justifyContent: 'center',
                                }}
                              >
                                {singleEnrol.course_reviews_avg_rating
                                  ? Math.round(
                                      singleEnrol.course_reviews_avg_rating * 10
                                    ) / 10
                                  : 0}
                                &nbsp;
                                <Star sx={{ color: '#fff', fontSize: '1rem' }} />
                              </span>
                              <div>
                                {singleEnrol.course.status === 'published'
                                  ? `Published at ${
                                      singleEnrol.course.created_at &&
                                      new Date(
                                        singleEnrol.course?.created_at
                                      ).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                      })
                                    }`
                                  : ''}{' '}
                                | Total Enrollment
                              </div>
                            </div>
                          </div>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          paragraph
                          sx={{ margin: '0rem', fontSize: '14px' }}
                          title={
                            singleEnrol?.course?.description.length > 0 &&
                            singleEnrol.course.description
                          }
                        >
                          {singleEnrol.course.description.substring(0, 118)}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                              value={
                                findVideoProgress(
                                  singleEnrol.course,
                                  singleEnrol.video_progress
                                ) || 0
                              }
                              color="secondary"
                              variant="determinate"
                              sx={{
                                height: 10,
                                borderRadius: 5,
                              }}
                            />
                          </Box>
                          <Box sx={{ minWidth: 35, overflowWrap: 'normal' }}>
                            <Typography variant="body2" color="text.secondary">{`${
                              findVideoProgress(
                                singleEnrol.course,
                                singleEnrol.video_progress
                              ) || 0
                            }%`}</Typography>
                          </Box>
                        </Box>
                        <PieChart
                          series={[
                            {
                              data: [
                                { label: 'Group A', value: 2400 },
                                { label: 'Group B', value: 4567 },
                                { label: 'Group C', value: 1398 },
                                { label: 'Group D', value: 9800 },
                                { label: 'Group E', value: 3908 },
                                { label: 'Group F', value: 4800 },
                              ],
                              cx: 500,
                              cy: 200,
                              innerRadius: 40,
                              outerRadius: 80,
                            },
                          ]}
                          height={300}
                          legend={{ hidden: true }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs="12"
                      sm="12"
                      md="4"
                      lg="3"
                      xl="3"
                      key={index}
                      sx={{ m: 2, margin: '0px' }}
                    >
                      <div
                        style={{ width: '100%', height: '100%', display: 'flex' }}
                      >
                        <div style={{ width: '50%', height: '100%' }}>
                          <CardMedia
                            component="img"
                            sx={{
                              width: '100%',
                              height: '100%',
                              display: { xs: 'none', sm: 'block' },
                            }}
                            image={
                              singleEnrol.course.url ||
                              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                            }
                          />
                        </div>
                        <div style={{ width: '50%', height: '100%' }}>
                          <div
                            style={{
                              display: 'grid',
                              gap: '1rem',
                              padding: '0.5rem',
                            }}
                          >
                            <span
                              color="warning"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ShareSharp sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Share
                              </span>
                            </span>
                            <span
                              color="warning"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Star sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Favourite
                              </span>
                            </span>
                            <span
                              color="info"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ThumbUp sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Like
                              </span>
                            </span>
                            <span
                              color="error"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ThumbDown sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Dislike
                              </span>
                            </span>
                            <span
                              color="warning"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <RateReview sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Rate & Review
                              </span>
                            </span>
                            <span
                              color="error"
                              aria-label="delete"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <ReportProblem sx={{ color: '#4b87c7' }} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  color: 'GrayText',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                Report
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </EnrolledCourseTabPanel>
          <EnrolledCourseTabPanel value={value} index={1}></EnrolledCourseTabPanel> */}
        </Grid>
      </Grid>
    </>
  );
}

export default EnroledCourses;
