import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { usePublicationQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewPublicationEdition from './CreateNewPublicationEdition';
import PublicationEditionsList from './PublicationEditionsList';

function SinglePublication() {
  const params = useParams();

  const { data, isLoading, isSuccess } = usePublicationQuery({
    id: parseInt(params.publicationId, 10),
  });

  const [open, setOpen] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isSuccess && data.publication ? (
        <>
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Publication Edition
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle> Create New Publication Edition</DialogTitle>
              <DialogContent>
                <CreateNewPublicationEdition publicationId={data.publication.id} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8">
              <Card>
                <CardContent>
                  <Typography component="h2" variant="h5">
                    Title: {data.publication.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {data.publication.publication_editions &&
          data.publication.publication_editions.length ? (
            <PublicationEditionsList
              publicationEditions={data.publication.publication_editions}
            />
          ) : (
            <Alert severity="info">Did Not Find Any Publication Editions</Alert>
          )}
        </>
      ) : (
        <Alert severity="info">Did Not Find Publication</Alert>
      )}
    </>
  );
}

export default SinglePublication;
