import { BaseApi } from './BaseApi';

export const coursesApi = BaseApi.injectEndpoints({
  reducerPath: 'coursesApi',
  endpoints: (builder) => ({
    courses: builder.query({
      query: () => ({
        url: '/courses',
        method: 'GET',
      }),
      providesTags: ['Course'],
    }),
    createCourse: builder.mutation({
      query: (details) => ({
        url: '/course/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          // start_date: details.start_date,
          // end_date: details.end_date,
          cost: details.cost,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
          publication_id: details.publication_id,
          publication_edition_id: details.publication_edition_id,
          level: details.level,
        },
      }),
      invalidatesTags: ['Course'],
    }),
    course: builder.query({
      query: (details) => ({
        url: `/course/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course'],
    }),
    updateCourse: builder.mutation({
      query: (details) => ({
        url: `/course/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          // start_date: details.start_date,
          // end_date: details.end_date,
          educator_id: details.educator_id,
          institution_id: details.institution_id,
          cost: details.cost,
          level: details.level,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
          publication_id: details.publication_id,
          publication_edition_id: details.publication_edition_id,
        },
      }),
      invalidatesTags: ['Course'],
    }),
    updateCourseSatus: builder.mutation({
      query: (details) => ({
        url: '/course/status/update',
        method: 'POST',

        body: {
          status: details.status,
          course_id: details.id,
          published_at: details.published_at,
        },
      }),
      invalidatesTags: ['Course'],
    }),

    deleteCourse: builder.mutation({
      query: (details) => ({
        url: `/course/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }),
    // Subjects
    subjects: builder.query({
      query: () => ({
        url: '/subjects',
        method: 'GET',
      }),
      providesTags: ['Subject'],
    }),
    createSubject: builder.mutation({
      query: (details) => ({
        url: '/course/subject/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          // start_time: details.start_time,
          // end_time: details.end_time,
          course_id: details.course_id,
        },
      }),
      invalidatesTags: ['Course'],
    }),

    subject: builder.query({
      query: (details) => ({
        url: `/course/subject/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Subject'],
    }),
    updateSubject: builder.mutation({
      query: (details) => ({
        url: `/course/subject/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          // start_time: details.start_time,
          // end_time: details.end_time,
          course_id: details.course_id,
        },
      }),
      invalidatesTags: ['Subject'],
    }),

    deleteSubject: builder.mutation({
      query: (details) => ({
        url: `/course/subject/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }),

    // Topics

    topics: builder.query({
      query: () => ({
        url: '/courses/subject/topics',
        method: 'GET',
      }),
      providesTags: ['Topic'],
    }),
    createTopic: builder.mutation({
      query: (details) => ({
        url: '/course/subject/topic/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          subject_id: details.subject_id,
        },
      }),
      invalidatesTags: ['Subject', 'Course'],
    }),
    topic: builder.query({
      query: (details) => ({
        url: `/course/subject/topic/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Topic'],
    }),
    updateTopic: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          subject_id: details.subject_id,
        },
      }),
      invalidatesTags: ['Topic'],
    }),

    updateTopicOnSort: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/update/sort/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          external_link_title: details.external_link_title,
          external_video_link: details.external_video_link,
          video_id: details.video_id,
          document_id: details.document_id,
        },
      }),
      // invalidatesTags: ['Topic'],
    }),

    deleteTopic: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Subject', 'Course'],
    }),

    getVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/video/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Video'],
    }),

    videos: builder.query({
      query: (token) => ({
        url: '/videos',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Videos'],
    }),
    getVideo: builder.query({
      query: (details) => ({
        url: `/video/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Videos'],
    }),
    updateVideoStatus: builder.mutation({
      query: (details) => ({
        url: '/video/status/update',
        method: 'POST',

        body: {
          video_status: details.status,
          video_id: details.videoId,
        },
      }),
      invalidatesTags: ['Videos'],
    }),

    getSubjectVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/subject/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    getTopicVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/topic/video/show/${details.id}`,
        method: 'GET',
      }),
    }),

    updateVideoDuration: builder.mutation({
      query: (details) => ({
        url: '/video/duration/update',
        method: 'POST',

        body: {
          duration: details.duration,
          video_id: details.videoId,
        },
      }),
      invalidatesTags: ['Course', 'Subject', 'Topic', 'Video', 'Videos'],
    }),
    videoViolations: builder.query({
      query: (token) => ({
        url: '/video/violations',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Video Violations'],
    }),
    courseViolations: builder.query({
      query: (token) => ({
        url: '/course/violations',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Course Violations'],
    }),
    getVideoViolation: builder.query({
      query: (details) => ({
        url: `/video/violation/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Video Violation'],
    }),
    getCourseViolation: builder.query({
      query: (details) => ({
        url: `/course/violation/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course Violation'],
    }),
    deleteVideo: builder.mutation({
      query: (details) => ({
        url: `/video/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Videos'],
    }),
    documents: builder.query({
      query: (token) => ({
        url: '/documents',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Documents'],
    }),
    updateTopicExternalLink: builder.mutation({
      query: (details) => ({
        url: `/topic/external/link/update/${details.id}`,
        method: 'POST',

        body: {
          external_link_title: details.external_link_title,
          external_video_link: details.external_video_link,
        },
      }),
      invalidatesTags: ['Topic', 'Course'],
    }),
    deleteDocument: builder.mutation({
      query: (details) => ({
        url: `/document/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents'],
    }),
    updateDocumentStatus: builder.mutation({
      query: (details) => ({
        url: '/document/status/update',
        method: 'POST',

        body: {
          document_status: details.status,
          document_id: details.documentId,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteTopicDocument: builder.mutation({
      query: (details) => ({
        url: `/document/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Topic'],
    }),
    deleteTopicVideo: builder.mutation({
      query: (details) => ({
        url: `/video/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Topic'],
    }),
    createLiveCourse: builder.mutation({
      query: (details) => ({
        url: '/course/live/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          start_date: details.start_date,
          end_date: details.end_date,
          cost: details.cost,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
          publication_id: details.publication_id,
          publication_edition_id: details.publication_edition_id,
          level: details.level,
        },
      }),
      invalidatesTags: ['Course'],
    }),
    updateLiveCourse: builder.mutation({
      query: (details) => ({
        url: `/course/live/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          start_date: details.start_date,
          end_date: details.end_date,
          educator_id: details.educator_id,
          institution_id: details.institution_id,
          cost: details.cost,
          level: details.level,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
          publication_id: details.publication_id,
          publication_edition_id: details.publication_edition_id,
        },
      }),
      invalidatesTags: ['Course'],
    }),
    liveCourses: builder.query({
      query: () => ({
        url: '/courses/live',
        method: 'GET',
      }),
      providesTags: ['Course'],
    }),
    liveCourse: builder.query({
      query: (details) => ({
        url: `/course/live/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course'],
    }),
    updateTopicLive: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/live/update/${details.id}`,
        method: 'POST',

        body: {
          start_date: details.start_date,
          start_time: details.start_time,
          end_time: details.end_time,
        },
      }),
      invalidatesTags: ['Topic', 'Course'],
    }),
    liveTopic: builder.query({
      query: (details) => ({
        url: `/course/${details.id}/subject/topic/live/show/${details.topicId}`,
        method: 'GET',
      }),
      providesTags: ['Topic'],
    }),
    updateLiveTopicOnSort: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/live/update/sort/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          external_link_title: details.external_link_title,
          external_video_link: details.external_video_link,
          video_id: details.video_id,
          document_id: details.document_id,
          start_date: details.start_date,
          start_time: details.start_time,
          end_time: details.end_time,
        },
      }),
      // invalidatesTags: ['Topic'],
    }),
  }),
});

export const {
  useCoursesQuery,
  useCreateCourseMutation,
  useUpdateCourseSatusMutation,
  useDeleteCourseMutation,
  useCourseQuery,
  useUpdateCourseMutation,
  useCreateSubjectMutation,
  useSubjectQuery,
  useUpdateSubjectMutation,
  useCreateTopicMutation,
  useUpdateTopicMutation,
  useUpdateTopicOnSortMutation,
  useTopicQuery,
  useDeleteSubjectMutation,
  useDeleteTopicMutation,
  useGetVideoUrlQuery,
  useVideosQuery,
  useGetVideoQuery,
  useUpdateVideoStatusMutation,
  useGetSubjectVideoUrlQuery,
  useGetTopicVideoUrlQuery,
  useUpdateVideoDurationMutation,
  useVideoViolationsQuery,
  useCourseViolationsQuery,
  useGetVideoViolationQuery,
  useGetCourseViolationQuery,
  useDeleteVideoMutation,
  useDocumentsQuery,
  useUpdateTopicExternalLinkMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentStatusMutation,
  useDeleteTopicDocumentMutation,
  useDeleteTopicVideoMutation,
  useCreateLiveCourseMutation,
  useUpdateLiveCourseMutation,
  useLiveCoursesQuery,
  useLiveCourseQuery,
  useUpdateTopicLiveMutation,
  useLiveTopicQuery,
  useUpdateLiveTopicOnSortMutation,
} = coursesApi;
