/* eslint-disable no-unused-vars */

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  usePublicPublicationsQuery,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';
import {
  useCreateExamMutation,
  useExamQuery,
  useUpdateExamSatusMutation,
} from '../../../app/services/ExamsApi';
import { uploadExamImageRequest } from '../../../app/services/UploadRequests';
import { allLanguages } from '../../../Uitils';
import DisplayExamPreview from './DisplayExamPreview';
import NewCreateQuestion from './NewCreateQuestion';
import EditExam from './EditExam';

const steps = ['Create Exam', 'Progress', 'Questions', 'Preview', 'Publish Status'];

function NewCreateExam() {
  const { token, isSignedIn } = useSelector((state) => state.auth);
  const { data } = usePublishedCategoriesQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [createExam] = useCreateExamMutation();
  const [updateExamStatus] = useUpdateExamSatusMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get('examId');

  const publicationsRes = usePublicPublicationsQuery();
  const [activeIndex, setActiveIndex] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [level, setLevel] = useState('beginner');
  const [examId, setExamId] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState('');

  const [subCategoryTitle, setSubCategoryTitle] = useState('');
  const [gradeTitle, setGradeTitle] = useState('');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState('');

  const [publicationTitle, setPublicationTitle] = useState('');
  const [publicationEditionTitle, setPublicationEditionTitle] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const allowedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const [questions, setQuestions] = useState(false);
  const [examStatus, setExamStatus] = useState(false);
  const [imageStatus, setImageStatus] = useState(false);
  const [createdCourse, setCreatedCourse] = useState({});
  const CreatedCourseData = useExamQuery({
    id: parseInt(examId, 10),
  });
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const [selectedGrade, setSelectedGrade] = useState({
    title: '',
    id: null,
  });

  const [selectedGradeSubject, setSelectedGradeSubject] = useState({
    title: '',
    id: null,
  });

  const [selectedPublication, setSelectedPublication] = useState({
    title: '',
    id: null,
  });

  const [selectedPublicationEdition, setSelectedPublicationEdition] = useState({
    edition: '',
    id: null,
  });
  const [examCostType, setExamCostType] = useState(null);
  const [examCost, setExamCost] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    setExamId(queryParamValue);
  }, [queryParamValue]);

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data?.categories?.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
    setSelectedSubCategory({
      title: '',
      id: null,
    });

    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationChange = (event) => {
    const tempSelectedPublication = publicationsRes.data.publications.find(
      (singlePublication) => singlePublication.id === event.target.value
    );

    setSelectedPublication(tempSelectedPublication);
    setPublicationTitle(event.target.value);
    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationEditionChange = (event) => {
    const tempPublicationEdition = selectedPublication.publication_editions.find(
      (singlePublicationEdition) =>
        singlePublicationEdition.id === event.target.value
    );

    setSelectedPublicationEdition(tempPublicationEdition);
    setPublicationEditionTitle(event.target.value);
  };

  const setNextButton = (truthValue) => {
    setQuestions(truthValue);
  };

  const validateData = () => {
    let err = true;
    if (title.trim().length === 0 || description.trim().length === 0) {
      enqueueSnackbar(
        'Title or Description cannot be empty or contain only spaces',
        {
          variant: 'error',
        }
      );
      return err;
    }
    if (!title || !selectedSubCategory.id || !language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = () => {
    if (examCostType === 'paid') {
      if (examCost <= 0) {
        enqueueSnackbar('Cost should be greater than zero', {
          variant: 'error',
        });
      }
    }
    let examData = {
      title: title,
      description: description,
      sub_category_id: selectedSubCategory.id,
      language: language,
      level: level,
      cost: examCostType === 'paid' ? examCost : null,
    };

    createExam(examData)
      .unwrap()
      .then((res) => {
        setExamStatus('success');
        setExamId(res.exam.id);

        if (res.exam.id && selectedImage) {
          const UploadData = {
            file: selectedImage,
            token: token,
          };

          uploadExamImageRequest(UploadData, res.exam.id)
            .then((response) => {
              if (response.url) {
                setImageStatus('success');
              } else {
                setImageStatus('error');
              }
            })
            .catch((err) => {
              setImageStatus('error');
            });
        } else {
          setImageStatus('error');
        }
      })
      .catch((error) => {
        setExamStatus('error');
        setImageStatus('error');
      });
  };

  const onStatusChange = (id, status) => {
    updateExamStatus({ id, status });
  };

  const handleExternalNextButton = () => {
    if (activeIndex === 0) {
      setActiveIndex(activeIndex + 1);
    }
  };
  return !isSignedIn ? (
    <Typography>Not Authorized</Typography>
  ) : (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xl="10" lg="10" md="12" sm="11" xs="11">
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#ee3163',
                fontSize: '18px',
                fontWeight: 600,
                marginBottom: '1rem',
              }}
            >
              New Exam
            </Typography>
            <Stepper activeStep={activeIndex} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            {activeIndex == 0 &&
              (examId && CreatedCourseData?.data?.exam.id ? (
                <EditExam
                  exam={CreatedCourseData?.data?.exam}
                  showExtraButton={true}
                  handleExternalNextButton={handleExternalNextButton}
                  setImageStatus={setImageStatus}
                />
              ) : (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    style={{ height: 'auto' }}
                  >
                    <TextField
                      required
                      fullWidth
                      id="title"
                      label=" Title"
                      name="title"
                      autoComplete="title"
                      autoFocus
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete="description"
                      autoFocus
                      multiline
                      rows="2"
                      onChange={handleDescriptionChange}
                    />
                  </Grid>
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      select
                      required
                      fullWidth
                      id="category"
                      label="Category"
                      onChange={handleCategoryChange}
                      name="category"
                      autoComplete="category"
                      autoFocus
                      value={categoryTitle}
                    >
                      {data?.categories && data?.categories.length
                        ? data?.categories.map((singleCategory) => (
                            <MenuItem
                              value={singleCategory.id}
                              key={singleCategory.id}
                            >
                              {singleCategory.title}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      select
                      required
                      fullWidth
                      id="sub_category"
                      label="Sub Category"
                      onChange={handleSubCategoryChange}
                      name="sub_category"
                      autoComplete="sub_category"
                      autoFocus
                      value={subCategoryTitle}
                    >
                      {selectedCategory &&
                      selectedCategory.published_sub_categories.length
                        ? selectedCategory.published_sub_categories.map(
                            (singleSubCategory) => (
                              <MenuItem
                                value={singleSubCategory.id}
                                key={singleSubCategory.id}
                              >
                                {singleSubCategory.title}
                              </MenuItem>
                            )
                          )
                        : null}
                    </TextField>
                  </Grid>
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedSubCategory.grades.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="grade"
                        label="Grade"
                        onChange={handleGradeChange}
                        name="grade"
                        autoComplete="grade"
                        autoFocus
                        value={gradeTitle}
                      >
                        {selectedCategory &&
                        selectedSubCategory &&
                        selectedSubCategory.grades.length
                          ? selectedSubCategory.grades.map((singleGrade) => (
                              <MenuItem value={singleGrade.id} key={singleGrade.id}>
                                {singleGrade.title}
                              </MenuItem>
                            ))
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedGrade.id &&
                  selectedGrade.grade_subjects.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="grade_subject"
                        label="Grade Subject"
                        onChange={handleGradeSubjectChange}
                        name="grade subject"
                        autoComplete="grade subject"
                        autoFocus
                        value={gradeSubjectTitle}
                      >
                        {selectedGrade.grade_subjects.length
                          ? selectedGrade.grade_subjects.map(
                              (singleGradeSubject) => (
                                <MenuItem
                                  value={singleGradeSubject.id}
                                  key={singleGradeSubject.id}
                                >
                                  {singleGradeSubject.title}
                                </MenuItem>
                              )
                            )
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedGrade.id &&
                  publicationsRes.data?.publications.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="publication"
                        label="Publication"
                        onChange={handlePublicationChange}
                        name="publication"
                        autoComplete="publication"
                        autoFocus
                        value={publicationTitle}
                      >
                        {publicationsRes.data.publications.length
                          ? publicationsRes.data.publications.map(
                              (singlePublication) => (
                                <MenuItem
                                  value={singlePublication.id}
                                  key={singlePublication.id}
                                >
                                  {singlePublication.title}
                                </MenuItem>
                              )
                            )
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {selectedCategory.id &&
                  selectedSubCategory.id &&
                  selectedGrade.id &&
                  publicationsRes.data?.publications.length &&
                  selectedPublication.id &&
                  selectedPublication.publication_editions.length ? (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        select
                        required
                        fullWidth
                        id="publication_edition"
                        label="Publication Edition"
                        onChange={handlePublicationEditionChange}
                        name="Publication Edition"
                        autoComplete="Publication Edition"
                        autoFocus
                        value={publicationEditionTitle}
                      >
                        {selectedPublication.publication_editions.length
                          ? selectedPublication.publication_editions.map(
                              (singlePublicationEdition) => (
                                <MenuItem
                                  value={singlePublicationEdition.id}
                                  key={singlePublicationEdition.id}
                                >
                                  {singlePublicationEdition.edition}
                                </MenuItem>
                              )
                            )
                          : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="language"
                      label="What Language the exam is in"
                      name="language"
                      autoComplete="language"
                      autoFocus
                      onChange={handleLanguageChange}
                      select
                    >
                      {Array.isArray(allLanguages) && allLanguages.length > 0
                        ? allLanguages.map((singleLanguage) => (
                            <MenuItem value={singleLanguage} key={singleLanguage}>
                              {singleLanguage}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                  <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                    <TextField
                      required
                      fullWidth
                      id="examfee"
                      label="Exam Fee"
                      name="examfee"
                      autoComplete="examfee"
                      autoFocus
                      onChange={(e) => {
                        setExamCostType(e.target.value);
                      }}
                      select
                    >
                      {[
                        { label: 'Free', value: 'free' },
                        { label: 'Paid', value: 'paid' },
                      ].map((singleItem) => (
                        <MenuItem value={singleItem.value} key={singleItem.value}>
                          {singleItem.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {examCostType === 'paid' && (
                    <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
                      <TextField
                        required
                        fullWidth
                        id="cost"
                        label="Cost"
                        name="cost"
                        autoComplete="cost"
                        autoFocus
                        type="number"
                        onChange={(e) => {
                          setExamCost(e.target.value);
                        }}
                        defaultValue={examCost}
                      />
                    </Grid>
                  )}

                  <Grid item xl="4" lg="4" md="12" sm="12" xs="12">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Learning Level
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={level}
                        onChange={handleLevelChange}
                      >
                        <FormControlLabel
                          value="beginner"
                          control={<Radio />}
                          label="Beginner"
                        />
                        <FormControlLabel
                          value="intermediate"
                          control={<Radio />}
                          label="Intermediate"
                        />
                        <FormControlLabel
                          value="expert"
                          control={<Radio />}
                          label="Expert"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xl="5" lg="5" md="12" sm="12" xs="12">
                    <Box sx={{ height: '100%' }}>
                      <Grid container spacing={1}>
                        <Grid item xl="3" lg="3" md="3" sm="3" xs="3">
                          <img
                            src={imageUrl ? imageUrl : null}
                            style={{
                              border: '1px solid #c7c7c7',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </Grid>
                        <Grid item xl="9" lg="9" md="9" sm="9" xs="9">
                          <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                            {`${
                              selectedCategory?.title == 'Academics'
                                ? 'Textbook'
                                : ''
                            } Cover Image`}
                          </Typography>
                          <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                            Upload exam image here. It must meet our exam image
                            quality standard to be accepted. Important guidelines:
                            750*422 pixels; .jpg, .png, .jpeg, .gif or .png no text
                            on the image
                          </Typography>
                          <form className="form" style={{ marginTop: '2rem' }}>
                            <div
                              className="file-upload-wrapper"
                              data-text={
                                selectedImage
                                  ? selectedImage.name
                                  : 'No File Selected'
                              }
                              data-button-text="Select File"
                            >
                              <input
                                name="file-upload-field"
                                type="file"
                                accept="image/*"
                                className="file-upload-field"
                                value=""
                                onChange={(e) => {
                                  if (
                                    allowedImageTypes.includes(
                                      e.target.files[0].type
                                    )
                                  ) {
                                    setSelectedImage(e.target.files[0]);
                                  } else {
                                    enqueueSnackbar(
                                      'Please select a PNG, JPEG, or SVG file.',
                                      {
                                        variant: 'error',
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          </form>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              ))}

            {activeIndex == 1 && (
              <div style={{ width: '100%' }}>
                <Divider textAlign="left">Exam Description</Divider>

                <Box
                  sx={{
                    display: 'flex',
                    minInlineSize: '-webkit-fill-available',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      {title}
                    </Typography>
                  </Box>
                  {examStatus === false ? <CircularProgress disableShrink /> : null}
                  {examStatus === 'success' ? (
                    <Alert severity="success">Exam Created</Alert>
                  ) : null}
                  {examStatus === 'error' ? (
                    <Alert severity="error">(Error Creating Exam)</Alert>
                  ) : null}
                </Box>

                <Divider textAlign="left">Exam Image</Divider>

                <Grid container spacing={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      minInlineSize: '-webkit-fill-available',
                      mt: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    {selectedImage ? (
                      <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                        <img
                          src={imageUrl}
                          style={{
                            border: '1px solid #c7c7c7',
                            // width: '100%',
                            // height: '100%',
                            height: '500px',
                            width: '300px',
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                      <>
                        {imageStatus === false ? (
                          <CircularProgress disableShrink />
                        ) : null}
                        {imageStatus === 'success' ? (
                          <Alert severity="success">Exam Image Uploaded</Alert>
                        ) : null}
                        {imageStatus === 'error' && selectedImage ? (
                          <>
                            <Alert severity="error">Error Uploading Image</Alert>
                          </>
                        ) : null}
                        {!selectedImage ? (
                          <>
                            <Alert severity="error">No Image Provided</Alert>
                          </>
                        ) : null}
                      </>
                    </Grid>
                  </Box>
                </Grid>
              </div>
            )}
            {activeIndex == 2 &&
              (queryParamValue || examId ? (
                <NewCreateQuestion examId={examId} nextButton={setNextButton} />
              ) : null)}
            {activeIndex == 3 && <DisplayExamPreview examId={examId} />}
            {activeIndex == 4 && (
              <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
                    <Typography variant="subtitle1" paragraph gutterBottom>
                      Thank You for Creating Your Exam
                    </Typography>
                    <Typography variant="subtitle1" paragraph gutterBottom>
                      Click Below Button to Submit for Review.
                    </Typography>
                    {/* <Button
                      type="button"
                      variant="contained"
                      onClick={() => navigate(`/exams`)}
                    >
                      Submit For Review And Exit
                    </Button> */}
                    <Alert severity="info">
                      If You Submit, Admin will review and Publish your Exam. You
                      will recieve notification.{' '}
                    </Alert>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid
              item
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box>
                {activeIndex > 0 && (
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // disabled={result.isLoading || result.isSuccess}
                    onClick={() => {
                      if (activeIndex == 1) {
                        setActiveIndex(0);
                        if (examId && location.search == '') {
                          navigate({ search: `examId=${examId}` });
                        }
                      } else {
                        if (activeIndex > 1) setActiveIndex(activeIndex - 1);
                      }
                    }}
                    // onClick={() => setActiveIndex(activeIndex - 1)}
                  >
                    Previous
                  </Button>
                )}
              </Box>

              {examId && CreatedCourseData?.data?.exam && activeIndex == 0 ? null : (
                <Box>
                  {activeIndex == 4 && (
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => {
                        onStatusChange(examId, 'in_review');
                        navigate(`/exams`);
                      }}
                      sx={{ mt: 3, mb: 2, mr: 2 }}
                    >
                      Submit For Review And Exit
                    </Button>
                  )}

                  {activeIndex <= 3 && (
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => {
                        if (activeIndex === 0) {
                          const res = validateData();
                          if (!res) {
                            handleSubmit();
                            setActiveIndex(activeIndex + 1);
                          }
                        }
                        if (activeIndex === 1) {
                          if (examId) {
                            setActiveIndex(activeIndex + 1);
                          } else if (examStatus === 'error') {
                            enqueueSnackbar('Error Creating Exam', {
                              variant: 'error',
                            });
                          } else {
                            enqueueSnackbar(' Creating Exam, Please Wait', {
                              variant: 'success',
                            });
                          }
                        }

                        if (activeIndex === 2) {
                          if (questions) {
                            setActiveIndex(activeIndex + 1);
                          } else {
                            enqueueSnackbar('Please Provide at least one Question', {
                              variant: 'error',
                            });
                          }
                        }
                        if (activeIndex === 3) {
                          setActiveIndex(activeIndex + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default NewCreateExam;
