import { Rating, Typography, Grid, Avatar, Paper, Alert } from '@mui/material';

function DisplayTestReviews(props) {
  const { testReviews } = props;
  return (
    <>
      <Typography>Reviews</Typography>
      {testReviews.length >= 1 ? (
        testReviews.map((singleTestReview) => (
          <>
            <Paper
              sx={{
                my: 1,
                mx: 'auto',
                p: 2,
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar>
                    {singleTestReview.review_creator.name.charAt(0).toUpperCase()}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography>{singleTestReview.review_creator.name}</Typography>

                  <Rating
                    name="read-only"
                    value={singleTestReview.rating}
                    readOnly
                    precision={0.5}
                  />
                  <Typography>{singleTestReview.review_text}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </>
        ))
      ) : (
        <Alert severity="info">No Reviews Yet</Alert>
      )}
    </>
  );
}
export default DisplayTestReviews;
