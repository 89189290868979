import { Avatar, Button, Grid, Typography } from '@mui/material';
import {
  useFollowingEducatorsQuery,
  useUnFollowUserMutation,
} from '../../app/services/UserApi';
import DataLoader from '../../components/DataLoader';
import { CastForEducation, People, Reviews, Star } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function EducatorFollowing() {
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useFollowingEducatorsQuery();
  const [unFollowUser] = useUnFollowUserMutation();

  const onUnFollowClick = (id) => {
    unFollowUser({ id });
  };
  const getReducedText = (text) => {
    const finalText = text
      ? text.length > 118
        ? text.substring(0, 118) + '...'
        : text
      : '';
    return finalText;
  };
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const displayEducatorAvgCourseRating = (courses) => {
    let avgRating = 0;
    let noOfRatings = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        avgRating += Number(singleCourse.course_reviews_avg_rating);
        noOfRatings += Number(singleCourse.course_reviews_avg_rating) ? 1 : 0;
      });
      return Math.round((avgRating / noOfRatings) * 10) / 10;
      // return avgRatin g / noOfRatings;
    } else {
      return avgRating;
    }
  };

  const displayEducatorCourseEnrols = (courses) => {
    let enrols = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        enrols += Number(singleCourse.course_enrols_count);
      });
      return enrols;
    } else {
      return enrols;
    }
  };

  const displayReviewCounts = (courses) => {
    let reviewCounts = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        reviewCounts += Number(singleCourse.course_reviews_count);
      });
      return reviewCounts;
    } else {
      return reviewCounts;
    }
  };

  const viewEducator = (id) => {
    navigate(`/educator/channel/${id}`);
  };

  return (
    <Grid container spacing={2}>
      {isSuccess && data.following.length >= 1 ? (
        data.following.map((singleFollowing) => (
          <Grid item xl="4" lg="4" md="6" sm="12" xs="12" key={singleFollowing.id}>
            <Grid container sx={{ background: '#fff' }}>
              <>
                <Grid item xl="4" lg="4" md="12" sm="12" xs="12">
                  {!singleFollowing.following_educator?.educator_info?.image_url ? (
                    <img
                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                      src="https://cdn4.vectorstock.com/i/1000x1000/95/28/cartoon-male-teacher-vector-16659528.jpg"
                      onClick={() =>
                        viewEducator(singleFollowing.following_educator?.id)
                      }
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      sx={{
                        height: '100%',
                        width: '100%',
                        // border: '1px solid',
                      }}
                      alt={singleFollowing.following_educator?.name
                        .charAt(0)
                        .toUpperCase()}
                      src={
                        singleFollowing.following_educator?.educator_info?.image_url
                          ? singleFollowing.following_educator?.educator_info
                              .image_url
                          : null
                      }
                      onClick={() =>
                        viewEducator(singleFollowing.following_educator?.id)
                      }
                    ></Avatar>
                  )}
                </Grid>
                <Grid
                  item
                  xl="8"
                  lg="8"
                  md="12"
                  sm="12"
                  xs="12"
                  sx={{ padding: '0rem 1rem 0rem 1rem' }}
                >
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontWeight: 700,
                      color: '#727391',
                      lineHeight: '1.2',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() =>
                      viewEducator(singleFollowing.following_educator?.id)
                    }
                    title={`${
                      singleFollowing.following_educator?.educator_info
                        ?.first_name || singleFollowing.following_educator?.name
                    } ${
                      singleFollowing?.following_educator?.educator_info?.last_name
                        ? ` ${singleFollowing?.following_educator?.educator_info?.last_name}`
                        : null
                    }`}
                  >
                    {singleFollowing.following_educator?.educator_info?.first_name ||
                      singleFollowing.following_educator?.name}
                    {singleFollowing?.following_educator?.educator_info?.last_name
                      ? ` ${singleFollowing?.following_educator?.educator_info?.last_name}`
                      : null}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#5e7fb1',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    title={
                      singleFollowing.following_educator?.educator_info?.headline ||
                      singleFollowing.following_educator?.educator_info?.subject
                    }
                  >
                    {singleFollowing.following_educator?.educator_info?.headline ||
                      singleFollowing.following_educator?.educator_info?.subject}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.5rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <Star sx={{ fontSize: '1rem' }} />
                    {/* 4.6 Instructor Rating */}
                    {singleFollowing.following_educator
                      ?.educator_course_enrols_and_reviews
                      ? displayEducatorAvgCourseRating(
                          singleFollowing.following_educator
                            .educator_course_enrols_and_reviews
                        )
                      : 'No'}
                    &nbsp; Educator Rating
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.2rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <Reviews sx={{ fontSize: '1rem' }} />
                    {/* 18,534 Reviews */}
                    {singleFollowing.following_educator
                      ?.educator_course_enrols_and_reviews
                      ? displayReviewCounts(
                          singleFollowing.following_educator
                            ?.educator_course_enrols_and_reviews
                        )
                      : 'No'}
                    &nbsp;Reviews
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.2rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <People sx={{ fontSize: '1rem' }} />
                    {/* 115,904 Students */}
                    {singleFollowing.following_educator
                      ?.educator_course_enrols_and_reviews
                      ? displayEducatorCourseEnrols(
                          singleFollowing.following_educator
                            ?.educator_course_enrols_and_reviews
                        )
                      : 'No'}
                    &nbsp;Students
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.2rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <CastForEducation sx={{ fontSize: '1rem' }} />
                    {/* 13 Courses */}
                    {singleFollowing.following_educator
                      ?.educator_course_enrols_and_reviews
                      ? singleFollowing.following_educator
                          ?.educator_course_enrols_and_reviews?.length
                      : 'No'}
                    &nbsp;Courses
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '11px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.5rem',
                    }}
                    title={
                      getReducedText(
                        singleFollowing.following_educator?.educator_info?.bio ||
                          singleFollowing.following_educator?.educator_info?.field
                      ).length > 115 &&
                      (singleFollowing.following_educator?.educator_info?.bio ||
                        singleFollowing.following_educator?.educator_info?.field)
                    }
                  >
                    {getReducedText(
                      singleFollowing.following_educator?.educator_info?.bio ||
                        singleFollowing.following_educator?.educator_info?.field
                    )}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => onUnFollowClick(singleFollowing.followed_id)}
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '30px',
                      float: 'right',
                    }}
                  >
                    UnFollow
                  </Button>
                </Grid>
              </>{' '}
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography>Not Following Any Educators</Typography>
      )}
    </Grid>
  );
}

export default EducatorFollowing;
