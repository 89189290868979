import { BaseApi } from './BaseApi';

export const learningObjectivesApi = BaseApi.injectEndpoints({
  reducerPath: 'learningObjectivesApi',
  endpoints: (builder) => ({
    learningObjectives: builder.query({
      query: () => ({
        url: '/learning/objectives',
        method: 'GET',
      }),
      providesTags: ['learning objective'],
    }),
    createLearningObjective: builder.mutation({
      query: (details) => ({
        url: '/learning/objective/create',
        method: 'POST',

        body: {
          title: details.title,
          objectives: details.objectives,
        },
      }),
      invalidatesTags: ['learning objective'],
    }),
    learningObjective: builder.query({
      query: (details) => ({
        url: `/learning/objective/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['learning objective'],
    }),
    learningObjectivesForStudent: builder.query({
      query: () => ({
        url: '/student/learning/objectives',
        method: 'GET',
      }),
      providesTags: ['learning objective'],
    }),
  }),
});

export const {
  useLearningObjectivesQuery,
  useCreateLearningObjectiveMutation,
  useLearningObjectiveQuery,
  useLearningObjectivesForStudentQuery,
} = learningObjectivesApi;
