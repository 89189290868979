/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSingleCourseExamsQuery } from '../../../app/services/CourseExamsApi';
import CourseExamsList from './CourseExamsList';
import SingleCourseExamsList from './SingleCourseExamsList';

function DispalaySingleCourseExams() {
  const params = useParams();

  //   const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useSingleCourseExamsQuery({
    id: parseInt(params.Id, 10),
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
        {isSuccess ? (
          <SingleCourseExamsList exams={data?.course?.exams} course={data?.course} />
        ) : (
          <p>No exams found</p>
        )}
      </Grid>
    </Grid>
  );
}

export default DispalaySingleCourseExams;
