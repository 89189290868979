import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';
function SingleEducatorTickets() {
  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Support Tickets
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, maxHeight: '20px', overflow: 'scroll' }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell> Ticket Title</TableCell>
              <TableCell> Ticket Status</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                1
              </TableCell>
              <TableCell>
                deexsxssededsassdwssxswxdseesdcxsxsxwssdxwdwsdxwsdxasxscdcddxseddxsxsxddddddddddddd
              </TableCell>
              <TableCell>open</TableCell>
              <TableCell>17th Mar 25 12:42 PM</TableCell> <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SingleEducatorTickets;
