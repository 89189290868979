/* eslint-disable no-unused-vars */
import { Paper, Typography } from '@mui/material';
import { useEducatorSubscriptionsQuery } from '../../../app/services/EducatorSubscriptionApi';
import { ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DataLoader from '../../../components/DataLoader';
import moment from 'moment';

function SubscribedPlans() {
  const { data, isLoading, isSuccess } = useEducatorSubscriptionsQuery();

  const navigate = useNavigate();

  const [expandedId, setExpandedId] = useState(-1);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewSub = (id) => {
    navigate(`/subscribed-plans/${id}`);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.subscriptions ? (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Billed</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>
                <span style={{ marginLeft: '1rem' }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.subscriptions &&
              data.subscriptions.length &&
              data.subscriptions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((singleSubscription, index) => (
                  <TableRow
                    key={singleSubscription.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{singleSubscription.bill_type}</TableCell>
                    <TableCell>
                      {moment(singleSubscription.created_at).format(
                        'Do MMM YY hh:mm A'
                      )}
                    </TableCell>
                    <TableCell>
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 200,
                          bgcolor: 'background.paper',
                        }}
                      >
                        <ListItemButton
                          onClick={() => handleClick(index)}
                          aria-expanded={expandedId === index}
                        >
                          <ListItemText primary="Options" />
                          <ExpandMore />
                        </ListItemButton>
                        <Collapse
                          in={expandedId === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              onClick={() => viewSub(singleSubscription.id)}
                            >
                              <ListItemText primary="View" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.subscriptions && data.subscriptions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  ) : (
    <Typography>No subscriptions found</Typography>
  );
}

export default SubscribedPlans;
