import { BaseApi } from './BaseApi';

export const publicExamsApi = BaseApi.injectEndpoints({
  reducerPath: 'publicExamsApi',
  endpoints: (builder) => ({
    publiExams: builder.query({
      query: () => ({
        url: '/public/exams',
        method: 'GET',
      }),
    }),
    publicExam: builder.query({
      query: (details) => ({
        url: `/public/category/subCategory/exam/show/${details.id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { usePubliExamsQuery, usePublicExamQuery } = publicExamsApi;
