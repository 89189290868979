/* eslint-disable no-unused-vars */

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  useUpdateUserBanStatusMutation,
  useUpdateUserUnBanStatusMutation,
} from '../../../app/services/AdminUsersApi';

function ChangeUserStatus(props) {
  const { user } = props;
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [updateUserBanStatus, result] = useUpdateUserBanStatusMutation();
  const [updateUserUnBanStatus, result1] = useUpdateUserUnBanStatusMutation();

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateData = {
      id: user.id,
    };

    if (status === 'ban') {
      updateUserBanStatus(updateData);
    } else {
      updateUserUnBanStatus(updateData);
    }
  };

  return result?.data || result1?.data ? (
    result?.data?.message || result1?.data?.message
  ) : (
    <>
      <Box
        noValidate
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
        }}
        onSubmit={onChangeStatus}
      >
        <Typography>
          Current Ban Status: {user.is_banned ? 'banned' : 'unbanned'}
        </Typography>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          <InputLabel htmlFor="max-width">status</InputLabel>

          <Select
            autoFocus
            onChange={handleChangeStatus}
            label="maxWidth"
            inputProps={{
              name: 'max-width',
              id: 'max-width',
            }}
          >
            {user.is_banned ? (
              <MenuItem value="unban">UNBAN USER</MenuItem>
            ) : (
              <MenuItem value="ban">BAN USER</MenuItem>
            )}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={status === user.status || loading || !status}
        >
          Change User Status
        </Button>
      </Box>
    </>
  );
}

export default ChangeUserStatus;
