import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  AlertTitle,
  Rating,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { usePublicExamQuery } from '../../../app/services/PublicExamsApi';

// import moment from 'moment';
import {
  useCreateStudentExamEnrolPaymentOrderIdMutation,
  useCreateStudentExamEnrolWithPaymentMutation,
  useExamEnrolMutation,
} from '../../../app/services/StudentExamsApi';
import DisplayExamReviews from './DisplayExamReviews';
import DataLoader from '../../../components/DataLoader';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

function DisplaySingleExam() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess, refetch } = usePublicExamQuery({
    id: parseInt(params.examId, 10),
  });
  const [examEnrol, result] = useExamEnrolMutation();
  const [createEnrolOrderId] = useCreateStudentExamEnrolPaymentOrderIdMutation();
  const [createExamEnrolPayment] = useCreateStudentExamEnrolWithPaymentMutation();

  if (result.isSuccess) {
    return <Navigate replace to={`/enrols/exam/${result.data.enrol.id}`} />;
  }

  const enrolexam = async () => {
    await examEnrol({ exam_id: data.exam.id });
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);

    const createOrderIdRequest = await createEnrolOrderId({
      amount: data.exam.cost,
      currency: 'INR',
      exam_id: data.exam.id,
    });

    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: data.exam.cost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          enqueueSnackbar('Error Paying for the exam. ', {
            variant: 'error',
          });
        } else {
          await createExamEnrolPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: data.exam.cost,
            email: user.email,
            exam_id: data.exam.id,
          })
            .unwrap()
            .then((res) => {
              if (res.enrol.id) {
                enqueueSnackbar('Enrolled Exam with success.', {
                  variant: 'success',
                });
                navigate(`/enrols/exam/${res.enrol.id}`);
              } else {
                enqueueSnackbar('Error Enroling for exam. ', {
                  variant: 'error',
                });
              }
            })
            .finally(() => {
              refetch();
            });
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };

  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  return isSuccess && data.exam ? (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: data.exam.url
            ? `url(${data.exam.url})`
            : `url("https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
        }}
      >
        {
          <img
            style={{ display: 'none' }}
            src={
              data.exam.url ||
              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
            }
          />
        }
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <Typography component="h2" variant="h5">
                <strong>{data.exam.title}</strong>
              </Typography>
              <Typography variant="subtitle1">
                In&nbsp;
                {data.exam.language}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <Typography>
                    {Math.round(data.exam.exam_reviews_avg_rating * 10) / 10}&nbsp;
                  </Typography>
                </Box>
                <Rating
                  name="read-only"
                  value={Math.round(data.exam.exam_reviews_avg_rating * 10) / 10}
                  readOnly
                  precision={0.5}
                  className="custom-rating"
                />

                {data.exam.exam_reviews_count ? (
                  <Typography>
                    &#40;{data.exam.exam_reviews_count}
                    &#41;
                  </Typography>
                ) : null}
              </Box>
              <CardActions sx={{ padding: 0 }}>
                {isSignedIn && user.role !== 'student' ? (
                  <Alert severity="info">
                    Please register as a student to enrol for the exam
                  </Alert>
                ) : data?.exam?.alreadyEnroled?.id ? (
                  <>
                    <Alert severity="info">
                      <>
                        <AlertTitle>
                          You are already Enrolled to this exam.
                        </AlertTitle>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() =>
                            navigate(
                              `/enrols/exam/${data?.exam?.alreadyEnroled?.id}`
                            )
                          }
                        >
                          Click here to take your Exam
                        </Button>
                      </>
                    </Alert>
                  </>
                ) : data.exam.cost ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/student-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Buy This Exam (₹{data.exam.cost})
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      isSignedIn
                        ? enrolexam()
                        : navigate('/student-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Enroll (Free)
                  </Button>
                )}
              </CardActions>
            </Box>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 3 },
              pr: { md: 0 },
              overflowWrap: 'anywhere',
            }}
          >
            <Typography component="h2" variant="h5">
              <strong>Exam Description</strong>
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {data.exam.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <DisplayExamReviews examReviews={data.exam.exam_reviews} />
            </Box>
          </>
        </Grid>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>
    </>
  ) : (
    <p>No exam found</p>
  );
}

export default DisplaySingleExam;
