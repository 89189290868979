import { Grid } from '@mui/material';
import React from 'react';
import { useCoursesQuery } from '../../../app/services/CoursesApi';
import DataLoader from '../../../components/DataLoader';
import CoursesList from './CoursesList';

function CoursesManager() {
  const { data, isLoading, isSuccess } = useCoursesQuery();
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1} justifyContent={'center'}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess ? (
            <CoursesList courses={data?.courses} />
          ) : (
            <p>No courses found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CoursesManager;
