/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEnroledCourseQuery } from '../../../app/services/StudentCoursesApi';
import DataLoader from '../../../components/DataLoader';
import DisplayEnroledLiveCourseCurriculum from './DisplayEnroledLiveCourseCurriculum';

function DisplayEnroledLiveCourse() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useEnroledCourseQuery({
    id: parseInt(params.enrolId, 10),
  });
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return isSuccess && data.enrol ? (
    <Grid
      container
      spacing={2}
      sx={{
        // bgcolor: 'background.paper',
        overflowWrap: 'anywhere',
      }}
    >
      <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
        <Box sx={{ margin: '20px' }}>
          {moment().isSameOrAfter(data.enrol.course.start_date) &&
          moment().isSameOrBefore(data.enrol.course.end_date) ? (
            <Typography>Watching Live Course</Typography>
          ) : (
            <Typography>Course is not Live yet</Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
        <Box sx={{ margin: '20px' }}>
          <Typography variant="subtitle1" color="text.secondary">
            Course Content
          </Typography>{' '}
          {data.enrol.course.subjects.length ? (
            <DisplayEnroledLiveCourseCurriculum
              subjects={data.enrol.course.subjects}
              enrolId={data.enrol.id}
              //   progressData={data.enrol.video_progress}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <p>No course found</p>
  );
}

export default DisplayEnroledLiveCourse;
