/* eslint-disable no-unused-vars */
import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useLiveCourseQuery,
  useLiveTopicQuery,
} from '../../../app/services/CoursesApi';
import DataLoader from '../../../components/DataLoader';
import DisplayLiveCourseCurriculum from './DisplayLiveCourseCurriculum';

function DisplaySingleLiveCourseTopic() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useLiveTopicQuery({
    id: parseInt(params.Id, 10),
    topicId: parseInt(params.topicId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return isSuccess && data.topic ? (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          // bgcolor: 'background.paper',
          overflowWrap: 'anywhere',
        }}
      >
        <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography>
              <strong>{data.topic.title}</strong>
            </Typography>
            {data.course.status === 'published' ? (
              moment().isBetween(
                moment(data.topic.start_time, 'HH:mm:ss'),
                moment(data.topic.end_time, 'HH:mm:ss')
              ) ? (
                <>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => navigate(`/obs-streaming/${data.topic.id}`)}
                  >
                    Go Live with OBS
                  </Button>
                  &nbsp;
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`/web-streaming`)}
                    disabled
                  >
                    Go Live with Web Camera and Microphone
                  </Button>
                </>
              ) : (
                <>
                  <Typography>Course Topic is not Live yet</Typography>
                </>
              )
            ) : (
              <Alert severity="info">Course is still in {data.course.status}</Alert>
            )}
          </Box>
        </Grid>
        <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography variant="subtitle1" color="text.secondary">
              Course Content
            </Typography>{' '}
            {data.course.subjects.length ? (
              <DisplayLiveCourseCurriculum subjects={data.course.subjects} />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>No course found</p>
  );
}

export default DisplaySingleLiveCourseTopic;
