/* eslint-disable no-unused-vars */
import { Alert, AlertTitle, Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiveCourseQuery } from '../../../app/services/CoursesApi';
import DataLoader from '../../../components/DataLoader';
import DisplayLiveCourseCurriculum from './DisplayLiveCourseCurriculum';

function DisplaySingleLiveCourse() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useLiveCourseQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.course ? (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          // bgcolor: 'background.paper',
          overflowWrap: 'anywhere',
        }}
      >
        <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography>
              <strong>{data.course.title}</strong>
            </Typography>
            {data.course.status === 'published' ? (
              moment().isSameOrAfter(data.course.start_date) &&
              moment().isSameOrBefore(data.course.end_date) ? (
                <>
                  {/* <Typography>Instructions to go live</Typography> */}

                  <Alert severity="info" sx={{ marginTop: '1rem' }}>
                    <AlertTitle>
                      Click on the lecture in the sidebar to go to the lecture page.
                    </AlertTitle>
                  </Alert>
                  <Alert severity="info" sx={{ marginTop: '1rem' }}>
                    <AlertTitle>
                      On the lecture page you will see buttons to go live on the time
                      you set during the creation of lecture.
                    </AlertTitle>
                  </Alert>

                  <Alert severity="info" sx={{ marginTop: '1rem' }}>
                    <AlertTitle>
                      You can choose to go live with OBS or by choosing your web
                      camera and microphone.
                    </AlertTitle>
                  </Alert>
                </>
              ) : (
                <Typography>Course is not Live yet</Typography>
              )
            ) : (
              <Alert severity="info">Course is still in {data.course.status}</Alert>
            )}
          </Box>
        </Grid>
        <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography variant="subtitle1" color="text.secondary">
              Course Content
            </Typography>{' '}
            {data.course.subjects.length ? (
              <DisplayLiveCourseCurriculum subjects={data.course.subjects} />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>No course found</p>
  );
}

export default DisplaySingleLiveCourse;
