import { Typography, Grid, Card, CardContent } from '@mui/material';
import { useParams } from 'react-router';
import { useGetVideoQuery } from '../../../../app/services/CoursesApi';
import DataLoader from '../../../../components/DataLoader';
import HlsJs from '../../HlsJs';

function DisplaySingleVideo() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGetVideoQuery({
    id: parseInt(params.Id, 10),
  });
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.video ? (
        <Grid container spacing={1}>
          <Grid item xs="12" sm="12" md="1" lg="1" xl="1" align="left"></Grid>

          <Grid item xs="12" sm="12" md="6" lg="6" xl="6" align="left">
            {data.video ? <HlsJs vidSrc={data.video.vid_url} /> : null}

            <Card>
              <CardContent sx={{ overflowWrap: 'anywhere' }}>
                <Typography component="h2" variant="h5">
                  <strong>{data.video.title}</strong>
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Description: {data.video.description}
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Status: {data.video.status}
                </Typography>
                {data.video.course ? (
                  <Typography variant="subtitle1" paragraph>
                    Course: &nbsp;
                    {data.video.course ? data.video.course.title : null}
                  </Typography>
                ) : null}
                <>
                  {data.video.subject ? (
                    <Typography variant="subtitle1" paragraph>
                      Course:&nbsp;
                      {data.video.subject.course.title}
                    </Typography>
                  ) : null}
                  {data.video.topic ? (
                    <Typography variant="subtitle1" paragraph>
                      Course:&nbsp;
                      {data.video.topic.subject.course.title}
                    </Typography>
                  ) : null}
                  {data.video.topic ? (
                    <Typography variant="subtitle1" paragraph>
                      Subject:&nbsp;
                      {data.video.topic.subject.title}
                    </Typography>
                  ) : null}

                  {data.video.subject ? (
                    <Typography variant="subtitle1" paragraph>
                      Subject:&nbsp;
                      {data.video.subject.title}
                    </Typography>
                  ) : null}

                  {data.video.topic ? (
                    <Typography variant="subtitle1" paragraph>
                      Topic:&nbsp;
                      {data.video.topic.title}
                    </Typography>
                  ) : null}
                </>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <p>No video found</p>
      )}
    </>
  );
}

export default DisplaySingleVideo;
