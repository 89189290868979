import { Avatar, Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAdminDashboardCountsQuery } from '../../../app/services/UserApi';
import DataLoader from '../../../components/DataLoader';

function AdminDashboard() {
  const { isSignedIn, user } = useSelector((state) => state.auth);

  const { data, isLoading, isSuccess } = useAdminDashboardCountsQuery();
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  const allData = [
    {
      title: 'Users',
      subTitleData: [
        {
          title: 'Total Users',
          attribute: 'totalRegisteredUsers',
        },
        {
          title: 'Total Admins',
          attribute: 'totalAdmins',
        },
        {
          title: 'Total Educators',
          attribute: 'totalEducators',
        },
        {
          title: 'Total Institutions',
          attribute: 'totalInstitutions',
        },
        {
          title: 'Total Students',
          attribute: 'totalStudents',
        },
      ],
    },
    {
      title: 'Courses',
      subTitleData: [
        {
          title: 'Total Courses',
          attribute: 'totalCourses',
        },
        {
          title: 'Published Courses',
          attribute: 'totalPublishedCourses',
        },
        {
          title: 'Draft Courses',
          attribute: 'totalDraftCourses',
        },
        {
          title: 'Free Courses',
          attribute: 'totalFreeCourses',
        },
        {
          title: 'Premium Courses',
          attribute: 'totalPremiumCourses',
        },
      ],
    },
    {
      title: 'Exams',
      subTitleData: [
        {
          title: 'Total Exams',
          attribute: 'totalExams',
        },
        {
          title: 'Published Exams',
          attribute: 'totalPublishedExams',
        },
        {
          title: 'Draft Exams',
          attribute: 'totalDraftExams',
        },
        {
          title: 'Free Exams',
          attribute: 'totalFreeExams',
        },
        {
          title: 'Premium Exams',
          attribute: 'totalPremiumExams',
        },
      ],
    },
    {
      title: 'Test Series',
      subTitleData: [
        {
          title: 'Total Test Series',
          attribute: 'totalTests',
        },
        {
          title: 'Published Tests',
          attribute: 'totalPublishedTests',
        },
        {
          title: 'Draft Tests',
          attribute: 'totalDraftTests',
        },
        {
          title: 'Free Tests',
          attribute: 'totalFreeTests',
        },
        {
          title: 'Premium Tests',
          attribute: 'totalPremiumTests',
        },
      ],
    },
  ];
  return (
    <>
      {isSuccess && data ? (
        <Grid container spacing={2}>
          <Grid item xl="1" lg="1" md="12" sm="12" xs="12"></Grid>
          <Grid
            item
            xl="2"
            lg="2"
            md="12"
            sm="12"
            xs="12"
            //   sx={{ border: '0.5px solid', padding: '0.5rem' }}
          >
            <Box sx={{ border: '0.5px solid', padding: '0.5rem' }}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div>
                  <Avatar sx={{ height: '80px', width: '80px' }}>
                    {isSignedIn ? user.name.charAt(0).toUpperCase() : null}
                  </Avatar>
                </div>
                <div>
                  <Typography
                    component={'h1'}
                    variant="h6"
                    sx={{
                      color: '#397abb',
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    Welcome !
                  </Typography>
                  <Typography
                    component={'h1'}
                    variant="h6"
                    sx={{
                      color: '#397abb',
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    {user ? user.name : null}
                  </Typography>
                </div>
              </div>
            </Box>
          </Grid>

          <Grid
            item
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            //   sx={{ border: '0.5px solid', padding: '0.5rem' }}
          >
            <Grid container>
              <Grid
                item
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
                sx={{ border: '0.5px solid', padding: '0.5rem' }}
              >
                {Array.isArray(allData) && allData.length > 0 ? (
                  allData.map((singleItem, mainIndex) => (
                    <Box key={`singlrAdminCardRow ${mainIndex + 1}`}>
                      <Typography
                        sx={{
                          color: '#1c76bb',
                          fontSize: '18px',
                          fontWeight: '600',
                        }}
                      >
                        {singleItem?.title}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.5rem',
                          flexWrap: 'wrap',
                        }}
                      >
                        {/* #f6f6f6 */}
                        {Array.isArray(singleItem?.subTitleData) &&
                        singleItem?.subTitleData.length > 0 ? (
                          singleItem?.subTitleData.map(
                            (singleDisplayData, index) => (
                              <Box
                                sx={{
                                  padding: '1rem',
                                  width: 'calc(100% / 5 - 10px)',
                                  minWidth: '100px',
                                  height: '80px',
                                  borderRadius: '10px',
                                  backgroundColor: '#f6f6f6',
                                  textAlign: 'center',
                                }}
                                key={`singlrCard ${index + 1}`}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <img
                                    src="/sand-clock.jpeg"
                                    style={{ height: '20px', width: '20px' }}
                                  />
                                  <span style={{ fontSize: '12px' }}>
                                    {singleDisplayData?.title}
                                  </span>
                                </div>
                                <Typography
                                  component={'h5'}
                                  variant="h5"
                                  sx={{ fontWeight: 700 }}
                                >
                                  {data[singleDisplayData?.attribute]
                                    ? data[singleDisplayData.attribute]
                                    : 0}
                                </Typography>
                              </Box>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography>No Dashboard</Typography>
      )}
    </>
  );
}

export default AdminDashboard;
