import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
// import { CustomDateRange } from './CustomDateRange';
import { useEducatorCoursesAndEnrolsQuery } from '../../../app/services/EducatorApi';
import moment from 'moment';
import { courseDurationFormater } from '../../../Uitils';

export const EducatorEnrollment = () => {
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [selectedCourseID, setSelectedCourseID] = useState(null);
  const { data } = useEducatorCoursesAndEnrolsQuery();
  const [coursesToBeDisplayed, setCoursesToBeDisplayed] = useState(
    data?.courses ? data?.courses : []
  );

  const [barChart, setBarChart] = useState([]);
  const valueFormatter = (value) => `${value}`;

  const chartSetting = {
    yAxis: [
      {
        label: 'Enrolment Count',
        tickMinStep: 1,
      },
    ],
    height: 350,
  };
  const getMonthWiseEnrollments = (data) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthCounts = monthNames.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {});
    data
      .flatMap((course) => course.course_enrols)
      .forEach((enrol) => {
        const date = new Date(enrol.created_at);
        const month = monthNames[date.getMonth()];
        monthCounts[month] += 1;
      });
    return monthNames.map((month) => ({
      month,
      enrollCount: monthCounts[month],
    }));
  };

  const findVideoProgress = (course, videoProgress) => {
    const totalCourseDuration =
      Number(course.published_video_sum_duration) +
      Number(course.published_subject_video_sum_duration) +
      Number(course.published_topic_video_sum_duration);

    const totalVideoProgressDuration =
      videoProgress && videoProgress.reduce((n, { timeStamp }) => n + timeStamp, 0);

    if (
      Math.round((totalVideoProgressDuration * 100) / totalCourseDuration) <= 100
    ) {
      return Math.round((totalVideoProgressDuration * 100) / totalCourseDuration);
    } else {
      return 0;
    }
  };

  const getEnrollmentCount = (plan) => {
    let totalCount = 0;
    let filteredCourses = [];
    if (Array.isArray(data?.courses)) {
      if (plan == 'free') {
        filteredCourses = data?.courses.filter(
          (elem) => elem.cost == 0 || elem.cost == null
        );
      }
      if (plan == 'premium') {
        filteredCourses = data?.courses.filter((elem) => elem.cost > 0);
      }
    }
    if (Array.isArray(filteredCourses)) {
      totalCount = filteredCourses.reduce(
        (acc, cuu) => acc + (cuu?.course_enrols_count || 0),
        0
      );
    }
    return totalCount;
  };

  useEffect(() => {
    let filteredCourses = [];
    if (Array.isArray(data?.courses)) {
      if (selectedPlan == 'free') {
        filteredCourses = data?.courses.filter(
          (elem) => elem.cost == 0 || elem.cost == null
        );
        if (Array.isArray(filteredCourses)) {
          setCoursesToBeDisplayed([...filteredCourses]);
        }
      }
      if (selectedPlan == 'premium') {
        filteredCourses = data?.courses.filter((elem) => elem.cost > 0);
        if (Array.isArray(filteredCourses)) {
          setCoursesToBeDisplayed([...filteredCourses]);
        }
      }
    }
    setSelectedCourseID(null);
  }, [selectedPlan, data?.courses]);

  useEffect(() => {
    let finalResult = getMonthWiseEnrollments(coursesToBeDisplayed);
    if (Array.isArray(finalResult)) {
      setBarChart([...finalResult]);
    }
  }, [coursesToBeDisplayed]);

  return (
    <Fragment>
      {/* <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div> */}
      <Grid container spacing={2} sx={{ background: '', marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Grid container sx={{ background: '#eef7fa' }}>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    // padding: '1rem',
                    background: '#fff',
                  }}
                >
                  <div
                    style={{
                      height: '100px',
                      width: '200px',
                      background: selectedPlan == 'free' ? '#eef7fa' : '#10496a',
                      display: 'grid',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedPlan('free');
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Free Plan Enrolls
                      </Typography>
                      <Typography
                        sx={{
                          color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        {`${getEnrollmentCount('free')}`}
                      </Typography>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      height: '100px',
                      width: '200px',
                      background:
                        selectedPlan == 'subscription' ? '#eef7fa' : '#10496a',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setSelectedPlan('subscription');
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: selectedPlan == 'subscription' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Subscription Plan
                      </Typography>
                      <Typography
                        sx={{
                          color: selectedPlan == 'subscription' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Enrolls 12000
                      </Typography>
                    </div>
                  </div> */}
                  <div
                    style={{
                      height: '100px',
                      width: '200px',
                      background: selectedPlan == 'premium' ? '#eef7fa' : '#10496a',
                      display: 'grid',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedPlan('premium');
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: selectedPlan == 'premium' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Premium Plan Enrolls
                      </Typography>
                      <Typography
                        sx={{
                          color: selectedPlan == 'premium' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        {`${getEnrollmentCount('premium')}`}
                      </Typography>
                    </div>
                  </div>
                </div>
                <BarChart
                  dataset={barChart}
                  xAxis={[{ scaleType: 'band', dataKey: 'month', label: 'Month' }]}
                  series={[{ dataKey: 'enrollCount', valueFormatter }]}
                  {...chartSetting}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
              <Table
                sx={{ minWidth: '100%' }}
                aria-label="simple table"
                stickyHeader={true}
              >
                <TableHead sx={{ background: '#10496a' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        background: '#10496a',
                        width: '50px',
                      }}
                      align="center"
                    >
                      Sr.No
                    </TableCell>
                    <TableCell
                      align="start"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '150px',
                        background: '#10496a',
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '150px',
                        background: '#10496a',
                      }}
                    >
                      No of Enrollments
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coursesToBeDisplayed?.map((row, index) => (
                    <TableRow
                      key={row?.id}
                      onClick={() => {
                        setSelectedCourseID(row);
                      }}
                      className="analytics-custom-mui-table-row"
                      sx={{
                        background: selectedCourseID?.id == row?.id ? '#cdeef9' : '',
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell align="center">
                        <div>{index + 1}</div>
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            width: '150px',
                          }}
                        >
                          <Tooltip title={row?.title}>{row?.title}</Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.course_enrols_count}</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12}>
          {selectedCourseID && (
            <Box
              sx={{
                background: '#fff',
                padding: '1rem',
                boxShadow: '1px 1px 10px 1px #dcdcdc',
              }}
            >
              {selectedPlan === 'free' ? (
                <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
                  <Table
                    sx={{ minWidth: '100%' }}
                    aria-label="simple table"
                    stickyHeader={true}
                  >
                    <TableHead sx={{ background: '#10496a' }}>
                      <TableRow>
                        {[
                          'Course ID',
                          'Type',
                          'Total Duration',
                          'Student Name',
                          'Enroll Date',
                          'Completion Rate',
                          'Ratings',
                        ].map((item, ind) => (
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                              minWidth: `${item.length}0px`,
                              background: '#10496a',
                            }}
                            key={'ind' + ind}
                          >
                            {item}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="analytics-custom-mui-table-body">
                      {selectedCourseID.course_enrols?.map((row) => (
                        <TableRow
                          key={row?.id}
                          className="analytics-custom-mui-table-row"
                        >
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{row?.course_id}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{selectedCourseID?.cost ? 'Paid' : 'Free'}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {courseDurationFormater(
                                Number(
                                  selectedCourseID.published_video_sum_duration
                                ) +
                                  Number(
                                    selectedCourseID.published_subject_video_sum_duration
                                  ) +
                                  Number(
                                    selectedCourseID.published_topic_video_sum_duration
                                  ),
                                moment
                              )}
                              {/* {moment
                                .utc(
                                  (Number(
                                    selectedCourseID.published_video_sum_duration
                                  ) +
                                    Number(
                                      selectedCourseID.published_subject_video_sum_duration
                                    ) +
                                    Number(
                                      selectedCourseID.published_topic_video_sum_duration
                                    )) *
                                    1000
                                )
                                .format('HH:mm:ss')} */}
                            </div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {row?.user?.student_info?.first_name &&
                              row?.user?.student_info?.last_name
                                ? `${row?.user?.student_info.first_name} ${row?.user?.student_info.last_name}`
                                : row?.user.name}
                            </div>
                          </TableCell>

                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{moment(row?.created_at).format('Do MMM YY ')}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {' '}
                              {findVideoProgress(
                                selectedCourseID,
                                row.video_progress
                              ) || 0}
                              %
                            </div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {row?.user_review?.rating
                                ? parseFloat(row?.user_review?.rating).toFixed(1)
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
                  <Table
                    sx={{ minWidth: '100%' }}
                    aria-label="simple table"
                    stickyHeader={true}
                  >
                    <TableHead sx={{ background: '#10496a' }}>
                      <TableRow>
                        {[
                          'Course ID',
                          'Type',
                          'Total Duration',
                          'Student Name',
                          'Enroll Date',
                          'Status',
                          'Price',
                          'Pay Method',
                          'Transact ID',
                          'Completion Rate',
                          'Ratings',
                        ].map((item, ind) => (
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                              minWidth: `${item.length}0px`,
                              background: '#10496a',
                            }}
                            key={'ind' + ind}
                          >
                            {item}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="analytics-custom-mui-table-body">
                      {selectedCourseID.course_enrols?.map((row) => (
                        <TableRow
                          key={row?.id}
                          className="analytics-custom-mui-table-row"
                        >
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{row?.course_id}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{selectedCourseID?.cost ? 'Paid' : 'Free'}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {courseDurationFormater(
                                Number(
                                  selectedCourseID.published_video_sum_duration
                                ) +
                                  Number(
                                    selectedCourseID.published_subject_video_sum_duration
                                  ) +
                                  Number(
                                    selectedCourseID.published_topic_video_sum_duration
                                  ),
                                moment
                              )}
                              {/* {moment
                                .utc(
                                  (Number(
                                    selectedCourseID.published_video_sum_duration
                                  ) +
                                    Number(
                                      selectedCourseID.published_subject_video_sum_duration
                                    ) +
                                    Number(
                                      selectedCourseID.published_topic_video_sum_duration
                                    )) *
                                    1000
                                )
                                .format('HH:mm:ss')} */}
                            </div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {row?.user?.student_info?.first_name &&
                              row?.user?.student_info?.last_name
                                ? `${row?.user?.student_info.first_name} ${row?.user?.student_info.last_name}`
                                : row?.user.name}
                            </div>
                          </TableCell>

                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{moment(row?.created_at).format('Do MMM YY ')}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{row?.payment?.status}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>Rs {row?.payment?.paid_amount}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{row?.PayMethod}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>{row?.payment?.transaction_id}</div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {' '}
                              {findVideoProgress(
                                selectedCourseID,
                                row.video_progress
                              ) || 0}
                              %
                            </div>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '0.2rem' }}>
                            <div>
                              {row?.user_review?.rating
                                ? parseFloat(row?.user_review?.rating).toFixed(1)
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
