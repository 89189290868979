/* eslint-disable no-unused-vars */
import { CurrencyRupee, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  useCreateApprovePaymentWithdrawalMutation,
  useEducatorPaymentWithdrawalQuery,
} from '../../../app/services/EducatorApi';
import DataLoader from '../../../components/DataLoader';

function SingleEducatorWithdrawal() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useEducatorPaymentWithdrawalQuery({
    id: parseInt(params.Id, 10),
  });

  const [createApprovalRequest, result] =
    useCreateApprovePaymentWithdrawalMutation();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  const approveWithdrawal = (withdrawalId) => {
    createApprovalRequest({ id: withdrawalId });
  };

  return isSuccess && data.withdrawal ? (
    <Grid container spacing={1}>
      <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
        <Card>
          <CardContent>
            <Typography>Account Name: {data.withdrawal.account_name}</Typography>
            <Typography>
              Account Number:
              {data.withdrawal.account_number}
            </Typography>
            <Typography>
              Amount: <CurrencyRupee />
              {data.withdrawal.amount}
            </Typography>
            <Typography>Status: {data.withdrawal.status}</Typography>
            <Typography>Notes: {data.withdrawal.notes}</Typography>
            {data.withdrawal.status !== 'success' &&
            data.totalCourseEarnings +
              data.totalExamEarnings +
              data.totalTestEarnings >
              data.totalAmountWithdrawals ? (
              <Button
                onClick={() => approveWithdrawal(data.withdrawal.id)}
                variant="contained"
              >
                Approve Withdrawal
              </Button>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
        <Card>
          <CardContent>
            <Typography>
              Total Earnings:
              <CurrencyRupee />
              {data.totalCourseEarnings +
                data.totalExamEarnings +
                data.totalTestEarnings}
            </Typography>
            <Typography>
              Total Course Earnings: <CurrencyRupee />
              {data.totalCourseEarnings}
            </Typography>
            <Typography>
              Total Exam Earnings: <CurrencyRupee />
              {data.totalExamEarnings}
            </Typography>
            <Typography>Total Test Earnings:{data.totalTestEarnings}</Typography>
            <Typography>
              Total Previous successful Withdrawals: <CurrencyRupee />
              {data.totalAmountWithdrawals}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <>
        <Typography
          component="div"
          variant="h5"
          sx={{ padding: 4, color: '#233c8d' }}
        >
          Previous Educator Payment Withdrawals
        </Typography>
        <Paper sx={{ height: 400, width: '100%', overflow: 'scroll' }}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created On</TableCell>
                  {/* <TableCell>
                    <span style={{ marginLeft: '1rem' }}>Action</span>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.totalWithdrawals.map((singleWithdrawal, index) => (
                  <TableRow
                    key={singleWithdrawal.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      {' '}
                      <CurrencyRupee />
                      {singleWithdrawal.amount}
                    </TableCell>
                    <TableCell>{singleWithdrawal.status}</TableCell>
                    <TableCell>
                      {moment(singleWithdrawal.created_at).format(
                        'Do MMM YY hh:mm A'
                      )}
                    </TableCell>{' '}
                    {/* <TableCell>
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 200,
                          bgcolor: 'background.paper',
                        }}
                      >
                        <ListItemButton
                          onClick={() => handleClick(index)}
                          aria-expanded={expandedId === index}
                        >
                          <ListItemText primary="Options" />
                          <ExpandMore />
                        </ListItemButton>
                        <Collapse
                          in={expandedId === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              //   onClick={() => viewWithdrawal(singleWithdrawal.id)}
                              disabled
                            >
                              <ListItemText primary="View" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    </Grid>
  ) : (
    <Typography>No Withdrawal found</Typography>
  );
}

export default SingleEducatorWithdrawal;
