/* eslint-disable no-unused-vars */

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { useUpdateVideoStatusMutation } from '../../../../app/services/CoursesApi';

function ChangeVideoStatus(props) {
  const { video } = props;
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [localVideo, setLocalVideo] = React.useState(video);
  const [updateVideoStatus, result] = useUpdateVideoStatusMutation();

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateData = {
      videoId: localVideo.id,
      status: status,
    };

    updateVideoStatus(updateData);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box
        noValidate
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
        }}
        onSubmit={onChangeStatus}
      >
        <Typography>Current Status: {localVideo.status}</Typography>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          {localVideo.status === 'COMPLETED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>

              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="BLOCKED">BLOCK</MenuItem>
                <MenuItem value="PUBLISHED">PUBLISH</MenuItem>
              </Select>
            </>
          ) : null}
          {localVideo.status === 'PUBLISHED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>
              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="BLOCKED">BLOCK</MenuItem>
              </Select>
            </>
          ) : null}

          {localVideo.status === 'BLOCKED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>
              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="PUBLISHED">PUBLISH</MenuItem>
              </Select>
            </>
          ) : null}
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={status === localVideo.status || loading || !status}
        >
          Change Status
        </Button>
      </Box>
    </>
  );
}

export default ChangeVideoStatus;
