import { Box, Grid, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTopicQuery } from '../../../app/services/CoursesApi';
import DataLoader from '../../../components/DataLoader';

/* eslint-disable no-unused-vars */
function OBSStream() {
  const serverUrl = `${process.env.REACT_APP_LIVE_RTMP_SERVER}`;

  const params = useParams();

  const { data, isLoading, isSuccess } = useTopicQuery({
    id: parseInt(params.Id, 10),
  });
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  console.log(data);

  return isSuccess && data.topic ? (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          // bgcolor: 'background.paper',
          overflowWrap: 'anywhere',
        }}
      >
        <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography>
              Stream Details. Add these in OBS settings before going live.
            </Typography>
            <TextField
              id="filled-read-only-input"
              label="Stream URL"
              defaultValue={serverUrl}
              variant="filled"
              fullWidth
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
            <br />
            <TextField
              id="filled-read-only-input"
              label="Stream Key"
              defaultValue={data.topic.stream_key}
              variant="filled"
              fullWidth
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography>Live Chat</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>No lecture found</p>
  );
}

export default OBSStream;
