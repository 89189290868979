/* eslint-disable no-unused-vars */
import { Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEducatorSubscriptionQuery } from '../../../app/services/EducatorSubscriptionApi';
import DataLoader from '../../../components/DataLoader';

function SingleSubscribedPlan() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useEducatorSubscriptionQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.subscription ? (
    <Grid container spacing={1}>
      <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      <Grid item xs="12" sm="12" md="8" lg="8" xl="8">
        <Card>
          <CardContent>
            <Typography>
              Title: {data.subscription.educator_subscription_plan?.title}
            </Typography>
            <Typography>
              Description:{' '}
              {data.subscription.educator_subscription_plan?.description}
            </Typography>
            <Typography>
              Billed:{' '}
              {data.subscription.bill_type === 'annual' ? 'Annually' : 'Monthly'}
            </Typography>
            <Typography>
              Start Date:{' '}
              {moment(data.subscription.start_date).format('Do MMM YY hh:mm A')}
            </Typography>
            <Typography>
              End Date:{' '}
              {moment(data.subscription.end_date).format('Do MMM YY hh:mm A')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ) : (
    <Typography>No plan found</Typography>
  );
}

export default SingleSubscribedPlan;
