/* eslint-disable no-unused-vars */

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateCourseMutation } from '../../../app/services/CoursesApi';
import {
  usePublicPublicationsQuery,
  usePublishedCategoriesQuery,
} from '../../../app/services/CategoriesApi';
import { uploadCourseImageRequest } from '../../../app/services/UploadRequests';
import axios from 'axios';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import HlsJs from '../HlsJs';
import { allLanguages } from '../../../Uitils';
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
export const EditCourse = (props) => {
  const { course } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSelector((state) => state.auth);
  const [updateCourse] = useUpdateCourseMutation();
  const { data } = usePublishedCategoriesQuery();
  const publicationsRes = usePublicPublicationsQuery();

  const [imageStatus, setImageStatus] = useState(false);
  const [videoStatus, setVideoStatus] = useState(false);

  const [title, setTitle] = useState(course.title || '');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [categoryTitle, setCategoryTitle] = useState(
    course.sub_category.category.id || ''
  );

  const [subCategoryTitle, setSubCategoryTitle] = useState(
    course.sub_category.id || ''
  );
  const [gradeTitle, setGradeTitle] = useState(course.grade ? course.grade.id : '');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState(
    course.grade_subject ? course.grade_subject.id : ''
  );

  const [publicationTitle, setPublicationTitle] = useState(
    course.publication ? course.publication.id : ''
  );
  const [publicationEditionTitle, setPublicationEditionTitle] = useState(
    course.publication_edition ? course.publication_edition.id : ''
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [vidUrl, setVideoUrl] = useState(null);
  const [duration, setSetDuration] = useState(0);
  const videoRef = useRef();
  const [processProgress, setProcessProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const allowedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const [isDisabled, setIsDiabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  let [buttonType, setButtonType] = useState('');
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      setImageStatus(false);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedVideo) {
      setVideoUrl(URL.createObjectURL(selectedVideo));
      setVideoStatus(false);
      setProcessProgress(0);
    }
    videoRef.current?.load();
  }, [selectedVideo]);

  const loadedMetaData = (event) => {
    const video = videoRef.current;
    if (!video) return;

    setSetDuration(Math.floor(video.duration));
  };

  const [selectedCategory, setSelectedCategory] = useState(
    course.sub_category.category || {
      title: '',
      id: null,
      published_sub_categories: [],
    }
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    course.sub_category || {
      title: '',
      id: null,
    }
  );

  const [selectedGrade, setSelectedGrade] = useState(
    course.grade || {
      title: '',
      id: null,
    }
  );

  const [selectedGradeSubject, setSelectedGradeSubject] = useState(
    course.grade_subject || {
      title: '',
      id: null,
    }
  );

  const [selectedPublication, setSelectedPublication] = useState(
    course.publication || {
      title: '',
      id: null,
      publication_editions: [],
    }
  );

  const [selectedPublicationEdition, setSelectedPublicationEdition] = useState(
    course.publication_edition || {
      edition: '',
      id: null,
    }
  );

  const [level, setLevel] = useState(course.level || 'beginner');

  const [courseCostType, setCourseCostType] = useState(
    course?.cost ? 'paid' : 'free'
  );
  const [courseCost, setCourseCost] = useState(course?.cost || null);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const validateData = (courseData) => {
    let err = true;

    if (
      courseData.title.trim().length === 0 ||
      courseData.description.trim().length === 0
    ) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });
      setIsDiabled(false);
      return err;
    }
    if (!courseData.title || !courseData.sub_category_id || !courseData.language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
      setIsDiabled(false);
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    setIsDiabled(true);
    event.preventDefault();
    console.log('this is getting button Type', buttonType);

    const data = new FormData(event.currentTarget);
    if (courseCostType === 'paid') {
      if (data.get('cost') <= 1) {
        enqueueSnackbar('Cost should be greater than 1', {
          variant: 'error',
        });
        setIsDiabled(false);
        return;
      }
    }
    const courseData = {
      id: course.id,
      title: data.get('title'),
      description: data.get('description'),
      sub_category_id: selectedSubCategory.id,
      language: data.get('language'),
      // start_date: startDate.format(),
      // end_date: endDate.format(),
      educator_id: course.educator_id,
      institution_id: course.institution_id,
      cost: data.get('cost'),
      grade_id: selectedGrade.id,
      publication_id: selectedPublication.id,
      grade_subject_id: selectedGradeSubject.id,
      publication_edition_id: selectedPublicationEdition.id,
      level: level,
    };

    const res = validateData(courseData);

    if (!res) {
      updateCourse(courseData)
        .then((res) => {
          enqueueSnackbar(res?.data?.message || 'Course Updated', {
            variant: 'success',
          });
          setIsDiabled(false);
          if (buttonType === 'saveasdraft' && props.handleExternalSaveAsDraft) {
            props.handleExternalSaveAsDraft && props.handleExternalSaveAsDraft();
          }
          if (buttonType === 'next' && props.handleExternalNextButton) {
            props.handleExternalNextButton && props.handleExternalNextButton();
          }
        })
        .catch((error) => {
          enqueueSnackbar('Course Not Updated (Refresh the page)', {
            variant: 'error',
          });
          setIsDiabled(false);
        });
    }
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data?.categories?.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);

    setSelectedSubCategory({
      title: '',
      id: null,
    });

    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory = selectedCategory.published_sub_categories.find(
      (singleSubCategory) => singleSubCategory.id === event.target.value
    );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
    setSelectedGrade({
      title: '',
      id: null,
    });

    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
    setSelectedGradeSubject({
      title: '',
      id: null,
    });

    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
    setSelectedPublication({
      title: '',
      id: null,
      publication_editions: [],
    });

    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationChange = (event) => {
    const tempSelectedPublication = publicationsRes.data.publications.find(
      (singlePublication) => singlePublication.id === event.target.value
    );

    setSelectedPublication(tempSelectedPublication);
    setPublicationTitle(event.target.value);
    setSelectedPublicationEdition({
      edition: '',
      id: null,
    });
  };

  const handlePublicationEditionChange = (event) => {
    const tempPublicationEdition = selectedPublication.publication_editions.find(
      (singlePublicationEdition) =>
        singlePublicationEdition.id === event.target.value
    );

    setSelectedPublicationEdition(tempPublicationEdition);
    setPublicationEditionTitle(event.target.value);
  };
  const UploadImage = () => {
    // event.preventDefault();
    setLoading(true);
    if (props.setImageStatus) {
      props.setImageStatus(false);
    }
    const UploadData = {
      file: selectedImage,
      token: token,
    };
    uploadCourseImageRequest(UploadData, course.id).then((res) => {
      if (res.url) {
        setLoading(false);
        setImageStatus('SUCCESS');

        enqueueSnackbar(
          'Image Uploaded Successfully. Please refresh your page for now.',
          {
            variant: 'success',
          }
        );
        if (props.setImageStatus) {
          props.setImageStatus('success');
        }
      } else {
        setLoading(false);
        setImageStatus('ERROR');

        enqueueSnackbar('Error Uploading Image. ', {
          variant: 'error',
        });
        if (props.setImageStatus) {
          props.setImageStatus('error');
        }
      }
    });
  };
  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/video/upload/${uploadData.courseId}`,
      {
        file: uploadData.file,
        file_type: 'course',
        title: title,
        description: description,
        duration: uploadData.duration,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };
  const validateVideoData = (videoData) => {
    let err = true;

    if (!videoData.title) {
      enqueueSnackbar('Please provide title for the video', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const UploadVideo = () => {
    setVideoLoading(true);
    if (props.setExternalVideoStatus) {
      props.setExternalVideoStatus(false);
    }
    const UploadData = {
      file: selectedVideo,
      token: token,
      courseId: course.id,
      title: course.title,
      description: course.description,
      duration: duration,
    };
    const res = validateVideoData(UploadData);
    if (!res) {
      enqueueSnackbar(
        'Your video is being uploaded. Please do not refresh or leave this page',
        {
          variant: 'success',
        }
      );

      fileUploadRequest(UploadData)
        .then((res) => {
          if (res.data.video.id) {
            setVideoLoading(false);
            setVideoStatus('SUCCESS');
            setProcessProgress(100);
            if (props.setExternalVideoStatus) {
              props.setExternalVideoStatus('success');
            }
            enqueueSnackbar(
              'Video Uploaded Successfully. Please refresh your page for now.',
              {
                variant: 'success',
              }
            );
          } else {
            setVideoLoading(false);
            setVideoStatus('ERROR');

            enqueueSnackbar('Error uploading Video. ', {
              variant: 'error',
            });
            if (props.setExternalVideoStatus) {
              props.setExternalVideoStatus('error');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setVideoStatus('ERROR');
        });
    }
  };
  return (
    <Fragment>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            item
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            style={{ height: 'auto' }}
          >
            <TextField
              required
              fullWidth
              id="title"
              label=" Title"
              name="title"
              autoComplete="title"
              autoFocus
              onChange={handleTitleChange}
              defaultValue={course.title}
            />
          </Grid>
          <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              autoFocus
              multiline
              rows="2"
              onChange={handleDescriptionChange}
              defaultValue={course.description}
            />
          </Grid>
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              select
              required
              fullWidth
              id="category"
              label="Category"
              onChange={handleCategoryChange}
              name="category"
              autoComplete="category"
              autoFocus
              value={categoryTitle}
            >
              {data?.categories && data?.categories.length
                ? data?.categories.map((singleCategory) => (
                    <MenuItem value={singleCategory.id} key={singleCategory.id}>
                      {singleCategory.title}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              select
              required
              fullWidth
              id="sub_category"
              label="Sub Category"
              onChange={handleSubCategoryChange}
              name="sub_category"
              autoComplete="sub_category"
              autoFocus
              value={subCategoryTitle}
            >
              {selectedCategory && selectedCategory.published_sub_categories.length
                ? selectedCategory.published_sub_categories.map(
                    (singleSubCategory) => (
                      <MenuItem
                        value={singleSubCategory.id}
                        key={singleSubCategory.id}
                      >
                        {singleSubCategory.title}
                      </MenuItem>
                    )
                  )
                : null}
            </TextField>
          </Grid>
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedSubCategory?.grades?.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="grade"
                label="Grade"
                onChange={handleGradeChange}
                name="grade"
                autoComplete="grade"
                autoFocus
                value={gradeTitle}
              >
                {selectedCategory &&
                selectedSubCategory &&
                selectedSubCategory.grades.length
                  ? selectedSubCategory.grades.map((singleGrade) => (
                      <MenuItem value={singleGrade.id} key={singleGrade.id}>
                        {singleGrade.title}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ) : null}
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedGrade.id &&
          selectedGrade?.grade_subjects?.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="grade_subject"
                label="Grade Subject"
                onChange={handleGradeSubjectChange}
                name="grade subject"
                autoComplete="grade subject"
                autoFocus
                value={gradeSubjectTitle}
              >
                {selectedGrade.grade_subjects.length
                  ? selectedGrade.grade_subjects.map((singleGradeSubject) => (
                      <MenuItem
                        value={singleGradeSubject.id}
                        key={singleGradeSubject.id}
                      >
                        {singleGradeSubject.title}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ) : null}
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedGrade.id &&
          publicationsRes.data?.publications.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="publication"
                label="Publication"
                onChange={handlePublicationChange}
                name="publication"
                autoComplete="publication"
                autoFocus
                value={publicationTitle}
              >
                {publicationsRes.data.publications.length
                  ? publicationsRes.data.publications.map((singlePublication) => (
                      <MenuItem
                        value={singlePublication.id}
                        key={singlePublication.id}
                      >
                        {singlePublication.title}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ) : null}
          {selectedCategory.id &&
          selectedSubCategory.id &&
          selectedGrade.id &&
          publicationsRes.data?.publications.length &&
          selectedPublication.id &&
          selectedPublication.publication_editions.length ? (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                select
                required
                fullWidth
                id="publication_edition"
                label="Publication Edition"
                onChange={handlePublicationEditionChange}
                name="Publication Edition"
                autoComplete="Publication Edition"
                autoFocus
                value={publicationEditionTitle}
              >
                {selectedPublication.publication_editions.length
                  ? selectedPublication.publication_editions.map(
                      (singlePublicationEdition) => (
                        <MenuItem
                          value={singlePublicationEdition.id}
                          key={singlePublicationEdition.id}
                        >
                          {singlePublicationEdition.edition}
                        </MenuItem>
                      )
                    )
                  : null}
              </TextField>
            </Grid>
          ) : null}
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="language"
              label="Language"
              name="language"
              autoComplete="language"
              autoFocus
              defaultValue={course.language}
              onChange={handleLanguageChange}
              select
            >
              {Array.isArray(allLanguages) && allLanguages.length > 0
                ? allLanguages.map((singleLanguage) => (
                    <MenuItem value={singleLanguage} key={singleLanguage}>
                      {singleLanguage}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="coursefee"
              label="Course Fee"
              name="coursefee"
              autoComplete="coursefee"
              autoFocus
              onChange={(e) => {
                setCourseCostType(e.target.value);
              }}
              select
              defaultValue={courseCostType}
            >
              {[
                { label: 'Free', value: 'free' },
                { label: 'Paid', value: 'paid' },
              ].map((singleItem) => (
                <MenuItem value={singleItem.value} key={singleItem.value}>
                  {singleItem.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {courseCostType === 'paid' && (
            <Grid item xl="3" lg="3" md="6" sm="12" xs="12">
              <TextField
                required
                fullWidth
                id="cost"
                label="Cost"
                name="cost"
                autoComplete="cost"
                autoFocus
                type="number"
                onChange={(e) => {
                  setCourseCost(e.target.value);
                }}
                defaultValue={courseCost}
              />
            </Grid>
          )}
          <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Learning Level
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={level}
                onChange={handleLevelChange}
              >
                <FormControlLabel
                  value="beginner"
                  control={<Radio />}
                  label="Beginner"
                />
                <FormControlLabel
                  value="intermediate"
                  control={<Radio />}
                  label="Intermediate"
                />
                <FormControlLabel
                  value="expert"
                  control={<Radio />}
                  label="Expert"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
            <Box sx={{ height: '100%' }}>
              <Grid container spacing={1}>
                <Grid item xl="3" lg="3" md="3" sm="3" xs="3">
                  <img
                    src={imageUrl ? imageUrl : course?.url || course?.image_url}
                    style={{
                      border: '1px solid #c7c7c7',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Grid>
                <Grid item xl="9" lg="9" md="9" sm="9" xs="9">
                  <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    Textbook Cover Image
                  </Typography>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                    title={`Upload Course image here. It must meet our course image quality
                    standard to be accepted. Important guidelines: 750*422 pixels;
                    .jpg, .png, .jpeg, .gif or .png no text on the image`}
                  >
                    <label
                      style={{
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '3',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      Upload Course image here. It must meet our course image quality
                      standard to be accepted. Important guidelines: 750*422 pixels;
                      .jpg, .png, .jpeg, .gif or .png no text on the image
                    </label>
                  </Typography>
                  <form
                    className="form"
                    // onSubmit={UploadImage}
                    style={{ marginTop: '2rem' }}
                  >
                    <div
                      className="file-upload-wrapper"
                      data-text={
                        selectedImage ? selectedImage.name : 'No File Selected'
                      }
                      data-button-text="Select File"
                    >
                      <input
                        name="file-upload-field"
                        type="file"
                        accept="image/*"
                        className="file-upload-field"
                        value=""
                        onChange={(e) => {
                          if (allowedImageTypes.includes(e.target.files[0].type)) {
                            setSelectedImage(e.target.files[0]);
                          } else {
                            enqueueSnackbar(
                              'Please select a PNG, JPEG, or SVG file.',
                              {
                                variant: 'error',
                              }
                            );
                          }
                        }}
                      />
                    </div>
                    {imageStatus === 'SUCCESS'
                      ? 'image uploaded successfully'
                      : null}
                    {imageStatus === 'ERROR' ? 'error uploading image' : null}
                    {selectedImage && (
                      <Button
                        type="button"
                        variant="contained"
                        disabled={loading || imageStatus}
                        onClick={() => {
                          UploadImage();
                        }}
                        sx={{ marginTop: '1rem' }}
                      >
                        Upload
                      </Button>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
            <Box sx={{ maxWidth: '100%' }}>
              <Grid container spacing={1}>
                <Grid item xl="4" lg="4" md="4" sm="4" xs="4">
                  {vidUrl ? (
                    <video
                      controls
                      ref={videoRef}
                      controlsList="nodownload"
                      onLoadedMetadata={loadedMetaData}
                    >
                      <source
                        // src={vidUrl}
                        src={vidUrl}
                        type="video/mp4"
                      />
                      Your browser does not support HTML video.
                    </video>
                  ) : course.video?.vid_url ? (
                    <HlsJs vidSrc={course.video?.vid_url} />
                  ) : null}
                  <Box>
                    <Typography>
                      File Name
                      <br />
                      <a href="" style={{ fontSize: '12px' }}>
                        {selectedVideo ? selectedVideo.name : null}
                      </a>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl="8" lg="8" md="8" sm="8" xs="8">
                  <div style={{ maxWidth: '100%' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                      Promotional video
                    </Typography>
                    <Typography
                      sx={{ fontSize: '16px', fontWeight: 500 }}
                      title={`Your promo video is a quick and compelling way for students
                        to preview what they learn in your course. Students
                        considering your course are more likely to enroll if your
                        promo video is well made. Learn how to make your promo video
                        awesome!`}
                    >
                      <label
                        style={{
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: '3',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }}
                      >
                        Your promo video is a quick and compelling way for students
                        to preview what they learn in your course. Students
                        considering your course are more likely to enroll if your
                        promo video is well made. Learn how to make your promo video
                        awesome!
                      </label>
                    </Typography>
                    {course.video?.status === 'PROCESSING' ? (
                      <p>processing uploaded video. You can check later.</p>
                    ) : (
                      <form className="form" style={{ marginTop: '2rem' }}>
                        <div
                          className="file-upload-wrapper"
                          data-text={
                            selectedVideo ? selectedVideo.name : 'No File Selected'
                          }
                          data-button-text="Select File"
                        >
                          <input
                            name="file-upload-field"
                            type="file"
                            accept="video/*"
                            className="file-upload-field"
                            value=""
                            onChange={(e) => {
                              const selectedFile = e.target.files[0];
                              if (
                                selectedFile &&
                                selectedFile.type.includes('video')
                              ) {
                                setSelectedVideo(e.target.files[0]);
                              } else {
                                enqueueSnackbar('Please select a Video file.', {
                                  variant: 'error',
                                });
                              }
                            }}
                          />
                        </div>
                        {progress > 0 ? (
                          <Box sx={{ width: '100%', marginTop: '1rem' }}>
                            <LinearProgressWithLabel
                              value={progress}
                              color="secondary"
                            />
                          </Box>
                        ) : null}

                        {videoStatus === 'ERROR' ? 'Error Uploading Video' : null}
                        {videoStatus === 'SUCCESS'
                          ? 'Video Uploaded Successfully'
                          : null}
                        {selectedVideo && (
                          <Button
                            type="button"
                            variant="contained"
                            disabled={videoLoading || videoStatus}
                            onClick={() => {
                              UploadVideo();
                            }}
                            sx={{ marginTop: '1rem' }}
                          >
                            Upload
                          </Button>
                        )}
                      </form>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            style={
              props.showExtraButton ? { display: 'flex', justifyContent: 'end' } : {}
            }
          >
            {props.showExtraButton ? (
              <>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2 }}
                  onClick={() => {
                    buttonType = 'saveasdraft';
                    setButtonType(buttonType);
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => {
                    buttonType = 'next';
                    setButtonType(buttonType);
                  }}
                >
                  Next
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isDisabled}
              >
                Update Course
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
