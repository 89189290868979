import React, { Fragment, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import TabPanel from '../../../components/authentication/TabPanel';
import CourseViolationManager from '../../courses/adminOrEducatorOrInstitution/admin/CourseViolationManager';
import VideoViolationManager from '../../courses/adminOrEducatorOrInstitution/admin/VideoViolationManager';
import SingleEducatorTickets from './SingleEducatorTickets';

export const EducatorNotification = () => {
  const [value, setValue] = useState(0);
  const [violationType, setViolationType] = useState('courseViolation');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ background: '', marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Tabs
            value={value}
            onChange={(e, newval) => handleChange(e, newval)}
            textColor="primary"
            indicatorColor="primary"
            aria-label="Account forms"
            scrollButtons={true}
            allowScrollButtonsMobile
          >
            <Tab
              label="Report Abuse"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label="Q&A"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
              sx={{ textTransform: 'capitalize' }}
            />
          </Tabs>
          <Grid container justifyContent={'center'}>
            <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
              <TabPanel value={value} index={0}>
                <Grid container justifyContent={'center'}>
                  <Grid item xl="3" lg="3" md="3" sm="12" xs="12">
                    <Box sx={{ m: 2, overflowWrap: 'anywhere' }}>
                      <>
                        <Typography color="text.secondary">Filters</Typography>

                        <Divider />
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                          <FormLabel component="legend" id="price">
                            Type
                          </FormLabel>
                          <FormGroup aria-label="position">
                            <FormControlLabel
                              label="Course Violation"
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setViolationType('courseViolation');
                                    }
                                  }}
                                  checked={violationType == 'courseViolation'}
                                />
                              }
                              size="small"
                            />
                            <FormControlLabel
                              label="Video Violation"
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setViolationType('videoViolation');
                                    }
                                  }}
                                  checked={violationType == 'videoViolation'}
                                />
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    </Box>
                  </Grid>
                  <Grid item xl="9" lg="9" md="9" sm="12" xs="12">
                    {violationType == 'courseViolation' ? (
                      <CourseViolationManager />
                    ) : violationType == 'videoViolation' ? (
                      <VideoViolationManager />
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SingleEducatorTickets />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
