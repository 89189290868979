/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  TextField,
  Typography,
  IconButton,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Icon,
  FormLabel,
  Switch,
  FormGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { DeleteOutline, EditNote, SaveOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import {
  useCreateTestQuestionMutation,
  useDeleteTestQuestionMutation,
  useTestTopicQuery,
  useUpdateTestQuestionMutation,
} from '../../../app/services/TestsApi';

function EditTestQuestion(props) {
  const { testTopicId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [addTestQuestion, setAddTestQuestion] = useState(false);

  const [selectedTestQuestion, setSelectedTestQuestion] = useState({
    title: '',
    edit: false,
    id: null,
    options: [],
    severity: 'low',
    timeChecked: false,
    time: null,
  });
  const { data } = useTestTopicQuery({
    id: testTopicId,
  });
  const [updateTestQuestion] = useUpdateTestQuestionMutation();
  const [deleteTestQuestion] = useDeleteTestQuestionMutation();
  const [createTestQuestion] = useCreateTestQuestionMutation();

  const validateData = (testQuestionData) => {
    let err = true;

    if (testQuestionData.title?.trim().length === 0) {
      enqueueSnackbar('Please provide test Question title', {
        variant: 'error',
      });
    } else if (!testQuestionData.options?.length >= 2) {
      enqueueSnackbar('Please provide testQuestion with atleast 2 options', {
        variant: 'error',
      });
    } else if (
      testQuestionData.options?.some(
        (singleOption) => singleOption.optionText.trim().length === 0
      )
    ) {
      enqueueSnackbar('Please provide proper options', {
        variant: 'error',
      });
    } else if (
      !testQuestionData.options?.some(
        (singleOption) => singleOption.isCorrect === true
      )
    ) {
      enqueueSnackbar(
        'Please provide testQuestion with atleast 2 options and 1 true option',
        {
          variant: 'error',
        }
      );
    } else if (selectedTestQuestion.timeChecked && !testQuestionData.time) {
      enqueueSnackbar('Please provide time in seconds', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleEditButton = (testQuestion) => {
    setSelectedTestQuestion({
      title: testQuestion.title,
      edit: true,
      id: testQuestion.id,
      options: testQuestion.options,
      severity: testQuestion.severity,
      time: testQuestion.time,
      timeChecked: testQuestion.time ? true : false,
    });
  };

  const handleUpdateSubmit = () => {
    const testQuestionData = {
      id: selectedTestQuestion.id,
      title: selectedTestQuestion.title,
      options: selectedTestQuestion.options,
      test_topic_id: testTopicId,
      severity: selectedTestQuestion.severity,
      time: selectedTestQuestion.time,
    };

    const res = validateData(testQuestionData);
    if (!res) {
      updateTestQuestion(testQuestionData)
        .unwrap()
        .then((res) => {
          if (res.message === 'test question updated successfully') {
            setSelectedTestQuestion({
              ...selectedTestQuestion,
              title: '',
              edit: false,
              id: null,
              options: [],
              severity: 'low',
              time: null,
              timeChecked: false,
            });
          }
        });
    }
  };

  const handleSubmit = () => {
    const testQuestionData = {
      title: selectedTestQuestion.title,
      options: selectedTestQuestion.options,
      test_topic_id: testTopicId,
      severity: selectedTestQuestion.severity,
      time: selectedTestQuestion.time,
    };

    const res = validateData(testQuestionData);
    if (!res) {
      createTestQuestion(testQuestionData)
        .unwrap()
        .then((res) => {
          if (res.testQuestion.id) {
            setAddTestQuestion(false);
            setSelectedTestQuestion({
              ...selectedTestQuestion,
              title: '',
              edit: false,
              id: null,
              options: [],
              severity: 'low',
              time: false,
              timeChecked: false,
            });
          } else {
            console.log(res);
          }
        });
    }
  };

  const onDelete = (id) => {
    deleteTestQuestion({ id });
  };

  const addOption = () => {
    if (selectedTestQuestion.options?.length > 0) {
      setSelectedTestQuestion({
        ...selectedTestQuestion,
        options: [
          ...selectedTestQuestion.options,
          { isCorrect: false, optionText: '' },
        ],
      });
    } else {
      setSelectedTestQuestion({
        ...selectedTestQuestion,
        options: [{ isCorrect: false, optionText: '' }],
      });
    }
  };
  const handleValueChange = (index, e) => {
    const updatedOptions = selectedTestQuestion.options?.map((value, i) => {
      if (value === index) {
        return { ...value, optionText: e.target.value };
      } else {
        return value;
      }
    });
    // setOptions(updatedOptions);
    setSelectedTestQuestion({ ...selectedTestQuestion, options: updatedOptions });
  };
  const deleteOption = (singleOption) => {
    setSelectedTestQuestion({
      ...selectedTestQuestion,
      options: selectedTestQuestion?.options?.filter(
        (option) => option !== singleOption
      ),
    });
  };
  const handleChange = (index, e) => {
    const updatedOptions = selectedTestQuestion.options?.map((value, i) => {
      if (value === index) {
        return { ...value, isCorrect: e.target.value === 'right' ? true : false };
      } else {
        return value;
      }
    });
    // setOptions(updatedOptions);
    setSelectedTestQuestion({ ...selectedTestQuestion, options: updatedOptions });
  };

  const handleTestQuestionTitleChange = (e) => {
    setSelectedTestQuestion({ ...selectedTestQuestion, title: e.target.value });
  };

  const handleTimeCheckedChange = (event) => {
    setSelectedTestQuestion({
      ...selectedTestQuestion,
      timeChecked: event.target.checked,
      time: event.target.checked === false ? null : selectedTestQuestion.time,
    });
  };

  const handleTestQuestionTimeChange = (e) => {
    setSelectedTestQuestion({ ...selectedTestQuestion, time: e.target.value });
  };

  const handleSeverityChange = (event) => {
    setSelectedTestQuestion({
      ...selectedTestQuestion,
      severity: event.target.value,
    });
  };

  //   const validateData = (questionData) => {
  //     let err = true;

  //     if (!questionData.question || !options.length > 1 || !questionData.severity) {
  //       enqueueSnackbar('Please provide all the required fields', {
  //         variant: 'error',
  //       });
  //     } else {
  //       err = false;
  //     }

  //     return err;
  //   };

  return (
    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
      <>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              //   sx={{
              //     mt: 1,
              //     border: '1px solid #cacbcf',
              //     background: '#fafafa',
              //     borderRadius: '6px',
              //     padding: '1rem',
              //   }}
            >
              {data?.testTopic?.test_questions?.length > 0
                ? data?.testTopic.test_questions.map(
                    (singleTestQuestion, testQuestionIndex) => (
                      <Grid
                        item
                        xl="12"
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        key={singleTestQuestion.id}
                      >
                        {' '}
                        <div
                          style={{
                            border: '1px solid #cacbcf',
                            background: '#fafafa',
                            borderRadius: '6px',
                            padding: '1rem',
                          }}
                        >
                          <Box style={{ display: 'flex' }} component="form">
                            <TextField
                              required
                              fullWidth
                              size="small"
                              placeholder="What Where How and When"
                              label={`TestQuestion ${testQuestionIndex + 1}`}
                              disabled={
                                singleTestQuestion.id !== selectedTestQuestion.id
                              }
                              defaultValue={singleTestQuestion.title}
                              autoFocus
                              onChange={(e) => handleTestQuestionTitleChange(e)}
                              InputProps={{
                                endAdornment:
                                  singleTestQuestion.id ===
                                    selectedTestQuestion.id &&
                                  selectedTestQuestion.edit ? null : (
                                    <InputAdornment position="end">
                                      <Icon color="success">
                                        <CheckCircleIcon twoToneColor="#52c41a" />
                                      </Icon>{' '}
                                    </InputAdornment>
                                  ),
                              }}
                            />

                            <>
                              <div className="font-icon-wrapper">
                                <IconButton
                                  onClick={() => onDelete(singleTestQuestion.id)}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </div>
                              <div className="font-icon-wrapper">
                                {singleTestQuestion.id === selectedTestQuestion.id &&
                                selectedTestQuestion.edit ? (
                                  <IconButton onClick={() => handleUpdateSubmit()}>
                                    <SaveOutlined />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleEditButton(singleTestQuestion)
                                    }
                                    disabled={addTestQuestion}
                                  >
                                    <EditNote />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          </Box>
                          {selectedTestQuestion.edit &&
                          selectedTestQuestion.id === singleTestQuestion.id
                            ? selectedTestQuestion?.options?.map(
                                (singleOption, index) => (
                                  <Box key={singleOption}>
                                    <Grid container spacing={1} alignItems="center">
                                      <Grid item xs={8}>
                                        <TextField
                                          autoFocus
                                          margin="dense"
                                          label={`Option ${index + 1}`}
                                          value={singleOption.optionText}
                                          onChange={(e) =>
                                            handleValueChange(singleOption, e)
                                          }
                                          required
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <FormControl>
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={
                                              singleOption.isCorrect
                                                ? 'right'
                                                : 'wrong'
                                            }
                                            onChange={(e) =>
                                              handleChange(singleOption, e)
                                            }
                                            sx={{ marginLeft: '1rem' }}
                                          >
                                            <FormControlLabel
                                              value="right"
                                              control={
                                                <Radio sx={{ padding: '3px' }} />
                                              }
                                              label="Right"
                                            />
                                            <FormControlLabel
                                              value="wrong"
                                              control={
                                                <Radio sx={{ padding: '3px' }} />
                                              }
                                              label="Wrong"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div
                                          className="font-icon-wrapper"
                                          onClick={() => deleteOption(singleOption)}
                                        >
                                          <IconButton aria-label="delete">
                                            <DeleteOutline />
                                          </IconButton>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )
                            : singleTestQuestion.options?.map(
                                (singleOption, index) => (
                                  <Box key={singleOption}>
                                    <Grid container spacing={1} alignItems="center">
                                      <Grid item xs={8}>
                                        <TextField
                                          autoFocus
                                          margin="dense"
                                          label={`Option ${index + 1}`}
                                          defaultValue={singleOption.optionText}
                                          onChange={(e) =>
                                            handleValueChange(singleOption, e)
                                          }
                                          disabled={
                                            singleTestQuestion.id !==
                                            selectedTestQuestion.id
                                          }
                                          required
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <FormControl
                                          disabled={
                                            singleTestQuestion.id !==
                                            selectedTestQuestion.id
                                          }
                                        >
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={
                                              singleOption.isCorrect
                                                ? 'right'
                                                : 'wrong'
                                            }
                                            onChange={(e) =>
                                              handleChange(singleOption, e)
                                            }
                                            sx={{ marginLeft: '1rem' }}
                                          >
                                            <FormControlLabel
                                              value="right"
                                              control={
                                                <Radio sx={{ padding: '3px' }} />
                                              }
                                              label="Right"
                                            />
                                            <FormControlLabel
                                              value="wrong"
                                              control={
                                                <Radio sx={{ padding: '3px' }} />
                                              }
                                              label="Wrong"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div
                                          className="font-icon-wrapper"
                                          onClick={() => deleteOption(singleOption)}
                                          disabled={
                                            singleTestQuestion.id !==
                                            selectedTestQuestion.id
                                          }
                                        >
                                          <IconButton aria-label="delete">
                                            <DeleteOutline />
                                          </IconButton>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )}
                          <Button
                            onClick={() => addOption()}
                            disabled={
                              singleTestQuestion.id !== selectedTestQuestion.id
                            }
                          >
                            Add Option
                          </Button>

                          {selectedTestQuestion.edit &&
                          selectedTestQuestion.id === singleTestQuestion.id ? (
                            <>
                              <Grid item xs={12}>
                                <FormControl>
                                  <FormLabel id="demo-radio-buttons-group-label">
                                    Question Severity
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedTestQuestion.severity}
                                    onChange={handleSeverityChange}
                                  >
                                    <FormControlLabel
                                      value="low"
                                      control={<Radio />}
                                      label="Low"
                                    />
                                    <FormControlLabel
                                      value="medium"
                                      control={<Radio />}
                                      label="Medium"
                                    />
                                    <FormControlLabel
                                      value="high"
                                      control={<Radio />}
                                      label="High"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                  <FormLabel component="legend">
                                    Assign Question Type
                                  </FormLabel>
                                  <FormGroup>
                                    <FormControlLabel
                                      //   value={false}
                                      control={
                                        <Switch
                                          onChange={handleTimeCheckedChange}
                                          checked={selectedTestQuestion?.timeChecked}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                      }
                                      label={
                                        selectedTestQuestion.timeChecked
                                          ? 'Timed Question'
                                          : 'Untimed Question'
                                      }
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>

                              {selectedTestQuestion.timeChecked ? (
                                <TextField
                                  id="time"
                                  name="time"
                                  required
                                  label="Question Time(in seconds)"
                                  sx={{ mt: 3, mb: 2 }}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  defaultValue={selectedTestQuestion.time}
                                  onChange={(e) => handleTestQuestionTimeChange(e)}
                                  disabled={!selectedTestQuestion.timeChecked}
                                />
                              ) : null}
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <FormControl
                                  disabled={
                                    singleTestQuestion.id !== selectedTestQuestion.id
                                  }
                                >
                                  <FormLabel id="demo-radio-buttons-group-label">
                                    Question Severity
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={singleTestQuestion.severity}
                                    onChange={handleSeverityChange}
                                  >
                                    <FormControlLabel
                                      value="low"
                                      control={<Radio />}
                                      label="Low"
                                    />
                                    <FormControlLabel
                                      value="medium"
                                      control={<Radio />}
                                      label="Medium"
                                    />
                                    <FormControlLabel
                                      value="high"
                                      control={<Radio />}
                                      label="High"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl
                                  component="fieldset"
                                  variant="standard"
                                  disabled={
                                    singleTestQuestion.id !== selectedTestQuestion.id
                                  }
                                >
                                  <FormLabel component="legend">
                                    Assign Question Type
                                  </FormLabel>
                                  <FormGroup>
                                    <FormControlLabel
                                      //   value={false}
                                      control={
                                        <Switch
                                          onChange={handleTimeCheckedChange}
                                          checked={
                                            singleTestQuestion?.time ? true : false
                                          }
                                          inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                      }
                                      label={
                                        singleTestQuestion?.time
                                          ? 'Timed Question'
                                          : 'Untimed Question'
                                      }
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>

                              {singleTestQuestion.time && (
                                <TextField
                                  id="time"
                                  name="time"
                                  required
                                  label="Question Time(in seconds)"
                                  sx={{ mt: 3, mb: 2 }}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  defaultValue={singleTestQuestion?.time}
                                  onChange={(e) => handleTestQuestionTimeChange(e)}
                                  disabled={
                                    singleTestQuestion.id !== selectedTestQuestion.id
                                  }
                                />
                              )}
                            </>
                          )}
                        </div>
                      </Grid>
                    )
                  )
                : null}
              {addTestQuestion ? (
                <>
                  <Box
                    component="form"
                    //   onSubmit={handleSubmit}
                    noValidate
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="title"
                      label={`TestQuestion`}
                      name="title"
                      autoComplete="title"
                      autoFocus
                      onChange={(e) => handleTestQuestionTitleChange(e)}
                    />
                  </Box>

                  <Box>
                    {selectedTestQuestion?.options?.map((singleOption, index) => (
                      <Box key={singleOption}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={8}>
                            <TextField
                              autoFocus
                              margin="dense"
                              label={`Option ${index + 1}`}
                              value={singleOption.optionText}
                              onChange={(e) => handleValueChange(singleOption, e)}
                              required
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={singleOption.isCorrect ? 'right' : 'wrong'}
                                onChange={(e) => handleChange(singleOption, e)}
                                sx={{ marginLeft: '1rem' }}
                              >
                                <FormControlLabel
                                  value="right"
                                  control={<Radio sx={{ padding: '3px' }} />}
                                  label="Right"
                                />
                                <FormControlLabel
                                  value="wrong"
                                  control={<Radio sx={{ padding: '3px' }} />}
                                  label="Wrong"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <div
                              className="font-icon-wrapper"
                              onClick={() => deleteOption(singleOption)}
                            >
                              <IconButton aria-label="delete">
                                <DeleteOutline />
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    <Button onClick={() => addOption()}>Add Option</Button>

                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Question Severity
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedTestQuestion?.severity}
                          onChange={handleSeverityChange}
                        >
                          <FormControlLabel
                            value="low"
                            control={<Radio />}
                            label="Low"
                          />
                          <FormControlLabel
                            value="medium"
                            control={<Radio />}
                            label="Medium"
                          />
                          <FormControlLabel
                            value="high"
                            control={<Radio />}
                            label="High"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">
                          Assign Question Type
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            // value={false}
                            control={
                              <Switch
                                onChange={handleTimeCheckedChange}
                                checked={selectedTestQuestion?.timeChecked}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label={
                              selectedTestQuestion?.timeChecked
                                ? 'Timed Question'
                                : 'Untimed Question'
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    {selectedTestQuestion.timeChecked ? (
                      <TextField
                        id="time"
                        name="time"
                        required
                        label="Question Time(in seconds)"
                        sx={{ mt: 3, mb: 2 }}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handleTestQuestionTimeChange(e)}
                        disabled={!selectedTestQuestion.timeChecked}
                      />
                    ) : null}

                    <Box sx={{ display: 'flex', float: 'right' }}>
                      <Button
                        type="submit"
                        sx={{ padding: '8px 8px' }}
                        variant="outlined"
                        onClick={() => setAddTestQuestion(false)}
                      >
                        Delete
                        <DeleteOutline />
                      </Button>
                      &nbsp;
                      {selectedTestQuestion?.options?.length >= 2 ? (
                        <Box>
                          <Button
                            // type="submit"
                            sx={{ padding: '8px 8px' }}
                            variant="outlined"
                            onClick={() => handleSubmit()}
                          >
                            Create Test Question
                          </Button>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setAddTestQuestion(true)}
                >
                  Add Test Question
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </Grid>
  );
}

export default EditTestQuestion;
