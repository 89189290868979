import { BaseApi } from './BaseApi';

export const examsApi = BaseApi.injectEndpoints({
  reducerPath: 'examsApi',
  endpoints: (builder) => ({
    exams: builder.query({
      query: () => ({
        url: '/exams',
        method: 'GET',
      }),
      providesTags: ['Exam'],
    }),
    createExam: builder.mutation({
      query: (details) => ({
        url: '/exam/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          // start_date: details.start_date,
          // end_date: details.end_date,
          cost: details.cost,
          level: details.level,
        },
      }),
      invalidatesTags: ['Exam'],
    }),
    exam: builder.query({
      query: (details) => ({
        url: `/exam/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Exam'],
    }),
    updateExam: builder.mutation({
      query: (details) => ({
        url: `/exam/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          cost: details.cost,
          level: details.level,
        },
      }),
      invalidatesTags: ['Exam'],
    }),
    updateExamSatus: builder.mutation({
      query: (details) => ({
        url: '/exam/status/update',
        method: 'POST',

        body: {
          status: details.status,
          exam_id: details.id,
        },
      }),
      invalidatesTags: ['Exam'],
    }),
    deleteExam: builder.mutation({
      query: (details) => ({
        url: `/exam/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Exam'],
    }),

    // Questions
    questions: builder.query({
      query: () => ({
        url: '/questions',
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    createQuestion: builder.mutation({
      query: (details) => ({
        url: '/exam/question/create',
        method: 'POST',

        body: {
          title: details.title,
          options: details.options,
          exam_id: details.exam_id,
        },
      }),
      invalidatesTags: ['Exam'],
    }),
    question: builder.query({
      query: (details) => ({
        url: `/exam/question/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    updateQuestion: builder.mutation({
      query: (details) => ({
        url: `/exam/question/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          options: details.options,
          exam_id: details.exam_id,
        },
      }),
      invalidatesTags: ['Question', 'Exam'],
    }),

    deleteQuestion: builder.mutation({
      query: (details) => ({
        url: `/exam/question/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Exam'],
    }),
  }),
});

export const {
  useExamQuery,
  useExamsQuery,
  useCreateExamMutation,
  useUpdateExamMutation,
  useUpdateExamSatusMutation,
  useDeleteExamMutation,
  useQuestionsQuery,
  useQuestionQuery,
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useUpdateQuestionMutation,
} = examsApi;
