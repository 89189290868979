import { Box, Grid } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useLoginGoogleUrlQuery } from '../../../app/services/AuthApi';

function StudentGoogleSignIn() {
  const { data } = useLoginGoogleUrlQuery();

  return (
    <Grid container align="center" sx={{ padding: '1rem' }}>
      <Grid item xs={12}>
        {data && data.url != null && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'solid',
            }}
          >
            <GoogleIcon />
            &nbsp;
            <a
              href={data.url}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'aliceblue' }}
            >
              Sign In With Google
            </a>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default StudentGoogleSignIn;
