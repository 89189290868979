import { BaseApi } from './BaseApi';

export const studentCourseExamsApi = BaseApi.injectEndpoints({
  reducerPath: 'studentCourseExamsApi',
  endpoints: (builder) => ({
    courseExamEnrol: builder.mutation({
      query: (details) => ({
        url: '/course/exam/enrol/create',
        method: 'POST',
        body: {
          course_exam_id: details.course_exam_id,
        },
      }),
    }),
    enroledCourseExams: builder.query({
      query: (token) => ({
        url: '/course/exam/enrols',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    enroledCourseExam: builder.query({
      query: (details) => ({
        url: `/course/exam/enrol/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course Exam Enrol'],
    }),
    createCourseExamResult: builder.mutation({
      query: (details) => ({
        url: `/course/exam/result/create`,
        method: 'POST',
        body: {
          answers: details.answers,
          course_exam_id: details.examId,
        },
      }),
    }),
    createCourseExamReview: builder.mutation({
      query: (details) => ({
        url: '/course/exam/review/create',
        method: 'POST',
        body: {
          rating: details.rating,
          review_text: details.reviewText,
          course_exam_id: details.course_exam_id,
        },
      }),
      invalidatesTags: ['Course Exam Enrol'],
    }),
    createStudentCourseExamEnrolPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/student/course/exam/enrol/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
          course_exam_id: details.course_exam_id,
        },
      }),
    }),
    createStudentCourseExamEnrolWithPayment: builder.mutation({
      query: (details) => ({
        url: '/student/course/exam/enrol/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          email: details.email,
          course_exam_id: details.course_exam_id,
        },
      }),
    }),
  }),
});

export const {
  useCourseExamEnrolMutation,
  useEnroledCourseExamQuery,
  useEnroledCourseExamsQuery,
  useCreateCourseExamResultMutation,
  useCreateCourseExamReviewMutation,
  useCreateStudentCourseExamEnrolPaymentOrderIdMutation,
  useCreateStudentCourseExamEnrolWithPaymentMutation,
} = studentCourseExamsApi;
