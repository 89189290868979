import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { usePracticeTestsQuery } from '../../../app/services/StudentTestsApi';
import DataLoader from '../../../components/DataLoader';

function PracticeTests() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { data, isLoading } = usePracticeTestsQuery(token);
  const viewPracticeTest = (id) => {
    navigate(`/practice/tests/${id}`);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Your Practice Tests
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Created On</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.practiceTests &&
              data.practiceTests.length &&
              data.practiceTests.map((singlePracticeTest, index) => (
                <TableRow
                  key={singlePracticeTest.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singlePracticeTest.title}</TableCell>

                  <TableCell>
                    {moment(singlePracticeTest.created_at).format(
                      'Do MMM YY hh:mm A'
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{ pl: 4 }}
                      onClick={() => viewPracticeTest(singlePracticeTest.id)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PracticeTests;
