import { PhotoCamera } from '@mui/icons-material';
import { IconButton, Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { uploadEducatorDocumentRequest } from '../../../app/services/UploadRequests';
import DisplayDocument from '../../courses/adminOrEducatorOrInstitution/DisplayDocument';

function UploadDocument(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { imgUrl, bankInfoId, fileType } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(imgUrl);
  const [isPdf, setIsPdf] = useState(null);
  const allowedImageTypes = [
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'application/pdf',
  ];
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  const UploadImage = (event) => {
    event.preventDefault();
    setLoading(true);

    const UploadData = {
      file: selectedImage,
      token: token,
      fileType: fileType,
    };

    uploadEducatorDocumentRequest(UploadData, bankInfoId).then((res) => {
      if (res.url) {
        setLoading(false);
        enqueueSnackbar('Uploaded Successfully. Please refresh your page for now.', {
          variant: 'success',
        });
      } else {
        setLoading(false);

        enqueueSnackbar('Error Uploading . ', {
          variant: 'error',
        });
      }
    });
  };
  useEffect(() => {
    const checkIfPdf = async (url) => {
      if (url.toLowerCase().endsWith('.pdf')) {
        setIsPdf(true);
        return;
      }

      try {
        const response = await fetch(url, { method: 'HEAD' });
        const contentType = response.headers.get('Content-Type');
        setIsPdf(contentType === 'application/pdf');
      } catch (error) {
        console.error('Error fetching the URL:', error);
        setIsPdf(false);
      }
    };
    if (imageUrl) {
      checkIfPdf(imageUrl);
    }
  }, [imageUrl]);
  return (
    <>
      {imageUrl && (
        <Box mt={2} textAlign="center">
          <div> {`${isPdf ? 'PDF' : 'Image'}`} Preview:</div>
          {isPdf ? (
            <DisplayDocument doc={{ doc_url: imageUrl }} />
          ) : (
            <img src={imageUrl} height="400px" />
          )}
        </Box>
      )}
      <Box component="form" onSubmit={UploadImage} noValidate sx={{ mt: 1 }}>
        <IconButton color="primary" aria-label="upload picture" component="label">
          <input
            hidden
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (!props.bankInfoId) {
                enqueueSnackbar('Provide bank details first. ', {
                  variant: 'error',
                });
                return;
              }
              if (allowedImageTypes.includes(e.target.files[0].type)) {
                setSelectedImage(e.target.files[0]);
              } else {
                enqueueSnackbar('Please select a PDF, PNG, JPEG, or SVG file.', {
                  variant: 'error',
                });
              }
            }}
          />
          <PhotoCamera />
        </IconButton>
        {loading ? <p>please wait while we upload</p> : null}
        {selectedImage ? (
          <Button variant="contained" type="submit" disabled={loading}>
            Upload
          </Button>
        ) : null}
      </Box>
    </>
  );
}

export default UploadDocument;
