import { BaseApi } from './BaseApi';

export const homePageApi = BaseApi.injectEndpoints({
  reducerPath: 'homePageApi',
  endpoints: (builder) => ({
    homeData: builder.query({
      query: () => ({
        url: '/home/page',
        method: 'GET',
      }),
      providesTags: ['Home'],
    }),
  }),
});

export const { useHomeDataQuery } = homePageApi;
