/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { DeleteOutline, EditNote, SaveOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  useCreateTestSubjectMutation,
  useDeleteTestSubjectMutation,
  useTestQuery,
  useUpdateTestSubjectMutation,
} from '../../../app/services/TestsApi';
import EditTestTopic from './EditTestTopic';

function AddTestSubject(props) {
  const { testId, nextButton } = props;

  const [createTestSubject] = useCreateTestSubjectMutation();
  const [deleteId] = useDeleteTestSubjectMutation();
  const [selectedTestSubject, setSelectedTestSubject] = useState({
    title: '',
    edit: false,
    id: null,
    testTopics: [],
  });
  const [updateTestSubject] = useUpdateTestSubjectMutation();
  const [addTestSubject, setAddTestSubject] = useState(false);
  const { data } = useTestQuery({
    id: testId,
  });
  useEffect(() => {
    if (
      data?.test?.test_subjects[0] &&
      data?.test?.test_subjects[0]?.test_topics[0] &&
      data?.test?.test_subjects[0]?.test_topics[0]?.test_questions[0] &&
      data?.test?.test_subjects[0]?.test_topics[0]?.test_questions[0]?.id
    ) {
      nextButton(true);
    } else {
      nextButton(false);
    }
  }, [data?.test]);

  const validateData = (subjectData) => {
    let err = true;

    if (!subjectData.title) {
      err = true;
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const subjectData = {
      test_id: testId,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(subjectData);
    if (!res) {
      createTestSubject(subjectData)
        .unwrap()
        .then((res) => {
          if (res.testSubject.id) {
            setAddTestSubject(false);
          } else {
            console.log(res);
          }
        });
    }
  };

  const handleUpdateSubmit = () => {
    const subjectData = {
      id: selectedTestSubject.id,
      title: selectedTestSubject.title,
      description: selectedTestSubject.title,
    };

    const res = validateData(subjectData);
    if (!res) {
      updateTestSubject(subjectData)
        .unwrap()
        .then((res) => {
          if (res.message === 'testSubject updated successfully') {
            setSelectedTestSubject({
              ...selectedTestSubject,
              title: '',
              edit: false,
              id: null,
              testTopics: [],
            });
          }
        });
    }
  };

  const handleEditButton = (testSubject) => {
    setSelectedTestSubject({
      ...selectedTestSubject,
      title: testSubject.title,
      edit: true,
      id: testSubject.id,
      testTopics: testSubject.test_topics,
    });
  };

  const handleTestSubjectTitleChange = (e) => {
    setSelectedTestSubject({ ...selectedTestSubject, title: e.target.value });
  };

  const deleteTestSubjectWithId = (singleTestSubject) => {
    deleteId({ id: singleTestSubject.id })
      .unwrap()
      .then((res) => {
        if (res.message === 'DELETED') {
          setSelectedTestSubject({
            ...selectedTestSubject,
            title: '',
            edit: false,
            id: null,
            testTopics: [],
          });
        }
      });
  };

  return (
    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
      <>
        <Box>
          <Alert severity="info">
            Create test subjects, topics and add questions to topics so that students
            can create practice tests based on their choice of subjects and topics.
          </Alert>
          <Grid container spacing={2}>
            {data?.test?.test_subjects?.length > 0
              ? data?.test.test_subjects.map((singleTestSubject, subjectIndex) => (
                  <Grid
                    item
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    key={singleTestSubject.id}
                  >
                    {' '}
                    <div
                      style={{
                        border: '1px solid #cacbcf',
                        background: '#fafafa',
                        borderRadius: '6px',
                        padding: '1rem',
                      }}
                    >
                      <Box style={{ display: 'flex' }} component="form">
                        <TextField
                          required
                          fullWidth
                          size="small"
                          placeholder="What Where How and When"
                          label={`TestSubject ${subjectIndex + 1}`}
                          disabled={singleTestSubject.id !== selectedTestSubject.id}
                          defaultValue={singleTestSubject.title}
                          autoFocus
                          onChange={(e) => handleTestSubjectTitleChange(e)}
                          InputProps={{
                            endAdornment:
                              singleTestSubject.id === selectedTestSubject.id &&
                              selectedTestSubject.edit ? null : (
                                <InputAdornment position="end">
                                  <Icon color="success">
                                    <CheckCircleIcon twoToneColor="#52c41a" />
                                  </Icon>{' '}
                                </InputAdornment>
                              ),
                          }}
                        />
                        <>
                          <div className="font-icon-wrapper">
                            <IconButton
                              onClick={() =>
                                deleteTestSubjectWithId(singleTestSubject)
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </div>
                          <div className="font-icon-wrapper">
                            {singleTestSubject.id === selectedTestSubject.id &&
                            selectedTestSubject.edit ? (
                              <IconButton onClick={() => handleUpdateSubmit()}>
                                <SaveOutlined />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => handleEditButton(singleTestSubject)}
                              >
                                <EditNote />
                              </IconButton>
                            )}
                          </div>
                        </>
                      </Box>
                      <div style={{ marginTop: '1rem' }}>
                        <EditTestTopic
                          //   testSubject={singleTestSubject}
                          testSubjectId={singleTestSubject.id}
                        />
                      </div>
                    </div>
                  </Grid>
                ))
              : null}
            <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
              {addTestSubject ? (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{
                    mt: 1,
                    display: 'flex',
                    border: '1px solid #cacbcf',
                    background: '#fafafa',
                    borderRadius: '6px',
                    padding: '1rem',
                  }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label={`TestSubject`}
                    name="title"
                    autoComplete="title"
                    autoFocus
                  />
                  <div className="font-icon-wrapper" style={{ padding: 'inherit' }}>
                    <IconButton onClick={() => setAddTestSubject(false)}>
                      <DeleteOutline />
                    </IconButton>
                  </div>
                  <Button
                    type="submit"
                    sx={{ padding: '0px 8px' }}
                    variant="outlined"
                  >
                    Create Test Subject
                  </Button>
                </Box>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setAddTestSubject(true)}
                >
                  Add Test Subject
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </Grid>
  );
}

export default AddTestSubject;
