/* eslint-disable no-unused-vars */

import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
  AlertTitle,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import React from 'react';

import { useSnackbar } from 'notistack';
import DataLoader from '../../../components/DataLoader';
import { useEnroledTestQuery } from '../../../app/services/StudentTestsApi';
import CreateNewPracticeTest from './CreateNewPracticeTest';
import DisplayTestSubjectsAndTopics from '../public/DisplayTestSubjectsAndTopics';

function EnroledTest() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useEnroledTestQuery({
    id: parseInt(params.enrolId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isSuccess && data.enrol && data.test ? (
    <>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Card>
            <CardHeader title={data.test.title} />

            <CardMedia
              component="img"
              height="194"
              image={
                data.test.url ||
                `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
              }
              alt="Paella dish"
            />

            <CardContent sx={{ alignItems: 'center', overflowWrap: 'anywhere' }}>
              <Typography variant="subtitle1">
                In&nbsp;
                {data.test.language}
              </Typography>
              {data.test.test_reviews_count ? (
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Typography>
                      {Math.round(data.test.test_reviews_avg_rating * 10) / 10}&nbsp;
                    </Typography>
                  </Box>
                  <Rating
                    name="read-only"
                    value={Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                    readOnly
                    precision={0.5}
                  />

                  {data.test.test_reviews_count ? (
                    <Typography>
                      &#40;{data.test.test_reviews_count}
                      &#41;
                    </Typography>
                  ) : null}
                </Box>
              ) : (
                <Rating
                  name="read-only"
                  // value={Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                  readOnly
                  precision={0.5}
                />
              )}
            </CardContent>
            <CardActions sx={{ padding: 0 }}>
              <Alert severity="info">
                <>
                  {/* <AlertTitle>You have already Paid for this test.</AlertTitle> */}
                  <>
                    <Button variant="contained" onClick={handleClickOpen}>
                      Create Your New Practice Test
                    </Button>
                    <>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="sm"
                      >
                        <DialogTitle> Create Your New Practice Test</DialogTitle>
                        <DialogContent>
                          <CreateNewPracticeTest test={data.test} />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  </>
                </>
              </Alert>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 3 },
              pr: { md: 0 },
              overflowWrap: 'anywhere',
            }}
          >
            <Typography component="h2" variant="h5">
              <strong>Test Description</strong>
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {data.test.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              {/* <Alert severity="info">
                {data.test.cost
                  ? 'Buy Once and create any number of practice tests.'
                  : 'Create any number of practice tests.'}{' '}
              </Alert> */}
              <Typography component="h2" variant="h5">
                <strong>
                  You can choose Questions from these Subjects and Topics
                </strong>
              </Typography>
              <DisplayTestSubjectsAndTopics testSubjects={data.test.test_subjects} />
            </Box>
          </>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>No test found</p>
  );
}

export default EnroledTest;
