import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export const AcceptanceOfAgreement = () => {
  const APP_NAME = process.env.REACT_APP_NAME;
  const pageData = [
    {
      title: 'Information We Collect',
      list: [
        'Do not insult, abuse, harass, or infringe the rights of others.',
        'Do not post any defamatory, offensive, or unlawful material.',
        'Do not upload or share content that violates intellectual property laws.',
        'Do not attempt to hack or reverse engineer our services.',
        'Do not engage in spamming, advertising, or distributing malware.',
      ],
      data: `You agree to use our website in a lawful manner and
      adhere to the following guidelines`,
    },
    {
      title: 'User ID and Password',
      data: `Your user ID and password are for your exclusive use only.
      Sharing your account details is prohibited and may result in termination of your access. You are
      responsible for maintaining the confidentiality of your login credentials and for all activities that
      occur under your account. Notify us immediately if you suspect any unauthorized use of your
      account`,
    },
    {
      title: 'Content and Courseware',
      data: `We provide access to various content and courseware
      through our Services. This material is for your personal use only. You may not distribute,
      reproduce, or exploit the content without our written permission. We reserve the right to update
      or change our content at any time`,
    },
    {
      title: 'Free Access',
      data: `Your access to free courses is limited to specific content and may be subject
      to restrictions. We reserve the right to modify or revoke free access at any time`,
    },
    {
      title: 'Intellectual Property Rights',
      data: `All intellectual property rights in the website, services, and
      content belong to ${APP_NAME} and its licensors. You are granted a limited license to use our
      content for personal, non-commercial purposes only`,
    },
    {
      title: 'Transaction Terms',
      data: `All transactions made through our website are subject to these
      Terms. Ensure you review all payment details before completing a purchase. We do not provide
      warranties for products or services sold through our website, and we reserve the right to modify
      prices and terms`,
    },
    {
      title: 'Limitation of Liability',
      data: `We are not liable for any damages arising from the use of our
      website or services. Your use of our services is at your own risk. We do not guarantee
      uninterrupted access to our website or services`,
    },
    {
      title: 'Indemnity',
      data: `You agree to indemnify and hold ${APP_NAME} and its affiliates harmless from
      any claims arising from your use of our website or services`,
    },
    {
      title: 'Governing Law and Jurisdiction',
      data: `These Terms are governed by the laws of India. Any
      disputes arising from these Terms will be resolved in the courts of Pune, Maharashtra, India`,
    },
    {
      title: 'Amendments and Assignment',
      data: `We may update these Terms at any time without prior
      notice. Your continued use of our services after changes have been made indicates your
      acceptance of the new Terms. You may not assign your rights under these Terms to any third
      party`,
    },
    {
      title: 'Entire Agreement',
      data: `These Terms, along with our Privacy Policy, constitute the entire
      agreement between you and ${APP_NAME} regarding your use of our services`,
    },
    {
      title: 'Grievance Redressal',
      data: `For any concerns or queries, please contact our Grievance
      Officer`,
      list: [
        'Grievance Officer : Jameel Ahamad Mujawar',
        `Email : support@${APP_NAME}.in`,
        'Tel : 81779 00557',
      ],
    },
  ];
  return (
    <Fragment>
      <Grid container justifyContent={'center'}>
        <Grid item xl={10} lg={10} md={11} sm={11}>
          <Typography
            sx={{
              color: '#000',
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Acceptance of Agreement
          </Typography>
          <Box sx={{ marginTop: '1rem' }}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              Introduction
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                marginTop: '0.5rem',
              }}
            >
              By using our website, you agree to these Terms and our Privacy Policy.
              If you are accessing the website on behalf of a company, you represent
              that you have the authority to bind that company to these Terms.
            </Typography>
          </Box>
          {pageData.map((singleData, ind) => (
            <Box sx={{ marginTop: '1.5rem' }} key={ind + singleData?.title}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '18px',
                  fontWeight: 500,
                }}
              >
                {singleData?.title ? (
                  <span
                    style={{ fontWeight: 700 }}
                  >{`${singleData?.title} : `}</span>
                ) : null}
                {`${singleData?.data} .`}
              </Typography>
              {Array.isArray(singleData?.list) &&
                singleData?.list.map((singleList, singleIndex) => (
                  <li
                    style={{
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginTop: '0.5rem',
                      marginLeft: '1rem',
                    }}
                    key={singleIndex + ' list'}
                  >
                    {singleList}
                  </li>
                ))}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};
