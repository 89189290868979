import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
  CardContent,
  Icon,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTestQuestionQuery } from '../../../../../../app/services/TestsApi';
import UpdateTestQuestion from './UpdateTestQuestion';
import DataLoader from '../../../../../../components/DataLoader';

function SingleTestQuestion() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useTestQuestionQuery({
    id: parseInt(params.testQuestionId, 10),
  });

  const [open1, setOpen1] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <>
      {isSuccess && data.testQuestion ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Test Question
                </Button>
                <>
                  <Dialog
                    open={open1}
                    onClose={handleClose1}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle> Update Test Question</DialogTitle>
                    <DialogContent>
                      <UpdateTestQuestion testQuestion={data.testQuestion} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="2" lg="2" xl="2" align="left"></Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <>
                <Card>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ padding: 4, color: '#233c8d', overflowWrap: 'anywhere' }}
                  >
                    Question: {data.testQuestion.question}
                  </Typography>
                </Card>

                <Card>
                  <CardContent>
                    <Typography gutterBottom>
                      Question Severity: {data.testQuestion.severity}
                    </Typography>
                    {data.testQuestion.time ? (
                      <Typography gutterBottom>
                        Time to answer the question: {data.testQuestion.time} seconds
                      </Typography>
                    ) : (
                      <Typography gutterBottom>No time limit(untimed) </Typography>
                    )}
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ padding: 4, overflowWrap: 'anywhere' }}
                      color="text.secondary"
                    >
                      Options:
                      <br />
                      {data.testQuestion.options.map((singleOption, index) =>
                        singleOption.isCorrect ? (
                          <>
                            <Typography>
                              {`${index + 1}. ${singleOption.optionText}`}&nbsp;
                              <Icon color="success">
                                <CheckCircleIcon />
                              </Icon>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography>
                              {`${index + 1}. ${singleOption.optionText}`}&nbsp;
                              <Icon color="error">
                                <CancelIcon />
                              </Icon>
                            </Typography>
                          </>
                        )
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="2" lg="2" xl="2" align="left"></Grid>
          </Grid>
        </>
      ) : (
        <p>No Test question found</p>
      )}
    </>
  );
}

export default SingleTestQuestion;
