import React from 'react';
import { useParams } from 'react-router';
import { useCourseQuery } from '../../../app/services/CoursesApi';

import DataLoader from '../../../components/DataLoader';
import DisplayCoursePreview from './DisplayCoursePreview';

function SingleCourse() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useCourseQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.course ? (
        <>
          <DisplayCoursePreview courseId={params.Id} />
        </>
      ) : (
        <p>No course found</p>
      )}
    </>
  );
}

export default SingleCourse;
