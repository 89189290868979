import { BaseApi } from './BaseApi';

export const courseExamsApi = BaseApi.injectEndpoints({
  reducerPath: 'courseExamsApi',
  endpoints: (builder) => ({
    courseExams: builder.query({
      query: () => ({
        url: '/course/exams',
        method: 'GET',
      }),
      providesTags: ['CourseExam'],
    }),
    createCourseExam: builder.mutation({
      query: (details) => ({
        url: '/course/exam/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          course_id: details.course_id,
          language: details.language,
          cost: details.cost,
          level: details.level,
        },
      }),
      invalidatesTags: ['CourseExam'],
    }),
    updateCourseExam: builder.mutation({
      query: (details) => ({
        url: `/course/exam/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          course_id: details.course_id,
          language: details.language,
          cost: details.cost,
          level: details.level,
        },
      }),
      invalidatesTags: ['CourseExam'],
    }),
    courseExam: builder.query({
      query: (details) => ({
        url: `/course/exam/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['CourseExam'],
    }),

    updateCourseExamSatus: builder.mutation({
      query: (details) => ({
        url: '/course/exam/status/update',
        method: 'POST',

        body: {
          status: details.status,
          course_exam_id: details.id,
        },
      }),
      invalidatesTags: ['CourseExam'],
    }),
    deleteCourseExam: builder.mutation({
      query: (details) => ({
        url: `/course/exam/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CourseExam'],
    }),

    // Questions
    courseExamQuestions: builder.query({
      query: () => ({
        url: '/questions',
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    createCourseExamQuestion: builder.mutation({
      query: (details) => ({
        url: '/course/exam/question/create',
        method: 'POST',

        body: {
          title: details.title,
          options: details.options,
          course_exam_id: details.course_exam_id,
        },
      }),
      invalidatesTags: ['CourseExam'],
    }),
    courseExamQuestion: builder.query({
      query: (details) => ({
        url: `/course/exam/question/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    updateCourseExamQuestion: builder.mutation({
      query: (details) => ({
        url: `/course/exam/question/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          options: details.options,
          course_exam_id: details.course_exam_id,
        },
      }),
      invalidatesTags: ['Question', 'CourseExam'],
    }),

    deleteCourseExamQuestion: builder.mutation({
      query: (details) => ({
        url: `/course/exam/question/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CourseExam'],
    }),
    singleCourseExams: builder.query({
      query: (details) => ({
        url: `/course/${details.id}/exams`,
        method: 'GET',
      }),
      providesTags: ['CourseExams'],
    }),
  }),
});

export const {
  useCourseExamQuery,
  useCourseExamsQuery,
  useUpdateCourseExamSatusMutation,
  useDeleteCourseExamMutation,
  useCourseExamQuestionsQuery,
  useCourseExamQuestionQuery,
  useCreateCourseExamQuestionMutation,
  useDeleteCourseExamQuestionMutation,
  useUpdateCourseExamQuestionMutation,
  useCreateCourseExamMutation,
  useUpdateCourseExamMutation,
  useSingleCourseExamsQuery,
} = courseExamsApi;
