/* eslint-disable no-unused-vars */
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEducatorSubscriptionPlanQuery } from '../../../app/services/SubscriptionPlansApi';
import DataLoader from '../../../components/DataLoader';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { CurrencyRupee } from '@mui/icons-material';

function SingleEducatorPlan() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useEducatorSubscriptionPlanQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.educatorSubscriptionPlan ? (
    <Grid container spacing={1}>
      <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      <Grid item xs="12" sm="12" md="8" lg="8" xl="8">
        <Card>
          <CardContent>
            <Typography component="h2" variant="h5">
              Title: {data.educatorSubscriptionPlan.title}
            </Typography>
            <Typography component="h2" variant="h5">
              Description: {data.educatorSubscriptionPlan.description}
            </Typography>
            <Typography>
              Cost Per Month: <CurrencyRupee />
              {data.educatorSubscriptionPlan.cost_per_month}
            </Typography>
            <List>
              {data.educatorSubscriptionPlan.features?.length >= 1 ? (
                data.educatorSubscriptionPlan.features.map((singleFeature) => (
                  <ListItem key={singleFeature}>
                    <ListItemIcon>
                      {singleFeature.icon === true ? <CheckIcon /> : <ClearIcon />}
                    </ListItemIcon>
                    <ListItemText primary={singleFeature.text} />
                  </ListItem>
                ))
              ) : (
                <Typography>No Features added</Typography>
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ) : (
    <Typography>No plan found</Typography>
  );
}

export default SingleEducatorPlan;
