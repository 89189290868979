/* eslint-disable no-unused-vars */

import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useKeywordsQuery } from '../app/services/KeywordsApi';
import { useSnackbar } from 'notistack';
import zIndex from '@mui/material/styles/zIndex';

const Search = styled('div')(({ theme }) => ({
  flex: 1,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.65),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.75),
  },
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
    maxWidth: '800px',
    zIndex: 1800,
  },
}));

function SearchBar() {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isSuccess } = useKeywordsQuery();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.get('q') ? setInputValue(searchParams.get('q')) : null;
  }, [searchParams.get('q')]);

  const onSearchClick = (inputSearchValue) => {
    if (inputSearchValue.trim().length === 0) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });

      return;
    }
    navigate({
      pathname: 'search',
      search: createSearchParams({
        q: inputSearchValue,
      }).toString(),
    });
  };

  return (
    <Search className="custom-search-input">
      <Autocomplete
        freeSolo
        size="small"
        inputValue={inputValue}
        disableClearable
        // options={isSuccess ? data.keywords : []}
        options={isSuccess ? data.keywords.map((option) => option.title) : []}
        onChange={(_event, newValue) => {
          onSearchClick(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        // getOptionLabel={(option) => `${option.title} `}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search courses… exams... practice tests..."
            required
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => onSearchClick(inputValue)}
                    disabled={!inputValue.length}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Search>
  );
}

export default SearchBar;
