/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useUpdateCourseExamSatusMutation } from '../../../app/services/CourseExamsApi';

function ChangeCourseExamStatus(props) {
  const { exam } = props;
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [updateExamStatus, result] = useUpdateCourseExamSatusMutation();

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateData = {
      id: exam.id,
      status: status,
    };

    updateExamStatus(updateData);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box
        noValidate
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
        }}
        onSubmit={onChangeStatus}
      >
        <Typography>Current Status: {exam.status}</Typography>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          <InputLabel htmlFor="max-width">status</InputLabel>

          <Select
            autoFocus
            onChange={handleChangeStatus}
            label="maxWidth"
            inputProps={{
              name: 'max-width',
              id: 'max-width',
            }}
          >
            <MenuItem value="draft">DRAFT</MenuItem>
            <MenuItem value="in_review">IN REVIEW</MenuItem>
            <MenuItem value="published">PUBLISH</MenuItem>
            <MenuItem value="on_hold">ON HOLD</MenuItem>
            <MenuItem value="rejected">REJECT</MenuItem>
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={status === exam.status || loading || !status}
        >
          Change Status
        </Button>
      </Box>
    </>
  );
}

export default ChangeCourseExamStatus;
