import { ThemeProvider } from '@emotion/react';
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box,
  createTheme,
  Avatar,
  InputLabel,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { signIn, toggleforgotPasswordButton } from '../../../app/slices/authSlice';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocation } from 'react-router';
import React from 'react';
import { PasswordEye } from './PasswordEye';
import { useCourseEnrolMutation } from '../../../app/services/StudentCoursesApi';
import { useNavigate } from 'react-router';
import StudentGoogleSignIn from './StudentGoogleSignIn';

const theme = createTheme();

// eslint-disable-next-line no-undef
// const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Sign In Page with ui form
function StudentSignInPage() {
  const { enqueueSnackbar } = useSnackbar();

  // const { changeView } = props;
  const dispatch = useDispatch();
  const [verifyButton, setVerifyButton] = useState(false);
  const { isSignedIn } = useSelector((state) => state.auth);
  const location = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [courseEnrol] = useCourseEnrolMutation();

  const navigateTo = useNavigate();

  const enrolCourse = async (courseId) => {
    await courseEnrol({ course_id: courseId })
      .unwrap()
      .then((res) => {
        console.log('this is res', res);
        if (res.enrol.id) {
          enqueueSnackbar('Enrolled Course with success.', {
            variant: 'success',
          });
          navigateTo(`/enrols/course/${res.enrol.id}`);
        } else {
          enqueueSnackbar('Error Enroling for course. ', {
            variant: 'error',
          });
        }
      })
      .catch((res) => {
        if (res?.data?.enrol?.id) {
          navigateTo(`/enrols/course/${res?.data?.enrol?.id}`);
        }
        console.log('this is catch', res);
      })
      .finally(() => {
        // refetch();
      });

    // reloadPage();
  };
  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (!authData.email || !authData.password) {
      enqueueSnackbar('Both Email and Password are required', { variant: 'error' });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else if (authData.password.length < 8) {
      enqueueSnackbar('Password length must be at least 8 chars', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  // Handle Submit of the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Authdata = {
      email: data.get('email'),
      password: data.get('password'),
    };
    const res = validateData(Authdata);
    if (!res) {
      // Dispatch with signin parameters
      dispatch(signIn(Authdata))
        .unwrap()
        .then((response) => {
          if (
            response.user.role === 'student' &&
            location?.state?.isEnroll &&
            location?.state?.courseID
          ) {
            enrolCourse(location?.state?.courseID);
          }
          if (response.free === true && response.user.role === 'student') {
            enqueueSnackbar('Signin Success. You are in a free trial period', {
              variant: 'success',
            });
          } else if (response.free === false && response.user.role === 'student') {
            enqueueSnackbar('Signin Success. Your free trial period has ended', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Signin Success.', {
              variant: 'success',
            });
          }
        })
        .catch((error) => {
          const errRes = Object.values(error.data);
          errRes.forEach((singleError) => {
            singleError.forEach((message) => {
              if (message === 'EMAIL_NOT_VERIFIED') {
                setVerifyButton(true);
              }
              enqueueSnackbar(message, { variant: 'error' });
            });
          });
        });
    }
  };

  if (isSignedIn) {
    return location?.state?.state ? (
      <Navigate replace to={location?.state?.state} />
    ) : (
      <Navigate replace to="/" />
    );
  }

  // const openRegisterTab = (e) => changeView(e, 1);

  const toggleForgotPassword = () => {
    dispatch(toggleforgotPasswordButton());
  };

  const textFieldStyling = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 1)', // Background color
      borderRadius: '6px',
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color when focused
      },
      '& fieldset': {
        borderColor: 'white', // Default border color
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Label color when focused
    },
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            border: '2px solid #60696e',
            borderRadius: '10px',
            marginTop: '5rem',
          }}
        >
          <CssBaseline />

          <Typography
            style={{
              font: 800,
              position: 'relative',
              top: '-3rem',
              color: '#f6b605',
              fontSize: '20px',
            }}
            align="center"
          >
            Transforming Education In To Digital
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                style={{
                  color: '#f6b605',
                }}
              >
                Student Login
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, width: '100%' }}
              >
                <InputLabel sx={{ color: '#fff' }}>Email Address</InputLabel>
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  size="small"
                  sx={{ ...textFieldStyling }}
                />
                <InputLabel sx={{ color: '#fff', mt: 2 }}>Password</InputLabel>
                <div style={{ position: 'relative', width: '100%' }}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    size="small"
                    sx={{ ...textFieldStyling }}
                  />
                  <PasswordEye
                    stylingEye={{
                      position: 'absolute',
                      right: '1rem',
                      top: '1.3rem',
                    }}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </div>
                {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    sx={{ ...textFieldStyling }}
                    endAdornment={
                     
                    }
                    label="Password"
                  />
                </FormControl> */}
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                {verifyButton ? (
                  <div>
                    <Link
                      to="/resend/email/verification"
                      style={{
                        color: '#fff',
                        textDecoration: 'underline !important',
                      }}
                      className="decoration-underline"
                    >
                      Verify Your Email
                    </Link>
                  </div>
                ) : null}
                <Grid container align="center">
                  <Grid item xs>
                    <Button sx={{ color: '#fff' }} onClick={toggleForgotPassword}>
                      Forgot password?
                    </Button>
                  </Grid>
                </Grid>
                <Grid container align="center">
                  <Grid item xs>
                    <StudentGoogleSignIn />
                  </Grid>
                </Grid>
              </Box>
            </>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default StudentSignInPage;
