import { Button, Box, TextField, Grid, IconButton } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useCreateLearningObjectiveMutation } from '../../../../app/services/LearningObjectivesApi';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function CreateNewLearningObjective() {
  const { enqueueSnackbar } = useSnackbar();
  const [createLearningObjective, result] = useCreateLearningObjectiveMutation();
  const [objectives, setObjectives] = useState([]);

  const validateData = (learningObjectiveData) => {
    let err = true;

    if (!learningObjectiveData.title) {
      enqueueSnackbar('Please provide learning objective title', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const learningObjectiveData = {
      title: data.get('title'),
      objectives: objectives,
    };

    const res = validateData(learningObjectiveData);
    if (!res) {
      createLearningObjective(learningObjectiveData);
    }
  };

  const addObjective = () => {
    setObjectives([...objectives, { title: '', description: '' }]);
  };
  const handleTitleChange = (index, e) => {
    const updatedObjectives = objectives.map((value, i) => {
      if (i === index) {
        return { ...value, title: e.target.value };
      } else {
        return value;
      }
    });
    setObjectives(updatedObjectives);
  };
  const handleDescriptionChange = (index, e) => {
    const updatedObjectives = objectives.map((value, i) => {
      if (i === index) {
        return { ...value, description: e.target.value };
      } else {
        return value;
      }
    });
    setObjectives(updatedObjectives);
  };
  const deleteObjective = (singleObjective) => {
    setObjectives(objectives.filter((objective) => objective !== singleObjective));
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          autoFocus
        />

        {objectives.map((singleObjective, index) => (
          <Box key={index}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={8}>
                <TextField
                  autoFocus
                  margin="dense"
                  label={`objective ${index + 1} title`}
                  value={singleObjective.title}
                  onChange={(e) => handleTitleChange(index, e)}
                  required
                  fullWidth
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="description"
                  label={`objective ${index + 1} description`}
                  name="description"
                  autoComplete="description"
                  value={singleObjective.description}
                  onChange={(e) => handleDescriptionChange(index, e)}
                  autoFocus
                  multiline
                  rows="2"
                />
              </Grid>

              <Grid item xs={2}>
                <div
                  className="font-icon-wrapper"
                  onClick={() => deleteObjective(singleObjective)}
                >
                  <IconButton aria-label="delete">
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Button onClick={addObjective}>Add Objective</Button>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Learning Objective
        </Button>
      </Box>
    </>
  );
}

export default CreateNewLearningObjective;
