import { Link, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { usePublishedCategoriesQuery } from '../app/services/CategoriesApi';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
function MenuToolBar() {
  const { data, isSuccess } = usePublishedCategoriesQuery();
  const [selectedPublishedCategory, setSelectedPublishedCategoryPublished] =
    React.useState(false);
  const [selectedPublishedSubCategory, setSelectedPublishedSubCategory] =
    useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [selectedPublishedSubCategoryGrade, setSelectedPublishedSubCategoryGrade] =
    useState(false);
  const onSubMenuClick = (id) => {
    navigate(`/${selectedPublishedCategory.title}/${id}`);
    // setSelectedPublishedCategoryPublished(false);
  };
  const onGradeClick = (id) => {
    navigate(
      `/allgrades/${selectedPublishedCategory.title}/${selectedPublishedSubCategory?.id}/${id}`
    );
  };

  const location = useLocation();
  return (
    <>
      {isSuccess &&
      data.categories.length &&
      user?.role !== 'educator' &&
      location?.pathname !== '/teach-educator' ? (
        <>
          <Box>
            <Toolbar
              component="nav"
              variant="dense"
              sx={{
                // justifyContent: 'flex-start',
                overflowX: 'auto',
                backgroundColor: '#fff',
                color: '#000',
                zIndex: 1500,
              }}
              // onMouseEnter={() => {
              //   setSelectedPublishedSubCategory(false);
              // }}
              onMouseLeave={() => {
                setSelectedPublishedSubCategory(false);
              }}
            >
              {data.categories.map((singlePublishedCategory) => (
                <>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setSelectedPublishedCategoryPublished(singlePublishedCategory);
                    }}
                    color="inherit"
                    noWrap
                    key={singlePublishedCategory.id}
                    underline="none"
                    sx={{ p: 1, flexShrink: 0 }}
                  >
                    {singlePublishedCategory.title.charAt(0).toUpperCase() +
                      singlePublishedCategory.title.slice(1)}
                  </Link>
                </>
              ))}
            </Toolbar>
            {selectedPublishedCategory &&
            selectedPublishedCategory.published_sub_categories.length ? (
              <Toolbar
                component="nav"
                variant="dense"
                sx={{
                  justifyContent: 'flex-start',
                  overflowX: 'auto',
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  height: 'auto',
                }}
              >
                {selectedPublishedCategory.published_sub_categories.map(
                  (singlePublishedCategorySubCategory) => (
                    <Link
                      component="button"
                      variant="body2"
                      color="inherit"
                      noWrap
                      key={singlePublishedCategorySubCategory.id.toString()}
                      underline="none"
                      sx={{ p: 1, flexShrink: 0 }}
                      onClick={() =>
                        onSubMenuClick(singlePublishedCategorySubCategory.id)
                      }
                      onMouseEnter={() => {
                        setSelectedPublishedSubCategory(
                          singlePublishedCategorySubCategory
                        );
                      }}
                    >
                      {singlePublishedCategorySubCategory.title
                        .charAt(0)
                        .toUpperCase() +
                        singlePublishedCategorySubCategory.title.slice(1)}
                    </Link>
                  )
                )}
              </Toolbar>
            ) : null}
            {selectedPublishedSubCategory &&
            Array.isArray(selectedPublishedSubCategory.grades) &&
            selectedPublishedSubCategory.grades.length ? (
              <Toolbar
                component="nav"
                variant="dense"
                sx={{
                  justifyContent: 'flex-start',
                  overflowX: 'auto',
                  backgroundColor: '#59d2eb',
                  color: '#000000',
                }}
                onMouseLeave={() => {
                  setSelectedPublishedSubCategory(false);
                }}
              >
                {Array.isArray(selectedPublishedSubCategory.grades) &&
                  selectedPublishedSubCategory.grades.length > 0 &&
                  [...selectedPublishedSubCategory.grades]
                    .sort((a, b) => {
                      const numA = parseInt(a?.title.replace(/\D/g, ''), 10); // Extracts the number
                      const numB = parseInt(b?.title.replace(/\D/g, ''), 10);
                      return numA - numB; // Ascending order (Class 1, Class 2, ...)
                    })
                    .map((singlePublishedgrade) => (
                      <Link
                        component="button"
                        variant="body2"
                        color="inherit"
                        noWrap
                        key={singlePublishedgrade.id.toString()}
                        underline="none"
                        sx={{
                          p: 1,
                          flexShrink: 0,
                          color:
                            selectedPublishedSubCategoryGrade ===
                            singlePublishedgrade?.title
                              ? 'red'
                              : '',
                          fontWeight:
                            selectedPublishedSubCategoryGrade ===
                            singlePublishedgrade?.title
                              ? 700
                              : 500,
                        }}
                        onMouseEnter={() => {
                          setSelectedPublishedSubCategoryGrade(
                            singlePublishedgrade.title
                          );
                        }}
                        onMouseLeave={() => {
                          setSelectedPublishedSubCategoryGrade(false);
                          // setSelectedPublishedSubCategory(false);
                        }}
                        onClick={() => onGradeClick(singlePublishedgrade.id)}
                      >
                        {singlePublishedgrade.title.charAt(0).toUpperCase() +
                          singlePublishedgrade.title.slice(1)}
                      </Link>
                    ))}
              </Toolbar>
            ) : null}
          </Box>
        </>
      ) : null}
    </>
  );
}

export default MenuToolBar;
