import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import MomentUtils from '@date-io/moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { Fragment, useState } from 'react';
import moment from 'moment';
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export const CustomDateRange = () => {
  const [createEL, setCreateEL] = React.useState(null);
  const [selectedRange, setSelectedRange] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };
  const createOpen = Boolean(createEL);
  const handleCreateMenuClick = (event) => {
    setCreateEL(event.currentTarget);
  };
  const handleCloseCreate = () => {
    setCreateEL(null);
  };
  const rendeCreaterMenuItems = () => {
    return (
      <>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={createEL}
          open={createOpen}
          onClose={handleCloseCreate}
        >
          <MenuItem
            onClick={() => {
              setSelectedRange('7days');
            }}
            // sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
          >
            Last 7 Days
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedRange('30days');
            }}
            // sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
          >
            Last 30 Days
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedRange('90days');
            }}
            // sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
          >
            Last 90 Days
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedRange('alltime');
            }}
            // sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
          >
            All Time
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedRange('customrange');
            }}
            // sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
          >
            Custom Range
          </MenuItem>
          {selectedRange === 'customrange' && (
            <MenuItem
              onClick={() => {}}
              // sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
            >
              <Box sx={{ mt: 2, gap: '1rem', display: 'flex' }}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DatePicker
                    label="From"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField {...params} size="small" sx={{ width: '150px' }} />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DatePicker
                    label="To"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => (
                      <TextField {...params} size="small" sx={{ width: '150px' }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </MenuItem>
          )}
          <MenuItem sx={{ display: 'flex', gap: '0.5rem' }}>
            <Button variant="contained" onClick={handleCloseCreate}>
              Apply
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseCreate}
              sx={{
                background: '#fff',
                color: '#000',
                '&:hover': {
                  background: '#fff',
                },
              }}
            >
              Cancel
            </Button>
          </MenuItem>
        </StyledMenu>{' '}
      </>
    );
  };
  return (
    <Fragment>
      {' '}
      <Button
        id="demo-customized-button"
        aria-controls={createOpen ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={createOpen ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleCreateMenuClick}
        endIcon={<KeyboardArrowDown />}
        // startIcon={<FormatListBulleted />}
        sx={{
          background: '#09768d',
          '&:hover': {
            background: '#09768d',
          },
        }}
      >
        Select Date Range
      </Button>
      {rendeCreaterMenuItems()}
    </Fragment>
  );
};
