import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateExamResultMutation } from '../../../app/services/StudentExamsApi';
import QuestionAndAnswersList from './QuestionAndAnswersList';
/* eslint-disable no-unused-vars */

function ExamWithQuestions(props) {
  const { questions, examId } = props;

  const [answers, setAnswers] = useState([]);

  const [examResult, setExamResult] = useState({});
  const [createExamResult] = useCreateExamResultMutation();

  const onChange = (e, questionId) => {
    if (answers && answers.find((element) => element.question_id === questionId)) {
      const updatedAnswers = answers.map((singleAnswer) => {
        if (singleAnswer.question_id === questionId) {
          return {
            ...singleAnswer,
            given_answer: e.target.value,
          };
        }
        return singleAnswer;
      });
      setAnswers(updatedAnswers);
    } else {
      setAnswers([
        ...answers,
        { given_answer: e.target.value, question_id: questionId },
      ]);
    }
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [finished, setFinished] = useState(false);

  const resetCount = (newQuestion) => {
    // eslint-disable-next-line no-unused-expressions
    newQuestion < questions.length + 1
      ? setCurrentQuestion(newQuestion)
      : setCurrentQuestion(1);
  };

  // const setQuestionsFinished = () => {
  //   // eslint-disable-next-line no-unused-expressions
  //   setFinished(true);
  // };

  useEffect(() => {
    if (questions.length - 1 === currentQuestion) {
      setLastQuestion(true);
    }
  }, [currentQuestion]);

  const checkAnswers = (singleQuestion) => {
    const found = answers.some(
      (singleAnswer) => singleAnswer.question_id === singleQuestion.id
    );
    if (!found)
      answers.push({ question_id: singleQuestion.id, given_answer: 'not given' });
    return true;
  };

  const submitAnswers = async () => {
    await questions.every(checkAnswers);

    const res = await createExamResult({ answers, examId });
    if (res.data.message === 'SUCCESS') {
      setExamResult(res.data.examRresult);
    }
  };

  return (
    <>
      {'id' in examResult ? (
        <>
          <Grid item md={2}></Grid>
          <Grid item md={10}>
            <Typography>
              Result:&nbsp;
              {examResult.total_marks}
            </Typography>
            {examResult.answers ? (
              <QuestionAndAnswersList
                questions={questions}
                answers={examResult.answers}
              />
            ) : null}
          </Grid>
          <Grid item md={2}></Grid>
        </>
      ) : questions[currentQuestion] && !finished ? (
        <>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
            <>
              <Alert severity="info">
                <>
                  <AlertTitle>
                    Don&apos;t press back button or navigate to other pages while
                    examis is in progress
                  </AlertTitle>
                </>
              </Alert>
              <Card sx={{ overflowWrap: 'anywhere' }}>
                <CardHeader
                  title={`${questions[currentQuestion].title}`}
                  sx={{ color: '#233c8d' }}
                />
                <CardContent>
                  <FormControl sx={{ marginTop: 2 }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      // value={value}
                      onChange={(e) => onChange(e, questions[currentQuestion].id)}
                    >
                      {questions[currentQuestion].options.map((option) => (
                        <FormControlLabel
                          value={option.optionText}
                          control={<Radio />}
                          label={option.optionText}
                          key={option.optionText}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </CardContent>
                <CardActions sx={{ float: 'right' }}>
                  {lastQuestion ? (
                    <>
                      <Button size="small" disabled>
                        Last Question
                      </Button>

                      <Button size="small" onClick={() => submitAnswers()}>
                        Submit Answers
                      </Button>
                    </>
                  ) : (
                    <Button
                      size="small"
                      onClick={() => resetCount(currentQuestion + 1)}
                    >
                      Next
                    </Button>
                  )}
                </CardActions>
              </Card>
            </>
          </Grid>
          <Grid item md={1}></Grid>
        </>
      ) : null}
    </>
  );
}

export default ExamWithQuestions;
