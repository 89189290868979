import { BaseApi } from './BaseApi';

export const userApi = BaseApi.injectEndpoints({
  reducerPath: 'userApi',
  endpoints: (builder) => ({
    followers: builder.query({
      query: () => ({
        url: '/user/followers',
        method: 'GET',
      }),
      providesTags: ['Followers'],
    }),
    following: builder.query({
      query: () => ({
        url: '/user/following',
        method: 'GET',
      }),
      providesTags: ['Following'],
    }),
    followUser: builder.mutation({
      query: (details) => ({
        url: '/follow/user',
        method: 'POST',
        body: {
          id_to_follow: details.id,
        },
      }),
      invalidatesTags: ['Following'],
    }),

    userCourseReviews: builder.query({
      query: () => ({
        url: '/user/course/reviews',
        method: 'GET',
      }),
      providesTags: ['Course Reviews'],
    }),
    userExamReviews: builder.query({
      query: () => ({
        url: '/user/exam/reviews',
        method: 'GET',
      }),
      providesTags: ['Exam Reviews'],
    }),
    userTestReviews: builder.query({
      query: () => ({
        url: '/user/test/reviews',
        method: 'GET',
      }),
      providesTags: ['Test Reviews'],
    }),
    adminDashboardCounts: builder.query({
      query: () => ({
        url: '/admin/dashboard/counts',
        method: 'GET',
      }),
    }),
    educatorDashboardCounts: builder.query({
      query: () => ({
        url: '/educator/dashboard/counts',
        method: 'GET',
      }),
    }),
    institutionDashboardCounts: builder.query({
      query: () => ({
        url: '/institution/dashboard/counts',
        method: 'GET',
      }),
    }),
    studentDashboardCounts: builder.query({
      query: () => ({
        url: '/student/dashboard/counts',
        method: 'GET',
      }),
    }),
    followingEducators: builder.query({
      query: () => ({
        url: '/user/following/educators',
        method: 'GET',
      }),
      providesTags: ['Educator Following'],
    }),
    followingInstitutions: builder.query({
      query: () => ({
        url: '/user/following/institutions',
        method: 'GET',
      }),
      providesTags: ['Institution Following'],
    }),
    followingStudents: builder.query({
      query: () => ({
        url: '/user/following/students',
        method: 'GET',
      }),
      providesTags: ['Student Following'],
    }),
    followerEducators: builder.query({
      query: () => ({
        url: '/user/follower/educators',
        method: 'GET',
      }),
      providesTags: ['Educator Follower'],
    }),
    followerInstitutions: builder.query({
      query: () => ({
        url: '/user/follower/institutions',
        method: 'GET',
      }),
      providesTags: ['Institution Follower'],
    }),
    followerStudents: builder.query({
      query: () => ({
        url: '/user/follower/students',
        method: 'GET',
      }),
      providesTags: ['Student Follower'],
    }),
    unFollowUser: builder.mutation({
      query: (details) => ({
        url: `/unfollow/user/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Educator Following',
        'Institution Following',
        'Student Following',
        'Following',
      ],
    }),
  }),
});

export const {
  useFollowersQuery,
  useFollowingQuery,
  useFollowUserMutation,
  useUnFollowUserMutation,
  useUserCourseReviewsQuery,
  useUserExamReviewsQuery,
  useUserTestReviewsQuery,
  useAdminDashboardCountsQuery,
  useEducatorDashboardCountsQuery,
  useInstitutionDashboardCountsQuery,
  useStudentDashboardCountsQuery,
  useFollowingEducatorsQuery,
  useFollowingInstitutionsQuery,
  useFollowingStudentsQuery,
  useFollowerEducatorsQuery,
  useFollowerInstitutionsQuery,
  useFollowerStudentsQuery,
} = userApi;
