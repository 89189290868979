import { Grid, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useExamQuery } from '../../../app/services/ExamsApi';
import TabPanel from '../../../components/authentication/TabPanel';
import EditExam from './EditExam';
import EditExamQuestion from './EditExamQuestion';

function EditSingleExam() {
  const [value, setValue] = useState(0);
  const params = useParams();
  const { data, isSuccess } = useExamQuery({
    id: parseInt(params.Id, 10),
  });

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isSuccess && data.exam ? (
        <>
          <Grid container spacing={1} justifyContent={'center'}>
            <Grid item xs="11" sm="11" md="10" lg="10" xl="10">
              <Tabs
                value={value}
                onChange={(e, newval) => handleChange(e, newval)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Account forms"
              >
                <Tab
                  label="Edit Details"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
                <Tab
                  label="Edit Questions"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                  <EditExam exam={data.exam} />
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                  <EditExamQuestion examId={params.Id} exam={data.exam} />
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No exam found</p>
      )}
    </>
  );
}

export default EditSingleExam;
