import {
  Avatar,
  Box,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useEducatorDashboardCountsQuery } from '../../../app/services/UserApi';
import DataLoader from '../../../components/DataLoader';
import { useEffect, useState } from 'react';
import TabPanel from '../../../components/authentication/TabPanel';
import {
  useEducatorEarningsQuery,
  useEducatorProfileQuery,
} from '../../../app/services/EducatorApi';
import {
  People,
  PersonAdd,
  PlayArrow,
  RateReview,
  StarRate,
} from '@mui/icons-material';
import { BarChart } from '@mui/x-charts';
import CoursesManager from '../../courses/adminOrEducatorOrInstitution/CoursesManager';
import { useLocation, useNavigate } from 'react-router-dom';
import ExamsManager from '../../exams/adminOrEducatorOrInstitution/ExamsManager';
import { EducatorChannel } from './EducatorChannel';
import moment from 'moment';
import TestsManager from '../../tests/adminOrEducatorOrInstitution/TestsManager';

function EducatorDashboard() {
  const { isSignedIn, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const activeIndex = location?.state?.activeIndex;

  const [value, setValue] = useState(activeIndex ? activeIndex : 0);
  const [selectedChartValue, setSelectedChartValue] = useState('tillDate');
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const { data, isLoading, isSuccess } = useEducatorDashboardCountsQuery();
  const educator = useEducatorProfileQuery();
  const { data: educatorEarning } = useEducatorEarningsQuery();
  const chartSetting = {
    yAxis: [
      {
        label: 'Income',
      },
    ],
    // width: 500,
    height: 250,
  };
  const dataset = [
    {
      tillDate: 59,
      lastMonth: 57,
      currentMonth: 86,
      lastWithdrawal: 21,
      month: 'Jan',
    },
    {
      tillDate: 50,
      lastMonth: 52,
      currentMonth: 78,
      lastWithdrawal: 28,
      month: 'Feb',
    },
    {
      tillDate: 47,
      lastMonth: 53,
      currentMonth: 106,
      lastWithdrawal: 41,
      month: 'Mar',
    },
    {
      tillDate: 54,
      lastMonth: 56,
      currentMonth: 92,
      lastWithdrawal: 73,
      month: 'Apr',
    },
    {
      tillDate: 57,
      lastMonth: 69,
      currentMonth: 92,
      lastWithdrawal: 99,
      month: 'May',
    },
    {
      tillDate: 60,
      lastMonth: 63,
      currentMonth: 103,
      lastWithdrawal: 144,
      month: 'June',
    },
    {
      tillDate: 59,
      lastMonth: 60,
      currentMonth: 105,
      lastWithdrawal: 319,
      month: 'July',
    },
    {
      tillDate: 65,
      lastMonth: 60,
      currentMonth: 106,
      lastWithdrawal: 249,
      month: 'Aug',
    },
    {
      tillDate: 51,
      lastMonth: 51,
      currentMonth: 95,
      lastWithdrawal: 131,
      month: 'Sept',
    },
    {
      tillDate: 60,
      lastMonth: 65,
      currentMonth: 97,
      lastWithdrawal: 55,
      month: 'Oct',
    },
    {
      tillDate: 67,
      lastMonth: 64,
      currentMonth: 76,
      lastWithdrawal: 48,
      month: 'Nov',
    },
    {
      tillDate: 61,
      lastMonth: 70,
      currentMonth: 103,
      lastWithdrawal: 25,
      month: 'Dec',
    },
  ];

  const barChartLabels = {
    tillDate: 'Till Date',
    lastMonth: 'Last Month',
    currentMonth: 'Current Month',
    lastWithdrawal: 'Last Withdrawal',
  };
  const earningRanks = [];
  const templateObject = {
    rank: 1,
    name: 'Khan Sir',
    headline: 'UPSC Trainer',
  };

  // Loop to generate objects with different ranks
  for (let i = 1; i <= 24; i++) {
    const newObject = { ...templateObject, rank: i };
    earningRanks.push(newObject);
  }
  // const valueFormatter = (value) => `${value}mm`;
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  useEffect(() => {
    const newActiveIndex = location?.state?.activeIndex;
    if (
      typeof newActiveIndex === 'number' &&
      newActiveIndex !== null &&
      newActiveIndex !== undefined
    ) {
      // setValue(newValue);
      handleChange({}, newActiveIndex);
    }
  }, [location, location?.state]);

  const displayEducatorAvgCourseRating = (courses) => {
    let avgRating = 0;
    let noOfRatings = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        avgRating += Number(singleCourse.course_reviews_avg_rating);
        noOfRatings += Number(singleCourse.course_reviews_avg_rating) ? 1 : 0;
      });
      return Math.round((avgRating / noOfRatings) * 10) / 10;

      // return avgRating / noOfRatings;
    } else {
      return avgRating;
    }
  };

  const displayEducatorCourseEnrols = (courses) => {
    let enrols = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        enrols += Number(singleCourse.course_enrols_count);
      });
      return enrols;
    } else {
      return enrols;
    }
  };

  const displayReviewCounts = (courses) => {
    let reviewCounts = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        reviewCounts += Number(singleCourse.course_reviews_count);
      });
      return reviewCounts;
    } else {
      return reviewCounts;
    }
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <>
      {isSuccess && data ? (
        <Grid container spacing={2}>
          {/* <Grid
            item
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            sx={{
              minHeight: '200px',
              background: '#0a6d84',
              alignSelf: 'center',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                textAlign: 'center',
                alignSelf: 'center',
                width: '100%',
                fontSize: '24px',
                fontWeight: 700,
              }}
            >
              {`Welcome ${
                educator?.data
                  ? educator?.data?.profile?.first_name
                    ? educator?.data?.profile?.first_name
                    : educator?.data?.user?.name
                  : '#'
              } to`}
              <br />
              Teacher&apos;s Hub
            </Typography>
          </Grid>
          <Grid
            item
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            sx={{
              minHeight: '200px',
              backgroundImage: 'url(/teacher-dashbooard.webp)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          ></Grid> */}
          <Grid
            item
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            sx={{
              minHeight: '80px',
              background: '#09768d',
              paddingTop: 0,
            }}
            padding={'0rem'}
            className="padding-0"
          >
            <Grid
              container
              justifyContent={'center'}
              className="custom-tab-parent-container"
              sx={{ maxHeight: '60px' }}
            >
              <Grid item xl="10" lg="10" md="11" sm="12" xs="12">
                <Tabs
                  value={value}
                  onChange={(e, newval) => {
                    handleChange(e, newval);
                    navigate(location.pathname, {
                      state: { activeIndex: newval }, // Update state without changing the URL
                    });
                  }}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="Account forms"
                  className="custom-dashboard-tab-orange"
                  sx={{ height: '100%', textTransform: 'capitalize' }}
                  scrollButtons={true}
                  allowScrollButtonsMobile
                >
                  <Tab
                    label="Dashboard"
                    id="simple-tab-0"
                    aria-controls="simple-tabpanel-0"
                    sx={{ textTransform: 'capitalize' }}
                  />
                  <Tab
                    label="Manage Courses"
                    id="simple-tab-0"
                    aria-controls="simple-tabpanel-0"
                    sx={{ textTransform: 'capitalize' }}
                  />
                  <Tab
                    label="Manage Exams"
                    id="simple-tab-1"
                    aria-controls="simple-tabpanel-1"
                    sx={{ textTransform: 'capitalize' }}
                  />
                  <Tab
                    label="Manage Tests"
                    id="simple-tab-1"
                    aria-controls="simple-tabpanel-1"
                    sx={{ textTransform: 'capitalize' }}
                  />
                  <Tab
                    label="My Channel"
                    id="simple-tab-1"
                    aria-controls="simple-tabpanel-1"
                    sx={{ textTransform: 'capitalize' }}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ background: '#fff' }}>
              <Grid item xl="10" lg="10" md="11" sm="12" xs="12">
                <TabPanel value={value} index={0}>
                  <Grid container>
                    <Grid
                      item
                      xl="3"
                      lg="3"
                      md="12"
                      sm="12"
                      xs="12"
                      // sx={{ background: '#fff' }}
                    >
                      <Box
                        sx={{
                          padding: '2rem 1rem',
                          justifyContent: 'center',
                          display: 'flex',
                          justifyItems: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          background:
                            'linear-gradient(180deg, #86d5f4 30%, white 70%)',
                          borderRadius: '0px 0px 20px 20px',
                          marginTop: '1rem',
                          boxShadow: '1px 1px 10px 1px #dcdcdc',
                        }}
                      >
                        <Avatar
                          src={
                            educator?.data?.profile?.image_url
                              ? educator?.data?.profile?.image_url
                              : null
                          }
                          alt={
                            isSignedIn
                              ? educator?.data?.profile?.image_url
                                ? null
                                : user?.name?.charAt(0).toUpperCase()
                              : null
                          }
                          sx={{ height: '150px', width: '150px', marginTop: '6rem' }}
                        >
                          {/* A */}
                        </Avatar>
                        <Typography
                          sx={{
                            color: '#57494a',
                            fontSize: '26px',
                            fontWeight: 700,
                            textAlign: 'center',
                            marginTop: '1rem',
                          }}
                        >
                          {' '}
                          {`${
                            educator?.data
                              ? educator?.data?.profile?.first_name
                                ? educator?.data?.profile?.first_name
                                : educator?.data?.user?.name
                              : '#'
                          } ${
                            educator?.data
                              ? educator?.data?.profile?.last_name
                                ? educator?.data?.profile?.last_name
                                : null
                              : '#'
                          }`}
                        </Typography>
                        {educator?.data?.profile?.headline && (
                          <Typography
                            sx={{
                              color: '#57494a',
                              fontSize: '20px',
                              fontWeight: 550,
                              textAlign: 'center',
                            }}
                          >
                            {`${educator?.data?.profile?.headline}`}
                          </Typography>
                        )}
                        <span
                          style={{
                            borderTop: '2px solid',
                            width: '100%',
                            marginTop: '1rem',
                          }}
                        ></span>
                        <div
                          style={{
                            width: 'calc(100% - 20px)',
                            padding: '0.5rem 1rem 0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            background: '#bfdae1',
                            borderRadius: '8px',
                            marginTop: '1rem',
                          }}
                        >
                          <Typography sx={{ color: '#57494a', fontWeight: 600 }}>
                            Proile Status
                          </Typography>
                          <Typography sx={{ color: '#ee3163' }}>Active</Typography>
                        </div>
                        <div
                          style={{
                            width: 'calc(100% - 20px)',
                            padding: '0.5rem 1rem 0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            background: '#bfdae1',
                            borderRadius: '8px',
                            marginTop: '1rem',
                          }}
                        >
                          <Typography sx={{ color: '#57494a', fontWeight: 600 }}>
                            Joining Date
                          </Typography>
                          <Typography sx={{ color: '#ee3163' }}>
                            {/* {new Date(
                              educator?.data?.user?.created_at
                            ).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}{' '} */}
                            {moment(educator?.data?.user?.created_at).format(
                              'Do MMM YY'
                            )}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: 'calc(100% - 20px)',
                            padding: '0.5rem 1rem 0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            background: '#bfdae1',
                            borderRadius: '8px',
                            marginTop: '1rem',
                          }}
                        >
                          <Typography sx={{ color: '#57494a', fontWeight: 600 }}>
                            Last Logged In
                          </Typography>
                          <Typography sx={{ color: '#ee3163' }}>
                            {' '}
                            {new Date(
                              educator?.data?.user?.created_at
                            ).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}{' '}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: 'calc(100% - 20px)',
                            padding: '0.5rem 1rem 0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            background: '#bfdae1',
                            borderRadius: '8px',
                            marginTop: '1rem',
                          }}
                        >
                          <Typography sx={{ color: '#57494a', fontWeight: 600 }}>
                            Subscription Plan
                          </Typography>
                          <Typography sx={{ color: '#ee3163' }}>
                            Rs. 14,999
                          </Typography>
                        </div>
                      </Box>
                      <Box
                        sx={{
                          // padding: '1rem',
                          justifyContent: 'center',
                          display: 'flex',
                          justifyItems: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginTop: '1rem',
                          background: '#fff',
                          borderRadius: '20px',
                          boxShadow: '1px 1px 10px 1px #dcdcdc',
                        }}
                      >
                        <div
                          style={{
                            background: '#86d5f4',
                            width: '100%',
                            borderRadius: '20px 20px 0px 0px',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#020000',
                              fontSize: '22px',
                              fontWeight: 600,
                              textAlign: 'center',
                              paddingTop: '0.5rem',
                              paddingBottom: '0.5rem',
                            }}
                          >
                            Community Engagement
                          </Typography>
                        </div>
                        <Box
                          sx={{
                            // width: 'calc(100% - 20px)',
                            padding: '1rem',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '0.5rem',
                                  justifyContent: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                    background: '#ed1848',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                  }}
                                >
                                  <PersonAdd
                                    sx={{
                                      color: '#fff',
                                    }}
                                  />
                                </div>
                                <Typography
                                  sx={{
                                    margin: '0rem',
                                    color: '#ed1848',
                                    textAlign: 'center',
                                  }}
                                >
                                  {data?.totalFollowing}
                                  <br /> Following
                                </Typography>
                              </div>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '0.5rem',
                                  justifyContent: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                    background: '#113559',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                  }}
                                >
                                  <People
                                    sx={{
                                      color: '#fff',
                                    }}
                                  />
                                </div>
                                <Typography
                                  sx={{
                                    margin: '0rem',
                                    color: '#113559',
                                    textAlign: 'center',
                                  }}
                                >
                                  {data?.totalFollowers} <br /> Followers
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                                // gap: '0.5rem',
                              }}
                            >
                              <Typography
                                sx={{
                                  display: 'flex',
                                  gap: '0.5rem',
                                  alignItems: 'center',
                                  color: '#113559',
                                  width: '50%',
                                  textAlign: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <StarRate />
                                {Array.isArray(
                                  educator?.data?.info
                                    ?.educator_course_enrols_and_reviews
                                ) &&
                                educator?.data?.info
                                  ?.educator_course_enrols_and_reviews.length > 0
                                  ? displayEducatorAvgCourseRating(
                                      educator?.data?.info
                                        ?.educator_course_enrols_and_reviews
                                    )
                                  : 'No'}
                                &nbsp; Rating
                              </Typography>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  gap: '0.5rem',
                                  alignItems: 'center',
                                  color: '#113559',
                                  width: '50%',
                                  textAlign: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <RateReview />
                                {educator?.data?.info
                                  ?.educator_course_enrols_and_reviews
                                  ? displayReviewCounts(
                                      educator?.data?.info
                                        ?.educator_course_enrols_and_reviews
                                    )
                                  : 'No'}
                                &nbsp; Reviews
                              </Typography>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  gap: '0.5rem',
                                  alignItems: 'center',
                                  color: '#113559',
                                  width: '50%',
                                  justifyContent: 'center',
                                }}
                              >
                                <People />
                                {educator?.data?.info
                                  ?.educator_course_enrols_and_reviews
                                  ? displayEducatorCourseEnrols(
                                      educator?.data?.info
                                        ?.educator_course_enrols_and_reviews
                                    )
                                  : 'No'}
                                &nbsp; Students
                              </Typography>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  gap: '0.5rem',
                                  alignItems: 'center',
                                  color: '#113559',
                                  width: '50%',
                                  justifyContent: 'center',
                                }}
                              >
                                <PlayArrow />
                                {educator?.data?.info
                                  ?.educator_course_enrols_and_reviews
                                  ? educator?.data?.info
                                      ?.educator_course_enrols_and_reviews?.length
                                  : 'No'}
                                &nbsp; Courses
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xl="9" lg="9" md="12" sm="12" xs="12">
                      <Box
                        sx={{
                          marginTop: '1rem',
                          marginLeft: '1rem',
                          height: '200px',
                          width: '100%',
                          boxShadow: '1px 1px 10px 1px #dcdcdc',
                        }}
                      >
                        <img
                          src="/home/Add4.png"
                          style={{ width: '100%', height: '100%' }}
                        />
                      </Box>
                      <Box
                        sx={{
                          background: '#fff',
                          marginTop: '1rem',
                          marginLeft: '1rem',
                          borderRadius: '20px',
                          boxShadow: '1px 1px 10px 1px #dcdcdc',
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{
                            borderRadius: '0px 0px 20px 20px',
                          }}
                        >
                          <Table
                            sx={{ minWidth: '100%', marginTop: '1rem' }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: '#020000',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    // background: '#86d5f4',
                                    // borderRadius: '20px 0px 0px 20px',
                                    padding: '0rem',
                                    paddingLeft: '0.5rem',
                                  }}
                                >
                                  <div
                                    style={{
                                      color: '#020000',
                                      fontSize: '16px',
                                      fontWeight: 700,
                                      background: '#86d5f4',
                                      borderRadius: '20px 0px 0px 20px',
                                      padding: '0.5rem',
                                    }}
                                  >
                                    My Work
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: '#020000',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    width: '100px',
                                    background: '#86d5f4',
                                    padding: '0.5rem',
                                  }}
                                >
                                  Published
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: '#020000',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    width: '100px',
                                    background: '#86d5f4',
                                    padding: '0.5rem',
                                  }}
                                >
                                  Draft
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: '#020000',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    width: '100px',
                                    background: '#86d5f4',
                                    padding: '0.5rem',
                                  }}
                                >
                                  On Hold
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color: '#020000',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    padding: '0rem',
                                    paddingRight: '0.5rem',
                                  }}
                                >
                                  <div
                                    style={{
                                      color: '#020000',
                                      fontSize: '16px',
                                      fontWeight: 700,
                                      background: '#86d5f4',
                                      borderRadius: '0px 20px 20px 0px',
                                      padding: '0.5rem',
                                    }}
                                  >
                                    Rejected
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="">
                              {[
                                createData(
                                  'Self Paced Courses',
                                  data?.totalPublishedCourses,
                                  data?.totalDraftCourses,
                                  data?.totalOnHoldCourses,
                                  data?.totalRejectedCourses
                                ),
                                createData('Live Courses', '-', '-', '-', '-'),
                                createData(
                                  'Exam',
                                  data?.totalPublishedExams,
                                  data?.totalDraftExams,
                                  data?.totalOnHoldExams,
                                  data?.totalRejectedExams
                                ),
                                createData('Questions Answered', '-', '-', '-', '-'),
                                createData(
                                  'Announcements & Notification',
                                  '-',
                                  '-',
                                  '-',
                                  '-'
                                ),
                              ].map((row) => (
                                <TableRow
                                  key={row.name}
                                  sx={
                                    {
                                      // '&:last-child td, &:last-child th': {
                                      //   border: 0,
                                      // },
                                      // background: '#bfdae1',
                                    }
                                  }
                                  // className="custom-mui-table-row"
                                >
                                  <TableCell style={{ padding: '0.5rem' }}>
                                    <div
                                      style={{
                                        background: '#bfdae1',
                                        width: '100%',
                                        borderRadius: '8px',
                                        padding: '0.5rem 0rem',
                                      }}
                                    >
                                      <span style={{ marginLeft: '1rem' }}>
                                        {row.name}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ padding: '0.5rem' }}
                                  >
                                    <div
                                      style={{
                                        background: '#bfdae1',
                                        width: '100%',
                                        borderRadius: '8px',
                                        padding: '0.5rem 0rem',
                                      }}
                                    >
                                      {row.calories}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ padding: '0.5rem' }}
                                  >
                                    <div
                                      style={{
                                        background: '#bfdae1',
                                        width: '100%',
                                        borderRadius: '8px',
                                        padding: '0.5rem 0rem',
                                      }}
                                    >
                                      {row.fat}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ padding: '0.5rem' }}
                                  >
                                    <div
                                      style={{
                                        background: '#bfdae1',
                                        width: '100%',
                                        borderRadius: '8px',
                                        padding: '0.5rem 0rem',
                                      }}
                                    >
                                      {row.carbs}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ padding: '0.5rem' }}
                                  >
                                    <div
                                      style={{
                                        background: '#bfdae1',
                                        // width: '100%',
                                        borderRadius: '8px',
                                        padding: '0.5rem 0rem',
                                      }}
                                    >
                                      {row.protein}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Grid container sx={{ marginTop: '1rem' }}>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                          <Box
                            sx={{
                              background: '#fff',
                              // marginTop: '1rem',
                              borderRadius: '20px',
                              marginLeft: '1rem',
                              boxShadow: '1px 1px 10px 1px #dcdcdc',
                            }}
                          >
                            <div
                              style={{
                                background: '#86d5f4',
                                padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                                borderRadius: '20px 20px 0px 0px',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#020000',
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                Best Rank
                              </Typography>
                            </div>
                            <div
                              style={{
                                padding: '0.5rem',
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: 'center',
                                  color: '#10496a',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                }}
                              >
                                10th <br />
                                in Jan 2024
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                          <Box
                            sx={{
                              background: '#fff',
                              // marginTop: '1rem',
                              borderRadius: '20px',
                              marginLeft: '1rem',
                              boxShadow: '1px 1px 10px 1px #dcdcdc',
                            }}
                          >
                            <div
                              style={{
                                background: '#86d5f4',
                                padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                                borderRadius: '20px 20px 0px 0px',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#020000',
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                Present Rank
                              </Typography>
                            </div>
                            <div
                              style={{
                                padding: '0.5rem',
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: 'center',
                                  color: '#10496a',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                }}
                              >
                                10th <br />
                                on 24th June 2024
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '1rem' }}>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                          <Box
                            sx={{
                              background: '#fff',
                              // marginTop: '1rem',
                              borderRadius: '20px',
                              marginLeft: '1rem',
                              boxShadow: '1px 1px 10px 1px #dcdcdc',
                            }}
                          >
                            <div
                              style={{
                                background: '#86d5f4',
                                padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                                borderRadius: '20px 20px 0px 0px',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#020000',
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                Student Enrolled
                              </Typography>
                            </div>
                            <div
                              style={{
                                padding: '0.5rem',
                              }}
                            >
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap',
                                  color: '#09768d',
                                  fontSize: '14px',
                                }}
                              >
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '60%',
                                    textAlign: 'center',
                                  }}
                                >
                                  Recorded Courses
                                </span>
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '30%',
                                    textAlign: 'center',
                                  }}
                                >
                                  -
                                </span>
                              </Typography>
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap',
                                  color: '#09768d',
                                  fontSize: '14px',
                                  marginTop: '0.5rem',
                                }}
                              >
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '60%',
                                    textAlign: 'center',
                                  }}
                                >
                                  Live Courses
                                </span>
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '30%',
                                    textAlign: 'center',
                                  }}
                                >
                                  -
                                </span>
                              </Typography>
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap',
                                  color: '#09768d',
                                  fontSize: '14px',
                                  marginTop: '0.5rem',
                                }}
                              >
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '60%',
                                    textAlign: 'center',
                                  }}
                                >
                                  Exams
                                </span>
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '30%',
                                    textAlign: 'center',
                                  }}
                                >
                                  -
                                </span>
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                          <Box
                            sx={{
                              background: '#fff',
                              // marginTop: '1rem',
                              marginLeft: '1rem',
                              height: '100%',
                              borderRadius: '20px',
                              boxShadow: '1px 1px 10px 1px #dcdcdc',
                            }}
                          >
                            <div
                              style={{
                                background: '#86d5f4',
                                padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                                borderRadius: '20px 20px 0px 0px',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#020000',
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  textAlign: 'center',
                                }}
                              >
                                Course Type
                              </Typography>
                            </div>
                            <div
                              style={{
                                padding: '0.5rem',
                              }}
                            >
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap',
                                  color: '#09768d',
                                  fontSize: '14px',
                                }}
                              >
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '60%',
                                    textAlign: 'center',
                                  }}
                                >
                                  Free Courses
                                </span>
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '30%',
                                    textAlign: 'center',
                                  }}
                                >
                                  {data?.totalFreeCourses
                                    ? data?.totalFreeCourses
                                    : 0}
                                </span>
                              </Typography>
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap',
                                  color: '#09768d',
                                  marginTop: '0.5rem',
                                  fontSize: '14px',
                                }}
                              >
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '60%',
                                    textAlign: 'center',
                                  }}
                                >
                                  Premium Courses
                                </span>
                                <span
                                  style={{
                                    background: '#bfdae1',
                                    padding: '0.3rem',
                                    borderRadius: '8px',
                                    width: '30%',
                                    textAlign: 'center',
                                  }}
                                >
                                  {data?.totalPremiumCourses
                                    ? data?.totalPremiumCourses
                                    : 0}
                                </span>
                              </Typography>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                      <Box
                        sx={{
                          background: '#fff',
                          // marginTop: '1rem',
                          // marginLeft: '1rem',
                          marginBottom: '1rem',
                          borderRadius: '20px',
                          boxShadow: '1px 1px 10px 1px #dcdcdc',
                        }}
                      >
                        <div
                          style={{
                            background: '#86d5f4',
                            padding: '1rem',
                            borderRadius: '20px 20px 0px 0px',
                            marginTop: '1rem',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#020000',
                              fontSize: '16px',
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            My Earnings
                          </Typography>
                        </div>
                        <div style={{ padding: '0rem 1rem 1rem 1rem' }}>
                          <div className="comming-soon-parent">
                            <div className="comming-soon-text">Coming Soon</div>
                            <BarChart
                              dataset={dataset}
                              xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                              series={[
                                {
                                  dataKey: selectedChartValue,
                                  label: barChartLabels[selectedChartValue],
                                  // valueFormatter,
                                },
                              ]}
                              {...chartSetting}
                            />
                          </div>
                          <div>
                            <Grid container spacing={2}>
                              <Grid item xl="3" lg="3" md="6" sm="6" xs="6">
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '80px',
                                    background:
                                      selectedChartValue === 'tillDate'
                                        ? '#ee3163'
                                        : '#fff',
                                    boxShadow: ' 1px 5px 15px 0px #80808080',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setSelectedChartValue('tillDate');
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        selectedChartValue === 'tillDate'
                                          ? '#fff'
                                          : '#0d364e',
                                      fontWeight: '700',
                                      textAlign: 'center',
                                    }}
                                  >
                                    Earnings Till Date
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color:
                                        selectedChartValue === 'tillDate'
                                          ? '#fff'
                                          : '#0d364e',
                                      fontWeight: '700',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {`RS . ${
                                      educatorEarning?.totalCourseEarnings ||
                                      0 + educatorEarning?.totalExamEarnings ||
                                      0 + educatorEarning?.totalTestEarnings ||
                                      0
                                    }`}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xl="3" lg="3" md="6" sm="6" xs="6">
                                <div className="comming-soon-parent">
                                  <div className="comming-soon-text">
                                    Coming Soon
                                  </div>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: '80px',
                                      background:
                                        selectedChartValue === 'lastMonth'
                                          ? '#ee3163'
                                          : '#fff',
                                      boxShadow: ' 1px 5px 15px 0px #80808080',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setSelectedChartValue('lastMonth');
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          selectedChartValue === 'lastMonth'
                                            ? '#fff'
                                            : '#0d364e',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                      }}
                                    >
                                      Last Month
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          selectedChartValue === 'lastMonth'
                                            ? '#fff'
                                            : '#0d364e',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                      }}
                                    >
                                      RS . 150,000.00
                                    </Typography>
                                  </Box>
                                </div>
                              </Grid>
                              <Grid item xl="3" lg="3" md="6" sm="6" xs="6">
                                <div className="comming-soon-parent">
                                  <div className="comming-soon-text">
                                    Coming Soon
                                  </div>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: '80px',
                                      background:
                                        selectedChartValue === 'currentMonth'
                                          ? '#ee3163'
                                          : '#fff',
                                      boxShadow: ' 1px 5px 15px 0px #80808080',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setSelectedChartValue('currentMonth');
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          selectedChartValue === 'currentMonth'
                                            ? '#fff'
                                            : '#0d364e',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                      }}
                                    >
                                      Current Month
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          selectedChartValue === 'currentMonth'
                                            ? '#fff'
                                            : '#0d364e',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                      }}
                                    >
                                      RS . 150,000.00
                                    </Typography>
                                  </Box>
                                </div>
                              </Grid>
                              <Grid item xl="3" lg="3" md="6" sm="6" xs="6">
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '80px',
                                    background:
                                      selectedChartValue === 'lastWithdrawal'
                                        ? '#ee3163'
                                        : '#fff',
                                    boxShadow: ' 1px 5px 15px 0px #80808080',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setSelectedChartValue('lastWithdrawal');
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        selectedChartValue === 'lastWithdrawal'
                                          ? '#fff'
                                          : '#0d364e',
                                      fontWeight: '700',
                                      textAlign: 'center',
                                    }}
                                  >
                                    Payment Withdrawal
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color:
                                        selectedChartValue === 'lastWithdrawal'
                                          ? '#fff'
                                          : '#0d364e',
                                      fontWeight: '700',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {`RS . ${
                                      educatorEarning?.totalAmountWithdrawals || 0
                                    }`}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                      <div className="comming-soon-parent">
                        <div className="comming-soon-text">Coming Soon</div>
                        <Box
                          sx={{
                            background: '#fff',
                            // marginTop: '1rem',
                            // marginLeft: '1rem',
                            marginBottom: '1rem',
                            borderRadius: '20px',
                            boxShadow: '1px 1px 10px 1px #dcdcdc',
                          }}
                        >
                          <div
                            style={{
                              background: '#86d5f4',
                              padding: '1rem',
                              borderRadius: '20px 20px 0px 0px',
                              marginTop: '1rem',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#020000',
                                fontSize: '16px',
                                fontWeight: 700,
                                textAlign: 'center',
                              }}
                            >
                              Leader Board For June 2024
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', gap: '0.3rem' }}>
                            <div
                              style={{
                                width: '220px',
                                minWidth: '220px',
                                height: '120px',
                                display: 'flex',
                                alignSelf: 'center',
                                textAlign: 'center',
                                justifyContent: 'center',
                                background: '#fde9b0',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <Typography
                                  sx={{
                                    color: '#57494a',
                                    fontSize: '20px',
                                    fontWeight: 700,
                                  }}
                                >
                                  By Earnings
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                height: '120px',
                                display: 'flex',
                                overflow: 'hidden',
                                background: '#fde9b0',
                              }}
                            >
                              {earningRanks.map((item, ind) => (
                                <Box
                                  key={'rank' + ind}
                                  sx={{
                                    // display: 'grid',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyItems: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100px',
                                    minWidth: '100px',
                                    borderRight: '2px solid #fff',
                                  }}
                                >
                                  <Avatar
                                    alt={item.name.charAt()}
                                    src="/static/images/avatar/2.jpg"
                                    sx={{ height: '50px', width: '50px' }}
                                  />
                                  <Typography
                                    sx={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: '#a39c9c',
                                      fontSize: '12px',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {item.headline}
                                  </Typography>
                                </Box>
                              ))}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.3rem',
                              marginTop: '0.3rem',
                            }}
                          >
                            <div
                              style={{
                                width: '220px',
                                minWidth: '220px',
                                height: '120px',
                                display: 'flex',
                                alignSelf: 'center',
                                textAlign: 'center',
                                justifyContent: 'center',
                                background: '#bdf1b3',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#57494a',
                                  fontSize: '20px',
                                  fontWeight: 700,
                                }}
                              >
                                By Enrollments
                              </Typography>
                            </div>
                            <div
                              style={{
                                height: '120px',
                                display: 'flex',
                                overflow: 'hidden',
                                background: '#bdf1b3',
                              }}
                            >
                              {earningRanks.map((item, ind) => (
                                <Box
                                  key={'rank' + ind}
                                  sx={{
                                    // display: 'grid',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyItems: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100px',
                                    minWidth: '100px',
                                    borderRight: '2px solid #fff',
                                  }}
                                >
                                  <Avatar
                                    alt={item.name.charAt()}
                                    src="/static/images/avatar/2.jpg"
                                    sx={{ height: '50px', width: '50px' }}
                                  />
                                  <Typography
                                    sx={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: '#a39c9c',
                                      fontSize: '12px',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {item.headline}
                                  </Typography>
                                </Box>
                              ))}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.3rem',
                              marginTop: '0.3rem',
                            }}
                          >
                            <div
                              style={{
                                width: '220px',
                                minWidth: '220px',
                                height: '120px',
                                display: 'flex',
                                alignSelf: 'center',
                                textAlign: 'center',
                                justifyContent: 'center',
                                background: '#9edcdc',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#57494a',
                                  fontSize: '20px',
                                  fontWeight: 700,
                                }}
                              >
                                By Contribution
                              </Typography>
                            </div>
                            <div
                              style={{
                                height: '120px',
                                display: 'flex',
                                overflow: 'hidden',
                                background: '#9edcdc',
                              }}
                            >
                              {earningRanks.map((item, ind) => (
                                <Box
                                  key={'rank' + ind}
                                  sx={{
                                    // display: 'grid',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyItems: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100px',
                                    minWidth: '100px',
                                    borderRight: '2px solid #fff',
                                  }}
                                >
                                  <Avatar
                                    alt={item.name.charAt()}
                                    src="/static/images/avatar/2.jpg"
                                    sx={{ height: '50px', width: '50px' }}
                                  />
                                  <Typography
                                    sx={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: '#a39c9c',
                                      fontSize: '12px',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      width: '100%',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {item.headline}
                                  </Typography>
                                </Box>
                              ))}
                            </div>
                          </div>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box sx={{ background: '#fff', padding: '0.5rem' }}>
                    <CoursesManager />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box
                    sx={{
                      background: '#fff',
                      paddingRight: '1rem',
                      paddingLeft: '1rem',
                      mt: 2,
                    }}
                  >
                    <ExamsManager />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box
                    sx={{
                      background: '#fff',
                      paddingRight: '1rem',
                      paddingLeft: '1rem',
                      mt: 2,
                    }}
                  >
                    <TestsManager />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Box
                    sx={{
                      background: '#fff',
                      paddingRight: '1rem',
                      paddingLeft: '1rem',
                    }}
                  >
                    <EducatorChannel
                      xsValue="12"
                      smValue="12"
                      mdValue="12"
                      lgValue="12"
                      xlValue="12"
                      educatorID={educator?.data?.user.id}
                    />
                  </Box>
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography>No Dashboard</Typography>
      )}
    </>
  );
}

export default EducatorDashboard;
