export const allLanguages = [
  'Assamese',
  'Bengali',
  'Bodo',
  'Dogri',
  'English',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Kashmiri',
  'Konkani',
  'Maithili',
  'Malayalam',
  'Marathi',
  'Meitei',
  'Nepali',
  'Odia',
  'Punjabi',
  'Sanskrit',
  'Santali',
  'Sindhi',
  'Tamil',
  'Telugu',
  'Urdu',
];

export const getFileNameFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export function getOrigin() {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  } else {
    // Fallback or default origin (can be modified based on your needs)
    return 'http://localhost:3000';
  }
}

export const validatePhoneNumber = (phoneNumber) => {
  // Regular expression to match exactly 10 digits, no spaces or special characters
  const phoneRegex = /^\d{0,10}$/;
  return phoneRegex.test(phoneNumber);
};

export const handleRedirectToCourseWithCategoryTitle = (allCategories, course) => {
  const allFinalCategories = allCategories;
  let selectedSubCategory = null;
  const selectedCategory = Array.isArray(allFinalCategories)
    ? allFinalCategories.find((singleCategory) =>
        singleCategory?.published_sub_categories.find(
          (singleSubCategory) => singleSubCategory.id == course.sub_category_id
        )
      )
    : null;
  if (selectedCategory) {
    selectedSubCategory = Array.isArray(selectedCategory?.published_sub_categories)
      ? selectedCategory?.published_sub_categories.find(
          (singleCategory) => singleCategory.id == course.sub_category_id
        )
      : null;
  }
  if (selectedCategory && selectedCategory) {
    window.location.pathname = `/${selectedCategory?.title}/${selectedSubCategory.title}/course/${course?.id}`;
  } else {
    return;
  }
};

export const genericDisplayTopicCount = (subjects) => {
  let topicsCount = 0;
  if (subjects.length >= 1) {
    subjects.forEach(
      (singleSubject) => (topicsCount += singleSubject.topics.length)
    );
    return topicsCount;
  } else {
    return topicsCount;
  }
};

export const convertToTimeFormat = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}h ${minutes}m ${remainingSeconds}s`;
};

export const courseDurationFormater = (time, moment) => {
  if (!time) return '00:00:00';
  let formattedTime = '';
  if (moment) {
    const totalSeconds = time;
    const duration = moment.duration(totalSeconds, 'seconds');

    if (totalSeconds > 0) {
      formattedTime = `${Math.floor(duration.asHours())
        .toString()
        .padStart(2, '0')}:${duration
        .minutes()
        .toString()
        .padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
    }
  }

  return formattedTime;
};

export const getLimitedReducedText = (text, textLimit) => {
  if (text) {
    const finalText =
      text.length > textLimit ? text.substring(0, textLimit) + '...' : text;
    return finalText;
  } else {
    return true;
  }
};

export const getTotalEnrollmentCount = (plan, data) => {
  let totalCount = 0;
  let filteredCourses = [];
  if (Array.isArray(data?.courses)) {
    if (plan == 'free') {
      filteredCourses = data?.courses.filter(
        (elem) => elem.cost == 0 || elem.cost == null
      );
    } else if (plan == 'premium') {
      filteredCourses = data?.courses.filter((elem) => elem.cost > 0);
    } else {
      filteredCourses = data?.courses;
    }
  }
  if (Array.isArray(filteredCourses)) {
    totalCount = filteredCourses.reduce(
      (acc, cuu) => acc + (cuu?.course_enrols_count || 0),
      0
    );
  }
  return totalCount;
};

export const getTotalWatchHourseType = (data, plan, isAvarage) => {
  let totalWatchTime = 0;
  let filteredCourses = [];

  if (Array.isArray(data?.courses)) {
    if (plan === 'free') {
      filteredCourses = data.courses.filter(
        (elem) => elem.cost == 0 || elem.cost == null
      );
    } else if (plan === 'premium') {
      filteredCourses = data.courses.filter((elem) => elem.cost > 0);
    } else {
      filteredCourses = data.courses;
    }
  }

  // If no courses are available, return 0 early
  if (filteredCourses.length === 0) {
    return 0;
  }

  let allEnrolls = filteredCourses.flatMap((course) => course.course_enrols);

  if (Array.isArray(allEnrolls)) {
    totalWatchTime = allEnrolls.reduce((sum, enrol) => {
      if (enrol.video_progress) {
        try {
          const progressArray = JSON.parse(enrol.video_progress);
          const totalForUser = progressArray.reduce(
            (acc, item) => acc + item.timeStamp,
            0
          );
          return sum + totalForUser;
        } catch (error) {
          console.error('Error parsing video_progress:', error);
        }
      }
      return sum;
    }, 0);
  }

  // Ensure totalWatchTime is a valid number
  totalWatchTime = Number(totalWatchTime) || 0;

  if (isAvarage) {
    return filteredCourses.length > 0
      ? (totalWatchTime / filteredCourses.length).toFixed()
      : 0; // Ensure we return 0 instead of NaN
  } else {
    return totalWatchTime;
  }
};

export const calculateOverallRatingforCourse = (plan, data) => {
  let filteredCourses = [];
  if (Array.isArray(data?.courses)) {
    if (plan == 'free') {
      let finalCourses = data?.courses.filter(
        (elem) => elem.cost == 0 || elem.cost == null
      );
      if (Array.isArray(filteredCourses)) {
        filteredCourses = finalCourses || [];
      }
    } else if (plan == 'premium') {
      let finalCourses1 = data?.courses.filter((elem) => elem.cost > 0);
      if (Array.isArray(finalCourses1)) {
        filteredCourses = finalCourses1 || [];
      }
    } else {
      filteredCourses = data?.courses;
    }
  }
  const allReviews = filteredCourses.flatMap(
    (course) => course?.course_reviews || []
  );
  const totalReviews = allReviews.length || 0;
  const averageRating =
    totalReviews > 0
      ? allReviews.reduce((sum, review) => sum + parseFloat(review?.rating), 0) /
        totalReviews
      : 0;

  return {
    averageRating: averageRating.toFixed(2),
    totalReviews,
  };
};
