import { Grid, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useCourseQuery } from '../../../app/services/CoursesApi';

import { EditCourse } from './EditCourse';
import TabPanel from '../../../components/authentication/TabPanel';
import EditCourseSubject from './EditCourseSubject';
import { EditLiveCourse } from './EditLiveCourse';
import EditLiveCourseSubject from './EditLiveCourseSubject';

function EditSingleCourse() {
  const [value, setValue] = useState(0);
  const params = useParams();
  const { data, isSuccess } = useCourseQuery({
    id: parseInt(params.Id, 10),
  });

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isSuccess && data.course ? (
        <>
          <Grid container spacing={1} justifyContent={'center'}>
            <Grid item xs="11" sm="11" md="10" lg="10" xl="10">
              <Tabs
                value={value}
                onChange={(e, newval) => handleChange(e, newval)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Account forms"
              >
                <Tab
                  label="Edit Details"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
                <Tab
                  label="Edit Curriculum"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                  {data.course?.start_date ? (
                    <EditLiveCourse course={data.course} />
                  ) : (
                    <EditCourse course={data.course} />
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                  {data.course?.start_date ? (
                    <EditLiveCourseSubject
                      courseId={params.Id}
                      course={data.course}
                    />
                  ) : (
                    <EditCourseSubject courseId={params.Id} course={data.course} />
                  )}
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No course found</p>
      )}
    </>
  );
}

export default EditSingleCourse;
