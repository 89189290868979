import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
// import GaugeIndicator from './GaugeIndicator';
import { Gauge } from '@mui/x-charts/Gauge';
// import { CustomDateRange } from './CustomDateRange';
import { useEducatorCoursesAndReviewsQuery } from '../../../app/services/EducatorApi';
import moment from 'moment';
import { getLimitedReducedText } from '../../../Uitils';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(2), // Adjust spacing as needed
  },
  '& > *': {
    borderBottom: 'unset', // Remove default border-bottom
  },
  boxShadow: '1px 1px 2px 1px grey',
}));
export const ReviewandRating = () => {
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [selectedCourseID, setSelectedCourseID] = useState(null);
  const { data } = useEducatorCoursesAndReviewsQuery();
  const [coursesToBeDisplayed, setCoursesToBeDisplayed] = useState(
    data?.courses ? data?.courses : []
  );

  const [barChart, setBarChart] = useState([]);
  const chartSetting = {
    yAxis: [
      {
        label: 'Review Count',
        tickMinStep: 1,
      },
    ],
    height: 350,
  };
  const RatingchartSetting = {
    xAxis: [
      {
        // label: 'rainfall (mm)',
      },
    ],
    width: 250,
    height: 150,
  };
  const castchartSetting = {
    yAxis: [
      {
        // label: 'rainfall (mm)',
      },
    ],
    width: 400,
    height: 150,
  };

  const valueFormatter = (value) => `${value}`;

  const ratingData = [
    {
      data: 35,
      number: '1',
    },
    {
      data: 20,
      number: '2',
    },
    {
      data: 28,
      number: '3',
    },
    {
      data: 35,
      number: '4',
    },
    {
      data: 98,
      number: '5',
    },
  ];

  const getMonthWiseEnrollments = (data) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthCounts = monthNames.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {});
    data
      .flatMap((course) => course.course_reviews || [])
      .forEach((review) => {
        const date = new Date(review.created_at);
        const month = monthNames[date.getMonth()];
        monthCounts[month] += 1;
      });
    return monthNames.map((month) => ({
      month,
      reviewCount: monthCounts[month],
    }));
  };

  const calculateOverallRating = (plan) => {
    let filteredCourses = [];
    if (Array.isArray(data?.courses)) {
      if (plan == 'free') {
        let finalCourses = data?.courses.filter(
          (elem) => elem.cost == 0 || elem.cost == null
        );
        if (Array.isArray(filteredCourses)) {
          filteredCourses = finalCourses || [];
        }
      }
      if (plan == 'premium') {
        let finalCourses1 = data?.courses.filter((elem) => elem.cost > 0);
        if (Array.isArray(finalCourses1)) {
          filteredCourses = finalCourses1 || [];
        }
      }
    }
    const allReviews = filteredCourses.flatMap(
      (course) => course?.course_reviews || []
    );
    const totalReviews = allReviews.length || 0;
    const averageRating =
      totalReviews > 0
        ? allReviews.reduce((sum, review) => sum + parseFloat(review?.rating), 0) /
          totalReviews
        : 0;

    return {
      averageRating: averageRating.toFixed(2),
      totalReviews,
    };
  };

  useEffect(() => {
    let finalResult = getMonthWiseEnrollments(coursesToBeDisplayed);
    if (Array.isArray(finalResult)) {
      setBarChart([...finalResult]);
    }
  }, [coursesToBeDisplayed]);

  useEffect(() => {
    let filteredCourses = [];
    if (Array.isArray(data?.courses)) {
      if (selectedPlan == 'free') {
        filteredCourses = data?.courses.filter(
          (elem) => elem.cost == 0 || elem.cost == null
        );
        if (Array.isArray(filteredCourses)) {
          setCoursesToBeDisplayed([...filteredCourses]);
        }
      }
      if (selectedPlan == 'premium') {
        filteredCourses = data?.courses.filter((elem) => elem.cost > 0);
        if (Array.isArray(filteredCourses)) {
          setCoursesToBeDisplayed([...filteredCourses]);
        }
      }
    }
    setSelectedCourseID(null);
  }, [selectedPlan, data?.courses]);

  return (
    <Fragment>
      {/* <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div> */}
      <Grid container spacing={2} sx={{ background: '', marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box sx={{ background: '#fff', boxShadow: '1px 1px 10px 1px #dcdcdc' }}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <Box sx={{ margin: '1rem', background: '#eef7fa' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      flexWrap: 'wrap',
                      // padding: '1rem',
                      background: '#fff',
                    }}
                  >
                    <div
                      style={{
                        height: '100px',
                        width: '200px',
                        background: selectedPlan == 'free' ? '#eef7fa' : '#10496a',
                        display: 'grid',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedPlan('free');
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          Free Plan
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Avg Rating
                            </Typography>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              {calculateOverallRating('free')?.averageRating || 0}
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '2px',
                              backgroundColor:
                                selectedPlan == 'free' ? '#000' : '#fff',
                              height: '40px' /* Adjust height as needed */,
                            }}
                          ></div>
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              {calculateOverallRating('free')?.totalReviews || 0}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      style={{
                        height: '100px',
                        width: '200px',
                        background:
                          selectedPlan == 'subscription' ? '#eef7fa' : '#10496a',
                        display: 'grid',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedPlan('subscription');
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            color:
                              selectedPlan == 'subscription' ? '#124b6b' : '#fff',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          Subscription Plan
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Avg Rating
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              400
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '2px',
                              backgroundColor:
                                selectedPlan == 'subscription' ? '#000' : '#fff',
                              height: '40px' 
                            }}
                          ></div>
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              50
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div
                      style={{
                        height: '100px',
                        width: '200px',
                        background:
                          selectedPlan == 'premium' ? '#eef7fa' : '#10496a',
                        display: 'grid',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedPlan('premium');
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            color: selectedPlan == 'premium' ? '#124b6b' : '#fff',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          Premium Plan
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Avg Rating
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              {calculateOverallRating('premium')?.totalReviews || 0}
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '2px',
                              backgroundColor:
                                selectedPlan == 'premium' ? '#000' : '#fff',
                              height: '40px' /* Adjust height as needed */,
                            }}
                          ></div>
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              {calculateOverallRating('premium')?.totalReviews || 0}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BarChart
                    dataset={barChart}
                    xAxis={[{ scaleType: 'band', dataKey: 'month', label: 'Month' }]}
                    series={[{ dataKey: 'reviewCount', valueFormatter }]}
                    {...chartSetting}
                  />
                </Box>
              </Grid>
              {/* <Grid
                item
                xl={3}
                lg={3}
                md={12}
                sm={12}
                sx={{ borderLeft: '3px solid #cbcbcb', padding: '1rem' }}
              >
                <Typography
                  sx={{ color: '#000', fontWeight: 700, fontSize: '18px' }}
                >
                  Performance Meter
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '2rem',
                    alignSelf: 'center',
                  }}
                >
                  <GaugeIndicator />
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <TableContainer component={Paper} sx={{ maxHeight: '700px' }}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={{ background: '#10496a' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '120px',
                        padding: '0.3rem',
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '120px',
                        padding: '0.3rem',
                      }}
                    >
                      Ava Ratings
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '120px',
                        padding: '0.3rem',
                      }}
                    >
                      Reviews
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coursesToBeDisplayed?.map((row, i) => (
                    <TableRow
                      key={row?.id}
                      className="analytics-custom-mui-table-row"
                      sx={{
                        background: selectedCourseID?.id == row?.id ? '#cdeef9' : '',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedCourseID(row);
                      }}
                    >
                      <TableCell>
                        <div>{i + 1}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            width: '150px',
                          }}
                        >
                          <Tooltip title={row?.title}>{row?.title}</Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          {row?.course_reviews_avg_rating
                            ? parseFloat(row?.course_reviews_avg_rating).toFixed(1)
                            : '-'}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.course_reviews_count}</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12}>
          {selectedCourseID && (
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>Rating Distributions</Typography>
                  <div style={{ display: 'flex' }}>
                    <Box sx={{ width: '150px', position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '3rem',
                          right: '2.5rem',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: '20px',
                          }}
                        >
                          {selectedCourseID?.course_reviews_avg_rating
                            ? parseFloat(
                                selectedCourseID.course_reviews_avg_rating
                              ).toFixed(1)
                            : '-'}{' '}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        >
                          {selectedCourseID.course_reviews_count} Reviews
                        </Typography>
                      </div>
                      <Gauge
                        width={150}
                        height={150}
                        value={
                          selectedCourseID?.course_reviews_avg_rating
                            ? (parseFloat(
                                selectedCourseID.course_reviews_avg_rating
                              ).toFixed(1) *
                                100) /
                              5
                            : '-'
                        }
                        innerRadius="70%"
                        outerRadius="100%"
                        text={() => ``}
                      />
                    </Box>
                    <Box>
                      <BarChart
                        dataset={selectedCourseID.course_reviews}
                        yAxis={[{ scaleType: 'band', dataKey: 'rating' }]}
                        series={[
                          {
                            dataKey: 'rating',
                          },
                        ]}
                        layout="horizontal"
                        {...RatingchartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>See How Loyal Are Your Customers</Typography>
                  <div className="comming-soon-parent">
                    <div className="comming-soon-text">Coming Soon</div>
                    <Box>
                      <BarChart
                        dataset={ratingData}
                        yAxis={[{ scaleType: 'band', dataKey: 'number' }]}
                        series={[
                          {
                            dataKey: 'data',
                          },
                        ]}
                        layout="vertical"
                        {...castchartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
                    <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                      <TableHead sx={{ background: '#10496a' }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            #
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Rating
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                              width: '300px',
                            }}
                          >
                            Review
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="review-and-rating-table">
                        {selectedCourseID.course_reviews?.map((row, index) => (
                          <StyledTableRow
                            key={row?.id}

                            //   className="analytics-custom-mui-table-row"
                          >
                            <TableCell>
                              <div>{index + 1}</div>
                            </TableCell>
                            <TableCell>
                              <div>
                                {moment(row?.created_at).format('Do MMM YY hh:mm A')}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div>
                                {row?.review_creator?.student_info?.city ||
                                row?.review_creator?.student_info?.district ||
                                row?.review_creator?.student_info?.state
                                  ? row?.review_creator?.student_info?.city ||
                                    row?.review_creator?.student_info?.district ||
                                    row?.review_creator?.student_info?.state
                                  : ''}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div>{row?.rating}</div>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title={row?.review_text} placement="bottom">
                                {/* {row?.review_text} */}
                                {getLimitedReducedText(row?.review_text, 70)}{' '}
                              </Tooltip>
                              {/* <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  width: '150px',
                                }}
                              >
                                <Tooltip title={row?.review_text} placement="right">
                                  {row?.review_text}
                                </Tooltip>
                              </div> */}
                            </TableCell>
                            <TableCell align="center">
                              <div>{row?.action}</div>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
