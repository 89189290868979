import {
  Autocomplete,
  Box,
  Button,
  Chip,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { blue, grey } from '@mui/material/colors';
import { useUpdateTeachingPreferenceMutation } from '../../../app/services/EducatorApi';
const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 98%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const languages = [
  'Assamese',
  'Bengali',
  'Bodo',
  'Dogri',
  'English',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Kashmiri',
  'Konkani',
  'Maithili',
  'Malayalam',
  'Marathi',
  'Meitei',
  'Nepali',
  'Odia',
  'Punjabi',
  'Sanskrit',
  'Santali',
  'Sindhi',
  'Tamil',
  'Telugu',
  'Urdu',
];

const teachingInterests = [
  'School Teacher',
  'College Lecturer',
  'Degree Professor',
  'Competative Exam Trainer',
  'IT Trainer',
  'Language Trainer',
  'Vocational Trainer',
  'Life Skill Trainer',
];

// const hobbies = [
//   'Photography',
//   'Gardening',
//   'Reading',
//   'Cooking',
//   'Writing',
//   'Music',
//   'Painting',
//   'Dance',
//   'Woodworking',
//   'Drawing',
//   'Blog',
//   'Art',
//   'Chess',
//   'Baking',
//   'Fishing',
//   'Hiking',
//   'Pottery',
//   'Knitting',
// ];

function UpdateEducatorProfile(props) {
  const { teacherId, profile } = props;
  const [updateTeachingPreference, result] = useUpdateTeachingPreferenceMutation();

  // const [bio, setBio] = useState(profile?.bio || '');
  const [preferredLanguages, setPreferredLanguages] = useState(
    profile?.preferred_languages || [languages[4]]
  );
  const [preferredInterests, setPreferredInterests] = useState(
    profile?.teaching_interests || [teachingInterests[4]]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const details = {
      educator_id: teacherId,
      preferred_languages: preferredLanguages,
      teaching_interests: preferredInterests,
      bio: data.get('bio'),
    };

    updateTeachingPreference(details);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Box sx={{ m: 4 }}>
          <Typography
            sx={{
              fontSize: '14px',
              marginBottom: '0.3rem',
            }}
          >
            Bio
          </Typography>
          <TextareaAutosize
            required
            fullWidth
            id="Bio"
            placeholder="Bio"
            name="bio"
            autoComplete="Bio"
            autoFocus
            size="small"
            maxRows={7}
            defaultValue={profile.bio}
            sx={{
              maxWidth: '100%',
              minWidth: '100%',
              minHeight: '150px',
            }}
          />
        </Box>
        <Box sx={{ m: 4 }}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={languages.map((option) => option)}
            defaultValue={preferredLanguages || [languages[4]]}
            onChange={(_event, newValue) => {
              setPreferredLanguages(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                //   variant="filled"
                label="Prefrerred Languages"
                placeholder="Languages"
              />
            )}
          />
        </Box>
        <Box sx={{ m: 4 }}>
          <Autocomplete
            multiple
            id="tags-filled1"
            options={teachingInterests.map((option) => option)}
            defaultValue={preferredInterests || [teachingInterests[4]]}
            onChange={(_event, newValue) => {
              setPreferredInterests(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                //   variant="filled"
                label="Teaching Interests"
                placeholder="Interests"
              />
            )}
          />
        </Box>
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Update Profile{' '}
        </Button>
      </Box>
    </>
  );
}
export default UpdateEducatorProfile;
