/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEducatorSubscriptionPlansQuery } from '../../../app/services/SubscriptionPlansApi';
import DataLoader from '../../../components/DataLoader';
import CreateEducatorPlan from './CreateEducatorPlan';
import EducatorPlansList from './EducatorPlansList';

function ManageEducatorPlans() {
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useEducatorSubscriptionPlansQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const createPlan = (id) => {
    navigate(`/create-educator-subscription-plan`);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={createPlan}>
              Create New Sunscription Plan
            </Button>
          </div>
        </Grid>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.educatorSubscriptionPlans.length ? (
            <EducatorPlansList plans={data.educatorSubscriptionPlans} />
          ) : (
            <p>No educator Subscription Plans found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ManageEducatorPlans;
