/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  TextField,
  Typography,
  IconButton,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Icon,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { DeleteOutline, EditNote, SaveOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import { BaseApi } from '../../../app/services/BaseApi';
import {
  useCourseExamQuery,
  useCreateCourseExamQuestionMutation,
  useDeleteCourseExamQuestionMutation,
  useUpdateCourseExamQuestionMutation,
} from '../../../app/services/CourseExamsApi';

function EditCourseExamQuestion(props) {
  const { examId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [addQuestion, setAddQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({
    title: '',
    edit: false,
    id: null,
    options: [],
  });
  const { data } = useCourseExamQuery({
    id: examId,
  });
  const [updateQuestion] = useUpdateCourseExamQuestionMutation();
  const [deleteQuestion] = useDeleteCourseExamQuestionMutation();
  const [createQuestion] = useCreateCourseExamQuestionMutation();

  const validateData = (questionData) => {
    let err = true;

    if (questionData.title.trim().length === 0) {
      enqueueSnackbar('Please provide question title', {
        variant: 'error',
      });
    } else if (!questionData.options?.length >= 2) {
      enqueueSnackbar('Please provide question with atleast 2 options', {
        variant: 'error',
      });
    } else if (
      questionData.options?.some(
        (singleOption) => singleOption.optionText.trim().length === 0
      )
    ) {
      enqueueSnackbar('Please provide proper options', {
        variant: 'error',
      });
    } else if (
      !questionData.options?.some((singleOption) => singleOption.isCorrect === true)
    ) {
      enqueueSnackbar(
        'Please provide question with atleast 2 options and 1 true option',
        {
          variant: 'error',
        }
      );
    } else {
      err = false;
    }

    return err;
  };

  const handleEditButton = (question) => {
    setSelectedQuestion({
      title: question.title,
      edit: true,
      id: question.id,
      options: question.options,
    });
  };

  const handleUpdateSubmit = () => {
    const questionData = {
      id: selectedQuestion.id,
      title: selectedQuestion.title,
      options: selectedQuestion.options,
      exam_id: examId,
    };

    const res = validateData(questionData);
    if (!res) {
      updateQuestion(questionData)
        .unwrap()
        .then((res) => {
          if (res.message === 'question updated successfully') {
            dispatch(BaseApi.util.invalidateTags(['Exam']));

            setSelectedQuestion({
              ...selectedQuestion,
              title: '',
              edit: false,
              id: null,
              options: [],
            });
          }
        });
    }
  };

  const handleSubmit = () => {
    const questionData = {
      title: selectedQuestion.title,
      options: selectedQuestion.options,
      course_exam_id: examId,
    };

    const res = validateData(questionData);
    if (!res) {
      createQuestion(questionData)
        .unwrap()
        .then((res) => {
          if (res.question.id) {
            setAddQuestion(false);
            setSelectedQuestion({
              ...selectedQuestion,
              title: '',
              edit: false,
              id: null,
              options: [],
            });
          } else {
            console.log(res);
          }
        });
    }
  };

  const onDelete = (id) => {
    deleteQuestion({ id });
  };

  const addOption = () => {
    if (selectedQuestion.options?.length > 0) {
      setSelectedQuestion({
        ...selectedQuestion,
        options: [...selectedQuestion.options, { isCorrect: false, optionText: '' }],
      });
    } else {
      setSelectedQuestion({
        ...selectedQuestion,
        options: [{ isCorrect: false, optionText: '' }],
      });
    }
  };
  const handleValueChange = (index, e) => {
    const updatedOptions = selectedQuestion.options?.map((value, i) => {
      if (value === index) {
        return { ...value, optionText: e.target.value };
      } else {
        return value;
      }
    });
    // setOptions(updatedOptions);
    setSelectedQuestion({ ...selectedQuestion, options: updatedOptions });
  };
  const deleteOption = (singleOption) => {
    setSelectedQuestion({
      ...selectedQuestion,
      options: selectedQuestion?.options?.filter(
        (option) => option !== singleOption
      ),
    });
  };
  const handleChange = (index, e) => {
    const updatedOptions = selectedQuestion.options?.map((value, i) => {
      if (value === index) {
        return { ...value, isCorrect: e.target.value === 'right' ? true : false };
      } else {
        return value;
      }
    });
    // setOptions(updatedOptions);
    setSelectedQuestion({ ...selectedQuestion, options: updatedOptions });
  };

  const handleQuestionTitleChange = (e) => {
    setSelectedQuestion({ ...selectedQuestion, title: e.target.value });
  };

  return (
    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
      <>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              sx={{
                mt: 1,
                border: '1px solid #cacbcf',
                background: '#fafafa',
                borderRadius: '6px',
                padding: '1rem',
              }}
            >
              {data?.course_exam?.questions?.length > 0
                ? data?.course_exam.questions.map(
                    (singleQuestion, questionIndex) => (
                      <Grid
                        item
                        xl="12"
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        key={singleQuestion.id}
                      >
                        {' '}
                        <div
                          style={{
                            border: '1px solid #cacbcf',
                            background: '#fafafa',
                            borderRadius: '6px',
                            padding: '1rem',
                          }}
                        >
                          <Box style={{ display: 'flex' }} component="form">
                            <TextField
                              required
                              fullWidth
                              size="small"
                              placeholder="What Where How and When"
                              label={`Question ${questionIndex + 1}`}
                              disabled={singleQuestion.id !== selectedQuestion.id}
                              defaultValue={singleQuestion.title}
                              autoFocus
                              onChange={(e) => handleQuestionTitleChange(e)}
                              InputProps={{
                                endAdornment:
                                  singleQuestion.id === selectedQuestion.id &&
                                  selectedQuestion.edit === true ? null : (
                                    <InputAdornment position="end">
                                      <Icon color="success">
                                        <CheckCircleIcon twoToneColor="#52c41a" />
                                      </Icon>{' '}
                                    </InputAdornment>
                                  ),
                              }}
                            />

                            <>
                              <div className="font-icon-wrapper">
                                <IconButton
                                  onClick={() => onDelete(singleQuestion.id)}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </div>
                              <div className="font-icon-wrapper">
                                {singleQuestion.id === selectedQuestion.id &&
                                selectedQuestion.edit ? (
                                  <IconButton onClick={() => handleUpdateSubmit()}>
                                    <SaveOutlined />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => handleEditButton(singleQuestion)}
                                    disabled={addQuestion}
                                  >
                                    <EditNote />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          </Box>
                          {selectedQuestion.edit &&
                          selectedQuestion.id === singleQuestion.id
                            ? selectedQuestion?.options?.map(
                                (singleOption, index) => (
                                  <Box key={singleOption}>
                                    <Grid
                                      container
                                      spacing={1}
                                      alignItems="flex-end"
                                    >
                                      <Grid item xs={8}>
                                        <TextField
                                          autoFocus
                                          margin="dense"
                                          label={`option ${index + 1}`}
                                          value={singleOption.optionText}
                                          onChange={(e) =>
                                            handleValueChange(singleOption, e)
                                          }
                                          required
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <FormControl>
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={
                                              singleOption.isCorrect
                                                ? 'right'
                                                : 'wrong'
                                            }
                                            onChange={(e) =>
                                              handleChange(singleOption, e)
                                            }
                                            sx={{ marginLeft: '1rem' }}
                                          >
                                            <FormControlLabel
                                              value="right"
                                              control={
                                                <Radio sx={{ padding: '3px' }} />
                                              }
                                              label="Right"
                                            />
                                            <FormControlLabel
                                              value="wrong"
                                              control={
                                                <Radio sx={{ padding: '3px' }} />
                                              }
                                              label="Wrong"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div
                                          className="font-icon-wrapper"
                                          onClick={() => deleteOption(singleOption)}
                                        >
                                          <IconButton aria-label="delete">
                                            <DeleteOutline />
                                          </IconButton>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )
                            : singleQuestion.options?.map((singleOption, index) => (
                                <Box key={singleOption}>
                                  <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={8}>
                                      <TextField
                                        autoFocus
                                        margin="dense"
                                        label={`option ${index + 1}`}
                                        defaultValue={singleOption.optionText}
                                        onChange={(e) =>
                                          handleValueChange(singleOption, e)
                                        }
                                        disabled={
                                          singleQuestion.id !== selectedQuestion.id
                                        }
                                        required
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <FormControl
                                        disabled={
                                          singleQuestion.id !== selectedQuestion.id
                                        }
                                      >
                                        <RadioGroup
                                          aria-labelledby="demo-controlled-radio-buttons-group"
                                          name="controlled-radio-buttons-group"
                                          value={
                                            singleOption.isCorrect
                                              ? 'right'
                                              : 'wrong'
                                          }
                                          onChange={(e) =>
                                            handleChange(singleOption, e)
                                          }
                                          sx={{ marginLeft: '1rem' }}
                                        >
                                          <FormControlLabel
                                            value="right"
                                            control={
                                              <Radio sx={{ padding: '3px' }} />
                                            }
                                            label="Right"
                                          />
                                          <FormControlLabel
                                            value="wrong"
                                            control={
                                              <Radio sx={{ padding: '3px' }} />
                                            }
                                            label="Wrong"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div
                                        className="font-icon-wrapper"
                                        onClick={() => deleteOption(singleOption)}
                                        disabled={
                                          singleQuestion.id !== selectedQuestion.id
                                        }
                                      >
                                        <IconButton aria-label="delete">
                                          <DeleteOutline />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))}
                          <Button
                            onClick={() => addOption()}
                            disabled={singleQuestion.id !== selectedQuestion.id}
                          >
                            Add Option
                          </Button>
                        </div>
                      </Grid>
                    )
                  )
                : null}
              {addQuestion ? (
                <>
                  <Box
                    component="form"
                    //   onSubmit={handleSubmit}
                    noValidate
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="title"
                      label={`Question`}
                      name="title"
                      autoComplete="title"
                      autoFocus
                      onChange={(e) => handleQuestionTitleChange(e)}
                    />
                  </Box>

                  <Box>
                    {selectedQuestion?.options?.map((singleOption, index) => (
                      <Box key={singleOption}>
                        <Grid container spacing={1} alignItems="flex-end">
                          <Grid item xs={8}>
                            <TextField
                              autoFocus
                              margin="dense"
                              label={`option ${index + 1}`}
                              value={singleOption.optionText}
                              onChange={(e) => handleValueChange(singleOption, e)}
                              required
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={singleOption.isCorrect ? 'right' : 'wrong'}
                                onChange={(e) => handleChange(singleOption, e)}
                                sx={{ marginLeft: '1rem' }}
                              >
                                <FormControlLabel
                                  value="right"
                                  control={<Radio sx={{ padding: '3px' }} />}
                                  label="Right"
                                />
                                <FormControlLabel
                                  value="wrong"
                                  control={<Radio sx={{ padding: '3px' }} />}
                                  label="Wrong"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <div
                              className="font-icon-wrapper"
                              onClick={() => deleteOption(singleOption)}
                            >
                              <IconButton aria-label="delete">
                                <DeleteOutline />
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    <Button onClick={() => addOption()}>Add Option</Button>
                    <Box sx={{ display: 'flex', float: 'right' }}>
                      <Button
                        type="submit"
                        sx={{ padding: '8px 8px' }}
                        variant="outlined"
                        onClick={() => setAddQuestion(false)}
                      >
                        Delete
                        <DeleteOutline />
                      </Button>
                      &nbsp;
                      {selectedQuestion?.options?.length >= 2 ? (
                        <Box>
                          <Button
                            // type="submit"
                            sx={{ padding: '8px 8px' }}
                            variant="outlined"
                            onClick={() => handleSubmit()}
                          >
                            Create Question
                          </Button>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setAddQuestion(true)}
                >
                  Add Question
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </Grid>
  );
}

export default EditCourseExamQuestion;
