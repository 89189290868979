import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  // Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useCreateEducatorBankInfoMutation,
  useEducatorBankInfoQuery,
  useEducatorEarningsQuery,
} from '../../../app/services/EducatorApi';
import { useSnackbar } from 'notistack';
import UpdatePayout from './UpdatePayout';
import UploadDocument from './UploadDocument';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getFileNameFromUrl } from '../../../Uitils';
import moment from 'moment';
export const PayoutSettings = () => {
  const educatorBankInfo = useEducatorBankInfoQuery();
  const { data } = useEducatorEarningsQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  function smallcreateData(srNo, invoice, amount) {
    return { srNo, invoice, amount };
  }
  const [createBankInfo] = useCreateEducatorBankInfoMutation();
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (educatorBankInfo?.data?.bankInfo) {
      setInfo(educatorBankInfo?.data?.bankInfo);
    }
  }, [educatorBankInfo]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const validateData = (bankData) => {
    let err = true;

    if (
      !bankData.account_name ||
      !bankData.account_number ||
      !bankData.ifsc_code ||
      !bankData.account_type ||
      !bankData.branch ||
      !bankData.bank_name ||
      !bankData.branch_address
    ) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const bankData = {
      account_name: data.get('accountName'),
      account_number: data.get('accountNumber'),
      ifsc_code: data.get('ifscCode'),
      account_type: data.get('accountType'),
      branch: data.get('branch'),
      bank_name: data.get('bankName'),
      branch_address: data.get('branchAddress'),
    };

    const res = validateData(bankData);
    if (!res) {
      createBankInfo(bankData)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Success', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.log(error);

          enqueueSnackbar('Error ', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} sm={6}>
                <Typography
                  sx={{ color: '#10496a', fontSize: '20px', fontWeight: 700 }}
                >
                  My Bank Details
                </Typography>
                {!info.id ? (
                  <Box
                    sx={{
                      // margin: '1rem',
                      marginTop: '1rem',
                      padding: '1rem',
                      boxShadow: '1px 1px 10px 1px #dadada',
                      borderRadius: '6px',
                    }}
                    component="form"
                    onSubmit={handleAddSubmit}
                  >
                    <Grid container spacing={1}>
                      <Grid item xl={12} lg={12} md={12} sm={12}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          Account Name
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="accountName"
                          sx={{
                            //   border: '1px solid #57494a',
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          Account Number
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="accountNumber"
                          // disabled={true}
                          sx={{
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          IFSC Code
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="ifscCode"
                          sx={{
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          Account Type
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="accountType"
                          sx={{
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          Branch
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="branch"
                          sx={{
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={6} sm={6}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          Bank Name
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="bankName"
                          sx={{
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={6} sm={6}>
                        <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
                          Branch Address
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          name="branchAddress"
                          sx={{
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={6}
                        sm={6}
                        sx={{ textAlign: 'center' }}
                      >
                        <Button
                          sx={{
                            background: '#2bb18c',
                            color: '#fff',
                            borderRadius: '20px',
                            fontSize: '14px',
                            fontWeight: 600,
                            mt: '3rem',
                            '&:hover': {
                              backgroundColor: '#2bb18c',
                            },
                          }}
                          type="submit"
                        >
                          Add Bank Details
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <UpdatePayout bankInfo={info} />
                )}
              </Grid>
              <Grid item xl={5} lg={5} md={12} sm={12}>
                <Typography
                  sx={{
                    color: '#10496a',
                    fontSize: '20px',
                    fontWeight: 700,
                  }}
                >
                  KYC Documents
                </Typography>
                <Typography
                  sx={{
                    color: '#10496a',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  Documents required to verify the identity, suitability, and risks
                </Typography>
                <Box
                  sx={{
                    marginTop: '1rem',
                    padding: '1rem',
                    boxShadow: '1px 1px 10px 1px #dadada',
                    borderRadius: '6px',
                    minHeight: 'calc(100% - 100px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Box sx={{ width: '100px' }}>
                      <Typography
                        sx={{
                          color: '#10496a',
                          fontSize: '14px',
                          fontWeight: 500,
                          textAlign: 'center',
                        }}
                      >
                        Address Proof
                      </Typography>
                      <div
                        style={{
                          border: '1px solid',
                          height: '120px',
                          width: '100px',
                          marginTop: '0.3rem',
                          position: 'relative',
                        }}
                      >
                        {info?.address_proof_url &&
                          (info?.address_proof_url.toLowerCase().endsWith('.pdf') ? (
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '0.5rem',
                              }}
                            >
                              <p
                                style={{
                                  margin: '0rem',
                                  color: '#1e1eff',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  fontSize: '12px',
                                }}
                              >
                                {getFileNameFromUrl(info?.address_proof_url)}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={info?.address_proof_url}
                              style={{ height: '100%', width: '100%' }}
                            />
                          ))}
                        <IconButton
                          variant="contained"
                          sx={{ position: 'absolute', top: 0, left: 0 }}
                          onClick={handleClickOpen}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle> Upload Address Proof</DialogTitle>
                            <DialogContent>
                              <UploadDocument
                                bankInfoId={info.id}
                                imgUrl={info.address_proof_url}
                                fileType="address_proof"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </>{' '}
                      </div>
                    </Box>
                    <Box sx={{ width: '100px' }}>
                      <Typography
                        sx={{
                          color: '#10496a',
                          fontSize: '14px',
                          fontWeight: 500,
                          textAlign: 'center',
                        }}
                      >
                        PAN Card
                      </Typography>
                      <div
                        style={{
                          border: '1px solid',
                          height: '120px',
                          width: '100px',
                          marginTop: '0.3rem',
                          position: 'relative',
                        }}
                      >
                        {info?.pan_card_url &&
                          (info?.pan_card_url.toLowerCase().endsWith('.pdf') ? (
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '0.5rem',
                              }}
                            >
                              <p
                                style={{
                                  margin: '0rem',
                                  color: '#1e1eff',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  fontSize: '12px',
                                }}
                              >
                                {getFileNameFromUrl(info?.pan_card_url)}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={info?.pan_card_url}
                              style={{ height: '100%', width: '100%' }}
                            />
                          ))}
                        <IconButton
                          variant="contained"
                          sx={{ position: 'absolute', top: 0, left: 0 }}
                          onClick={handleClickOpen1}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <>
                          <Dialog
                            open={open1}
                            onClose={handleClose1}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle> Upload Pan Card</DialogTitle>
                            <DialogContent>
                              <UploadDocument
                                bankInfoId={info.id}
                                imgUrl={info.pan_card_url}
                                fileType="pan_card"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose1}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </>{' '}
                      </div>
                    </Box>
                    <Box sx={{ width: '100px' }}>
                      <Typography
                        sx={{
                          color: '#10496a',
                          fontSize: '14px',
                          fontWeight: 500,
                          textAlign: 'center',
                        }}
                      >
                        Bank Details
                      </Typography>
                      <div
                        style={{
                          border: '1px solid',
                          height: '120px',
                          width: '100px',
                          marginTop: '0.3rem',
                          position: 'relative',
                        }}
                      >
                        {info?.bank_details_url &&
                          (info?.bank_details_url.toLowerCase().endsWith('.pdf') ? (
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '0.5rem',
                              }}
                            >
                              <p
                                style={{
                                  margin: '0rem',
                                  color: '#1e1eff',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  fontSize: '12px',
                                }}
                              >
                                {getFileNameFromUrl(info?.bank_details_url)}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={info?.bank_details_url}
                              style={{ height: '100%', width: '100%' }}
                            />
                          ))}
                        <IconButton
                          variant="contained"
                          sx={{ position: 'absolute', top: 0, left: 0 }}
                          onClick={handleClickOpen2}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <>
                          <Dialog
                            open={open2}
                            onClose={handleClose2}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle> Upload Bank Details</DialogTitle>
                            <DialogContent>
                              <UploadDocument
                                bankInfoId={info.id}
                                imgUrl={info.bank_details_url}
                                fileType="bank_details"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose2}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </>{' '}
                      </div>
                    </Box>
                    <Box sx={{ width: '100px' }}>
                      <Typography
                        sx={{
                          color: '#10496a',
                          fontSize: '14px',
                          fontWeight: 500,
                          textAlign: 'center',
                        }}
                      >
                        Aadhaar Card
                      </Typography>
                      <div
                        style={{
                          border: '1px solid',
                          height: '120px',
                          width: '100px',
                          marginTop: '0.3rem',
                          position: 'relative',
                        }}
                      >
                        {info?.aadhaar_card_url &&
                          (info?.aadhaar_card_url.toLowerCase().endsWith('.pdf') ? (
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '0.5rem',
                              }}
                            >
                              <p
                                style={{
                                  margin: '0rem',
                                  color: '#1e1eff',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  fontSize: '12px',
                                }}
                              >
                                {getFileNameFromUrl(info?.aadhaar_card_url)}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={info?.aadhaar_card_url}
                              style={{ height: '100%', width: '100%' }}
                            />
                          ))}
                        <IconButton
                          variant="contained"
                          sx={{ position: 'absolute', top: 0, left: 0 }}
                          onClick={handleClickOpen3}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <>
                          <Dialog
                            open={open3}
                            onClose={handleClose3}
                            fullWidth
                            maxWidth="md"
                          >
                            <DialogTitle> Upload Aadhaar Card</DialogTitle>
                            <DialogContent>
                              <UploadDocument
                                bankInfoId={info.id}
                                imgUrl={info.aadhaar_card_url}
                                fileType="aadhaar_card"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose3}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </>{' '}
                      </div>
                    </Box>
                  </Box>
                  {/* <Box sx={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: '#2bb18c',
                        color: '#fff',
                        borderRadius: '20px',
                        fontSize: '14px',
                        fontWeight: 600,
                        mt: '2rem',
                        '&:hover': {
                          backgroundColor: '#2bb18c',
                        },
                      }}
                    >
                      Upload KYC Documents
                    </Button>
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={12} sm={12}>
                <Typography
                  sx={{
                    color: '#10496a',
                    fontSize: '20px',
                    fontWeight: 700,
                    // textAlign: 'center',
                  }}
                >
                  TDS Deducted
                </Typography>
                <div className="comming-soon-parent">
                  <div className="comming-soon-text">Coming Soon</div>
                  <Box
                    sx={{
                      marginTop: '1rem',
                      padding: '0.5rem',
                      boxShadow: '1px 1px 10px 1px #dadada',
                      borderRadius: '6px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                      alignItems: 'center',
                      minHeight: 'calc(100% - 30%)',
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                        <TableHead sx={{ background: '#eef7fa' }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: '#083faf',
                                fontSize: '16px',
                                fontWeight: 700,
                                padding: '0.3rem',
                              }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: '#083faf',
                                fontSize: '16px',
                                fontWeight: 700,
                                padding: '0.3rem',
                              }}
                            >
                              Invoice No
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: '#083faf',
                                fontSize: '16px',
                                fontWeight: 700,
                                padding: '0.3rem',
                              }}
                            >
                              TDS Deducted
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="analytics-custom-mui-table-body">
                          {[
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                            smallcreateData(1, 'INV00123', '400'),
                          ].map((row) => (
                            <TableRow
                              key={row?.name}
                              className="analytics-custom-mui-table-row"
                            >
                              <TableCell sx={{ padding: '0.3rem' }}>
                                <div>{row?.srNo} </div>
                              </TableCell>
                              <TableCell align="center" sx={{ padding: '0rem' }}>
                                <div>{row?.invoice}</div>
                              </TableCell>
                              <TableCell align="center" sx={{ padding: '0rem' }}>
                                <div>Rs {row?.amount}</div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box sx={{ textAlign: 'center' }}>
                      <Button
                        sx={{
                          background: '#2bb18c',
                          color: '#fff',
                          borderRadius: '20px',
                          fontSize: '14px',
                          fontWeight: 600,
                          mt: '0rem',
                          '&:hover': {
                            backgroundColor: '#2bb18c',
                          },
                        }}
                      >
                        Download TDS certificate
                      </Button>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Withdrawal History</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={{ background: '#eef7fa' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Order Number
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Plan
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="analytics-custom-mui-table-body">
                  {Array.isArray(data?.totalWithdrawals) &&
                    data?.totalWithdrawals.length > 0 &&
                    data?.totalWithdrawals.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        className="analytics-custom-mui-table-row"
                      >
                        <TableCell>
                          <div>
                            {index + 1}{' '}
                            {/* <Radio
                              value="a"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'A' }}
                              sx={{ padding: '0rem' }}
                            /> */}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{moment(row?.created_at).format('Do MMM YY ')}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.mode}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.reference_id ? row?.reference_id : '-'}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.plan ? row?.plan : '-'}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.amount}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div style={{ textTransform: 'capitalize' }}>
                            {row?.status}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              height: '150px',
              alignSelf: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: 'center', fontSize: '22px' }}
            >
              Add Area
            </Typography>
          </Box>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              height: '150px',
              alignSelf: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: 'center', fontSize: '22px' }}
            >
              Add Area
            </Typography>
          </Box>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              height: '150px',
              alignSelf: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: 'center', fontSize: '22px' }}
            >
              Add Area
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
