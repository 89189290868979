import {
  Autocomplete,
  Box,
  Button,
  Chip,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useLearningObjectivesForStudentQuery } from '../../../app/services/LearningObjectivesApi';
import { useUpdateLearningProfileMutation } from '../../../app/services/StudentApi';

const languages = [
  'Assamese',
  'Bengali',
  'Bodo',
  'Dogri',
  'English',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Kashmiri',
  'Konkani',
  'Maithili',
  'Malayalam',
  'Marathi',
  'Meitei',
  'Nepali',
  'Odia',
  'Punjabi',
  'Sanskrit',
  'Santali',
  'Sindhi',
  'Tamil',
  'Telugu',
  'Urdu',
];

const learningInterests = [
  'Writing',
  'Communication',
  'Art',
  'Dance',
  'Music',
  'Painting',
  'Gardening',
  'Drawing',
  'Culture',
  'Hiking',
  'Design',
  'History',
  'Graphic design',
  'Technology',
  'Acting',
  'Pottery',
  'Marketing',
  'Science',
  'Team sport',
  'Calligraphy',
  'Astronomy',
];

const hobbies = [
  'Photography',
  'Gardening',
  'Reading',
  'Cooking',
  'Writing',
  'Music',
  'Painting',
  'Dance',
  'Woodworking',
  'Drawing',
  'Blog',
  'Art',
  'Chess',
  'Baking',
  'Fishing',
  'Hiking',
  'Pottery',
  'Knitting',
];

function UpdateStudentLearningProfile(props) {
  const { studentId, profile } = props;

  const { data, isSuccess } = useLearningObjectivesForStudentQuery();
  const [objectiveTitle, seObjectiveTitle] = useState(
    profile.learning_objective_id || ''
  );
  const [preferredLanguages, setPreferredLanguages] = useState(
    profile.preferred_languages || [languages[4]]
  );
  const [preferredInterests, setPreferredInterests] = useState(
    profile.learning_interests || [learningInterests[8]]
  );
  const [preferredHobbies, setPreferredHobbies] = useState(
    profile.hobbies || [hobbies[4]]
  );

  const [selectedLearningobjective, setSelectedLearningobjective] = useState(
    profile.learning_objective || {
      title: '',
      id: null,
      objectives: [],
    }
  );

  const [updateLearningProfile, result] = useUpdateLearningProfileMutation();

  const handleObjectiveChange = (event) => {
    const tempSelectedObjective = data.learningObjectives.find(
      (singleLearningObjective) => singleLearningObjective.id === event.target.value
    );
    setSelectedLearningobjective(tempSelectedObjective);
    seObjectiveTitle(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const details = {
      student_id: studentId,
      learning_objective_id: selectedLearningobjective.id,
      preferred_languages: preferredLanguages,
      learning_interests: preferredInterests,
      hobbies: preferredHobbies,
    };

    updateLearningProfile(details);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Box sx={{ m: 4 }}>
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="learningObjective"
            label="Learning Objective"
            onChange={handleObjectiveChange}
            name="learningObjective"
            autoComplete="learningObjective"
            autoFocus
            value={objectiveTitle}
          >
            {isSuccess && data.learningObjectives && data.learningObjectives.length
              ? data.learningObjectives.map((singleLearningObjective) => (
                  <MenuItem
                    value={singleLearningObjective.id}
                    key={singleLearningObjective.id}
                  >
                    {singleLearningObjective.title}
                  </MenuItem>
                ))
              : null}
          </TextField>
          {selectedLearningobjective &&
          selectedLearningobjective.objectives &&
          selectedLearningobjective.objectives.length
            ? selectedLearningobjective.objectives.map((singleObjective, index) => (
                <Box key={index}>
                  <Typography>
                    Objective {index + 1}. {singleObjective.title}
                  </Typography>
                </Box>
              ))
            : null}
        </Box>
        <Box sx={{ m: 4 }}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={languages.map((option) => option)}
            defaultValue={preferredLanguages || [languages[4]]}
            onChange={(_event, newValue) => {
              setPreferredLanguages(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                //   variant="filled"
                label="Prefrerred Languages"
                placeholder="Languages"
              />
            )}
          />
        </Box>
        <Box sx={{ m: 4 }}>
          <Autocomplete
            multiple
            id="tags-filled1"
            options={learningInterests.map((option) => option)}
            defaultValue={preferredInterests || [learningInterests[8]]}
            onChange={(_event, newValue) => {
              setPreferredInterests(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                //   variant="filled"
                label="Learning Interests"
                placeholder="Interests"
              />
            )}
          />
        </Box>
        <Box sx={{ m: 4 }}>
          <Autocomplete
            multiple
            id="tags-filled2"
            options={hobbies.map((option) => option)}
            defaultValue={preferredHobbies || [hobbies[4]]}
            onChange={(_event, newValue) => {
              setPreferredHobbies(newValue);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                //   variant="filled"
                label="Hobbies"
                placeholder="Passionate About"
              />
            )}
          />
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Profile{' '}
        </Button>
      </Box>
    </>
  );
}
export default UpdateStudentLearningProfile;
