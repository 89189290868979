/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { CustomDateRange } from './CustomDateRange';
import {
  useEducatorCoursesAndEnrolsQuery,
  useEducatorCoursesAndReviewsQuery,
  useEducatorCoursesEnrolsCountQuery,
  useEducatorEarningsQuery,
} from '../../../app/services/EducatorApi';
import moment from 'moment';
import { CurrencyRupee } from '@mui/icons-material';
import CreateWithdrawalRequest from './CreateWithdrawalRequest';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  calculateOverallRatingforCourse,
  convertToTimeFormat,
  getTotalEnrollmentCount,
  getTotalWatchHourseType,
} from '../../../Uitils';

export const EducatorEarning = () => {
  const { data, isLoading, isSuccess } = useEducatorEarningsQuery();
  const { data: coursesData } = useEducatorCoursesAndEnrolsQuery();
  const { data: educatorEnrollCountQuery } = useEducatorCoursesEnrolsCountQuery();
  const { data: educatorCourseReview } = useEducatorCoursesAndReviewsQuery();
  const chartSetting = {
    yAxis: [
      {
        // label: 'My Earning',
      },
    ],
    width: 500,
    height: 400,
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const valueFormatter = (value) => `${value}`;

  function createData(one, two, three, four, five, six, seven, eight, nine) {
    return { one, two, three, four, five, six, seven, eight, nine };
  }
  const getClosestWithdrawal = (withdrawals) => {
    const currentDate = new Date();

    // Filter withdrawals with status "success"
    const successfulWithdrawals = withdrawals.filter(
      (withdrawal) => withdrawal.status === 'success'
    );

    if (successfulWithdrawals.length === 0) return null;

    // Find the withdrawal closest to the current date
    return successfulWithdrawals.reduce((closest, current) => {
      const currentDiff = Math.abs(new Date(current.created_at) - currentDate);
      const closestDiff = Math.abs(new Date(closest.created_at) - currentDate);

      return currentDiff < closestDiff ? current : closest;
    });
  };

  const handleClickOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {/* <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div> */}
      <Grid container spacing={2} sx={{ marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box sx={{ background: '#fff', boxShadow: '1px 1px 10px 1px #dcdcdc' }}>
            <Grid container>
              <Grid item xl={8} lg={8} md={12} sm={12}>
                <Grid container>
                  <Grid item xl={4} lg={5} md={12} sm={12}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        flexWrap: 'wrap',
                        padding: '1rem',
                      }}
                    >
                      <div
                        style={{
                          height: '120px',
                          minWidth: '200px',
                          background: '#ef9bb1',
                          borderRadius: '10px',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Earnings Till Date
                          </Typography>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <CurrencyRupee />
                            {` ${
                              data?.totalCourseEarnings ||
                              0 + data?.totalExamEarnings ||
                              0 + data?.totalTestEarnings ||
                              0
                            }`}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          height: '120px',
                          width: '200px',
                          background: '#e2b5ff',
                          borderRadius: '10px',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <div className="comming-soon-parent">
                            <div className="comming-soon-text">Coming Soon</div>
                            <Typography
                              sx={{
                                color: '#124b6b',
                                textAlign: 'center',
                                fontWeight: 600,
                              }}
                            >
                              Last Month
                            </Typography>
                            <Typography
                              sx={{
                                color: '#124b6b',
                                textAlign: 'center',
                                fontWeight: 600,
                              }}
                            >
                              Rs. 0
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          height: '120px',
                          width: '200px',
                          background: '#a0e894',
                          borderRadius: '10px',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <div className="comming-soon-parent">
                            <div className="comming-soon-text">Coming Soon</div>
                            <Typography
                              sx={{
                                color: '#124b6b',
                                textAlign: 'center',
                                fontWeight: 600,
                              }}
                            >
                              Current Month
                            </Typography>
                            <Typography
                              sx={{
                                color: '#124b6b',
                                textAlign: 'center',
                                fontWeight: 600,
                              }}
                            >
                              Rs. 0
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={8}
                    lg={7}
                    md={12}
                    sm={12}
                    alignSelf={'center'}
                    justifyContent={'center'}
                    sx={{ display: 'flex' }}
                  >
                    <BarChart
                      dataset={[
                        {
                          data:
                            data?.totalCourseEarnings ||
                            0 + data?.totalExamEarnings ||
                            0 + data?.totalTestEarnings ||
                            0,
                          month: 'Till Date',
                        },
                        {
                          data: 0,
                          month: 'Current Month',
                        },
                        {
                          data: 0,
                          month: 'Last Month',
                        },
                      ]}
                      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                      series={[
                        {
                          dataKey: 'data',
                          label: 'My Earning',
                          valueFormatter,
                        },
                      ]}
                      layout="vertical"
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                sx={{ borderLeft: '3px solid #cbcbcb', padding: '1rem' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    padding: '2rem',
                    background: '#f5f5b5',
                    justifyItems: 'center',
                    gap: '1rem',
                    borderRadius: '30px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '16px',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                  >
                    Unpaid Earnings
                  </Typography>
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '18px',
                      fontWeight: 500,
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CurrencyRupee />
                    {` ${
                      (data?.totalCourseEarnings ||
                        0 + data?.totalExamEarnings ||
                        0 + data?.totalTestEarnings ||
                        0) - (data?.totalAmountWithdrawals || 0)
                    }`}
                  </Typography>
                  <Button
                    sx={{
                      background: '#f5f5b5',
                      color: '#09768d',
                      border: '2px solid #09768d',
                      borderRadius: '50px',
                      fontSize: '18px',
                      fontWeight: 700,
                      maxWidth: '300px',
                      '&:hover': {
                        background: '#f5f5b5',
                      },
                    }}
                    onClick={() => handleClickOpen()}
                    disabled={
                      (data?.totalCourseEarnings ||
                        0 + data?.totalExamEarnings ||
                        0 + data?.totalTestEarnings ||
                        0) -
                        (data?.totalAmountWithdrawals || 0) <
                      0
                    }
                  >
                    Withdraw Payment
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    padding: '2rem',
                    background: '#9cf4b7',
                    justifyItems: 'center',
                    gap: '1rem',
                    borderRadius: '30px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '16px',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                  >
                    Last Payment Withdrawal
                  </Typography>
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '18px',
                      fontWeight: 500,
                      //   textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    {Array.isArray(data?.totalWithdrawals) &&
                    data?.totalWithdrawals.length > 0 ? (
                      <>
                        {' '}
                        <span>
                          {' '}
                          {getClosestWithdrawal(data?.totalWithdrawals)?.created_at
                            ? moment(
                                getClosestWithdrawal(data?.totalWithdrawals)
                                  ?.created_at
                              ).format('Do-MMM-YYYY')
                            : ''}
                        </span>{' '}
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <CurrencyRupee />
                          {`${
                            getClosestWithdrawal(data?.totalWithdrawals)?.amount || 0
                          }`}
                        </span>
                      </>
                    ) : (
                      ' - '
                    )}
                  </Typography>
                  <Button
                    sx={{
                      background: '#9cf4b7',
                      color: '#09768d',
                      border: '2px solid #09768d',
                      borderRadius: '50px',
                      fontSize: '18px',
                      fontWeight: 700,
                      maxWidth: '300px',
                      '&:hover': {
                        background: '#9cf4b7',
                      },
                    }}
                    onClick={() => {
                      if (
                        Array.isArray(data?.totalWithdrawals) &&
                        data?.totalWithdrawals.length > 0
                      ) {
                        navigate('/educator-earnings');
                      } else {
                        enqueueSnackbar('No withdrawal to display ', {
                          variant: 'error',
                        });
                      }
                    }}
                  >
                    View Payment History
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={{ background: '#10496a' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '200px',
                      }}
                    >
                      My Work
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Enrolls
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Average Watch Hrs
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Gross Earned
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Avg Rating
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    createData(
                      'Self Paced Courses',
                      `${
                        Array.isArray(coursesData?.courses) &&
                        coursesData?.courses.length > 0
                          ? getTotalEnrollmentCount(null, coursesData)
                          : 0
                      }`,
                      `${
                        Array.isArray(educatorEnrollCountQuery?.courses) &&
                        educatorEnrollCountQuery?.courses.length > 0
                          ? convertToTimeFormat(
                              getTotalWatchHourseType(
                                educatorEnrollCountQuery,
                                null,
                                null
                              )
                            )
                          : 0
                      }`,
                      `Rs ${
                        data?.totalCourseEarnings ||
                        0 + data?.totalExamEarnings ||
                        0 + data?.totalTestEarnings ||
                        0
                      }`,
                      `${
                        calculateOverallRatingforCourse(null, educatorCourseReview)
                          ?.averageRating
                      }`
                    ),
                  ].map((row) => (
                    <TableRow
                      key={row?.name}
                      //   className="analytics-custom-mui-table-row"
                    >
                      <TableCell sx={{ padding: '0rem' }}>
                        <div className="table-div-one">{row?.one}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>Free</Typography>
                          <Typography sx={{ margin: '0rem' }}>Premium</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.two}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>
                            {Array.isArray(coursesData?.courses) &&
                            coursesData?.courses.length > 0
                              ? getTotalEnrollmentCount('free', coursesData)
                              : 0}
                          </Typography>
                          <Typography sx={{ margin: '0rem' }}>
                            {' '}
                            {Array.isArray(coursesData?.courses) &&
                            coursesData?.courses.length > 0
                              ? getTotalEnrollmentCount('premium', coursesData)
                              : 0}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.three}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>
                            {' '}
                            {Array.isArray(educatorEnrollCountQuery?.courses) &&
                            educatorEnrollCountQuery?.courses.length > 0
                              ? convertToTimeFormat(
                                  getTotalWatchHourseType(
                                    educatorEnrollCountQuery,
                                    'free',
                                    true
                                  )
                                )
                              : 0}
                          </Typography>
                          <Typography sx={{ margin: '0rem' }}>
                            {' '}
                            {Array.isArray(educatorEnrollCountQuery?.courses) &&
                            educatorEnrollCountQuery?.courses.length > 0
                              ? convertToTimeFormat(
                                  getTotalWatchHourseType(
                                    educatorEnrollCountQuery,
                                    'premium',
                                    true
                                  )
                                )
                              : 0}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.four}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>-</Typography>
                          <Typography sx={{ margin: '0rem' }}>-</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.five}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>{`${
                            calculateOverallRatingforCourse(
                              'free',
                              educatorCourseReview
                            )?.averageRating
                          }`}</Typography>
                          <Typography sx={{ margin: '0rem' }}>{`${
                            calculateOverallRatingforCourse(
                              'premium',
                              educatorCourseReview
                            )?.averageRating
                          }`}</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ padding: '0rem' }}>
                      <div className="table-div-one">Total</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">
                        {Array.isArray(coursesData?.courses) &&
                        coursesData?.courses.length > 0
                          ? getTotalEnrollmentCount(null, coursesData)
                          : 0}
                      </div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">{`${
                        Array.isArray(educatorEnrollCountQuery?.courses) &&
                        educatorEnrollCountQuery?.courses.length > 0
                          ? convertToTimeFormat(
                              getTotalWatchHourseType(
                                educatorEnrollCountQuery,
                                null,
                                null
                              )
                            )
                          : 0
                      }`}</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">{`Rs ${
                        data?.totalCourseEarnings ||
                        0 + data?.totalExamEarnings ||
                        0 + data?.totalTestEarnings ||
                        0
                      }`}</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">{`${
                        calculateOverallRatingforCourse(null, educatorCourseReview)
                          ?.averageRating
                      }`}</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Create Payment Withdrawal Request</DialogTitle>
        <DialogContent>
          <CreateWithdrawalRequest
            balance={
              (data?.totalCourseEarnings ||
                0 + data?.totalExamEarnings ||
                0 + data?.totalTestEarnings ||
                0) - (data?.totalAmountWithdrawals || 0)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
