import { Box, Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCreateSupportTicketCommentMutation } from '../../app/services/SupportTicketsApi';

function SupportTicketCommentReply(props) {
  const { commentId, setReplyBox } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [createComment] = useCreateSupportTicketCommentMutation();

  const validateReplyData = (commentData) => {
    let err = true;

    if (!commentData.commentText) {
      enqueueSnackbar('Please provide comment in some words', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const onReply = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const commentData = {
      commentText: data.get('replyText'),
      support_ticket_comment_id: commentId,
    };

    const res = validateReplyData(commentData);
    if (!res) {
      createComment(commentData);
      setReplyBox(commentId);
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={onReply}
        noValidate
        sx={{ mt: 1, width: ' -webkit-fill-available' }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="replyText"
          label=" Your Reply"
          name="replyText"
          autoComplete="replyText"
          multiline
          rows="2"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          // disabled={result.isLoading || result.isSuccess}
        >
          Submit Reply
        </Button>
      </Box>
    </>
  );
}

export default SupportTicketCommentReply;
