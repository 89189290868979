import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useTestSubjectQuery } from '../../../../app/services/TestsApi';
import DataLoader from '../../../../components/DataLoader';
import CreateNewTestTopic from './testTopics/CreateNewTestTopic';
import TestTopicsList from './testTopics/TestTopicsList';
import UpdateTestSubject from './UpdateTestSubject';

function SingleTestSubject() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useTestSubjectQuery({
    id: parseInt(params.testSubjectId, 10),
  });

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <>
      {isSuccess && data.testSubject ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleClickOpen1}>
                  Update Test Subject
                </Button>
                <>
                  <Dialog
                    open={open1}
                    onClose={handleClose1}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle> Update Test Subject</DialogTitle>
                    <DialogContent>
                      <UpdateTestSubject testSubject={data.testSubject} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
                &nbsp;
                <Button variant="contained" onClick={handleClickOpen}>
                  Create New Test Topic
                </Button>
                <>
                  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle> Create New Test Topic</DialogTitle>
                    <DialogContent>
                      <CreateNewTestTopic testSubjectId={data.testSubject.id} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Box>
            </Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
              <Card>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ padding: 4, color: '#233c8d' }}
                >
                  Title: {data.testSubject.title}
                  <br />
                  Description: {data.testSubject.description}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
              {data.testSubject.test_topics &&
              data.testSubject.test_topics.length ? (
                <TestTopicsList testTopics={data.testSubject.test_topics} />
              ) : (
                <p>No Test Topics Found</p>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No Test Subject found</p>
      )}
    </>
  );
}

export default SingleTestSubject;
