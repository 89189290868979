import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { useDeleteKeywordMutation } from '../../../app/services/KeywordsApi';

function KeywordsList(props) {
  const { keywords } = props;
  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);
  const [deleteKeyword] = useDeleteKeywordMutation();

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const videwSubscriptionPlan = (id) => {
    navigate(`/keywords/${id}`);
  };

  const onDelete = (id) => {
    deleteKeyword({ id });
  };
  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Keywords
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Created On</TableCell>

              <TableCell>
                <span style={{ marginLeft: '1rem' }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keywords.map((singleKeyword, index) => (
              <TableRow
                key={singleKeyword.id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{singleKeyword.title}</TableCell>

                <TableCell>
                  {moment(singleKeyword.created_at).format('Do MMM YY hh:mm A')}
                </TableCell>
                <TableCell>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 200,
                      bgcolor: 'background.paper',
                    }}
                  >
                    <ListItemButton
                      onClick={() => handleClick(index)}
                      aria-expanded={expandedId === index}
                    >
                      <ListItemText primary="Options" />
                      <ExpandMore />
                    </ListItemButton>
                    <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => videwSubscriptionPlan(singleKeyword.id)}
                        >
                          <ListItemText primary="View" />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => onDelete(singleKeyword.id)}
                        >
                          <ListItemText primary="Delete" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </List>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default KeywordsList;
