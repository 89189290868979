import { BaseApi } from './BaseApi';

export const studentTestsApi = BaseApi.injectEndpoints({
  reducerPath: 'studentTestsApi',
  endpoints: (builder) => ({
    calculateQuestions: builder.mutation({
      query: (details) => ({
        url: '/test/questions/count',
        method: 'POST',
        body: {
          test_id: details.test_id,
          test_subject_topic_ids: details.test_subject_topic_ids,
          severity: details.severity,
          style: details.style,
        },
      }),
    }),
    createNewPracticeTest: builder.mutation({
      query: (details) => ({
        url: '/practicetest/create',
        method: 'POST',
        body: {
          title: details.title,
          style: details.style,
          test_id: details.test_id,
          severity: details.severity,
          test_subject_topic_ids: details.test_subject_topic_ids,
          no_of_questions: details.no_of_questions,
        },
      }),
    }),
    practiceTests: builder.query({
      query: (token) => ({
        url: '/practice/tests',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    practiceTest: builder.query({
      query: (details) => ({
        url: `/practicetest/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Practice Test'],
    }),
    getPracticeTestQuestions: builder.query({
      query: (details) => ({
        url: `/practicetest/questions/${details.id}`,
        method: 'GET',
      }),
    }),
    createPracticeTestResult: builder.mutation({
      query: (details) => ({
        url: `/practicetest/result/create`,
        method: 'POST',
        body: {
          answers: details.answers,
          practice_test_id: details.practiceTestId,
        },
      }),
    }),
    createTestReview: builder.mutation({
      query: (details) => ({
        url: '/test/review/create',
        method: 'POST',
        body: {
          rating: details.rating,
          review_text: details.reviewText,
          test_id: details.test_id,
        },
      }),
      invalidatesTags: ['Practice Test'],
    }),
    testEnrol: builder.mutation({
      query: (details) => ({
        url: '/test/enrol/create',
        method: 'POST',
        body: {
          test_id: details.test_id,
        },
      }),
    }),
    enroledTests: builder.query({
      query: (token) => ({
        url: '/test/enrols',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    enroledTest: builder.query({
      query: (details) => ({
        url: `/test/enrol/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Test Enrol'],
    }),
    createStudentTestEnrolPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/student/test/enrol/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
          test_id: details.test_id,
        },
      }),
    }),
    createStudentTestEnrolWithPayment: builder.mutation({
      query: (details) => ({
        url: '/student/test/enrol/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          email: details.email,
          test_id: details.test_id,
        },
      }),
    }),
  }),
});

export const {
  useCalculateQuestionsMutation,
  useCreateNewPracticeTestMutation,
  usePracticeTestQuery,
  usePracticeTestsQuery,
  useLazyGetPracticeTestQuestionsQuery,
  useCreatePracticeTestResultMutation,
  useCreateTestReviewMutation,
  useTestEnrolMutation,
  useEnroledTestsQuery,
  useEnroledTestQuery,
  useCreateStudentTestEnrolPaymentOrderIdMutation,
  useCreateStudentTestEnrolWithPaymentMutation,
} = studentTestsApi;
