import React from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import PasswordReset from '../PasswordReset';
import { useSelector } from 'react-redux';
import Copyright from '../../../components/Copyright';
import { useState } from 'react';
import StudentSignInPage from './StudentSignInPage';
import StudentSignUpPage from './StudentSignUpPage';

function StudentRegistrationPage() {
  const { forgotPassword } = useSelector((state) => state.auth);
  const [isLogin, setIsLogin] = useState(false);
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <title>Account | {`${process.env.REACT_APP_NAME}`}</title>
      <CssBaseline />
      {/* {!isLogin ? (
        <>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
            sx={{
              background: '#0f435f',
              color: '#fff',
            }}
          >
            <div style={{ background: '#00687b', width: '100%', height: '40px' }}>
              <Button
                onClick={() => {
                  setIsLogin(!isLogin);
                }}
                sx={{
                  float: 'right',
                  color: '#fff',
                  fontSize: '18px',
                }}
              >
                Already Have An Account
              </Button>
            </div>
            {forgotPassword ? (
              <PasswordReset />
            ) : !isLogin ? (
              <StudentSignUpPage />
            ) : (
              <StudentSignInPage />
            )}
            <Container maxWidth="xs" sx={{ mt: 3, borderRadius: '10px' }}>
              <Box sx={{ background: '#fff', borderRadius: '4px' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: 600,
                    padding: '1rem',
                  }}
                >
                  Don&apos;t have an account?
                  <Button
                    sx={{ display: 'block' }}
                    onClick={() => {
                      setIsLogin(true);
                    }}
                  >
                    Sign Up Here
                  </Button>
                </Typography>
              </Box>
            </Container>
            <Copyright />
          </Grid>
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundImage:
                'url(https://source.unsplash.com/random/featured/?nature)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </>
      ) : (
        <>
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundImage:
                'url(https://source.unsplash.com/random/featured/?nature)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
            sx={{
              background: '#0f435f',
              color: '#fff',
            }}
          >
            <div style={{ background: '#00687b', width: '100%', height: '40px' }}>
              <Button
                onClick={() => {
                  setIsLogin(!isLogin);
                }}
                sx={{
                  float: 'left',
                  color: '#fff',
                  fontSize: '18px',
                }}
              >
                Create New Account
              </Button>
            </div>
            {forgotPassword ? <PasswordReset /> : <StudentSignInPage />}
            <Copyright />
          </Grid>
        </>
      )} */}
      <>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{
            background: '#0f435f',
            color: '#fff',
          }}
        >
          <div
            style={{
              background: '#00687b',
              width: '100%',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', fontWeight: 700, textAlign: 'center' }}
            >
              Student Login
            </Typography>
            {/* <Button
              onClick={() => {
                setIsLogin(!isLogin);
              }}
              sx={{
                float: 'right',
                color: '#fff',
                fontSize: '18px',
              }}
            >
              Already Have An Account
            </Button> */}
          </div>
          {forgotPassword ? (
            <PasswordReset />
          ) : isLogin ? (
            <StudentSignUpPage />
          ) : (
            <StudentSignInPage />
          )}
          {!forgotPassword && (
            <Container maxWidth="xs" sx={{ mt: 3, borderRadius: '10px' }}>
              <Box sx={{ background: '#fff', borderRadius: '4px' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: 600,
                    padding: '1rem',
                  }}
                >
                  {!isLogin ? `Don't have an account` : 'Already have an account'}
                  <Button
                    sx={{ display: 'block' }}
                    onClick={() => {
                      setIsLogin(!isLogin);
                    }}
                  >
                    {!isLogin ? 'Sign Up Here' : 'Login Here'}
                  </Button>
                </Typography>
              </Box>
            </Container>
          )}
          <Copyright />
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: 'url(https://gurumavin.in/HomepageAds/GMpost2.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </>
    </Grid>
  );
}

export default StudentRegistrationPage;
