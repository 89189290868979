import {
  Typography,
  Drawer,
  IconButton,
  Divider,
  Toolbar,
  Button,
  Link,
  Box,
  Avatar,
  Popover,
  // Tooltip,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import SideBarList from './SideBarList';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import SearchBar from './SearchBar';
import MenuToolBar from './MenuToolBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Footer } from './Footer';
import { useEducatorProfileQuery } from '../app/services/EducatorApi';
import { useStudentProfileQuery } from '../app/services/StudentApi';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function AppLayout() {
  const { isSignedIn, user } = useSelector((state) => state.auth);
  const [skipEducatorProfile, setSkipEducatorProfile] = useState(true);
  const [skipStudentProfile, setSkipStudentProfile] = useState(true);
  const educatorProfile = useEducatorProfileQuery(undefined, {
    skip: skipEducatorProfile,
  });
  const studentProfileData = useStudentProfileQuery(undefined, {
    skip: skipStudentProfile,
  });
  const trigger = useScrollTrigger();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const APP_NAME = process.env.REACT_APP_NAME;
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  const handleOpenPopper = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopper = () => {
    setAnchorEl(null);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const onClick = () => {
    navigate('/select-account');
  };
  useEffect(() => {
    if (user?.role === 'student') {
      setSkipStudentProfile(false); // Run query for admin role
    } else if (user?.role === 'educator') {
      setSkipEducatorProfile(false); // Skip query for other roles
    }
  }, [user?.role]);
  const popperButtonId = Boolean(anchorEl);
  const id = popperButtonId ? 'simple-popover' : undefined;

  return (
    <>
      <AppBar
        position="fixed"
        open={window.screen.width > 600 && open}
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: '#fff',
            justifyContent: 'space-between',
            borderBottom: '1px solid #d7dcdf',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="h6" noWrap component="div">
            <Link color="inherit" href="/" underline="none">
              {APP_NAME == 'Gurumavin' ? (
                <img
                  src="/TheModernEducator.png"
                  style={{
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    color: '#000',
                  }}
                >
                  {' '}
                  {APP_NAME}
                </Typography>
              )}
            </Link>
          </Typography>
          {location?.pathname !== '/select-account' &&
          window.screen.width > 600 &&
          user?.role !== 'educator' ? (
            <SearchBar />
          ) : null}

          {isSignedIn ? (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onMouseEnter={handleOpenPopper}
                sx={{ ...(open && { display: 'none' }) }}
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src={
                    user?.role == 'student'
                      ? studentProfileData?.data?.profile?.url
                        ? studentProfileData?.data?.profile?.url
                        : ''
                      : user?.role == 'educator'
                      ? educatorProfile?.data?.profile?.image_url
                        ? educatorProfile?.data?.profile?.image_url
                        : ''
                      : ''
                  }
                  srcSet={
                    user?.role == 'student'
                      ? studentProfileData?.data?.profile?.url
                        ? studentProfileData?.data?.profile?.url
                        : ''
                      : user?.role == 'educator'
                      ? educatorProfile?.data?.profile?.image_url
                        ? educatorProfile?.data?.profile?.image_url
                        : ''
                      : ''
                  }
                >
                  {user.name.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ justifyContent: 'flex-end' }}>
              {location?.pathname !== '/select-account' && (
                <>
                  {location?.pathname !== '/teach-educator' && (
                    <Button
                      sx={{ color: '#000', fontSize: '12px' }}
                      onClick={() => {
                        navigate('/teach-educator');
                      }}
                    >
                      Teach Here
                    </Button>
                  )}
                  <Button
                    onClick={onClick}
                    variant=""
                    sx={{ my: 1, mx: 1.5, color: '#000' }}
                  >
                    Sign In
                  </Button>
                </>
              )}
            </Box>
          )}
        </Toolbar>
        {trigger ? null : (
          <>
            {window.screen.width < 600 ? (
              <Toolbar
                sx={{
                  // backgroundColor: 'darkcyan',
                  margin: 0,
                  padding: 0,
                }}
              >
                <SearchBar />
              </Toolbar>
            ) : null}
            {location?.pathname !== '/select-account' && <MenuToolBar />}
          </>
        )}
      </AppBar>

      <Main
        open={window.screen.width > 600 && open}
        sx={{ paddingTop: user?.role == 'educator' ? 2 : 12 }}
      >
        <DrawerHeader />
        <Outlet />
        <Footer />
        {/* <Copyright /> */}
      </Main>
      <Popover
        id={id}
        open={popperButtonId}
        anchorEl={anchorEl}
        onClose={handleClosePopper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ width: 4000, zIndex: 1600 }}
        marginThreshold={0}

        // onMouseLeave={handleClosePopper}
      >
        <Box sx={{ width: '300px' }}>
          {user?.role == 'educator' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #c5c5c5',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  minWidth: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.5rem',
                }}
              >
                <Avatar
                  srcSet={
                    educatorProfile?.data?.profile?.image_url
                      ? educatorProfile?.data?.profile?.image_url
                      : educatorProfile?.data?.profile?.background_url &&
                        educatorProfile?.data?.profile?.image_url
                      ? educatorProfile?.data?.profile?.background_url +
                        educatorProfile?.data?.profile?.image_url
                      : ''
                  }
                  src={
                    educatorProfile?.data?.profile?.image_url
                      ? educatorProfile?.data?.profile?.image_url
                      : educatorProfile?.data?.profile?.background_url &&
                        educatorProfile?.data?.profile?.image_url
                      ? educatorProfile?.data?.profile?.background_url +
                        educatorProfile?.data?.profile?.image_url
                      : ''
                  }
                  sx={{ width: '100%', height: '100%' }}
                >
                  {educatorProfile?.data?.profile?.first_name
                    ? `${educatorProfile?.data?.profile?.first_name
                        ?.charAt(0)
                        .toUpperCase()}`
                    : user?.name?.charAt(0).toUpperCase()}
                </Avatar>
              </Box>
              <Box sx={{ padding: '0.5rem', maxWidth: 'calc(100% - 80px)' }}>
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '22px',
                    fontWeight: 700,
                    textAlign: 'left',
                    maxWidth: '200px',
                  }}
                  className="wrap-text-ellipsis"
                  title={`${
                    educatorProfile?.data?.profile?.first_name
                      ? `${educatorProfile?.data?.profile?.first_name} ${
                          educatorProfile?.data?.profile?.last_name
                            ? ` ${educatorProfile?.data?.profile?.last_name}`
                            : null
                        }`
                      : user?.name?.charAt(0).toUpperCase()
                  }`}
                >
                  {educatorProfile?.data?.profile?.first_name
                    ? `${educatorProfile?.data?.profile?.first_name} ${
                        educatorProfile?.data?.profile?.last_name
                          ? ` ${educatorProfile?.data?.profile?.last_name}`
                          : null
                      }`
                    : user?.name?.charAt(0).toUpperCase()}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'left',
                    maxWidth: '100%',
                  }}
                  className="wrap-text-ellipsis"
                  title={user?.email}
                >
                  {user?.email}
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'left',
                  }}
                >{`ID : ${
                  educatorProfile?.data?.profile?.id || user?.id
                }`}</Typography> */}
              </Box>
            </Box>
          ) : user?.role === 'student' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #c5c5c5',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.5rem',
                }}
              >
                <Avatar
                  srcSet={
                    studentProfileData?.data?.profile?.url
                      ? studentProfileData?.data?.profile?.url
                      : studentProfileData?.data?.profile?.url
                      ? studentProfileData?.data?.profile?.url
                      : ''
                  }
                  src={
                    studentProfileData?.data?.profile?.url
                      ? studentProfileData?.data?.profile?.url
                      : studentProfileData?.data?.profile?.url
                      ? studentProfileData?.data?.profile?.url
                      : ''
                  }
                  sx={{ width: '100%', height: '100%' }}
                >
                  {studentProfileData?.data?.profile?.first_name
                    ? `${studentProfileData?.data?.profile?.first_name
                        ?.charAt(0)
                        .toUpperCase()}`
                    : user?.name?.charAt(0).toUpperCase()}
                </Avatar>
              </Box>
              <Box sx={{ padding: '0.5rem', maxWidth: 'calc(100% - 80px)' }}>
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '22px',
                    fontWeight: 700,
                    textAlign: 'left',
                    maxWidth: '200px',
                    wordWrap: 'break-word',
                  }}
                  className="wrap-text-ellipsis"
                  title={`${
                    studentProfileData?.data?.profile?.first_name
                      ? `${studentProfileData?.data?.profile?.first_name} ${
                          studentProfileData?.data?.profile?.last_name
                            ? ` ${studentProfileData?.data?.profile?.last_name}`
                            : null
                        }`
                      : user?.name
                  }`}
                >
                  {studentProfileData?.data?.profile?.first_name
                    ? `${studentProfileData?.data?.profile?.first_name} ${
                        studentProfileData?.data?.profile?.last_name
                          ? ` ${studentProfileData?.data?.profile?.last_name}`
                          : null
                      }`
                    : user?.name}
                </Typography>
                {/* <Tooltip> */}
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'left',
                  }}
                  className="wrap-text-ellipsis"
                  title={user?.email}
                >
                  {user?.email}
                </Typography>
                {/* </Tooltip> */}
                {/* <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'left',
                  }}
                >{`ID : ${
                  studentProfileData?.data?.profile?.id || user?.id
                }`}</Typography> */}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                borderBottom: '1px solid #c5c5c5',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.5rem',
                }}
              >
                <Avatar sx={{ width: '100%', height: '100%' }}>
                  {user?.name?.charAt(0).toUpperCase()}
                </Avatar>
              </Box>
              <Box
                sx={{
                  padding: '0.5rem',
                  maxWidth: 'calc(100% - 80px)',
                  minWidth: 'calc(100% - 100px)',
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '22px',
                    fontWeight: 700,
                    textAlign: 'right',
                  }}
                >
                  {user?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'right',
                  }}
                >
                  {user?.email}
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textAlign: 'right',
                  }}
                >{`ID : ${user?.id}`}</Typography> */}
              </Box>
            </Box>
          )}
          <SideBarList handleClosePopper={handleClosePopper} />
        </Box>
      </Popover>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {isSignedIn && user ? (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 8px',
              }}
            >
              {user.role.toUpperCase()}
            </Typography>
          ) : null}
        </DrawerHeader>
        <Divider />
        <SideBarList />
      </Drawer>
    </>
  );
}

export default AppLayout;
