/* eslint-disable no-unused-vars */

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateCourseExamMutation } from '../../../app/services/CourseExamsApi';
import { uploadCourseExamImageRequest } from '../../../app/services/UploadRequests';
import { allLanguages } from '../../../Uitils';

function EditCourseExam(props) {
  const { exam } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSelector((state) => state.auth);
  const [updateCourseExam] = useUpdateCourseExamMutation();

  const [title, setTitle] = useState(exam.title || '');
  const [description, setDescription] = useState(exam.description || '');
  const [language, setLanguage] = useState(exam.language, '');

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const allowedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const [isDisabled, setIsDiabled] = useState(false);
  const [loading, setLoading] = useState(false);
  let [buttonType, setButtonType] = useState('');

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [level, setLevel] = useState(exam.level || 'beginner');

  const [examCostType, setExamCostType] = useState(exam?.cost ? 'paid' : 'free');
  const [examCost, setExamCost] = useState(exam?.cost || null);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const validateData = (examData) => {
    let err = true;

    if (
      examData.title.trim().length === 0 ||
      examData.description.trim().length === 0
    ) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });
      setIsDiabled(false);
      return err;
    }
    if (!examData.title || !examData.course_id || !examData.language) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
      setIsDiabled(false);
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    setIsDiabled(true);
    event.preventDefault();
    console.log('this is getting button Type', buttonType);
    const data = new FormData(event.currentTarget);
    const examData = {
      id: exam.id,
      title: title,
      description: description,
      course_id: exam.course_id,
      language: language,
      level: level,
      cost: examCostType === 'paid' ? examCost : null,
    };

    const res = validateData(examData);

    if (!res) {
      updateCourseExam(examData)
        .then((res) => {
          enqueueSnackbar(res?.data?.message || 'Exam Updated', {
            variant: 'success',
          });
          setIsDiabled(false);
          if (buttonType === 'saveasdraft' && props.handleExternalSaveAsDraft) {
            props.handleExternalSaveAsDraft && props.handleExternalSaveAsDraft();
          }
          if (buttonType === 'next' && props.handleExternalNextButton) {
            props.handleExternalNextButton && props.handleExternalNextButton();
          }
        })
        .catch((error) => {
          enqueueSnackbar('Exam Not Updated (Refresh the page)', {
            variant: 'error',
          });
          setIsDiabled(false);
        });
    }
  };

  const UploadImage = () => {
    // event.preventDefault();
    setLoading(true);
    if (props.setImageStatus) {
      props.setImageStatus(false);
    }
    const UploadData = {
      file: selectedImage,
      token: token,
    };
    uploadCourseExamImageRequest(UploadData, exam.id).then((res) => {
      if (res.url) {
        setLoading(false);
        enqueueSnackbar(
          'Image Uploaded Successfully. Please refresh your page for now.',
          {
            variant: 'success',
          }
        );
        if (props.setImageStatus) {
          props.setImageStatus('success');
        }
      } else {
        setLoading(false);

        enqueueSnackbar('Error Uploading Image. ', {
          variant: 'error',
        });
        if (props.setImageStatus) {
          props.setImageStatus('error');
        }
      }
    });
  };

  return (
    <Fragment>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid
            item
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            style={{ height: 'auto' }}
          >
            <TextField
              required
              fullWidth
              id="title"
              label=" Title"
              name="title"
              autoComplete="title"
              autoFocus
              onChange={handleTitleChange}
              defaultValue={exam.title}
            />
          </Grid>
          <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              autoFocus
              multiline
              rows="2"
              onChange={handleDescriptionChange}
              defaultValue={exam.description}
            />
          </Grid>

          <Grid item xl="4" lg="4" md="6" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="language"
              label="What Language the exam is in"
              name="language"
              autoComplete="language"
              autoFocus
              onChange={handleLanguageChange}
              select
              defaultValue={exam.language}
            >
              {Array.isArray(allLanguages) && allLanguages.length > 0
                ? allLanguages.map((singleLanguage) => (
                    <MenuItem value={singleLanguage} key={singleLanguage}>
                      {singleLanguage}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          <Grid item xl="4" lg="4" md="6" sm="12" xs="12">
            <TextField
              required
              fullWidth
              id="examfee"
              label="Exam Fee"
              name="examfee"
              autoComplete="examfee"
              autoFocus
              onChange={(e) => {
                setExamCostType(e.target.value);
              }}
              select
              defaultValue={examCostType}
            >
              {[
                { label: 'Free', value: 'free' },
                { label: 'Paid', value: 'paid' },
              ].map((singleItem) => (
                <MenuItem value={singleItem.value} key={singleItem.value}>
                  {singleItem.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {examCostType === 'paid' && (
            <Grid item xl="4" lg="4" md="6" sm="12" xs="12">
              <TextField
                required
                fullWidth
                id="cost"
                label="Cost"
                name="cost"
                autoComplete="cost"
                autoFocus
                type="number"
                onChange={(e) => {
                  setExamCost(e.target.value);
                }}
                defaultValue={examCost}
              />
            </Grid>
          )}

          <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Learning Level
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={level}
                onChange={handleLevelChange}
              >
                <FormControlLabel
                  value="beginner"
                  control={<Radio />}
                  label="Beginner"
                />
                <FormControlLabel
                  value="intermediate"
                  control={<Radio />}
                  label="Intermediate"
                />
                <FormControlLabel
                  value="expert"
                  control={<Radio />}
                  label="Expert"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xl="6" lg="6" md="12" sm="12" xs="12">
            <Box sx={{ height: '100%' }}>
              <Grid container spacing={1}>
                <Grid item xl="3" lg="3" md="3" sm="3" xs="3">
                  <img
                    src={imageUrl ? imageUrl : exam?.url || exam?.image_url}
                    style={{
                      border: '1px solid #c7c7c7',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Grid>
                <Grid item xl="9" lg="9" md="9" sm="9" xs="9">
                  <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    Textbook Cover Image
                  </Typography>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                    title={`Upload Exam image here. It must meet our exam image quality
                      standard to be accepted. Important guidelines: 750*422 pixels;
                      .jpg, .png, .jpeg, .gif or .png no text on the image`}
                  >
                    <label
                      style={{
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '3',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      Upload Exam image here. It must meet our exam image quality
                      standard to be accepted. Important guidelines: 750*422 pixels;
                      .jpg, .png, .jpeg, .gif or .png no text on the image
                    </label>
                  </Typography>
                  <form
                    className="form"
                    // onSubmit={UploadImage}
                    style={{ marginTop: '2rem' }}
                  >
                    <div
                      className="file-upload-wrapper"
                      data-text={
                        selectedImage ? selectedImage.name : 'No File Selected'
                      }
                      data-button-text="Select File"
                    >
                      <input
                        name="file-upload-field"
                        type="file"
                        accept="image/*"
                        className="file-upload-field"
                        value=""
                        onChange={(e) => {
                          if (allowedImageTypes.includes(e.target.files[0].type)) {
                            setSelectedImage(e.target.files[0]);
                          } else {
                            enqueueSnackbar(
                              'Please select a PNG, JPEG, or SVG file.',
                              {
                                variant: 'error',
                              }
                            );
                          }
                        }}
                      />
                    </div>
                    {selectedImage && (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          UploadImage();
                        }}
                        sx={{ marginTop: '1rem' }}
                      >
                        Upload
                      </Button>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={
            props.showExtraButton ? { display: 'flex', justifyContent: 'end' } : {}
          }
        >
          {props.showExtraButton ? (
            <>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                onClick={() => {
                  buttonType = 'saveasdraft';
                  setButtonType(buttonType);
                }}
              >
                Save as Draft
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  buttonType = 'next';
                  setButtonType(buttonType);
                }}
              >
                Next
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
            >
              Update Exam
            </Button>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}

export default EditCourseExam;
