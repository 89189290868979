/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

function WebcamStream() {
  const handleSuccess = (stream) => {
    const video = document.querySelector('video');

    const videoTracks = stream.getVideoTracks();

    video.srcObject = stream;
  };

  const startWebCam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      handleSuccess(stream);
    } catch (e) {
      console.log(e);
    }
  };

  const stopWebCam = async () => {
    const video = document.querySelector('video');

    const stream = video.srcObject;
    const videoTracks = stream.getVideoTracks();

    try {
      videoTracks[0].stop();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          // bgcolor: 'background.paper',
          overflowWrap: 'anywhere',
        }}
      >
        <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
          <Box sx={{ margin: '20px' }}>
            <div className="video-row">
              <div className=" video-container">
                <video id="video" autoPlay style={{ background: '#222' }}></video>

                <div className="action-btns">
                  <Button
                    onClick={() => startWebCam()}
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    {/* Start WebCam And Microphone */}
                    <VideocamIcon />
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => stopWebCam()}
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    {/* Stop WebCam And Microphone */}
                    <VideocamOffIcon />
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
          <Box sx={{ margin: '20px' }}>
            <Typography>Live Chat</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default WebcamStream;
