import { Avatar, Button, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { toggleforgotPasswordButton } from '../../app/slices/authSlice';
import { useSnackbar } from 'notistack';
import { useResetPasswordLinkMutation } from '../../app/services/AuthApi';

function PasswordReset() {
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector((state) => state.auth);

  const [resetPasswordLink, result] = useResetPasswordLinkMutation();
  const { enqueueSnackbar } = useSnackbar();

  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (!authData.email) {
      enqueueSnackbar('Email is required', { variant: 'error' });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else {
      err = false;
    }

    return err;
  };

  // Handle Submit of the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Authdata = {
      email: data.get('resetEmail'),
    };
    const res = validateData(Authdata);
    if (!res) {
      resetPasswordLink(Authdata)
        .unwrap()
        .then((response) => {
          console.log(response);

          enqueueSnackbar('Password Reset Link Sent. Please Check Your Email.', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.log(error);

          enqueueSnackbar('Error Sending Reset Link', {
            variant: 'error',
          });
        });
    }
  };

  if (isSignedIn) {
    return <Navigate replace to="/" />;
  }

  const toggleForgotPassword = () => {
    dispatch(toggleforgotPasswordButton());
  };

  const textFieldStyling = {
    '& .MuiOutlinedInput-root': {
      color: '#fff', // Text color
      borderRadius: '6px',
      backgroundColor: 'transparent', // Ensure default background is transparent
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'transparent', // Ensure input background remains transparent
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'white',
      opacity: 1,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#fff',
    },

    // ✅ Proper autofill background fix
    '& input:-webkit-autofill': {
      backgroundColor: 'transparent !important',
      WebkitBoxShadow: '0 0 0px 1000px rgba(0, 0, 0, 0) inset !important',
      WebkitTextFillColor: '#fff !important', // Ensure text remains white
      transition: 'background-color 5000s ease-in-out 0s', // Prevent background color change
    },
  };

  return (
    <div>
      <div>
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockResetOutlinedIcon />
          </Avatar>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography variant="h5" component="h5">
              Forget Password
            </Typography>
            <Typography>
              Enter your email and we&apos;ll send you a link to reset your password.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="resetEmail"
              label="Email Address"
              name="resetEmail"
              autoComplete="email"
              autoFocus
              sx={{ ...textFieldStyling }}
              InputLabelProps={{
                style: { color: 'white' }, // Change label color
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              data-cy-submit-button
              disabled={result.isLoading || result.isSuccess}
            >
              GET RESET LINK
            </Button>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button onClick={toggleForgotPassword}>Go back</Button>
              </Grid>
            </Grid>
          </Box>
          <Box mt={5}></Box>
        </Box>
      </div>
    </div>
  );
}

export default PasswordReset;
