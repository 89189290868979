import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled,
  Button,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { blue, grey } from '@mui/material/colors';
import { useCreateCourseViolationMutation } from '../../../app/services/StudentCoursesApi';
import { useSnackbar } from 'notistack';

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    width: 95%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
function ReportCourseViolation(props) {
  const { course_id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [abuse, setAbuse] = useState({
    issue: '',
    details: '',
  });
  const [createCourseViolation, result] = useCreateCourseViolationMutation();

  const handleSelectAbuse = (e) => {
    setAbuse({ ...abuse, issue: e.target.value });
  };

  const handleAbuseDetails = (e) => {
    setAbuse({ ...abuse, details: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const courseViolationData = {
      issue_type: abuse.issue,
      issue_details: abuse.details,
      course_id: course_id,
    };
    if (courseViolationData.issue_type && courseViolationData.issue_details) {
      createCourseViolation(courseViolationData);
    } else {
      enqueueSnackbar('Issue type or issue details is missing', {
        variant: 'error',
      });
    }
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <FormControl fullWidth sx={{ marginTop: '1rem' }}>
          <InputLabel id="demo-simple-select-label">Issue Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={abuse?.issue}
            label="Issue Type"
            onChange={handleSelectAbuse}
          >
            <MenuItem value={'Inappropriate Course Content'}>
              Inappropriate Course Content
            </MenuItem>
            <MenuItem value={'Inappropriate Behavior'}>
              Inappropriate Behavior
            </MenuItem>
            <MenuItem value={`${process.env.REACT_APP_NAME} Policy Violation`}>
              {`${process.env.REACT_APP_NAME}`} Policy Violation
            </MenuItem>
            <MenuItem value={'Spammy Content'}>Spammy Content</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl>
        <Typography sx={{ marginTop: '1rem' }}>Issue Details</Typography>
        <TextareaAutosize
          required
          labelId="demo-simple-select-label"
          fullWidth
          id="Details"
          placeholder="Issue Details"
          name="Details"
          autoComplete="Details"
          autoFocus
          size="large"
          sx={{ minHeight: '150px', maxWidth: '100%', minWidth: '100%' }}
          defaultValue={abuse?.details}
          onChange={handleAbuseDetails}
        />
        <Button
          type="submit"
          disabled={result.isLoading || result.isSuccess}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {' '}
          Submit
        </Button>
      </Box>
    </>
  );
}

export default ReportCourseViolation;
