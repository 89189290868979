import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  signInUserWithEmailAndPasswordRequest,
  signInUserWithGoogleRequest,
  signOutUserRequest,
  updateSignInStatusRequest,
} from '../services/AuthRequests';

// Initial Auth state of the application
const initialState = {
  isSignedIn: false,
  user: null,
  token: '',
  forgotPassword: false,
};

// Async Promise lifecycle action types to update redux state from localstorage. This function is called from App.js
export const updateLocalSignInStatus = createAsyncThunk(
  'authentication/updateLoginStatus',
  () => {
    try {
      const response = updateSignInStatusRequest();
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Async Promise lifecycle action types to update redux state to sign in user.
export const signIn = createAsyncThunk(
  'authentication/login',
  async (authData, thunkAPI) => {
    const response = await signInUserWithEmailAndPasswordRequest(authData);
    if (response.type === 'error') {
      return thunkAPI.rejectWithValue(response);
    }
    return response;
  }
);

// Async Promise lifecycle action types to update redux state to sign out user.
export const signOut = createAsyncThunk('authentication/logout', async (token) => {
  try {
    const response = await signOutUserRequest(token);
    return response;
  } catch (error) {
    return error;
  }
});

// Async Promise lifecycle action types to update redux state to sign in user with google.
export const signInWithGoogle = createAsyncThunk(
  'authentication/signInWithGoogle',
  async (authData, thunkAPI) => {
    const response = await signInUserWithGoogleRequest(authData);
    if (response.type === 'error') {
      return thunkAPI.rejectWithValue(response);
    }
    return response;
  }
);

// Auth slice reducers with auth state update based on above function responses
export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    toggleforgotPasswordButton: (state) => {
      state.forgotPassword = !state.forgotPassword;
    },
  },
  extraReducers: {
    [updateLocalSignInStatus.fulfilled]: (state, action) => {
      state.isSignedIn = action.payload.isSignedIn;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    [signIn.fulfilled]: (state, action) => {
      state.isSignedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.access_token;
    },
    [signIn.rejected]: (state) => {
      state.isSignedIn = false;
      state.user = null;
    },
    [signInWithGoogle.fulfilled]: (state, action) => {
      state.isSignedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.access_token;
    },
    [signInWithGoogle.rejected]: (state) => {
      state.isSignedIn = false;
      state.user = null;
    },
    [signOut.fulfilled]: (state) => {
      state.isSignedIn = false;
      state.user = {};
      state.token = '';
    },
  },
});

export const { toggleforgotPasswordButton } = authSlice.actions;

export default authSlice.reducer;
