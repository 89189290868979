import axios from 'axios';

// Server URL with api appended
// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

export const uploadCourseImageRequest = async (details, courseId) => {
  try {
    const response = await axios.post(
      `${API_URL}/course/image/upload/${courseId}`,
      {
        file: details.file,
        file_type: 'course',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadExamImageRequest = async (details, examId) => {
  try {
    const response = await axios.post(
      `${API_URL}/exam/image/upload/${examId}`,
      {
        file: details.file,
        file_type: 'exam',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadTestImageRequest = async (details, testId) => {
  try {
    const response = await axios.post(
      `${API_URL}/test/image/upload/${testId}`,
      {
        file: details.file,
        file_type: 'test',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadEducatorProfilePictureRequest = async (details, educatorId) => {
  try {
    const response = await axios.post(
      `${API_URL}/educator/profile/picture/upload/${educatorId}`,
      {
        file: details.file,
        file_type: 'profile_picture',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadStudentProfilePictureRequest = async (details, studentId) => {
  try {
    const response = await axios.post(
      `${API_URL}/student/profile/picture/upload/${studentId}`,
      {
        file: details.file,
        file_type: 'profile_picture',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadSupportTicketImageRequest = async (details, id) => {
  try {
    const response = await axios.post(
      `${API_URL}/support/ticket/image/upload/${id}`,
      {
        file: details.file,
        file_type: 'support',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadEducatorDocumentRequest = async (details, bankInfoId) => {
  try {
    const response = await axios.post(
      `${API_URL}/educator/document/upload/${bankInfoId}`,
      {
        file: details.file,
        file_type: details.fileType,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadCourseExamImageRequest = async (details, examId) => {
  try {
    const response = await axios.post(
      `${API_URL}/course/exam/image/upload/${examId}`,
      {
        file: details.file,
        file_type: 'exam',
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${details.token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
