import {
  Button,
  Box,
  TextField,
  FormControlLabel,
  Grid,
  IconButton,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCreateQuestionMutation } from '../../../../app/services/ExamsApi';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function CreateNewQuestion(props) {
  const { examId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [createQuestion, result] = useCreateQuestionMutation();

  const [options, setOptions] = useState([]);

  const validateData = (questionData) => {
    let err = true;

    if (!questionData.question || !options.length > 1) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const questionData = {
      exam_id: examId,
      question: data.get('question'),
      options: options,
    };

    const res = validateData(questionData);
    if (!res && options.length > 1) {
      createQuestion(questionData);
    } else {
      enqueueSnackbar(
        'Error adding question. Please provide valid question and options',
        {
          variant: 'error',
        }
      );
    }
  };
  const addOption = () => {
    setOptions([...options, { isCorrect: false, optionText: '' }]);
  };
  const handleValueChange = (index, e) => {
    const updatedOptions = options.map((value, i) => {
      if (i === index) {
        return { ...value, optionText: e.target.value };
      } else {
        return value;
      }
    });
    setOptions(updatedOptions);
  };
  const deleteOption = (singleOption) => {
    setOptions(options.filter((option) => option !== singleOption));
  };
  const handleChange = (index, e) => {
    const updatedOptions = options.map((value, i) => {
      if (i === index) {
        return { ...value, isCorrect: e.target.value === 'right' ? true : false };
      } else {
        return value;
      }
    });
    setOptions(updatedOptions);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="question"
          label="Question"
          name="question"
          autoComplete="question"
          autoFocus
        />

        {options.map((singleOption, index) => (
          <Box key={index}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={8}>
                <TextField
                  autoFocus
                  margin="dense"
                  label={`option ${index + 1}`}
                  value={singleOption.optionText}
                  onChange={(e) => handleValueChange(index, e)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={singleOption.isCorrect ? 'right' : 'wrong'}
                    onChange={(e) => handleChange(index, e)}
                  >
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Right"
                    />
                    <FormControlLabel
                      value="wrong"
                      control={<Radio />}
                      label="Wrong"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <div
                  className="font-icon-wrapper"
                  onClick={() => deleteOption(singleOption)}
                >
                  <IconButton aria-label="delete">
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Button onClick={addOption}>Add Option</Button>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Question
        </Button>
      </Box>
    </>
  );
}

export default CreateNewQuestion;
