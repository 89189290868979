/* eslint-disable no-unused-vars */
import { CheckCircleOutline, CurrencyRupee } from '@mui/icons-material';
import {
  Grid,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Chip,
  Alert,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { usePublishedEducatorSubscriptionPlansQuery } from '../../../app/services/SubscriptionPlansApi';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useCreateEducatorPaymentOrderIdMutation,
  useCreateEducatorSubscriptionWithPaymentMutation,
} from '../../../app/services/EducatorSubscriptionApi';
import { useSnackbar } from 'notistack';

const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

/* eslint-disable no-unused-vars */
function EducatorSubscriptionPlans() {
  const { data, isSuccess } = usePublishedEducatorSubscriptionPlansQuery();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedPaymentDuration, setSelectedPaymentDuration] = useState('Month');
  let [totalCost, setTotalCost] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [createSubscriptionId, res] = useCreateEducatorPaymentOrderIdMutation();

  const [createSubscriptionPlanPayment, result1] =
    useCreateEducatorSubscriptionWithPaymentMutation();
  const showModal = () => {
    setVisible(true);
  };
  const createOrderId = async (planId, totalAmount) => {
    const createOrderIdRequest = await createSubscriptionId({
      amount: totalAmount,
      currency: 'INR',
      educator_subscription_plan_id: planId,
    });
    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId, totalAmount, planId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: totalAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setLoading(true);

          await createSubscriptionPlanPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: totalAmount,
            email: user.email,
            educator_subscription_plan_id: planId,
            bill_type: selectedPaymentDuration == 'Year' ? 'annual' : 'per_month',
          })
            .unwrap()
            .then((res) => {
              if (res.subscription.id) {
                enqueueSnackbar('Subscription Success.', {
                  variant: 'success',
                });

                navigate(`/subscribed-plans/${res.subscription.id}`);
              } else {
                enqueueSnackbar('Subscription Error. ', {
                  variant: 'error',
                });
              }
            });
          // .finally(() => {
          //   refetch();
          // });
          setSuccess(true);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async (planId, totalAmount) => {
    const orderId = await createOrderId(planId, totalAmount);
    razorPayPoUp(orderId, totalAmount, planId);
  };

  const displayAnnualCost = (monthlyCost) => {
    const discount = (monthlyCost * 10) / 100;
    const theCost = monthlyCost - discount;

    return Math.round(theCost * 12);
  };

  const onSubscribe = (planId, monthlyCost) => {
    let totalAmount = 0;
    if (selectedPaymentDuration == 'Year') {
      const discount = (monthlyCost * 10) / 100;
      const theCost = monthlyCost - discount;
      totalAmount += Math.round(theCost * 12);
    } else {
      totalAmount += monthlyCost;
    }

    if (isSignedIn && user?.role === 'educator') {
      handleRazorPayPayment(planId, totalAmount);
    } else {
      navigate('/educator-account', {
        state: location.pathname,
      });
    }
  };
  const onDashboardClick = () => {
    navigate('/educator/dashboard');
  };

  //   if (success && result1?.data?.response?.id) {
  //     return (
  //       <>
  //         <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //           Successfully Onboarded.
  //         </Alert>
  //         <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //           Amount Paid: {result1?.data?.response?.paid_amount}
  //         </Alert>{' '}
  //         <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //           Status: {result1?.data?.response?.status}
  //         </Alert>
  //         <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //           Transaction Id: {result1?.data?.response?.transaction_id}
  //         </Alert>
  //         <Button variant="contained" color="secondary" onClick={onDashboardClick}>
  //           Go To Dashboard{' '}
  //         </Button>
  //       </>
  //     );
  //   }

  //   if (res?.isError) {
  //     enqueueSnackbar(res.error?.data?.message, {
  //       variant: 'error',
  //     });
  //   }

  return (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Grid
        container
        spacing={2}
        // sx={{ background: '#ddf0f5' }}
        justifyContent={'center'}
      >
        <Grid item xl={9} lg={9} md={10} sm={11} xs={11}>
          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display={'flex'}
              alignSelf={'center'}
              justifyContent={'center'}
            >
              <Typography
                sx={{
                  color: '#1b75bb',
                  fontSize: '24px',
                  fontWeight: 500,
                  maxWidth: '940px',
                  textAlign: 'center',
                  alignSelf: 'center',
                  marginTop: '3rem',
                }}
              >
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: '40px',
                    lineHeight: 0,
                  }}
                >
                  Unlock premium features
                </span>{' '}
                and elevate your teaching experience with GM&apos;s subscription
                plans. Choose the plan
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: '40px',
                    lineHeight: 0,
                  }}
                >
                  {' '}
                  that suits your needs
                </span>{' '}
                and start creating impactful learning experiences today
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display={'flex'}
        alignSelf={'center'}
        justifyContent={'center'}
        paddingBottom={'1rem'}
        paddingTop={'2rem !important'}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            position: 'relative',
          }}
        >
          <div>
            <Button
              variant={
                selectedPaymentDuration === 'Month' ? 'contained' : 'outlined'
              }
              sx={{
                borderRadius: '0px',
                background: selectedPaymentDuration === 'Month' ? '#20acc1' : '',
                ':hover': {
                  background: selectedPaymentDuration === 'Month' ? '#20acc1' : '',
                },
              }}
              onClick={() => {
                setSelectedPaymentDuration('Month');
              }}
            >
              Monthly Billing
            </Button>
            <Button
              variant={selectedPaymentDuration === 'Year' ? 'contained' : 'outlined'}
              sx={{
                borderRadius: '0px',
                background: selectedPaymentDuration === 'Year' ? '#20acc1' : '',
                ':hover': {
                  background: selectedPaymentDuration === 'Year' ? '#20acc1' : '',
                },
              }}
              onClick={() => {
                setSelectedPaymentDuration('Year');
              }}
            >
              Yearly Billing
            </Button>
          </div>
          {selectedPaymentDuration == 'Year' && (
            // <Typography sx={{ position: 'absolute', right: '-90px' }}>
            //   Save 10%
            // </Typography>
            <Chip label="Save 10%" />
          )}
        </div>
      </Grid>

      <Grid container justifyContent={'center'}>
        <Grid
          item
          xl={11}
          lg={11}
          md={12}
          sm={12}
          xs={12}
          sx={{ marginTop: '1rem' }}
        >
          <Grid
            container
            spacing={2}
            sx={{ position: 'sticky', top: 0, zIndex: 1000, background: '#fff' }}
          >
            <Grid item xl={1} lg={1}></Grid>
            {data?.educatorSubscriptionPlans?.map((singlePlan) => (
              <Grid item xl={3} lg={3} key={singlePlan?.title}>
                <Box
                  sx={{
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                    padding: '1rem',
                    borderRadius: '20px',
                    background: '#f2fdff',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflowWrap: 'anywhere',
                  }}
                  component={'div'}
                >
                  <div>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: '#000',
                        textAlign: 'center',
                      }}
                    >
                      {singlePlan?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#000',
                        textAlign: 'center',
                      }}
                    >
                      {singlePlan?.description}
                    </Typography>
                  </div>
                  <List>
                    {singlePlan.features?.length >= 1
                      ? singlePlan.features.map((singleFeature) => (
                          <ListItem key={singleFeature}>
                            <ListItemIcon>
                              {singleFeature.icon === true ? (
                                <CheckIcon />
                              ) : (
                                <ClearIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={singleFeature.text} />
                          </ListItem>
                        ))
                      : null}
                  </List>
                  <div>
                    {singlePlan.cost_per_month ? (
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 700,
                          color: '#000',
                          textAlign: 'center',
                          marginTop: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CurrencyRupee />
                        {selectedPaymentDuration == 'Year'
                          ? displayAnnualCost(singlePlan.cost_per_month)
                          : singlePlan.cost_per_month}
                      </Typography>
                    ) : null}

                    {/* <Button
                  variant="contained"
                  sx={{
                    background: '#20b0c5',
                    width: '100%',
                    marginTop: '1rem',
                    ':hover': {
                      background: '#20b0c5',
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (singlePlan?.name == 'Trail Plan') {
                      isSignedIn
                        ? navigate('/', {
                            state: location.pathname,
                          })
                        : navigate('/educator-account?isSignUp=true', {
                            state: location.pathname,
                          });
                    } else if (
                      selectedPaymentDuration === 'Year' &&
                      singlePlan?.price?.annual_monthly > 0
                    ) {
                      totalCost = singlePlan?.price?.annual_monthly;
                      setTotalCost(totalCost);
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    } else if (
                      selectedPaymentDuration === 'Month' &&
                      singlePlan?.price?.monthly > 0
                    ) {
                      totalCost = singlePlan?.price?.monthly;
                      setTotalCost(totalCost);
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    }
                  }}
                  type="button"
                >
                  {singlePlan?.buttonText ? singlePlan?.buttonText : 'Get Sarted'}
                </Button> */}

                    {singlePlan.title === 'Custom' ? (
                      <Button
                        variant="contained"
                        sx={{
                          background: '#20b0c5',
                          width: '100%',
                          marginTop: '1rem',
                          ':hover': {
                            background: '#20b0c5',
                          },
                        }}
                      >
                        Contact Sales
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          background: '#20b0c5',
                          width: '100%',
                          marginTop: '1rem',
                          ':hover': {
                            background: '#20b0c5',
                          },
                        }}
                        onClick={() =>
                          onSubscribe(singlePlan.id, singlePlan.cost_per_month)
                        }
                      >
                        Subscribe
                      </Button>
                    )}
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EducatorSubscriptionPlans;
