/* eslint-disable no-unused-vars */

import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
  AlertTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router';

import { usePublicTestQuery } from '../../../app/services/PublicTestsApi';
import React from 'react';
import CreateNewPracticeTest from '../student/CreateNewPracticeTest';
import DisplayTestSubjectsAndTopics from './DisplayTestSubjectsAndTopics';
import DisplayTestReviews from './DisplayTestReviews';
import DataLoader from '../../../components/DataLoader';
import {
  useCreateStudentTestEnrolPaymentOrderIdMutation,
  useCreateStudentTestEnrolWithPaymentMutation,
  useTestEnrolMutation,
} from '../../../app/services/StudentTestsApi';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

function DisplaySingleTest() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess, refetch } = usePublicTestQuery({
    id: parseInt(params.testId, 10),
  });
  const [testEnrol, result] = useTestEnrolMutation();

  const [createEnrolOrderId] = useCreateStudentTestEnrolPaymentOrderIdMutation();
  const [createTestEnrolPayment] = useCreateStudentTestEnrolWithPaymentMutation();

  if (result.isSuccess) {
    return <Navigate replace to={`/enrols/test/${result.data.enrol.id}`} />;
  }

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);

    const createOrderIdRequest = await createEnrolOrderId({
      amount: data.test.cost,
      currency: 'INR',
      test_id: data.test.id,
    });

    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: data.test.cost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          enqueueSnackbar('Error Paying for the test. ', {
            variant: 'error',
          });
        } else {
          await createTestEnrolPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: data.test.cost,
            email: user.email,
            test_id: data.test.id,
          })
            .unwrap()
            .then((res) => {
              if (res.enrol.id) {
                enqueueSnackbar('Enrolled Test with success.', {
                  variant: 'success',
                });
                navigate(`/enrols/test/${res.enrol.id}`);
              } else {
                enqueueSnackbar('Error Enroling for test. ', {
                  variant: 'error',
                });
              }
            })
            .finally(() => {
              refetch();
            });
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };

  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  const enroltest = async () => {
    await testEnrol({ test_id: data.test.id });
  };

  return isSuccess && data.test ? (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: data.test.url
            ? `url(${data.test.url})`
            : `url("https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: 'none' }}
            src={
              data.test.url ||
              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
            }
            // alt={data.test.imageText}
          />
        }
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <Typography component="h2" variant="h5">
                <strong>{data.test.title}</strong>
              </Typography>

              <Typography variant="subtitle1">
                In&nbsp;
                {data.test.language}
              </Typography>
              {data.test.test_reviews_count ? (
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Typography>
                      {Math.round(data.test.test_reviews_avg_rating * 10) / 10}&nbsp;
                    </Typography>
                  </Box>
                  <Rating
                    name="read-only"
                    value={Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                    readOnly
                    precision={0.5}
                    className="custom-rating"
                  />

                  {data.test.test_reviews_count ? (
                    <Typography>
                      &#40;{data.test.test_reviews_count}
                      &#41;
                    </Typography>
                  ) : null}
                </Box>
              ) : (
                <Rating
                  name="read-only"
                  // value={Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                  readOnly
                  precision={0.5}
                  className="custom-rating"
                />
              )}
              {/* {data.test.cost ? (
                <Typography component="h2" color="text.primary">
                  <strong>₹{data.test.cost}</strong>
                </Typography>
              ) : (
                <Typography>Free Practice Test</Typography>
              )} */}
              <CardActions sx={{ padding: 0 }}>
                {isSignedIn && user.role !== 'student' ? (
                  <Alert severity="info">
                    Please register as a student to enrol for the test
                  </Alert>
                ) : data?.test?.alreadyEnroled?.id ? (
                  <>
                    <Alert severity="info">
                      <>
                        <AlertTitle>
                          You are already Enrolled to this test.
                        </AlertTitle>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() =>
                            navigate(
                              `/enrols/test/${data?.test?.alreadyEnroled?.id}`
                            )
                          }
                        >
                          Click here to view test and practice
                        </Button>
                      </>
                    </Alert>
                  </>
                ) : data.test.cost ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/student-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Buy This Test (₹{data.test.cost})
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      isSignedIn
                        ? enroltest()
                        : navigate('/student-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Enroll (Free)
                  </Button>
                )}

                {/* 
                {isSignedIn && user.role !== 'student' ? (
                  <Alert severity="info">
                    Please register as a student to enrol for the exam
                  </Alert>
                ) : data?.test?.alreadyEnroled?.id ? (
                  <>
                    <Alert severity="info">
                      <>
                        <AlertTitle>You have already Paid for this test.</AlertTitle>
                        <>
                          <Button variant="contained" onClick={handleClickOpen}>
                            Create Your New Practice Test (Free)
                          </Button>
                          <>
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              fullWidth
                              maxWidth="sm"
                            >
                              <DialogTitle>
                                {' '}
                                Create Your New Practice Test
                              </DialogTitle>
                              <DialogContent>
                                <CreateNewPracticeTest test={data.test} />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        </>
                      </>
                    </Alert>
                  </>
                ) : data.test.cost ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/student-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Buy This Test (₹{data.test.cost})
                  </Button>
                ) : (
                  <>
                    <Button variant="contained" onClick={handleClickOpen}>
                      Create Your New Practice Test (Free)
                    </Button>
                    <>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="sm"
                      >
                        <DialogTitle> Create Your New Practice Test</DialogTitle>
                        <DialogContent>
                          <CreateNewPracticeTest test={data.test} />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  </>
                )} */}
              </CardActions>
            </Box>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 3 },
              pr: { md: 0 },
              overflowWrap: 'anywhere',
            }}
          >
            <Typography component="h2" variant="h5">
              <strong>Test Description</strong>
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {data.test.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <Alert severity="info">
                {data.test.cost
                  ? 'Buy Once and create any number of practice tests.'
                  : 'Create any number of practice tests.'}{' '}
              </Alert>
              <Typography component="h2" variant="h5">
                <strong>
                  You can choose Questions from these Subjects and Topics
                </strong>
              </Typography>
              <DisplayTestSubjectsAndTopics testSubjects={data.test.test_subjects} />
            </Box>
          </>
        </Grid>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <DisplayTestReviews testReviews={data.test.test_reviews} />
            </Box>
          </>
        </Grid>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>
    </>
  ) : (
    <p>No test found</p>
  );
}

export default DisplaySingleTest;
