/* eslint-disable no-unused-vars */

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import React from 'react';
import { useCourseExamsQuery } from '../../../app/services/CourseExamsApi';
import DataLoader from '../../../components/DataLoader';
import CourseExamsList from './CourseExamsList';

function CourseExamsManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useCourseExamsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess ? (
            <CourseExamsList exams={data?.exams} />
          ) : (
            <p>No exams found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CourseExamsManager;
