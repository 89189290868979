/* eslint-disable no-unused-vars */
import {
  useEducatorEarningsQuery,
  useVerifyBankAccountMutation,
} from '../../../app/services/EducatorApi';
import { CurrencyRupee, ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import CreateWithdrawalRequest from './CreateWithdrawalRequest';

function TotalEarnings() {
  const { data, isLoading, isSuccess } = useEducatorEarningsQuery();
  const [open, setOpen] = React.useState(false);
  const [createRequest, result] = useVerifyBankAccountMutation();

  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const handleClickOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //   const viewWithdrawal = (id) => {
  //     navigate(`/educator/${id}`);
  //   };

  const handleVerifyAccount = () => {
    createRequest();
  };

  return data ? (
    <>
      <Typography>
        Total Earnings:
        <CurrencyRupee />
        {data.totalCourseEarnings + data.totalExamEarnings + data.totalTestEarnings}
      </Typography>
      <Typography>
        Total Course Earnings: <CurrencyRupee />
        {data.totalCourseEarnings}
      </Typography>
      <Typography>
        Total Exam Earnings: <CurrencyRupee />
        {data.totalExamEarnings}
      </Typography>
      <Typography>
        Total Test Earnings: <CurrencyRupee />
        {data.totalTestEarnings}
      </Typography>
      <Typography>
        Total Previous successful Withdrawals: <CurrencyRupee />
        {data.totalAmountWithdrawals}
      </Typography>
      {/* <div style={{ textAlign: 'center' }}>
        <Button variant="contained" onClick={() => handleVerifyAccount()}>
          {' '}
          Verify Account
        </Button>
      </div> */}
      {/* &nbsp; */}
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          onClick={() => handleClickOpen()}
          disabled={
            data.totalCourseEarnings +
              data.totalExamEarnings +
              data.totalTestEarnings <
            data.totalAmountWithdrawals
          }
        >
          {data.totalCourseEarnings +
            data.totalExamEarnings +
            data.totalTestEarnings >
          data.totalAmountWithdrawals
            ? ' Create New Payment Withdrawal Request'
            : 'Not enough balance'}
        </Button>
      </div>
      <>
        <Typography
          component="div"
          variant="h5"
          sx={{ padding: 4, color: '#233c8d' }}
        >
          Educator Payment Withdrawals
        </Typography>
        <Paper sx={{ height: 400, width: '100%', overflow: 'scroll' }}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created On</TableCell>
                  <TableCell>
                    <span style={{ marginLeft: '1rem' }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.totalWithdrawals?.map((singleWithdrawal, index) => (
                  <TableRow
                    key={singleWithdrawal.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      <CurrencyRupee />
                      {singleWithdrawal.amount}
                    </TableCell>
                    <TableCell>{singleWithdrawal.status}</TableCell>
                    <TableCell>
                      {moment(singleWithdrawal.created_at).format(
                        'Do MMM YY hh:mm A'
                      )}
                    </TableCell>{' '}
                    <TableCell>
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 200,
                          bgcolor: 'background.paper',
                        }}
                      >
                        <ListItemButton
                          onClick={() => handleClick(index)}
                          aria-expanded={expandedId === index}
                        >
                          <ListItemText primary="Options" />
                          <ExpandMore />
                        </ListItemButton>
                        <Collapse
                          in={expandedId === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 4 }}
                              //   onClick={() => viewWithdrawal(singleWithdrawal.id)}
                              disabled
                            >
                              <ListItemText primary="View" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Create Payment Withdrawal Request</DialogTitle>
        <DialogContent>
          <CreateWithdrawalRequest
            balance={
              data.totalCourseEarnings +
              data.totalExamEarnings +
              data.totalTestEarnings
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Typography>No data available</Typography>
  );
}

export default TotalEarnings;
