/* eslint-disable no-unused-vars */

import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
  AlertTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import React from 'react';
import { useTestQuery } from '../../../app/services/TestsApi';
import DataLoader from '../../../components/DataLoader';
import DisplayTestSubjectsAndTopics from '../public/DisplayTestSubjectsAndTopics';

function DisplayTestPreview(props) {
  const { testId } = props;

  const { data, isLoading, isSuccess } = useTestQuery({
    id: testId,
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.test ? (
    <>
      <Grid container>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          <Paper
            sx={{
              position: 'relative',
              backgroundColor: 'grey.800',
              color: '#fff',
              mb: 4,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: data.test.url
                ? `url(${data.test.url})`
                : `url("https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
            }}
          >
            {/* Increase the priority of the hero background image */}
            {
              <img
                style={{ display: 'none' }}
                src={
                  data.test.url ||
                  `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                }
                // alt={data.test.imageText}
              />
            }
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,.3)',
              }}
            />
            <Grid container>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    position: 'relative',
                    p: { xs: 3, md: 6 },
                    pr: { md: 0 },
                    overflowWrap: 'anywhere',
                  }}
                >
                  <Typography component="h2" variant="h5">
                    <strong>{data.test.title}</strong>
                  </Typography>

                  <Typography variant="subtitle1">
                    In&nbsp;
                    {data.test.language}
                  </Typography>
                  {data.test.test_reviews_count ? (
                    <Box sx={{ display: 'flex' }}>
                      <Box>
                        <Typography>
                          {Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                          &nbsp;
                        </Typography>
                      </Box>
                      <Rating
                        name="read-only"
                        value={
                          Math.round(data.test.test_reviews_avg_rating * 10) / 10
                        }
                        readOnly
                        precision={0.5}
                        className="custom-rating"
                      />

                      {data.test.test_reviews_count ? (
                        <Typography>
                          &#40;{data.test.test_reviews_count}
                          &#41;
                        </Typography>
                      ) : null}
                    </Box>
                  ) : (
                    <Rating
                      name="read-only"
                      // value={Math.round(data.test.test_reviews_avg_rating * 10) / 10}
                      readOnly
                      precision={0.5}
                    />
                  )}
                  {/* {data.test.cost ? (
                <Typography component="h2" color="text.primary">
                  <strong>₹{data.test.cost}</strong>
                </Typography>
              ) : (
                <Typography>Free Practice Test</Typography>
              )} */}
                  <CardActions sx={{ padding: 0 }}>
                    {data.test.cost ? (
                      <Button variant="contained" color="primary">
                        Buy This Test (₹{data.test.cost})
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary">
                        Enroll (Free)
                      </Button>
                    )}
                  </CardActions>
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
            <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
              <Box
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 3 },
                  pr: { md: 0 },
                  overflowWrap: 'anywhere',
                }}
              >
                <Typography component="h2" variant="h5">
                  <strong>Test Description</strong>
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  {data.test.description}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
            <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
              <>
                <Box
                  sx={{
                    position: 'relative',
                    p: { xs: 3, md: 3 },
                    pr: { md: 0 },
                    overflowWrap: 'anywhere',
                  }}
                >
                  <Alert severity="info">
                    {data.test.cost
                      ? 'Buy Once and create any number of practice tests.'
                      : 'Create any number of practice tests.'}{' '}
                  </Alert>
                  <Typography component="h2" variant="h5">
                    <strong>
                      You can choose Questions from these Subjects and Topics
                    </strong>
                  </Typography>
                  <DisplayTestSubjectsAndTopics
                    testSubjects={data.test.test_subjects}
                  />
                </Box>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>

            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
            <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
              <>
                <Box
                  sx={{
                    position: 'relative',
                    p: { xs: 3, md: 3 },
                    pr: { md: 0 },
                    overflowWrap: 'anywhere',
                  }}
                ></Box>
              </>
            </Grid>
            <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>No test found</p>
  );
}

export default DisplayTestPreview;
