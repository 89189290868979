/* eslint-disable no-unused-vars */

import { Alert, Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import HlsJs from '../../../features/courses/HlsJs';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import {
//   useCreateEducatorOnboardPaymentOrderIdMutation,
//   useCreateEducatorOnboardWithPaymentMutation,
// } from '../../../app/services/EducatorSubscriptionPlansApi';
import { Helmet } from 'react-helmet';
import { CheckCircleOutline } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
const modelStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 10,
};

const allVideoCards = [
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `How to register on our platform`,
  },
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `Create Course`,
  },
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `Upload Curriculum`,
  },
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `Preview Course`,
  },
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `Publish Course`,
  },
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `Teach & Engage`,
  },
  {
    video_url: `https://d2vhj83ctq60no.cloudfront.net/educator/course/39/Job%20oriented%20Datacenter%20Program/Video-20231128_124602-Meeting_Recording_973568_39.m3u8`,
    title: `Earn`,
  },
];

const totalCost = 999;
const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

export const HowToUseApp = () => {
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [createOnboardingId, res] = useCreateEducatorOnboardPaymentOrderIdMutation();

  // const [createSubscriptionPlanPayment, result1] =
  //   useCreateEducatorOnboardWithPaymentMutation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const onDashboardClick = () => {
    navigate('/educator/dashboard');
  };
  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);
    // const createOrderIdRequest = await createOnboardingId({
    //   amount: totalCost,
    //   currency: 'INR',
    // });
    // return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: totalCost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setLoading(true);

          // const subscriptionRes = await createSubscriptionPlanPayment({
          //   razorpay_payment_id: response.razorpay_payment_id,
          //   razorpay_signature: response.razorpay_signature,
          //   order_id: orderId,
          //   amount: totalCost,
          //   email: user.email,
          // });
          setSuccess(true);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  // if (success && result1?.data?.response?.id) {
  //   return (
  //     <>
  //       <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //         Successfully Onboarded.
  //       </Alert>
  //       <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //         Amount Paid: {result1?.data?.response?.paid_amount}
  //       </Alert>{' '}
  //       <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //         Status: {result1?.data?.response?.status}
  //       </Alert>
  //       <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
  //         Transaction Id: {result1?.data?.response?.transaction_id}
  //       </Alert>
  //       <Button variant="contained" color="secondary" onClick={onDashboardClick}>
  //         Go To Dashboard{' '}
  //       </Button>
  //     </>
  //   );
  // }

  // if (res?.isError) {
  //   enqueueSnackbar(res.error?.data?.message, {
  //     variant: 'error',
  //   });
  // }

  return (
    <Fragment>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Grid container spacing={2} justifyContent={'center'}>
        <Typography
          sx={{
            color: '#083fae',
            fontSize: '36px',
            fontWeight: 700,
            textAlign: 'center',
            marginTop: '4rem',
            width: '100%',
          }}
        >
          How to use our platform
        </Typography>
        <Grid item xl={9} lg={9} md={10} sm={11} xs={11}>
          <Grid container spacing={2}>
            {Array.isArray(allVideoCards) &&
              allVideoCards.length > 0 &&
              allVideoCards.map((singleVideo, index) => (
                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} key={'ind' + index}>
                  <div style={{ padding: '1rem' }}>
                    <HlsJs vidSrc={singleVideo?.video_url} />
                    <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                      {singleVideo?.title}
                    </Typography>
                  </div>
                </Grid>
              ))}
          </Grid>
          <div
            style={{
              width: '100%',
              display: 'grid',
              justifyContent: 'center',
              paddingTop: '2rem',
              paddingBottom: '2rem',
            }}
          >
            <Button
              variant="contained"
              sx={{ background: '#ee3163', fontSize: '24px', fontWeight: 700 }}
              onClick={() => {
                // setOpen(true);
                navigate('/teacher-plan');
              }}
            >
              Join us now!
            </Button>
          </div>
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...modelStyle, width: 350, maxHeight: 600, minHeight: 550 }}>
              <Typography
                id="parent-modal-title"
                sx={{
                  background: '#20b0c5',
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: 600,
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                Trail Plan
              </Typography>
              <div style={{ padding: '2rem' }}>
                <div
                  style={{
                    height: '100px',
                    alignSelf: 'center',
                    display: 'contents',
                  }}
                >
                  <p
                    style={{
                      fontSize: '34px',
                      fontWeight: 700,
                      margin: '0rem',
                      textAlign: 'center',
                    }}
                  >
                    999{' '}
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                      }}
                    >
                      Exculdes GST
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 500,
                      margin: '0rem',
                      textAlign: 'center',
                      color: '#2bb08c',
                      marginTop: '0.5rem',
                    }}
                  >
                    Validity 10 Days
                  </p>
                </div>
                <p style={{ color: '#20b0c5', fontSize: '16px', fontWeight: 400 }}>
                  Explore application & entire features
                </p>
                <p style={{ color: '#20b0c5', fontSize: '16px', fontWeight: 400 }}>
                  Publish a Course
                </p>
                <p style={{ color: '#20b0c5', fontSize: '16px', fontWeight: 400 }}>
                  Create a Exam and a Test
                </p>
                <p style={{ color: '#20b0c5', fontSize: '16px', fontWeight: 400 }}>
                  Upload 5 videos of 5 mins each
                </p>
                <p style={{ color: '#20b0c5', fontSize: '16px', fontWeight: 400 }}>
                  10 Days access to Teacher&apos;s Channel
                </p>
                <div
                  style={{
                    height: '100px',
                    alignSelf: 'center',
                    display: 'contents',
                  }}
                >
                  <p
                    style={{
                      fontSize: '28px',
                      fontWeight: 700,
                      margin: '0rem',
                      textAlign: 'center',
                    }}
                  >
                    Start with Trail Plan for user experience
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* <Button
                  variant="contained"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 600,
                    width: '200px',
                    borderRadius: '30px',
                  }}
                  onClick={() => {
                    navigate(`/educator-account?plan=TrialPlan&amount=999`, {
                      state: { isLogin: true },
                    });
                  }}
                >
                  Pay Now
                </Button> */}

                {isSignedIn && user.role !== 'educator' ? (
                  <Alert severity="info">Please register as an educator</Alert>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Pay Now
                  </Button>
                )}
              </div>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Fragment>
  );
};
