/* eslint-disable no-unused-vars */
import { useCreateEducatorSubscriptionPlanMutation } from '../../../app/services/SubscriptionPlansApi';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

function CreateEducatorPlan() {
  const { enqueueSnackbar } = useSnackbar();

  const [createPlan, result] = useCreateEducatorSubscriptionPlanMutation();

  const [features, setFeatures] = useState([]);

  const validateData = (planData) => {
    let err = true;

    if (!planData.title || !features.length > 1) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const planData = {
      title: data.get('title'),
      description: data.get('description'),
      features: features,
      cost_per_month: data.get('cost'),
    };

    const res = validateData(planData);
    if (!res && features.length > 1) {
      createPlan(planData)
        .unwrap()
        .then((res) => {
          if (res) {
            enqueueSnackbar('Created Plan Successfully', {
              variant: 'success',
            });
          }
        })
        .finally(() => {
          // refetch();
        });
    } else {
      enqueueSnackbar('Error adding plan. Please provide valid title and features', {
        variant: 'error',
      });
    }
  };
  const addFeature = () => {
    setFeatures([...features, { icon: false, text: '' }]);
  };
  const handleValueChange = (index, e) => {
    const updatedFeatures = features.map((value, i) => {
      if (i === index) {
        return { ...value, text: e.target.value };
      } else {
        return value;
      }
    });
    setFeatures(updatedFeatures);
  };
  const deleteFeature = (singleFeature) => {
    setFeatures(features.filter((feature) => feature !== singleFeature));
  };
  const handleChange = (index, e) => {
    const updatedFeatures = features.map((value, i) => {
      if (i === index) {
        return { ...value, icon: e.target.value === 'right' ? true : false };
      } else {
        return value;
      }
    });
    setFeatures(updatedFeatures);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
        <Typography>Create New Educator Subscription Plan</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            autoComplete="title"
            autoFocus
          />
          <TextField
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            autoComplete="description"
            autoFocus
            multiline
            rows="2"
          />
          <TextField
            fullWidth
            id="cost"
            label="Cost Per Month"
            name="cost"
            autoComplete="cost"
            autoFocus
            type="number"
          />

          {features?.map((singleFeature, index) => (
            <Box key={index}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={8}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label={`feature ${index + 1}`}
                    value={singleFeature.text}
                    onChange={(e) => handleValueChange(index, e)}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={singleFeature.icon ? 'right' : 'wrong'}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <FormControlLabel
                        value="right"
                        control={<Radio />}
                        label="Right"
                      />
                      <FormControlLabel
                        value="wrong"
                        control={<Radio />}
                        label="Wrong"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <div
                    className="font-icon-wrapper"
                    onClick={() => deleteFeature(singleFeature)}
                  >
                    <IconButton aria-label="delete">
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button onClick={addFeature}>Add Feature</Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={result.isLoading || result.isSuccess}
          >
            Add Plan
          </Button>
        </Box>
      </Grid>
      <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
    </Grid>
  );
}

export default CreateEducatorPlan;
