import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import TabPanel from '../../../components/authentication/TabPanel';
import { useLocation } from 'react-router-dom';
import { ReviewandRating } from './ReviewandRating';
import UserFollowers from '../UserFollowers';
import { EducatorNotification } from './EducatorNotification';

export const EducatorEngagement = () => {
  const location = useLocation();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const newActiveIndex = location?.state?.activeIndex;
    if (
      typeof newActiveIndex === 'number' &&
      newActiveIndex !== null &&
      newActiveIndex !== undefined
    ) {
      // setValue(newValue);
      handleChange({}, newActiveIndex);
    }
  }, [location, location?.state]);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          sx={{
            minHeight: '60px',
            background: '#10496a',
            paddingTop: 0,
          }}
          padding={'0rem'}
          className="padding-0"
        >
          <Grid
            container
            justifyContent={'center'}
            className="custom-tab-parent-container"
            sx={{ maxHeight: '60px' }}
          >
            <Grid item xl="10" lg="10" md="11" sm="12" xs="12">
              <Tabs
                value={value}
                onChange={(e, newval) => handleChange(e, newval)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Account forms"
                className="custom-dashboard-tab"
                sx={{ height: '100%' }}
                scrollButtons={true}
                allowScrollButtonsMobile
              >
                <Tab
                  label="My Followers"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                  sx={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Ratings & Reviews"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                  sx={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Notification"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                  sx={{ textTransform: 'capitalize' }}
                />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container justifyContent={'center'} sx={{ background: '#fff' }}>
            <Grid item xl="10" lg="10" md="11" sm="12" xs="12">
              <TabPanel value={value} index={0}>
                <div style={{ background: '#fff' }}>
                  <UserFollowers
                    itemxl="12"
                    itemlg="12"
                    itemmd="12"
                    itemsm="12"
                    itemxs="12"
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ReviewandRating />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <EducatorNotification />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
