/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Alert,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import {
  useCreateTopicMutation,
  useDeleteTopicMutation,
  useUpdateLiveTopicOnSortMutation,
  useUpdateTopicExternalLinkMutation,
  useUpdateTopicLiveMutation,
  useUpdateTopicMutation,
} from '../../../app/services/CoursesApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DeleteOutline, EditNote, SaveOutlined } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import TabPanel from '../../../components/authentication/TabPanel';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DisplayDocument from './DisplayDocument';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import HlsJs from '../HlsJs';
import { BaseApi } from '../../../app/services/BaseApi';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableTopic from './SortableTopic';
import { useNavigate } from 'react-router-dom';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function EditLiveCourseTopic(props) {
  const { token } = useSelector((state) => state.auth);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { subjectId, subject } = props;
  const [addContent, setAddContent] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({
    title: '',
    id: null,
    start_date: null,
    start_time: null,
    end_time: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [createTopic] = useCreateTopicMutation();
  const [deleteTopicId] = useDeleteTopicMutation();
  const dispatch = useDispatch();

  const [updateTopic] = useUpdateTopicMutation();
  const [updateTopicOnSort] = useUpdateLiveTopicOnSortMutation();
  const [value, setValue] = useState(0);
  const [addLecture, setAddLecture] = useState(false);
  const [updateTopicDateAndTime] = useUpdateTopicLiveMutation();
  const [sort, setSort] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateStatus, setDateStatus] = useState(false);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleAddContentButton = (topic) => {
    setSelectedTopic({
      ...selectedTopic,
      title: topic.title,
      id: topic.id,
      start_date: moment(topic.start_date) || null,
      start_time: moment(topic.start_time, 'HH:mm:ss') || null,
      end_time: moment(topic.end_time, 'HH:mm:ss') || null,
    });
    setAddContent(true);
    setDateStatus(false);
  };

  const handleMinimizeButton = () => {
    setAddContent(false);
    setSelectedTopic({
      ...selectedTopic,
      title: '',
      id: null,
      start_date: null,
      start_time: null,
      end_time: null,
    });
    setDateStatus(false);
  };

  const validateData = (topicData) => {
    let err = true;

    if (!topicData.title) {
      err = true;
    } else {
      err = false;
    }

    return err;
  };

  const handleTopicSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const topicData = {
      subject_id: subjectId,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(topicData);
    if (!res) {
      createTopic(topicData)
        .unwrap()
        .then((res) => {
          if (res.topic.id) {
            setAddLecture(false);
          } else {
            console.log(res);
          }
        });
    }
  };

  const handleEditButton = (topic) => {
    setSelectedTopic({
      ...selectedTopic,
      title: topic.title,
      edit: true,
      id: topic.id,
      start_date: moment(topic.start_date) || null,
      start_time: moment(topic.start_time, 'HH:mm:ss') || null,
      end_time: moment(topic.end_time, 'HH:mm:ss') || null,
    });
  };

  const handleUpdateSubmit = () => {
    const topicData = {
      id: selectedTopic.id,
      title: selectedTopic.title,
      description: selectedTopic.title,
    };

    const res = validateData(topicData);
    if (!res) {
      updateTopic(topicData)
        .unwrap()
        .then((res) => {
          if (res.message === 'topic updated successfully') {
            setSelectedTopic({
              ...selectedTopic,
              title: '',
              edit: false,
              id: null,
              start_date: null,
              start_time: null,
              end_time: null,
            });
          }
        });
    }
  };

  const handleTopicTitleChange = (e) => {
    setSelectedTopic({ ...selectedTopic, title: e.target.value });
  };

  const onDateAndTimeSubmit = (topicToUpdate) => {
    // e.preventDefault();
    // const data = new FormData(e.currentTarget);

    if (
      !topicToUpdate.start_date ||
      !topicToUpdate.start_time ||
      !topicToUpdate.end_time
    ) {
      enqueueSnackbar(
        'Please provide start date, start time and end time. You can always change before going live',
        {
          variant: 'error',
        }
      );
      return;
    }
    // if (topicToUpdate.start_time.diff(moment(), 'hours') < 1) {
    //   enqueueSnackbar('Please provide start date an hour before going live', {
    //     variant: 'error',
    //   });
    //   return;
    // }
    if (
      topicToUpdate.end_time.diff(topicToUpdate.start_time, 'm') > 60 ||
      topicToUpdate.end_time.diff(topicToUpdate.start_time, 'm') <= 10
    ) {
      enqueueSnackbar(
        'Please provide proper start time and end time. Live should not be more than 1 hour or less than 10 minutes ',
        {
          variant: 'error',
        }
      );
      return;
    }
    const updateData = {
      start_date: topicToUpdate.start_date.format() || null,
      start_time: topicToUpdate.start_time.format('HH:mm:ss') || null,
      end_time: topicToUpdate.end_time.format('HH:mm:ss') || null,
      id: topicToUpdate.id,
    };
    updateTopicDateAndTime(updateData)
      .unwrap()
      .then((res) => {
        if (res.message === 'topic updated successfully') {
          setSelectedTopic({
            ...selectedTopic,
            start_date: moment(topicToUpdate.start_date) || null,
            start_time: moment(topicToUpdate.start_time, 'HH:mm:ss') || null,
            end_time: moment(topicToUpdate.end_time, 'HH:mm:ss') || null,
          });
          setDateStatus('success');
        } else {
          setDateStatus('error');
        }
      });
  };

  const deleteTopicWithId = (topic) => {
    deleteTopicId({ id: topic.id })
      .unwrap()
      .then((res) => {
        if (res.message === 'DELETED') {
          setSelectedTopic({
            ...selectedTopic,
            title: '',
            edit: false,
            id: null,
            start_date: null,
            start_time: null,
            end_time: null,
          });
        }
      });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!event.over) return;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const cleanUp = () => {
    setLoading(false);
    setSort(false);
    setItems([]);
  };
  const sortTopics = () => {
    if (subject?.topics === items) {
      setLoading(false);
      setSort(false);
      setItems([]);
    } else {
      const updatedTopics = items?.map((singleItem, index) => {
        const found = subject?.topics?.find(
          (singleTopic, topicIndex) => topicIndex === index
        );

        if (found) {
          return {
            ...singleItem,
            id: found.id,
            video_id: singleItem.video?.id || null,
            document_id: singleItem.document?.id || null,
          };
        }
      });

      updatedTopics.forEach((singleUpdatedTopic) => {
        updateTopicOnSort(singleUpdatedTopic)
          .unwrap()
          .then((res) => {
            if (res.message === 'topic updated successfully') {
              console.log('success');
            } else {
              enqueueSnackbar('Error sorting', {
                variant: 'error',
              });
            }
          });
      });
      dispatch(BaseApi.util.invalidateTags(['Course']));
      setTimeout(cleanUp, 3000);
    }
  };

  const handleStartDateChange = (newValue) => {
    setSelectedTopic({
      ...selectedTopic,

      start_date: newValue,
    });
  };
  const handleStartTimeChange = (newValue) => {
    setSelectedTopic({
      ...selectedTopic,

      start_time: newValue,
    });
  };
  const handleEndTimeChange = (newValue) => {
    setSelectedTopic({
      ...selectedTopic,

      end_time: newValue,
    });
  };

  return (
    <>
      {subject?.topics?.length > 1 ? (
        !sort ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setItems(subject?.topics);
              setSort(true);
            }}
          >
            Sort Lectures
          </Button>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Alert severity="info">
              Drag the lectures to sort and click done when you finish.
            </Alert>

            {loading ? (
              <Box sx={{ zIndex: 1300 }}>
                <CircularProgress />{' '}
              </Box>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setLoading(true);
                  setTimeout(sortTopics, 3000);
                }}
              >
                Done
              </Button>
            )}
          </Box>
        )
      ) : null}
      {sort ? (
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
        >
          <SortableContext items={items}>
            {items?.map((singleTopic, index) => (
              <SortableTopic
                key={singleTopic.id}
                id={singleTopic.id}
                title={singleTopic.title}
                displayIndex={index}
              />
            ))}
          </SortableContext>
        </DndContext>
      ) : subject?.topics?.length > 0 ? (
        subject.topics.map((singleTopic, topicIndex) => (
          <div
            key={singleTopic.id}
            style={{
              border: '1px solid #cacbcf',
              background: '#fff',
              borderRadius: '6px',
              padding: '1rem',
              marginTop: '1rem',
            }}
          >
            <Box style={{ display: 'flex' }} component="form">
              <TextField
                defaultValue={singleTopic.title}
                autoFocus
                required
                fullWidth
                size="small"
                placeholder="What is Herbivorus animal"
                label={`Lecture ${topicIndex + 1}`}
                disabled={
                  singleTopic.id !== selectedTopic.id ||
                  addContent === 'disable' ||
                  addContent === true
                }
                onChange={(e) => handleTopicTitleChange(e)}
                InputProps={{
                  endAdornment:
                    singleTopic.id === selectedTopic.id &&
                    selectedTopic.edit ? null : (
                      <InputAdornment position="end">
                        <Icon color="success">
                          <CheckCircleIcon twoToneColor="#52c41a" />
                        </Icon>{' '}
                      </InputAdornment>
                    ),
                }}
              />
              {addContent === 'disable' || addContent === true ? null : (
                <div className="font-icon-wrapper">
                  <IconButton onClick={() => deleteTopicWithId(singleTopic)}>
                    <DeleteOutline />
                  </IconButton>
                </div>
              )}
              <div className="font-icon-wrapper">
                {!addContent ? (
                  singleTopic.id === selectedTopic.id && selectedTopic.edit ? (
                    <IconButton onClick={() => handleUpdateSubmit()}>
                      <SaveOutlined />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEditButton(singleTopic)}>
                      <EditNote />
                    </IconButton>
                  )
                ) : null}
              </div>
              {singleTopic?.id === selectedTopic?.id && addContent ? (
                <Button
                  sx={{ width: '200px' }}
                  onClick={() => handleMinimizeButton()}
                  disabled={addContent === 'disable'}
                >
                  Minimize
                </Button>
              ) : (
                <Button
                  sx={{ width: '200px' }}
                  onClick={() => handleAddContentButton(singleTopic)}
                  disabled={
                    selectedTopic.id ? singleTopic?.id !== selectedTopic?.id : null
                  }
                >
                  Add Date And Time
                </Button>
              )}
            </Box>

            {singleTopic?.id === selectedTopic?.id && addContent ? (
              <div style={{ marginTop: '0.5rem' }}>
                <Tabs
                  value={value}
                  onChange={(e, newval) => handleChange(e, newval)}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="Account forms"
                >
                  <Tab
                    label="Set This Lecture Live Date And Time"
                    id="simple-tab-0"
                    aria-controls="simple-tabpanel-0"
                  />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Grid item xl="6" lg="6" md="6" sm="12" xs="12">
                    <Box sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={MomentUtils}>
                        <DatePicker
                          //   defaultValue={yesterday}
                          disablePast
                          //   views={['year', 'month', 'day']}
                          label="Start Date"
                          onChange={handleStartDateChange}
                          value={selectedTopic.start_date}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={MomentUtils}>
                        <TimePicker
                          //   defaultValue={todayStartOfTheDay}
                          disablePast
                          label="Start Time"
                          value={selectedTopic.start_time}
                          onChange={handleStartTimeChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={MomentUtils}>
                        <TimePicker
                          //   defaultValue={todayStartOfTheDay}
                          disablePast
                          label="End Time"
                          value={selectedTopic.end_time}
                          onChange={handleEndTimeChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => onDateAndTimeSubmit(selectedTopic)}
                    >
                      Submit
                    </Button>
                    {dateStatus === 'success' ? (
                      <Alert severity="success">
                        Date And Time Updated Successfully
                      </Alert>
                    ) : null}
                    {dateStatus === 'error' ? (
                      <Alert severity="error">Error Updating Date And Time</Alert>
                    ) : null}
                  </Grid>
                </TabPanel>
              </div>
            ) : null}
          </div>
        ))
      ) : null}
      {addLecture ? (
        <Box
          component="form"
          onSubmit={handleTopicSubmit}
          noValidate
          sx={{
            mt: 1,
            display: 'flex',
            border: '1px solid #cacbcf',
            background: '#fafafa',
            borderRadius: '6px',
            padding: '1rem',
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label={`Lecture`}
            name="title"
            autoComplete="title"
            autoFocus
          />
          <div className="font-icon-wrapper" style={{ padding: 'inherit' }}>
            <IconButton onClick={() => setAddLecture(false)}>
              <DeleteOutline />
            </IconButton>
          </div>
          <Button
            type="submit"
            sx={{ padding: '0px 8px' }}
            variant="outlined"
            color="secondary"
          >
            Create Lecture
          </Button>
        </Box>
      ) : !addContent && !sort ? (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => setAddLecture(true)}
          >
            Add Lecture
          </Button>
        </Box>
      ) : sort ? null : (
        <Alert severity="info">
          Please minimize any open lecture of a chapter to add a new lecture.
        </Alert>
      )}
    </>
  );
}

export default EditLiveCourseTopic;
