/* eslint-disable no-unused-vars */
import {
  TextField,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router';
import {
  useCalculateQuestionsMutation,
  useCreateNewPracticeTestMutation,
} from '../../../app/services/StudentTestsApi';

function CreateNewPracticeTest(props) {
  const { test } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [style, setStyle] = React.useState('');
  const [nuberOfQuestions, setNuberOfQuestions] = React.useState(null);
  const [topicIds, setTopicIds] = React.useState([]);
  const [severity, setSeverity] = React.useState({
    low: false,
    medium: false,
    high: false,
  });
  const [getNumberOfQuestions] = useCalculateQuestionsMutation();
  const [newPracticeTest, result] = useCreateNewPracticeTestMutation();

  const handleStyleChange = (event) => {
    setStyle(event.target.value);
    setNuberOfQuestions(null);
  };
  const handleSeverityChange = (event) => {
    setSeverity({
      ...severity,
      [event.target.name]: event.target.checked,
    });
    setNuberOfQuestions(null);
  };

  const handleTestTopicChange = (event) => {
    if (!event.target.checked) {
      const index = topicIds.indexOf(event.target.id);
      if (index > -1) {
        topicIds.splice(index, 1);
      }
    } else {
      setTopicIds([...topicIds, event.target.id]);
    }
    setNuberOfQuestions(null);
  };

  const validateData = (testData) => {
    let err = true;

    if (!testData.title) {
      enqueueSnackbar('Please provide title', {
        variant: 'error',
      });
    } else if (
      !(
        testData.no_of_questions >= 1 && testData.no_of_questions <= nuberOfQuestions
      )
    ) {
      enqueueSnackbar('Please provide valid number of questions', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const testData = {
      title: data.get('title'),
      no_of_questions: data.get('noOfQuestions'),
      test_id: test.id,
      test_subject_topic_ids: topicIds,
      severity: severity,
      style: style,
    };

    const res = validateData(testData);
    if (!res) {
      newPracticeTest(testData);
    }
  };

  const calculateQuestions = () => {
    const calculateQuestionsData = {
      test_id: test.id,
      test_subject_topic_ids: topicIds,
      severity: severity,
      style: style,
    };
    getNumberOfQuestions(calculateQuestionsData).then((res) =>
      setNuberOfQuestions(res.data.nuberOfQuestions)
    );
  };

  return result.data ? (
    <>
      {result.data.message}
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => navigate(`/practice/tests/${result.data.practiceTest.id}`)}
      >
        Click Here to take your practice Test
      </Button>
    </>
  ) : (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="title"
        label=" Title"
        name="title"
        autoComplete="title"
        autoFocus
      />
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Question Type</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={style}
            onChange={handleStyleChange}
          >
            <FormControlLabel value="timed" control={<Radio />} label="Timed" />
            <FormControlLabel value="untimed" control={<Radio />} label="Untimed" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
          <FormLabel component="legend">Question Severity</FormLabel>
          <FormGroup aria-label="position">
            <FormControlLabel
              control={
                <Checkbox
                  checked={severity.low}
                  onChange={handleSeverityChange}
                  name="low"
                />
              }
              label="Low"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={severity.medium}
                  onChange={handleSeverityChange}
                  name="medium"
                />
              }
              label="Medium"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={severity.high}
                  onChange={handleSeverityChange}
                  name="high"
                />
              }
              label="High"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      {test.test_subjects.length >= 1 ? (
        <>
          <Typography variant="subtitle1">
            <strong>Select Questions from test subjects and topics below</strong>
          </Typography>

          {test.test_subjects.map((singleTestSubject) => (
            <Grid key={singleTestSubject.id}>
              <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                <FormLabel component="legend">{singleTestSubject.title}</FormLabel>
                <FormGroup aria-label="position">
                  {Array.isArray(singleTestSubject.test_topics)
                    ? singleTestSubject.test_topics.map((singleTestTopic) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              ckecked={topicIds[singleTestTopic.id]}
                              onChange={handleTestTopicChange}
                              id={singleTestTopic.id}
                            />
                          }
                          label={singleTestTopic.title}
                          key={singleTestTopic.id}
                          sx={{ pl: 4 }}
                        />
                      ))
                    : null}
                </FormGroup>
              </FormControl>
            </Grid>
          ))}
        </>
      ) : null}

      <Divider />
      {test.test_subjects.length &&
      topicIds.length &&
      style &&
      (severity.low || severity.high || severity.medium) ? (
        <Button color="secondary" variant="contained" onClick={calculateQuestions}>
          Calculate Number of Questions
        </Button>
      ) : (
        <Typography>
          Please select question type, severity and test subject topics above to
          choose specific questions
        </Typography>
      )}
      <Divider />

      {nuberOfQuestions >= 1 ? (
        <>
          <Typography>
            Total Number Of Questions Available : {nuberOfQuestions}
          </Typography>

          <TextField
            id="noOfQuestions"
            name="noOfQuestions"
            required
            label="Add Number Of Questions"
            sx={{ mt: 3, mb: 2 }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </>
      ) : nuberOfQuestions === 0 ? (
        <Typography>
          There are no questions available please select other topics{' '}
        </Typography>
      ) : null}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={
          !test.test_subjects.length ||
          !topicIds.length ||
          !style ||
          !nuberOfQuestions ||
          !(severity.low || severity.high || severity.medium)
        }
      >
        Create Practice Test
      </Button>
    </Box>
  );
}

export default CreateNewPracticeTest;
