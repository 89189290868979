import { BaseApi } from './BaseApi';

export const adminUsersApi = BaseApi.injectEndpoints({
  reducerPath: 'adminUsersApi',
  endpoints: (builder) => ({
    students: builder.query({
      query: (token) => ({
        url: '/students',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['User'],
    }),
    educators: builder.query({
      query: (token) => ({
        url: '/educators',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['User'],
    }),
    institutions: builder.query({
      query: (token) => ({
        url: '/institutions',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['User'],
    }),
    updateUserBanStatus: builder.mutation({
      query: (details) => ({
        url: '/user/ban/create',
        method: 'POST',

        body: {
          user_id: details.id,
        },
      }),
      invalidatesTags: ['User'],
    }),
    updateUserUnBanStatus: builder.mutation({
      query: (details) => ({
        url: '/user/ban/delete',
        method: 'POST',

        body: {
          user_id: details.id,
        },
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useStudentsQuery,
  useEducatorsQuery,
  useInstitutionsQuery,
  useUpdateUserBanStatusMutation,
  useUpdateUserUnBanStatusMutation,
} = adminUsersApi;
