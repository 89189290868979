/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  Rating,
  CardActionArea,
  styled,
  Tooltip,
  tooltipClasses,
  CardMedia,
  Card,
  CardContent,
  IconButton,
  Alert,
  AlertTitle,
  Link,
} from '@mui/material';
import moment from 'moment';
import EnrolledCourseTabPanel from '../../courses/student/EnrolledCourseTabPanel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEducatorChannelQuery } from '../../../app/services/EducatorApi';
import { useSelector } from 'react-redux';
import { usePublishedEducatorSubscriptionPlansQuery } from '../../../app/services/SubscriptionPlansApi';
import { useCourseEnrolMutation } from '../../../app/services/StudentCoursesApi';
import DataLoader from '../../../components/DataLoader';
const StyledCardActionArea = styled(CardActionArea)(
  () => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`
);
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
export const EducatorChannel = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const EducatorID = props.educatorID;
  const { isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading } = useEducatorChannelQuery({
    id: parseInt(params.Id || EducatorID, 10),
  });
  const [value, setValue] = useState(0);
  const queryRes = usePublishedEducatorSubscriptionPlansQuery();

  const [courseEnrol, result] = useCourseEnrolMutation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  // const enrolCourse = async () => {
  //   await courseEnrol({ course_id: data.course.id });
  // };
  const enrolSubCourse = async (singleCourse) => {
    await courseEnrol({ course_id: singleCourse.id });
  };

  const onCourseClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/course/${selected.id}`
    );
  };
  // const displayEducatorCourseEnrols = (courses) => {
  //   let enrols = 0;
  //   if (courses.length >= 1) {
  //     courses.forEach((singleCourse) => {
  //       enrols += Number(singleCourse.course_enrols_count);
  //     });
  //     return enrols;
  //   } else {
  //     return enrols;
  //   }
  // };
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  // const onFollowClick = (id) => {
  //   const res = followUser({ id });
  // };

  const displayEnrollOptionsSubCards = (singleCourse) => {
    if (!singleCourse.cost) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            isSignedIn
              ? enrolSubCourse(singleCourse)
              : navigate('/student-account', {
                  state: location.pathname,
                });
          }}
          sx={{
            fontSize: '8px',
            padding: '0.1rem',
            height: '20px',
            background: '#3ec6ff',
          }}
        >
          Enroll
        </Button>
      );
    }
    if (singleCourse.cost) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            isSignedIn
              ? onCourseClick(singleCourse)
              : navigate('/student-account', {
                  state: location.pathname,
                });
          }}
          sx={{
            fontSize: '8px',
            padding: '0.1rem',
            height: '20px',
            background: '#3ec6ff',
          }}
        >
          Enroll
        </Button>
      );
    }

    if (singleCourse.cost && queryRes.isSuccess && queryRes.data.subscriptionPlans) {
      return (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              isSignedIn
                ? enrolSubCourse(singleCourse)
                : navigate('/student-account', {
                    state: location.pathname,
                  });
            }}
            sx={{
              fontSize: '8px',
              padding: '0.1rem',
              height: '20px',
              background: '#3ec6ff',
            }}
          >
            Buy This Course
          </Button>

          <Alert severity="info">
            <>
              <AlertTitle>
                Or subscribe and access {`${process.env.REACT_APP_NAME}`}&apos;s top
                Courses{' '}
                <Link color="inherit" to="/subscription-plans">
                  Learn more
                </Link>
              </AlertTitle>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() =>
                  navigate(`/enrols/course/${result.error.data.enrol.id}`)
                }
              >
                Start Subscription
              </Button>
            </>
          </Alert>
        </Box>
      );
    }

    if (
      singleCourse.cost &&
      queryRes.isSuccess &&
      !queryRes.data.subscriptionPlans
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            isSignedIn
              ? enrolSubCourse(singleCourse)
              : navigate('/student-account', {
                  state: location.pathname,
                });
          }}
          sx={{
            fontSize: '8px',
            padding: '0.1rem',
            height: '20px',
            background: '#3ec6ff',
          }}
        >
          Buy This Course
        </Button>
      );
    }
  };

  return (
    <>
      {data && data.educator ? (
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={props.xsValue ? props.xsValue : '11'}
            sm={props.smValue ? props.smValue : '11'}
            md={props.mdValue ? props.mdValue : '10'}
            lg={props.lgValue ? props.lgValue : '10'}
            xl={props.xlValue ? props.xlValue : '10'}
            sx={{ marginTop: '0.5rem' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                alignItems: 'flex-start',
              }}
            >
              {!data.educator.educator_info?.image_url ? (
                <IconButton variant="contained">
                  <img
                    src="https://cdn4.vectorstock.com/i/1000x1000/95/28/cartoon-male-teacher-vector-16659528.jpg"
                    style={{ width: '100%', maxHeight: '100px' }}
                  />
                </IconButton>
              ) : (
                <>
                  <IconButton variant="contained">
                    <Avatar
                      variant="square"
                      sx={{
                        height: '150px',
                        width: '150px',
                        border: '1px solid',
                      }}
                      alt={data.educator.name.charAt(0).toUpperCase()}
                      src={
                        data.educator.educator_info?.image_url
                          ? data.educator.educator_info.image_url
                          : null
                      }
                    ></Avatar>
                  </IconButton>
                </>
              )}
              <Box sx={{ marginTop: '0.2rem' }}>
                <Typography
                  sx={{ fontSize: '18px', fontWeight: 600, color: '#ee3163' }}
                >
                  {data.educator.educator_info?.first_name
                    ? data.educator.educator_info.first_name
                    : data.educator.name}{' '}
                  {`${
                    data.educator.educator_info?.last_name
                      ? data.educator.educator_info.last_name
                      : ''
                  }`}
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {data?.educator?.educator_info?.headline
                    ? `${data?.educator.educator_info?.headline} | `
                    : ''}
                  {data?.educator?.educator_info?.subject
                    ? `${data?.educator.educator_info?.subject}`
                    : ''}
                  {data?.educator?.educator_info?.qualification
                    ? ` | ${data?.educator.educator_info?.qualification}`
                    : ''}{' '}
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                  {data?.educator?.studentFollowers_count || 0}&nbsp;Followers
                </Typography>
                {/* <Button
                  variant="contained"
                  sx={{ width: '100%', marginTop: '0.5rem', borderRadius: '20px' }}
                  disabled={data.educator.alreadyFollowed || !isSignedIn}
                  onClick={() => onFollowClick(data.educator.id)}
                >
                  {data.educator.alreadyFollowed ? 'Following' : 'Follow'}
                </Button> */}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={props.xsValue ? props.xsValue : '11'}
            sm={props.smValue ? props.smValue : '11'}
            md={props.mdValue ? props.mdValue : '10'}
            lg={props.lgValue ? props.lgValue : '10'}
            xl={props.xlValue ? props.xlValue : '10'}
          >
            <Box>
              <Tabs
                value={value}
                onChange={(e, newval) => handleChange(e, newval)}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                variant={window.screen.width < 600 ? `scrollable` : `standard`}
              >
                <Tab
                  id="one"
                  label="My Courses"
                  {...a11yProps(0)}
                  sx={{
                    justifyContent: 'flex-start',
                    fontSize: 14,
                    alignItems: 'flex-start',
                    textAlign: 'start',
                    textTransform: 'capitalize',
                  }}
                ></Tab>
                <Tab
                  id="one"
                  label="About Teacher"
                  {...a11yProps(0)}
                  sx={{
                    justifyContent: 'flex-start',
                    fontSize: 14,
                    alignItems: 'flex-start',
                    textAlign: 'start',
                    textTransform: 'capitalize',
                  }}
                ></Tab>
              </Tabs>

              <EnrolledCourseTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Grid item xl={12}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {data.educator.educator_info?.bio
                        ? data.educator.educator_info.bio
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </EnrolledCourseTabPanel>
              <EnrolledCourseTabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  {Array.isArray(data.educator.courses) &&
                  data.educator.courses.length > 0
                    ? data.educator.courses.map((singleCourse) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={3}
                          xl={3}
                          key={singleCourse.id}
                        >
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  <em>{singleCourse.title}</em>
                                </Typography>
                                <Typography color="inherit">
                                  <em>
                                    {singleCourse.sub_category?.category?.title
                                      ? `${singleCourse.sub_category?.category?.title} |`
                                      : ''}
                                    {singleCourse.sub_category?.title
                                      ? `${singleCourse.sub_category?.title} |`
                                      : ''}
                                    {singleCourse.grade_subject?.title
                                      ? `${singleCourse.grade_subject?.title}`
                                      : ''}
                                  </em>
                                </Typography>
                                <Typography color="inherit">
                                  <em>
                                    {singleCourse.publication?.title
                                      ? `${singleCourse.publication?.title} |`
                                      : ''}
                                    {singleCourse.publication_edition?.edition
                                      ? `${singleCourse.publication_edition?.edition} Edition`
                                      : ''}
                                  </em>
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  By{' '}
                                  {singleCourse.creator.educator_info
                                    ? singleCourse.creator.educator_info
                                        ?.first_name || singleCourse.creator.name
                                    : null}
                                  {singleCourse.creator.educator_info?.last_name
                                    ? ` ${singleCourse.creator.educator_info?.last_name}`
                                    : null}
                                  {!singleCourse.creator.educator_info &&
                                  !singleCourse.institution_info
                                    ? singleCourse.creator.name
                                    : null}
                                </Typography>
                                <Typography
                                  paragraph
                                  variant="body1"
                                  gutterBottom
                                ></Typography>
                              </React.Fragment>
                            }
                            arrow
                            placement="right"
                          >
                            <StyledCardActionArea
                              component="a"
                              onClick={() => onCourseClick(singleCourse)}
                              sx={{
                                padding: '6px',
                                boxShadow: '1px 1px 10px 1px #dcdcdc',
                                borderRadius: '10px',
                                position: 'relative',
                                height: '360px',
                                background: '#fff',
                              }}
                            >
                              <Card sx={{ boxShadow: 'none' }}>
                                <CardMedia
                                  sx={{
                                    height: 130,
                                    boxShadow: 'none',
                                    // border: '1.5px solid #a0a5a7',
                                    borderRadius: '10px 10px 0px 0px',
                                  }}
                                  image={
                                    singleCourse.url
                                      ? singleCourse.url
                                      : `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                                  }
                                  title={singleCourse?.title}
                                />
                                <CardContent
                                  sx={{
                                    flex: 1,
                                    overflowWrap: 'anywhere',
                                    padding: '10px 0px 0px 0px',
                                  }}
                                >
                                  <>
                                    <Typography
                                      color="inherit"
                                      sx={{ lineHeight: '1.2' }}
                                    >
                                      {singleCourse.title
                                        ? ` ${singleCourse.title}`
                                        : null}
                                      {singleCourse.sub_category?.category?.title
                                        ? ` | ${singleCourse.sub_category?.category?.title}`
                                        : ''}
                                      {singleCourse.sub_category?.title
                                        ? ` | ${singleCourse.sub_category?.title}`
                                        : null}
                                      {singleCourse.grade?.title
                                        ? ` | ${singleCourse.grade?.title}`
                                        : null}
                                      {singleCourse.grade_subject?.title
                                        ? ` | ${singleCourse.grade_subject?.title}`
                                        : null}
                                      {singleCourse.publication?.title
                                        ? ` | ${singleCourse.publication?.title}`
                                        : null}
                                      {singleCourse.publication_edition?.edition
                                        ? ` | ${singleCourse.publication_edition?.edition} Edition`
                                        : null}
                                    </Typography>
                                  </>

                                  <Typography
                                    sx={{
                                      padding: '10px 10px 0px 10px',
                                    }}
                                    variant="subtitle2"
                                    color="text.secondary"
                                  >
                                    {singleCourse.creator.educator_info
                                      ? singleCourse.creator.educator_info
                                          ?.first_name || singleCourse.creator.name
                                      : null}
                                    {singleCourse.creator.educator_info?.last_name
                                      ? ` ${singleCourse.creator.educator_info?.last_name}`
                                      : null}
                                    {singleCourse.institution_info
                                      ? singleCourse.institution_info?.name ||
                                        singleCourse.creator.name
                                      : null}{' '}
                                    {!singleCourse.creator.educator_info &&
                                    !singleCourse.institution_info
                                      ? singleCourse.creator.name
                                      : null}{' '}
                                  </Typography>

                                  <Box
                                    sx={{
                                      display: 'flex',
                                      padding: '0px 10px 0px 10px',
                                    }}
                                  >
                                    <span>
                                      <Rating
                                        name="read-only"
                                        value={
                                          singleCourse?.course_reviews_avg_rating
                                        }
                                        readOnly
                                        precision={0.5}
                                      />
                                    </span>
                                    <Typography>
                                      &#40;
                                      {singleCourse?.course_reviews_count
                                        ? singleCourse?.course_reviews_count
                                        : 0}{' '}
                                      &nbsp;reviews&#41;
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      padding: '0px 10px 20px 10px',
                                      justifyContent: 'space-between',
                                      position: 'absolute',
                                      bottom: '0px',
                                      width: '-webkit-fill-available',
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: '12px' }}
                                      >
                                        {`Lessons ${
                                          singleCourse?.subjects?.length
                                            ? singleCourse?.subjects?.length
                                            : 0
                                        }`}{' '}
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: '12px' }}
                                      >
                                        {`Total Videos ${
                                          singleCourse.published_video_count +
                                          singleCourse.published_subject_video_count +
                                          singleCourse.published_subject_video_count +
                                          singleCourse.published_topic_video_count
                                        }`}{' '}
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: '12px' }}
                                      >
                                        {`Duration ${moment
                                          .utc(
                                            (Number(
                                              singleCourse.published_video_sum_duration
                                            ) +
                                              Number(
                                                singleCourse.published_subject_video_sum_duration
                                              ) +
                                              Number(
                                                singleCourse.published_topic_video_sum_duration
                                              )) *
                                              1000
                                          )
                                          .format('HH:mm:ss')} `}
                                      </Typography>
                                    </div>
                                    <div style={{ display: 'grid', gap: '0.5rem' }}>
                                      {displayEnrollOptionsSubCards(singleCourse)}

                                      {singleCourse.start_date &&
                                      moment().isSameOrBefore(
                                        singleCourse.end_date
                                      ) ? (
                                        <Button
                                          variant="contained"
                                          sx={{
                                            fontSize: '8px',
                                            padding: '0.1rem',
                                            height: '20px',
                                            background: '#a80000',
                                          }}
                                        >
                                          Watch Live
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                            fontSize: '8px',
                                            padding: '0.1rem',
                                            height: '20px',
                                            background: '#6a17b2',
                                          }}
                                        >
                                          Watch Later
                                        </Button>
                                      )}
                                    </div>
                                  </Box>
                                </CardContent>
                              </Card>
                            </StyledCardActionArea>
                          </HtmlTooltip>
                        </Grid>
                      ))
                    : ''}
                </Grid>
              </EnrolledCourseTabPanel>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography>Educator Not Found </Typography>
      )}
    </>
  );
};
