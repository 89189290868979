import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import React from 'react';
import { useGradesQuery } from '../../../app/services/CategoriesApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewGrade from './CreateNewGrade';
import GradesList from './GradesList';

function GradesManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useGradesQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Button variant="contained" onClick={handleClickOpen}>
          Create New Grade
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Create New Grade</DialogTitle>
          <DialogContent>
            <CreateNewGrade />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      {isSuccess && data.grades.length ? (
        <GradesList grades={data.grades} />
      ) : (
        <Alert severity="info">Did Not Find Any Grades</Alert>
      )}
    </>
  );
}

export default GradesManager;
