/* eslint-disable react/no-unescaped-entities */

import {
  Avatar,
  Box,
  Button,
  Grid,
  // Tab,
  // Tabs,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Badge,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
// import EnrolledCourseTabPanel from '../../courses/student/EnrolledCourseTabPanel';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useStudentProfileQuery } from '../../../app/services/StudentApi';
import UpdateStudentProfile from './UpdateStudentProfile';
import moment from 'moment';
import UpdateStudentLearningProfile from './UpdateStudentLearningProfile';
import DataLoader from '../../../components/DataLoader';
import UploadStudentProfilePicture from './UploadStudentProfilePicture';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#02b2af' : '#308fe8',
    borderColor: theme.palette.mode === 'light' ? '#02b2af' : '#308fe8',
    border: '0.5px solid 1a90ff',
  },
}));
const StudentProfile = () => {
  const { user, isSignedIn } = useSelector((state) => state.auth);
  // const [value, setValue] = React.useState(0);
  const { data, isLoading, isSuccess } = useStudentProfileQuery();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [updateProfilePic, setUpdateProfilePic] = React.useState(false);

  // const handleChange = (e, newValue) => {
  //   setValue(newValue);
  // };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const profileProgress = (profileData) => {
    let initialProgress = 0;
    if (profileData.profile.first_name) {
      initialProgress += 10;
    }

    if (profileData.profile.date_of_birth) {
      initialProgress += 10;
    }
    if (profileData.profile.city) {
      initialProgress += 10;
    }
    if (profileData.profile.address) {
      initialProgress += 10;
    }
    if (profileData.profile.school) {
      initialProgress += 10;
    }
    if (profileData.profile.gender) {
      initialProgress += 10;
    }
    if (profileData.profile.learning_objective) {
      initialProgress += 10;
    }
    if (profileData.profile.preferred_languages) {
      initialProgress += 10;
    }
    if (profileData.profile.learning_interests) {
      initialProgress += 10;
    }
    if (profileData.profile.hobbies) {
      initialProgress += 10;
    }

    return initialProgress;
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <>
      {isSuccess && data.profile ? (
        <Grid container spacing={2}>
          <Grid item xs="0" sm="0" md="1" lg="1" xl="1"></Grid>
          <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
            <Box>
              <Grid container spacing={2}>
                <Grid item xl="3" lg="3" md="12" sm="12" xs="12">
                  <Box sx={{ border: '0.5px solid', padding: '0.5rem' }}>
                    <div>
                      <div>
                        <Typography
                          component={'h1'}
                          variant="h6"
                          sx={{
                            color: '#397abb',
                            fontWeight: 600,
                            fontSize: '14px',
                          }}
                        >
                          Personal Profile
                        </Typography>
                        <Typography
                          sx={{
                            color: '#397abb',
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        >
                          Registered on&nbsp;
                          {data
                            ? data.user
                              ? moment(data.user.created_at).format(
                                  'Do MMM YY hh:mm A'
                                )
                              : ''
                            : ''}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'grid',
                          justifyItems: 'center',
                          marginTop: '2rem',
                        }}
                      >
                        <Tooltip
                          title={
                            data.profile.url
                              ? 'Change Profile Picture'
                              : 'Upload Profile Picture'
                          }
                          placement="right"
                        >
                          <IconButton
                            variant="contained"
                            onClick={() => setUpdateProfilePic(true)}
                          >
                            <Badge
                              // badgeContent={<PhotoCameraOutlinedIcon />}
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <Avatar
                                sx={{
                                  height: '120px',
                                  width: '120px',
                                  border: '1px solid #000',
                                }}
                                alt={
                                  isSignedIn
                                    ? data.profile.url
                                      ? null
                                      : user.name.charAt(0).toUpperCase()
                                    : null
                                }
                                srcSet={data.profile.url ? data.profile.url : null}
                                src={data.profile.url ? data.profile.url : null}
                              ></Avatar>
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          display: 'grid',
                          justifyItems: 'center',
                          marginTop: '1rem',
                        }}
                      >
                        <Typography
                          component={'h1'}
                          variant="h6"
                          sx={{
                            color: '#397abb',
                            fontWeight: 600,
                            fontSize: '16px',
                            maxWidth: '100%',
                          }}
                          className="wrap-text-ellipsis"
                          title={`${
                            data
                              ? data.profile.first_name
                                ? data.profile.first_name
                                : data.user.name
                              : ''
                          } ${
                            data
                              ? data.profile.last_name
                                ? data.profile.last_name
                                : null
                              : ''
                          }`}
                        >
                          {`${
                            data
                              ? data.profile.first_name
                                ? data.profile.first_name
                                : data.user.name
                              : ''
                          } ${
                            data
                              ? data.profile.last_name
                                ? data.profile.last_name
                                : ''
                              : ''
                          }`}
                        </Typography>
                        {/* <Typography
                          component={'h1'}
                          variant="h6"
                          sx={{
                            color: '#397abb',
                            fontWeight: 500,
                            fontSize: '11px',
                          }}
                        >
                          CLASS 6 | CBSE | English Medium
                        </Typography> */}
                      </div>
                      <div style={{ marginTop: '1rem' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={data ? profileProgress(data) : null}
                              sx={{ border: '0.5px solid #02b2af' }}
                            />
                          </Box>
                          <Box sx={{ minWidth: 48 }}>
                            <Typography variant="body2" color="text.secondary">
                              {data ? `${Math.round(profileProgress(data))}%` : null}
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                      <div style={{ marginTop: '1rem' }}>
                        <Button
                          variant="contained"
                          onClick={handleClickOpen}
                          size="small"
                        >
                          Update Profile
                        </Button>
                        <>
                          <Dialog open={open} onClose={handleClose}>
                            <DialogTitle> Update Profile</DialogTitle>
                            <DialogContent>
                              <UpdateStudentProfile
                                studentProfile={data ? data.profile : null}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </>
                      </div>
                      <div
                        style={{
                          marginTop: '1rem',
                          display: 'grid',
                          gap: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            Student ID
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data ? (data.profile.id ? data.profile.id : '') : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            Gender
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              textTransform: 'capitalize',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.profile.gender
                                ? data.profile.gender
                                : ''
                              : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            DOB
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.profile.date_of_birth
                                ? moment(data.profile.date_of_birth).format(
                                    'Do MMM YY'
                                  )
                                : ''
                              : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            School
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 2,
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              wordBreak: 'break-word',
                            }}
                            title={data?.profile?.school}
                          >
                            {data
                              ? data.profile.school
                                ? data.profile.school
                                : ''
                              : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            State
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.profile.state
                                ? data.profile.state
                                : ''
                              : '#'}
                          </h6>
                        </div>{' '}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            District
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.profile.district
                                ? data.profile.district
                                : ''
                              : '#'}
                          </h6>
                        </div>{' '}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            City
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.profile.city
                                ? data.profile.city
                                : ''
                              : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            Mobile
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.user.phone_number
                                ? data.user.phone_number
                                : ''
                              : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            Email
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data ? (data.user.email ? data.user.email : '') : '#'}
                          </h6>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#d8d8d8',
                            padding: '0.5rem',
                            height: 'auto',
                            alignItems: 'center',
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            Address
                          </h6>
                          <h6
                            style={{
                              fontSize: '13px',
                              fontWeight: '600',
                              margin: '0rem',
                            }}
                          >
                            {data
                              ? data.profile.address
                                ? data.profile.address
                                : ''
                              : '#'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid item xl="6" lg="6" md="12" sm="12" xs="12 ">
                  <Box sx={{ border: '0.5px solid', padding: '0.5rem' }}>
                    <Typography
                      sx={{
                        color: '#397abb',
                        fontWeight: 600,
                        fontSize: '18px',
                      }}
                    >
                      Your "Learning Objective"
                    </Typography>

                    {data.profile.learning_objective_id ? (
                      <Box>
                        <Typography
                          sx={{
                            color: '#397abb',
                            fontWeight: 600,
                            fontSize: '15px',
                          }}
                        >
                          {data.profile.learning_objective.title}
                        </Typography>
                        <Grid container sx={{ padding: '0.5rem 1rem 0rem 1rem' }}>
                          {data.profile.learning_objective.objectives.length
                            ? data.profile.learning_objective.objectives.map(
                                (singleObjective) => (
                                  <Grid
                                    item
                                    xl="4"
                                    lg="4"
                                    md="6"
                                    sm="6"
                                    xs="6"
                                    key={singleObjective.title}
                                  >
                                    <Typography
                                      sx={{
                                        color: '#397abb',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                      }}
                                    >
                                      {singleObjective.title}
                                    </Typography>
                                  </Grid>
                                )
                              )
                            : null}
                        </Grid>
                      </Box>
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      border: '0.5px solid',
                      padding: '0.5rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#397abb',
                        fontWeight: 400,
                        fontSize: '18px',
                      }}
                    >
                      Preferred Languages of Learning
                    </Typography>
                    <Box>
                      <Grid container sx={{ padding: '0.5rem 1rem 0rem 1rem' }}>
                        {data.profile.preferred_languages
                          ? data.profile.preferred_languages.map(
                              (singleLanguage) => (
                                <Grid
                                  item
                                  xl="2"
                                  lg="2"
                                  md="3"
                                  sm="4"
                                  xs="4"
                                  key={singleLanguage}
                                >
                                  <Typography
                                    sx={{
                                      color: '#397abb',
                                      fontWeight: 500,
                                      fontSize: '13px',
                                    }}
                                  >
                                    {singleLanguage}
                                  </Typography>
                                </Grid>
                              )
                            )
                          : null}
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: '0.5px solid',
                      padding: '0.5rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#397abb',
                        fontWeight: 400,
                        fontSize: '18px',
                      }}
                    >
                      What You Want To Learn
                    </Typography>
                    <Box>
                      <Grid container sx={{ padding: '0.5rem 1rem 0rem 1rem' }}>
                        {data.profile.learning_interests
                          ? data.profile.learning_interests.map((singleInterest) => (
                              <Grid
                                item
                                xl="2"
                                lg="2"
                                md="3"
                                sm="4"
                                xs="4"
                                key={singleInterest}
                              >
                                <Typography
                                  sx={{
                                    color: '#397abb',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                  }}
                                >
                                  {singleInterest}
                                </Typography>
                              </Grid>
                            ))
                          : null}
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: '0.5px solid',
                      padding: '0.5rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#397abb',
                        fontWeight: 400,
                        fontSize: '18px',
                      }}
                    >
                      Add your Hobbies and Passion
                    </Typography>
                    <Box>
                      <Grid container sx={{ padding: '0.5rem 1rem 0rem 1rem' }}>
                        {data.profile.hobbies
                          ? data.profile.hobbies.map((singleHobby) => (
                              <Grid
                                item
                                xl="2"
                                lg="2"
                                md="3"
                                sm="4"
                                xs="4"
                                key={singleHobby}
                              >
                                <Typography
                                  sx={{
                                    color: '#397abb',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                  }}
                                >
                                  {singleHobby}
                                </Typography>
                              </Grid>
                            ))
                          : null}
                      </Grid>
                    </Box>
                  </Box>
                  <>
                    <Box
                      sx={{
                        border: '0.5px solid',
                        padding: '0.5rem',
                        marginTop: '1rem',
                      }}
                    >
                      {' '}
                      <Button variant="outlined" onClick={handleClickOpen1}>
                        Update Learning Profile
                      </Button>
                      <Dialog
                        open={open1}
                        onClose={handleClose1}
                        fullWidth
                        maxWidth="md"
                      >
                        <DialogTitle>Update Learning Profile </DialogTitle>
                        <DialogContent>
                          <UpdateStudentLearningProfile
                            studentId={data.profile.id}
                            profile={data.profile}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose1}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs="0" sm="0" md="1" lg="1" xl="1"></Grid>
          <Dialog
            open={updateProfilePic}
            onClose={() => {
              setUpdateProfilePic(false);
            }}
          >
            <DialogTitle>
              {data.profile.url
                ? 'Change Profile Picture'
                : 'Upload Profile Picture'}
            </DialogTitle>
            <DialogContent>
              <UploadStudentProfilePicture
                imgUrl={data.profile.url}
                studentId={data.profile.id}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setUpdateProfilePic(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      ) : (
        <Typography>No Student Profile</Typography>
      )}
    </>
  );
};

export default StudentProfile;
