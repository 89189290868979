import { Avatar, Grid, Typography } from '@mui/material';
import { useFollowerStudentsQuery } from '../../app/services/UserApi';
import DataLoader from '../../components/DataLoader';

function StudentFollower() {
  const { data, isLoading, isSuccess } = useFollowerStudentsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <Grid container spacing={2}>
      {isSuccess && data.followers.length >= 1 ? (
        data.followers.map((singleFollower) => (
          <Grid item xl="2" lg="2" md="3" sm="6" xs="12" key={singleFollower.id}>
            <Grid container sx={{ background: '#fff' }}>
              <>
                <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                  <Avatar
                    variant="square"
                    sx={{
                      height: '150px',
                      width: '100%',
                      objectFit: 'fit',
                    }}
                    className="avatar-img-object-fit"
                    alt={
                      singleFollower.follower_student?.student_info?.first_name ||
                      singleFollower.follower_student?.name
                    }
                    src={
                      singleFollower.follower_student?.student_info?.url
                        ? singleFollower.follower_student?.student_info?.url
                        : 'https://cdn4.vectorstock.com/i/1000x1000/95/28/cartoon-male-teacher-vector-16659528.jpg'
                    }
                  ></Avatar>
                </Grid>
                <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
                  <div
                    style={{
                      background: '#fff',
                      width: '100%',
                      boxShadow: '0px 2px 6px 8px #eeeeee',
                      marginTop: '0.5rem',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 700,
                        color: '#1b75bb',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textAlign: 'center',
                      }}
                      title={`
                      ${
                        singleFollower.follower_student?.student_info?.first_name
                          ? singleFollower.follower_student?.student_info?.first_name
                          : singleFollower.follower_student?.name
                      } ${
                        singleFollower.follower_student?.student_info?.last_name
                          ? ` ${singleFollower.follower_student?.student_info?.last_name}`
                          : null
                      }`}
                    >
                      {singleFollower.follower_student?.student_info?.first_name ||
                        singleFollower.follower_student?.name}
                      {singleFollower.follower_student?.student_info?.last_name
                        ? ` ${singleFollower.follower_student?.student_info?.last_name}`
                        : null}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#1b75bb',
                        textTransform: 'capitalize',
                      }}
                    >
                      {singleFollower.follower_student?.student_info?.gender}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#1c1d1f',
                        marginTop: '0.5rem',
                      }}
                    >
                      {singleFollower.follower_student?.student_info?.location}
                    </Typography>
                  </div>
                </Grid>
              </>{' '}
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography>No followers</Typography>
      )}
    </Grid>
  );
}

export default StudentFollower;
