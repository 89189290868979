import { Button, Box, TextField } from '@mui/material';
import { useCreateSupportTicketMutation } from '../../../app/services/SupportTicketsApi';
import { PhotoCamera } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { uploadSupportTicketImageRequest } from '../../../app/services/UploadRequests';
import { useSnackbar } from 'notistack';

function CreateNewSupportTicket() {
  const { enqueueSnackbar } = useSnackbar();

  const [createSupportTicket, result] = useCreateSupportTicketMutation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const ticketData = {
      title: data.get('title'),
      description: data.get('description'),
    };

    await createSupportTicket(ticketData)
      .unwrap()
      .then((res) => {
        if (res.supportTicket.id && selectedImage) {
          const UploadData = {
            file: selectedImage,
            token: token,
          };

          uploadSupportTicketImageRequest(UploadData, res.supportTicket.id)
            .then((response) => {
              if (response.url) {
                enqueueSnackbar('Image Uploaded Successfully.', {
                  variant: 'success',
                });
              } else {
                enqueueSnackbar('Error Uploading Image. ', {
                  variant: 'error',
                });
              }
            })
            .catch((err) => {
              console.log(err);

              enqueueSnackbar('Error Uploading Image. ', {
                variant: 'error',
              });
            });
        }
      });
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          required
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows="2"
        />
        <Box>
          <IconButton color="primary" aria-label="upload picture" component="label">
            <input
              hidden
              accept="image/*"
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => setSelectedImage(e.target.files[0])}
            />
            <PhotoCamera />
          </IconButton>
        </Box>
        {imageUrl && (
          <Box mt={2} textAlign="center">
            <div>Image Preview:</div>
            <img src={imageUrl} height="200px" />
          </Box>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Create Ticket
        </Button>
      </Box>
    </>
  );
}

export default CreateNewSupportTicket;
