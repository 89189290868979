import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useEnroledExamsQuery } from '../../../app/services/StudentExamsApi';
import DataLoader from '../../../components/DataLoader';

function EnroledExams() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { data, isLoading } = useEnroledExamsQuery(token);
  const viewExam = (id) => {
    navigate(`/enrols/exam/${id}`);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Enrolled Exams
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Enrolled On</TableCell>

              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.enrols &&
              data.enrols.length &&
              data.enrols.map((singleEnrol, index) => (
                <TableRow
                  key={singleEnrol.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singleEnrol.exam.title}</TableCell>

                  <TableCell>
                    {moment(singleEnrol.created_at).format('Do MMM YY hh:mm A')}
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => viewExam(singleEnrol.id)}>View</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EnroledExams;
