/* eslint-disable no-unused-vars */
import { Box, Button, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useUpdateVideoDurationMutation } from '../../../app/services/CoursesApi';

function ChangeVideoDuration(props) {
  const { video } = props;
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [localVideo, setLocalVideo] = React.useState(video);
  const [updateVideoDuration, result] = useUpdateVideoDurationMutation();

  const validateData = (videoData) => {
    let err = true;

    if (!videoData.duration) {
      enqueueSnackbar('Please provide video duation', {
        variant: 'error',
      });
    } else if (videoData.duration == localVideo.duration) {
      enqueueSnackbar('Duration appears to be same. please check again', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const onChangeDuration = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setLoading(true);
    const updateData = {
      hours: data.get('hours'),
      minutes: data.get('minutes'),
      seconds: data.get('seconds'),
    };

    const hoursInSeconds = Number(updateData.hours) * 60 * 60;
    const minutesInSeconds = Number(updateData.minutes) * 60;
    const duration =
      Number(hoursInSeconds) + Number(minutesInSeconds) + Number(updateData.seconds);

    const dataToupdate = { videoId: localVideo.id, duration: duration };

    const res = validateData(dataToupdate);
    if (!res) {
      updateVideoDuration(dataToupdate);
    } else {
      setLoading(false);
    }
  };

  return result.data ? (
    result.data.message
  ) : (
    <Box
      noValidate
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        m: 'auto',
        width: 'fit-content',
      }}
      onSubmit={onChangeDuration}
    >
      <Typography>
        Current Duration:{' '}
        {`${moment.utc(localVideo.duration * 1000).format('HH:mm:ss')}` ||
          'No Duration Set'}
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <TextField
          margin="normal"
          // required
          fullWidth
          id="hours"
          label="Hours"
          name="hours"
          autoComplete="hours"
          type="number"
          autoFocus
          // value={hours}
        />
        <TextField
          margin="normal"
          // required
          fullWidth
          id="minutes"
          label="Minutes"
          name="minutes"
          autoComplete="minutes"
          type="number"
          autoFocus
          // value={minutes}
        />
        <TextField
          margin="normal"
          // required
          fullWidth
          id="seconds"
          label="Seconds"
          name="seconds"
          autoComplete="seconds"
          type="number"
          autoFocus
          // value={seconds}
        />
      </Box>
      <Button type="submit" variant="contained" sx={{ mt: 1 }} disabled={loading}>
        Change Duration
      </Button>
    </Box>
  );
}
export default ChangeVideoDuration;
