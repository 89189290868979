/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Hls from 'hls.js';
import Plyr from 'plyr';

export const LiveHls = (props) => {
  const { vidSrc } = props;

  function updateQuality(newQuality) {
    if (newQuality === 0) {
      window.hls.currentLevel = -1; //Enable AUTO quality if option.value = 0
    } else {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    }
  }

  React.useEffect(() => {
    if (Hls.isSupported) {
      const video = document.getElementById('video');
      const videoSrc = vidSrc;
      const defaultOptions = {};
      const hls = new Hls();
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        const allAvailableQualities = hls.levels.map((level) => level.height);
        defaultOptions.controls = [
          'play-large',
          'restart',
          'rewind',
          'play',
          'fast-forward',
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'captions',
          'settings',
          'pip',
          'fullscreen',
        ];
        const firstLevel = 0;

        defaultOptions.quality = {
          default: allAvailableQualities[0],
          options: [firstLevel].concat(allAvailableQualities),
          forced: true,

          onChange: (e) => updateQuality(e),
        };
        defaultOptions.i18n = {
          qualityLabel: {
            0: 'Auto',
          },
          enterFullscreen: 'Enter fullscreen',
          exitFullscreen: 'Exit fullscreen',
        };

        const player = new Plyr(video, defaultOptions);
        window.Plyr = player;

        // window.Plyr.on('loadeddata', (event) => {
        //   const instance = event.detail.plyr;

        //   instance.muted = true;
        //   // instance.stop();
        // });
      });

      hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
        var span = document.querySelector(
          ".plyr__menu__container [data-plyr='quality'][value='0'] span"
        );
        if (hls.autoLevelEnabled) {
          span.innerHTML = `AUTO`;
          // span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
        } else {
          span.innerHTML = `AUTO`;
        }
      });

      hls.loadSource(videoSrc);

      hls.attachMedia(video);
      window.hls = hls;
    }
  }, [vidSrc]);

  React.useEffect(() => {
    const cleanup = () => {
      if (window.Plyr) {
        window.hls.destroy();
        window.Plyr.destroy();
      }
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  return (
    <div>
      <div className="video-row">
        <div className=" video-container">
          <video controls id="video" autoPlay style={{ background: '#222' }}></video>
        </div>
      </div>
    </div>
  );
};

export default LiveHls;
