/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetEnroledTopicQuery } from '../../../app/services/StudentCoursesApi';
import DataLoader from '../../../components/DataLoader';
import DisplayEnroledLiveCourseCurriculum from './DisplayEnroledLiveCourseCurriculum';
import LiveHls from './LiveHls';

const serverUrl = `${process.env.REACT_APP_LIVE_STREAM_SERVER}`;

function DisplayEnroledLiveCourseTopic() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGetEnroledTopicQuery({
    id: parseInt(params.enrolId, 10),
    topicId: parseInt(params.topicId, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.enrol ? (
    <Grid
      container
      spacing={2}
      sx={{
        // bgcolor: 'background.paper',
        overflowWrap: 'anywhere',
      }}
    >
      <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
        <Box sx={{ margin: '20px' }}>
          {moment().isBetween(
            moment(data.topic.start_time, 'HH:mm:ss'),
            moment(data.topic.end_time, 'HH:mm:ss')
          ) ? (
            <>
              <LiveHls vidSrc={`${serverUrl}/${data.topic.stream_key}.m3u8`} />
              <Typography>{data.topic.title}</Typography>
            </>
          ) : (
            <Typography>Course Topic is not Live yet</Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
        <Box sx={{ margin: '20px' }}>
          <Typography>Live Chat</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Course Content
          </Typography>{' '}
          {data.enrol.course.subjects.length ? (
            <DisplayEnroledLiveCourseCurriculum
              subjects={data.enrol.course.subjects}
              enrolId={data.enrol.id}
              //   progressData={data.enrol.video_progress}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <p>No course topic found</p>
  );
}

export default DisplayEnroledLiveCourseTopic;
