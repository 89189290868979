/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { DeleteOutline, EditNote, SaveOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  useCreateTestTopicMutation,
  useDeleteTestTopicMutation,
  useTestSubjectQuery,
  useUpdateTestTopicMutation,
} from '../../../app/services/TestsApi';
import EditTestQuestion from './EditTestQuestion';

function EditTestTopic(props) {
  const { testSubjectId } = props;

  const [createTestTopic] = useCreateTestTopicMutation();
  const [deleteId] = useDeleteTestTopicMutation();
  const [selectedTestTopic, setSelectedTestTopic] = useState({
    title: '',
    edit: false,
    id: null,
    testQuestions: [],
    showQuestions: false,
  });
  const [updateTestTopic] = useUpdateTestTopicMutation();
  const [addTestTopic, setAddTestTopic] = useState(false);

  const { data } = useTestSubjectQuery({
    id: testSubjectId,
  });

  const validateData = (subjectData) => {
    let err = true;

    if (!subjectData.title) {
      err = true;
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const subjectData = {
      test_subject_id: testSubjectId,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(subjectData);
    if (!res) {
      createTestTopic(subjectData)
        .unwrap()
        .then((res) => {
          if (res.testTopic.id) {
            setAddTestTopic(false);
          } else {
            console.log(res);
          }
        });
    }
  };

  const handleUpdateSubmit = () => {
    const subjectData = {
      id: selectedTestTopic.id,
      title: selectedTestTopic.title,
      description: selectedTestTopic.title,
    };

    const res = validateData(subjectData);
    if (!res) {
      updateTestTopic(subjectData)
        .unwrap()
        .then((res) => {
          if (res.message === 'testTopic updated successfully') {
            setSelectedTestTopic({
              ...selectedTestTopic,
              title: '',
              edit: false,
              id: null,
              testQuestions: [],
              showQuestions: false,
            });
          }
        });
    }
  };

  const handleEditButton = (testTopic) => {
    setSelectedTestTopic({
      ...selectedTestTopic,
      title: testTopic.title,
      edit: true,
      id: testTopic.id,
      testQuestions: testTopic.test_questions,
    });
  };

  const handleTestTopicTitleChange = (e) => {
    setSelectedTestTopic({ ...selectedTestTopic, title: e.target.value });
  };

  const deleteTestTopicWithId = (singleTestTopic) => {
    deleteId({ id: singleTestTopic.id })
      .unwrap()
      .then((res) => {
        if (res.message === 'DELETED') {
          setSelectedTestTopic({
            ...selectedTestTopic,
            title: '',
            edit: false,
            id: null,
            testQuestions: [],
          });
        }
      });
  };

  const handleAddContentButton = (topic) => {
    setSelectedTestTopic({
      ...selectedTestTopic,
      title: topic.title,
      edit: false,
      id: topic.id,
      testQuestions: topic.test_questions,
      showQuestions: true,
    });
  };

  const handleMinimizeButton = () => {
    setSelectedTestTopic({
      ...selectedTestTopic,
      title: '',
      edit: false,
      id: null,
      testQuestions: [],
      showQuestions: false,
    });
  };

  return (
    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
      <>
        <Box>
          <Grid container spacing={2}>
            {data?.testSubject?.test_topics?.length > 0
              ? data?.testSubject.test_topics.map(
                  (singleTestTopic, subjectIndex) => (
                    <Grid
                      item
                      xl="12"
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      key={singleTestTopic.id}
                    >
                      {' '}
                      <div
                        style={{
                          border: '1px solid #cacbcf',
                          background: '#fafafa',
                          borderRadius: '6px',
                          padding: '1rem',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <Box style={{ display: 'flex' }} component="form">
                            <TextField
                              required
                              fullWidth
                              size="small"
                              placeholder="What Where How and When"
                              label={`TestTopic ${subjectIndex + 1}`}
                              disabled={
                                singleTestTopic.id !== selectedTestTopic.id ||
                                !selectedTestTopic.edit
                              }
                              defaultValue={singleTestTopic.title}
                              autoFocus
                              onChange={(e) => handleTestTopicTitleChange(e)}
                              InputProps={{
                                endAdornment:
                                  singleTestTopic.id === selectedTestTopic.id &&
                                  selectedTestTopic.edit ? null : (
                                    <InputAdornment position="end">
                                      <Icon color="success">
                                        <CheckCircleIcon twoToneColor="#52c41a" />
                                      </Icon>{' '}
                                    </InputAdornment>
                                  ),
                              }}
                            />
                            <>
                              <div className="font-icon-wrapper">
                                <IconButton
                                  onClick={() =>
                                    deleteTestTopicWithId(singleTestTopic)
                                  }
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </div>
                              <div className="font-icon-wrapper">
                                {singleTestTopic.id === selectedTestTopic.id &&
                                selectedTestTopic.edit ? (
                                  <IconButton onClick={() => handleUpdateSubmit()}>
                                    <SaveOutlined />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => handleEditButton(singleTestTopic)}
                                  >
                                    <EditNote />
                                  </IconButton>
                                )}
                              </div>
                            </>
                          </Box>

                          {singleTestTopic?.id === selectedTestTopic?.id &&
                          selectedTestTopic.showQuestions ? (
                            <Button
                              sx={{ width: '200px' }}
                              onClick={() => handleMinimizeButton()}
                            >
                              Minimize
                            </Button>
                          ) : (
                            <Button
                              sx={{ width: '200px' }}
                              onClick={() => handleAddContentButton(singleTestTopic)}
                            >
                              Show Questions
                            </Button>
                          )}
                        </div>
                        {singleTestTopic?.id === selectedTestTopic?.id &&
                        selectedTestTopic.showQuestions ? (
                          <div style={{ marginTop: '1rem' }}>
                            <EditTestQuestion testTopicId={singleTestTopic.id} />
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  )
                )
              : null}
            <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
              {addTestTopic ? (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{
                    mt: 1,
                    display: 'flex',
                    border: '1px solid #cacbcf',
                    background: '#fafafa',
                    borderRadius: '6px',
                    padding: '1rem',
                  }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label={`TestTopic`}
                    name="title"
                    autoComplete="title"
                    autoFocus
                  />
                  <div className="font-icon-wrapper" style={{ padding: 'inherit' }}>
                    <IconButton onClick={() => setAddTestTopic(false)}>
                      <DeleteOutline />
                    </IconButton>
                  </div>
                  <Button
                    type="submit"
                    sx={{ padding: '0px 8px' }}
                    variant="outlined"
                    color="secondary"
                  >
                    Create Test Topic
                  </Button>
                </Box>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => setAddTestTopic(true)}
                >
                  Add Test Topic
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </Grid>
  );
}

export default EditTestTopic;
